/** @jsxImportSource theme-ui */
import { Label, Link } from "@bottlebooks/gatsby-theme-base/src";
import { graphql } from "~/gql";
// @ts-ignore SVG isn't supported by TS
import previous from "../../assets/arrow-left.svg";
// @ts-ignore SVG isn't supported by TS
import next from "../../assets/arrow-right.svg";
const icons = { next, previous };

function getName({ name, stand }) {
  if (!stand?.name) return name;
  return `${stand.name} – ${name}`;
}

export default function ChangeExhibitorLink({
  exhibitor,
  direction = "next",
  to,
  showName = false,
  ...rest
}) {
  if (!exhibitor) return null;
  return (
    <Link
      to={to}
      title={getName(exhibitor)}
      sx={{ display: "inline-flex", alignItems: "center" }}
      {...rest}
    >
      {showName && direction === "next" && <Label>{exhibitor.name}</Label>}
      <img
        src={icons[direction]}
        alt={exhibitor.name}
        sx={{ padding: 3, boxSizing: "content-box" }}
        width={16}
        height={16}
      />
      {showName && direction === "previous" && <Label>{exhibitor.name}</Label>}
    </Link>
  );
}

export const fragment = graphql(`
  fragment ChangeExhibitorLink on Exhibitor {
    name
    stand {
      name: title
    }
    ...useLink_Exhibitor
  }
`);

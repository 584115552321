/** @jsxImportSource theme-ui */
import { Box, Flex, Label, Text } from "@bottlebooks/gatsby-theme-base/src";
import React from "react";
import BrandLogo from "../Brand/BrandLogo";
import Mark from "../Mark";
import ListRowSmall from "./ListRowSmall";
import { FragmentType, graphql, useFragment } from "~/gql";

const fragment = graphql(/* GraphQL */ `
  fragment ExhibitorListRowSmall on SingleRegistration {
    stand {
      name
    }
    profile {
      ...BrandLogo
      name
      countryName: country
      region
      city
    }
  }
`);

/**
 *
 * @param {object} props
 * @param {{ name?: string?; countryName?: string?; region?: string?; city?: string?; stand?:{ name?: string | null }? }} props.exhibitor;
 * @param {string} [props.searchTerm]
 * @param {string} [props.to]
 * @param {React.MouseEventHandler} [props.onClick]
 * @returns
 */
export default function ExhibitorListRowSmall({
  data,
  searchTerm,
  to,
  onClick,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
  searchTerm: string;
  onClick?: () => void;
  to: string;
}) {
  const registration = useFragment(fragment, data);
  const exhibitor = registration.profile;
  if (!exhibitor) throw new Error("Exhibitor not found");
  const subtitle = [exhibitor.countryName, exhibitor.region, exhibitor.city]
    .filter(Boolean)
    .join(", ");
  return (
    <ListRowSmall to={to} onClick={onClick} {...rest}>
      <Flex gap={2} align="center" sx={{ width: "100%" }}>
        <BrandLogo brand={exhibitor} size="smaller" />
        <Box>
          {searchTerm ? (
            <>
              <Label variant="small" sx={{ color: "lighterText" }}>
                <Mark
                  text={registration.stand?.name}
                  term={searchTerm}
                  render={(match) => (
                    <span sx={{ fontWeight: "bold", color: "text" }}>
                      {match}
                    </span>
                  )}
                />
              </Label>
              <Mark
                text={exhibitor.name}
                term={searchTerm}
                render={(match) => (
                  <span sx={{ fontWeight: "bold", color: "text" }}>
                    {match}
                  </span>
                )}
                Wrapper={LargeText}
                append={(term) => (
                  <Text
                    variant="small"
                    sx={{ fontWeight: "regular", color: "lighterText" }}
                  >
                    <Mark
                      text={subtitle}
                      term={term}
                      render={(match) => (
                        <span sx={{ fontWeight: "bold", color: "text" }}>
                          {match}
                        </span>
                      )}
                    />
                  </Text>
                )}
              />
            </>
          ) : (
            <>
              <Label variant="small" sx={{ color: "lighterText" }}>
                {registration.stand?.name}
              </Label>
              <Text variant="large">{exhibitor.name}</Text>
              <Text variant="small" sx={{ color: "lighterText" }}>
                {subtitle}
              </Text>
            </>
          )}
        </Box>
      </Flex>
    </ListRowSmall>
  );
}

function LargeText(props) {
  return (
    <Text
      variant="large"
      sx={{ fontWeight: "regular", color: "lighterText" }}
      {...props}
    />
  );
}

/** @jsxImportSource theme-ui */

export default function locationHierarchy({ brand }) {
  // US is special because of the states
  if (brand.countryCode === "us")
    return [brand.countryName, brand.stateProvince];
  // This preserves the current functionality for Austria customers who might expect it
  if (brand.countryCode === "at")
    return [brand.countryName, brand.region, brand.city];
  return [brand.countryName, brand.region, brand.city];
}

/** @jsxImportSource theme-ui */
import { FormattedText, Text } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";

const fragment = graphql(`
  fragment WineMaturation on Product {
    __typename
    ... on Wine {
      maturation
      monthsInBottle
      oakInfluence
    }
  }
`);

/**
 * Describes the wine's maturation.
 */
export default function WineMaturation({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const product = useFragment(fragment, data);
  const wine = product.__typename === "Wine" ? product : null;
  if (!wine?.maturation && !wine?.oakInfluence && !wine?.monthsInBottle)
    return null;
  return (
    <Section title={<Trans>Maturation</Trans>} {...rest}>
      <FormattedText variant="large" text={wine.maturation} />
      <MaturationValue title={<Trans>Oak influence</Trans>}>
        {wine.oakInfluence}
      </MaturationValue>
      <MaturationValue title={<Trans>Months in bottle</Trans>}>
        {wine.monthsInBottle}
      </MaturationValue>
    </Section>
  );
}

function MaturationValue({ title, value = undefined, children }) {
  // Only if the raw value is null or empty we have supplied it.
  if (value === null || value === "") return null;
  if (!children) return null;
  return (
    <Text variant="large">
      {title}: {children}
    </Text>
  );
}

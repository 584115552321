/** @jsxImportSource theme-ui */
import { Box, Text } from "@bottlebooks/gatsby-design-system";
import ExhibitorCard from "@bottlebooks/bottlebooks-site-base/src/components/ExhibitorsList/ExhibitorCard.next";
import useEventData from "@bottlebooks/bottlebooks-site-base/src/components/SiteSearch/useEventData";
import useLink from "@bottlebooks/bottlebooks-site-base/src/useLink";
// import { graphql } from "gatsby";

/**
 * @param {object} props
 * @param {string} props.label
 * @param {object} props.registration
 * @param {string} props.registration.companyId
 * @param {string} props.registration.registrationId
 * @param {string} props.registration.referenceName
 * @param {string} props.registration.collectionId
 * @returns
 */
export default function ProfileBlock({ label, registration, ...rest }) {
  const { exhibitors } = useEventData();
  const link = useLink();
  if (!registration) return null;
  const { companyId, referenceName } = registration;
  const exhibitor = exhibitors.find((exhibitor) => {
    return exhibitor.brandId === companyId;
  });
  if (!exhibitor)
    return (
      <Box {...rest}>
        <Text>
          Profile cannot be found: {referenceName} [{companyId}]
        </Text>
      </Box>
    );

  return (
    <ExhibitorCard
      exhibitor={exhibitor}
      to={link.exhibitor(exhibitor)}
      label={label}
    />
  );
}

// export const fragment = graphql`
//   fragment SanityProfileBlockFragment on SanityProfileBlock {
//     label
//     registration {
//       referenceName
//       registrationId
//       companyId
//       collectionId
//     }
//   }
// `;

/** @jsxImportSource theme-ui */
import { Button, ShoppingCartIcon } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";

const fragment = graphql(`
  fragment BrandOnlineShopButton on Profile {
    onlineShop
  }
`);

export default function BrandOnlineShopButton({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const brand = useFragment(fragment, data);
  if (!brand) return false;
  if (!brand.onlineShop) return false;
  return (
    <Button href={brand.onlineShop} openInNewTab variant="primary" {...rest}>
      <ShoppingCartIcon
        title=""
        size="small"
        sx={{ marginRight: 2, color: "currentColor" }}
      />
      <Trans>Go to online shop</Trans>
    </Button>
  );
}
BrandOnlineShopButton.hasContent = hasContent;

function hasContent(data: FragmentType<typeof fragment>) {
  const brand = useFragment(fragment, data);
  if (!brand) return false;
  if (!brand.onlineShop) return false;
  return true;
}

/** @jsxImportSource theme-ui */
import { FormattedText } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";

const fragment = graphql(`
  fragment WineHarvestNotes on Product {
    ... on Wine {
      harvestNotes
    }
  }
`);

export default function WineHarvestNotes({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const product = useFragment(fragment, data);
  const wine = product.__typename === "Wine" ? product : null;
  if (!wine?.harvestNotes) return null;
  return (
    <Section title={<Trans>Harvest Notes</Trans>} {...rest}>
      <FormattedText variant="large" text={wine.harvestNotes} />
    </Section>
  );
}

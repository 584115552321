/** @jsxImportSource theme-ui */
import { SemiTitle, View } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { FragmentType, graphql, useFragment } from "~/gql";
import Scale from "../Scale/Scale";

const fragment = graphql(`
  fragment ProductPalate on Product {
    palateAcidity
    palateBody
    palateTannins
  }
`);
export default function ProductPalate({
  data,
}: {
  data: FragmentType<typeof fragment>;
}) {
  const { i18n } = useLingui();
  const product = useFragment(fragment, data);
  if (!product || !hasContent(data)) return null;
  const { palateAcidity, palateBody, palateTannins } = product;
  return (
    <View>
      <SemiTitle>
        <Trans>Taste</Trans>
      </SemiTitle>

      <Scale
        text={
          <Trans>Acidity: {i18n._(`palateAcidity.${palateAcidity}`)}</Trans>
        }
        value={palateAcidity ? acidityScale[palateAcidity] : null}
        max={3}
        sx={{ maxWidth: 280 }}
      />

      <Scale
        text={<Trans>Body: {i18n._(`palateBody.${palateBody}`)}</Trans>}
        value={palateBody ? bodyScale[palateBody] : null}
        sx={{ maxWidth: 280 }}
      />
      <Scale
        text={
          <Trans>Tannins: {i18n._(`palateTannins.${palateTannins}`)}</Trans>
        }
        value={palateTannins ? tanninScale[palateTannins] : null}
        sx={{ maxWidth: 280 }}
      />
    </View>
  );
}
function hasContent(data: FragmentType<typeof fragment>) {
  const product = useFragment(fragment, data);
  if (product.palateAcidity != null) return true;
  if (product.palateBody != null) return true;
  if (product.palateTannins != null) return true;
  return false;
}

ProductPalate.hasContent = hasContent;

// The scale is used to give each text value a ranking.
const acidityScale: { [key: string]: number } = {
  low: 1,
  medium: 2,
  high: 3,
};
const bodyScale: { [key: string]: number } = {
  light: 1,
  moderate: 2,
  full: 3,
  complex: 4,
};
const tanninScale: { [key: string]: number } = {
  trace: 0,
  mild: 1,
  soft: 2,
  moderate: 3,
  intense: 4,
};

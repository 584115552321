/** @jsxImportSource theme-ui */
import useUser from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUser";
import { Button } from "@bottlebooks/gatsby-theme-base/src";
import React, { useEffect } from "react";
import { useCollectionLayout } from "../../CollectionLayoutProvider.next";
import env from "~/env";

export default function RequestMeetingOnSectorGlobal({
  companyId,
}: {
  companyId: string;
}) {
  const { siteConfig } = useCollectionLayout();
  const sectorGlobalLink = useSectorGlobal();
  if (siteConfig.layout !== "LONDON_WINE_FAIR") return null;
  return (
    <Button
      variant="primary"
      target="_blank"
      href={sectorGlobalLink.getExternalUrl({ companyId })}
    >
      Request meeting
    </Button>
  );
}

/**
 * This hook exposes the core authentication API and context.
 * It should be used wherever authentication status is needed.
 * @returns
 */
function useSectorGlobal() {
  const { siteConfig } = useCollectionLayout();
  const [user] = useUser();
  const [sectorGlobalLink, setSectorGlobalLink] = React.useState<
    string | null
  >();
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    async function getExternalUrl() {
      setLoading(true);
      const uid = user?.uid;
      const token = await user?.getIdToken();
      const response = await fetch(
        `${env.VITE_API_URL}/pub/next/londonwinefair/externalUrl/${token}/${uid}`
      );
      const data = await response.json();
      if (data.externalUrl) setSectorGlobalLink(data.externalUrl);
      // else do nothing
      // We only want to load one time.
      // setLoading(false);
    }
    // This is only active for the London Wine Fair.
    if (siteConfig.layout !== "LONDON_WINE_FAIR") return;
    if (!user) return;
    if (loading) return;
    if (sectorGlobalLink) return;
    getExternalUrl();
  }, [user, sectorGlobalLink, setSectorGlobalLink, loading, setLoading]);
  return {
    getExternalUrl({ companyId }: { companyId: string }) {
      if (!sectorGlobalLink)
        return `https://london-wine-fair.eventware.com/london-wine-fair-2024/companies/find/${companyId} `;
      else
        return `${sectorGlobalLink}&passthrough=/london-wine-fair-2024/companies/find/${companyId}`;
    },
  };
}

/** @jsxImportSource theme-ui */
import { Box, P, Title } from "@bottlebooks/gatsby-theme-base/src";
import emptyBottle from "../../assets/empty-bottle-illustration.svg";
import { Trans } from "@lingui/macro";

export default function EmptyState(props) {
  return (
    <Box {...props}>
      <img src={emptyBottle} width="120" height="120" alt="" />
      <Title variant="largest" sx={{ marginBottom: 3 }}>
        <Trans>Nothing here, all empty.</Trans>
      </Title>
      <P>
        <Trans>
          It looks like what you are searching doesn’t exist here. Try to apply
          less filters or clear all filters.
        </Trans>
      </P>
    </Box>
  );
}

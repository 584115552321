// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCqr0iiICbHvqcVHUSWGXSAYSKqM-3l-VY",
  authDomain: "bottlebooks-directories.firebaseapp.com",
  projectId: "bottlebooks-directories",
  storageBucket: "bottlebooks-directories.appspot.com",
  messagingSenderId: "83654863517",
  appId: "1:83654863517:web:3fc584fff02c44bf018274",
  measurementId: "G-2PK2S15JB5",
};

export default firebaseConfig;

import React from "react";
import { FragmentType, graphql, useFragment } from "~/gql";
import search from "../Filters/search";
import useExhibitorFilters from "./exhibitorFilters.next";

const fragment = graphql(`
  fragment Search_Exhibitor on SingleRegistration {
    profile {
      name
      countryName: country
      region
      city
    }
    stand {
      name
    }
  }
`);

export default function useExhbitorSearch(
  data: FragmentType<typeof fragment>[],
  query: string
) {
  const items = data.flatMap((item) => {
    const registration = useFragment(fragment, item);
    return {
      ...registration,
      ...registration.profile,
    };
  });
  const filterConfig = useExhibitorFilters();
  return React.useMemo(() => {
    if (!query) return items;
    // TODO: not sure how to make this match.
    return search(items, query, filterConfig.search || {});
  }, [items, query, filterConfig]);
}

/**
 * @typedef {{
 *  name: string;
 *  countryName: string | null;
 *  region: string | null;
 *  city: string | null;
 *  stand: { name: string | null };
 * }} Search_Exhibitor
 */

/** @jsxImportSource theme-ui */
import {
  Box,
  DownloadIcon,
  Flex,
  Label,
  Text,
} from "@bottlebooks/gatsby-design-system";
import { BottlebooksLogo, Link } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
// @ts-ignore TS doesn't like direct image imports.
import { useEffect, useState } from "react";
import backToTop from "../../assets/back-to-top.svg";
import { useCollectionLayout } from "../CollectionLayoutProvider.next";
import QRCode from "../QRCode";
import useEventData from "../SiteSearch/useEventData";

export default function StandardFooter({ children, homePath = "", ...rest }) {
  return (
    <Wrapper {...rest} className="bb-footer">
      <Top>{children}</Top>
      <Middle />
      <Bottom />
    </Wrapper>
  );
}

function resolveName({ products, exhibitors, collection }) {
  const tokens = window.location.pathname.split("/");
  // If there are no tokens, return the current URL.
  if (tokens.length < 2) return window.location.href;
  const lastToken = tokens[tokens.length - 1];
  const isProduct = tokens[1] === "products";
  const isExhibitor = tokens[1] === "exhibitors";
  if (isProduct) {
    const product = products.find((p) => p.productId === lastToken);
    if (product) {
      const productName = [
        product.producer.name,
        product.shortName,
        product.vintage,
      ]
        .filter(Boolean)
        .join(" ");
      // It is rare that people are downloading for multiple events at the same time
      // so put collection name last.
      return `${productName} - ${product.exhibitor.name} - ${collection.name}`;
    }
  }
  if (isExhibitor) {
    const exhibitor = exhibitors.find((e) => e.exhibitorId === lastToken);
    if (exhibitor) {
      // It is rare that people are downloading for multiple events at the same time
      // so put collection name last.
      return `${exhibitor.name} - ${collection.name}`;
    }
  }

  // Ok, this should only be either the home page or a CMS page
  return (
    // This should work for CMS pages
    lastToken ||
    // Return domain for homepage.
    window.location.hostname
  );
}

function Middle() {
  const { products, exhibitors } = useEventData();
  const collection = useCollectionLayout();
  const [pageUrl, setPageUrl] = useState("");
  const [qrCodeName, setQrCodeName] = useState("");
  useEffect(() => {
    setPageUrl(window.location.href);
    const name = resolveName({ products, exhibitors, collection });
    setQrCodeName(name);
  }, [window.location.href]);
  if (!pageUrl) return null;
  return (
    <Flex
      sx={{
        paddingX: [2, 5],
        paddingY: [3, 4],
        flexDirection: ["column-reverse", null, "row"],
      }}
      justify="space-between"
      align="center"
    >
      <Flex align="center" sx={{ flexShrink: 0, width: [null, null, "50%"] }}>
        <Flex
          sx={{
            marginRight: 3,
            flexShrink: 0,
            backgroundColor: "white",
            padding: 1,
          }}
        >
          <QRCode url={pageUrl} width={60} margin={0} />
        </Flex>
        <Link
          variant="text"
          onClick={() =>
            QRCode.downloadPng({
              name: qrCodeName,
              url: pageUrl,
              margin: 2,
            })
          }
        >
          <Text>
            <DownloadIcon sx={{ marginRight: 2 }} /> Download QR Code for this
            page
          </Text>
        </Link>
      </Flex>
    </Flex>
  );
}

export function Wrapper({ children, ...rest }) {
  return (
    <Box
      as="footer"
      sx={{
        variant: "styles.Footer",
        paddingY: [2, 4],
        paddingX: [2, 5],
        backgroundColor: "background",
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}

export function Top({ children, ...rest }) {
  function handleScrollToTop(e) {
    window.scrollTo({ top: 0, behavior: "smooth" });
    e.preventDefault();
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        paddingBottom: [2, 4],
        borderBottom: 1,
        borderColor: "border",
      }}
      {...rest}
    >
      {children}
      <Link
        href="#"
        onClick={handleScrollToTop}
        sx={{ marginLeft: "auto", color: "black" }}
      >
        <Label>
          <Trans>Back to the top</Trans>{" "}
          <img
            src={backToTop}
            alt="Back to the top"
            style={{ verticalAlign: "middle" }}
          />
        </Label>
      </Link>
    </Box>
  );
}

export function Bottom({ ...rest }) {
  return (
    <Flex align="baseline" gap={2} sx={{ paddingTop: [2, 4] }} {...rest}>
      <Text variant="smallest" sx={{ color: "lightText" }}>
        <Flex align="center">
          <Trans>Powered by</Trans>{" "}
          <Link
            href="https://www.bottlebooks.me"
            sx={{ textTransform: "uppercase" }}
          >
            <BottlebooksLogo title="Bottlebooks®" />
          </Link>
        </Flex>
        <Text variant="smallest">
          <Trans>Data management for the wine and drinks industry</Trans>
        </Text>
      </Text>

      <Text variant="smallest" sx={{ marginLeft: "auto", color: "lightText" }}>
        2024
      </Text>
    </Flex>
  );
}

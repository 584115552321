/** @jsxImportSource theme-ui */
import { Box, Text } from "@bottlebooks/gatsby-design-system";
import ProductCard from "@bottlebooks/bottlebooks-site-base/src/components/ProductCard/ProductCard.next";
import useEventData from "@bottlebooks/bottlebooks-site-base/src/components/SiteSearch/useEventData";
// import { graphql } from "gatsby";

/**
 * @param {object} props
 * @param {string} props.label
 * @param {object} props.registeredProduct
 * @param {string} props.registeredProduct.productId
 * @param {string} props.registeredProduct.referenceName
 * @param {string} props.registeredProduct.collectionId
 * @returns
 */
export default function ProductBlock({ label, registeredProduct, ...rest }) {
  const { products } = useEventData();
  if (!registeredProduct) return null;
  const { productId, referenceName } = registeredProduct;
  const product = products.find((p) => p.productId === productId);
  if (!product)
    return (
      <Box {...rest}>
        <Text>
          Product cannot be found: {referenceName} [{productId}]
        </Text>
      </Box>
    );

  return <ProductCard product={product} label={label} />;
}

// export const fragment = graphql`
//   fragment SanityProductBlockFragment on SanityProductBlock {
//     label
//     registeredProduct {
//       referenceName
//       productId
//       collectionId
//     }
//   }
// `;

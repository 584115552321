/** @jsxImportSource theme-ui */
import useUser from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUser";
import { Trans } from "@lingui/macro";
import { useEffect, useState } from "react";
import type { ContentLocale } from "../../useLocale";
import type { RequestChatButtonDialogData } from "./RequestChatButton.Dialog.next";
import type { RequestChatSchema } from "./RequestChatButton.Form.next";
import useSubmitChatRequest from "./RequestChatButton.useSubmit";

export type FormActions = {
  onNext: (values: RequestChatSchema) => void;
};

export type ErrorActions = {
  message: string | JSX.Element;
  title?: string | JSX.Element;
  onRetry?: () => void;
};

type StateProps = {
  collectionId: string;
  locale: ContentLocale;
  profile: RequestChatButtonDialogData;
  renderLoading: () => JSX.Element | null;
  renderLogin: () => JSX.Element | null;
  renderForm: (props: FormActions) => JSX.Element | null;
  renderSuccess: () => JSX.Element | null;
  renderError: (props: ErrorActions) => JSX.Element | null;
};

type RequestChatState =
  | "LOADING"
  | "LOGIN"
  | "FORM"
  | "SUBMITTING"
  | "SUCCESS"
  | ["ERROR", string | JSX.Element, string | JSX.Element | undefined];

export default function RequestChatStateMachine({
  collectionId,
  locale,
  profile,
  renderLoading,
  renderLogin,
  renderForm,
  renderSuccess,
  renderError,
}: StateProps) {
  const [user, isLoading, error] = useUser();
  const isAnonymous = !user || user.isAnonymous;
  const initialState = isLoading ? "LOADING" : isAnonymous ? "LOGIN" : "FORM";
  const [state, setState] = useState<RequestChatState>(initialState);
  // Handle changes in the user loading state.
  useEffect(() => {
    if (error?.message) return setState(["ERROR", error.message, undefined]);
    if (isLoading) return setState("LOADING");
    if (isAnonymous) return setState("LOGIN");
    return setState("FORM");
  }, [isLoading, isAnonymous, error?.message]);
  const submit = useSubmitChatRequest({
    onSuccess: () => setState("SUCCESS"),
    onError: (message, title) => setState(["ERROR", message, title]),
  });
  switch (state) {
    case "LOADING":
      return renderLoading();
    case "LOGIN":
      return renderLogin();
    case "FORM":
    case "SUBMITTING":
      return renderForm({
        onNext: async (values) => {
          try {
            // Double-check that you haven't logged out in the meantime.
            if (!user) {
              throw new Error("You are not logged in.");
            }
            // Get a token to write to your data.
            const senderToken = await user.getIdToken();
            if (!senderToken) throw new Error("No token could be acquired.");
            setState("SUBMITTING");
            return submit({
              collectionId,
              locale,
              senderId: user.uid,
              senderToken,
              useTestMode: false,
              input: {
                recipientId: profile.uid,
                displayName: values.displayName,
                email: values.email || "",
                message: values.message,
                telephone: values.telephone,
                companyName: values.companyName || "",
                jobTitle: values.jobTitle || "",
                //@ts-expect-error - this is in the form, but is not in the data submitted to the server.
                profileImage: values.profileImage,
                profileImageUrl: values.profileImageUrl,
                linkedInURL: values.linkedInURL || "",
                acceptTerms: values.acceptTerms,
                contactMeVia: values.allowChat
                  ? ["WHATSAPP", "IMESSAGE", "EMAIL", "TELEPHONE"]
                  : ["EMAIL", "TELEPHONE"],
                agreeToShareContactDetails: values.agreeToShareContactDetails,
              },
            });
          } catch (error) {
            setState(["ERROR", error.message, undefined]);
          }
        },
      });
    case "SUCCESS":
      return renderSuccess();
    default:
      if (state[0] === "ERROR")
        return renderError({
          message: state[1] || <Trans>Unknown error.</Trans>,
          title: state[2] || <Trans>An error has occurred.</Trans>,
          onRetry: () => setState("FORM"),
        });
      return null;
  }
}

/** @jsxImportSource theme-ui */
import { FormattedText, Text } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import React from "react";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";

const fragment = graphql(`
  fragment WineVinification on Product {
    __typename
    ... on Wine {
      vinification
      malolacticFermentation
      fermentationType
      fermentationVesselTypes
      wholeClusterPercent
    }
  }
`);

export default function WineVinification({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const product = useFragment(fragment, data);
  const wine = product.__typename === "Wine" ? product : null;
  if (!hasContent(data)) return null;
  return (
    <Section title={<Trans>Vinification</Trans>} {...rest}>
      <FormattedText text={wine.vinification} variant="large" />

      <VinificationValue
        title={<Trans>Whole cluster fermentation</Trans>}
        value={wine.wholeClusterPercent}
      >
        {wine.wholeClusterPercent}%
      </VinificationValue>
      <VinificationValue title={<Trans>Malolactic fermentation</Trans>}>
        {wine.malolacticFermentation}
      </VinificationValue>
      <VinificationValue title={<Trans>Fermentation type</Trans>}>
        {wine.fermentationType}
      </VinificationValue>
      <VinificationValue title={<Trans>Fermentation vessel type</Trans>}>
        {wine.fermentationVesselTypes?.join(", ")}
      </VinificationValue>
    </Section>
  );
}

function hasContent(data: FragmentType<typeof fragment>) {
  const wine = useFragment(fragment, data);
  if (wine.vinification) return true;
  if (wine.malolacticFermentation) return true;
  if (wine.fermentationType) return true;
  if (wine.fermentationVesselTypes?.length) return true;
  if (wine.wholeClusterPercent) return true;
  return false;
}

function VinificationValue({
  title,
  value = undefined,
  children,
}: {
  title: React.ReactNode;
  value?: string | null;
  children: React.ReactNode;
}) {
  // Only if the raw value is null or empty we have supplied it.
  if (value === null || value === "") return null;
  if (!children) return null;
  return (
    <Text variant="large">
      {title}: {children}
    </Text>
  );
}

/** @jsxImportSource theme-ui */
import { gql } from "urql";

export default gql`
  fragment ProfileBlockFragment on ProfileBlock {
    label
    registration {
      companyId
      registrationId
      referenceName
      collectionId
    }
    style {
      ...BlockStyle
    }
  }
`;

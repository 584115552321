// Generate path that the server can be used to invalidate a request
export default function getInvalidationPath(opt: {
  collectionId: string;
  slug: string;
}) {
  const { collectionId } = opt;
  // Allow individual CMS pages to be invalidated
  return {
    "bottlebooks-request-cache-invalidation-key": `/collections/${collectionId}/${opt.slug}`,
    "bottlebooks-use-request-cache": "true",
  };
}

/** @jsxImportSource theme-ui */
import { Box, Text } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";

const fragment = graphql(`
  fragment PackagingVariant on ProductPackagingVariant {
    bottlesPerCase
    GTIN
  }
`);

export default function PackagingVariant({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const packagingVariant = useFragment(fragment, data);
  if (!hasContent(data)) return null;

  return (
    <Box {...rest}>
      <Value title={<Trans>Bottles per case</Trans>}>
        {packagingVariant.bottlesPerCase}
      </Value>
      <Value title={<Trans>GTIN</Trans>}>{packagingVariant.GTIN}</Value>
    </Box>
  );
}
PackagingVariant.hasContent = hasContent;

function Value({ title, children, ...rest }:{
  title: React.ReactNode
  children: React.ReactNode
}) {
  if (!children) return null;
  return (
    <Text {...rest}>
      {title}: {children}
    </Text>
  );
}
function hasContent(data: FragmentType<typeof fragment>) {
  const packagingVariant = useFragment(fragment, data);
  if (!packagingVariant) return false;
  if (packagingVariant.GTIN) return true;
  if (packagingVariant.bottlesPerCase) return true;
  return false;
}

/** @jsxImportSource theme-ui */
import { SemiTitle, View } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { FragmentType, graphql, useFragment } from "~/gql";
import Scale from "../Scale/Scale";

const fragment = graphql(`
  fragment ProductAromas on Product {
    aromaIntensity
    aromaFruit
    aromaFloral
    aromaSpice
    aromaWood
  }
`);

export default function ProductAromas({
  data,
}: {
  data: FragmentType<typeof fragment>;
}) {
  const product = useFragment(fragment, data);
  const { i18n } = useLingui();
  if (!product || !hasContent(data)) return null;
  const { aromaIntensity, aromaFruit, aromaFloral, aromaSpice, aromaWood } =
    product;
  return (
    <View>
      <SemiTitle>
        <Trans>Aromas</Trans>
      </SemiTitle>

      <Scale
        text={
          <Trans>
            Aroma intensity: {i18n._(`aromaIntensity.${aromaIntensity}`)}
          </Trans>
        }
        value={aromaIntensity ? intensityScale[aromaIntensity] : null}
        sx={{ maxWidth: 280 }}
      />
      <Scale
        text={<Trans>Fruit: {i18n._(`aromaFruit.${aromaFruit}`)}</Trans>}
        value={aromaFruit ? fruitScale[aromaFruit] : null}
        sx={{ maxWidth: 280 }}
      />
      <Scale
        text={<Trans>Floral: {i18n._(`aromaFloral.${aromaFloral}`)}</Trans>}
        value={aromaFloral ? floralScale[aromaFloral] : null}
        sx={{ maxWidth: 280 }}
      />
      <Scale
        text={<Trans>Spice: {i18n._(`aromaSpice.${aromaSpice}`)}</Trans>}
        value={aromaSpice ? spicyScale[aromaSpice] : null}
        sx={{ maxWidth: 280 }}
      />
      <Scale
        text={<Trans>Wood: {i18n._(`aromaWood.${aromaWood}`)}</Trans>}
        value={aromaWood ? woodScale[aromaWood] : null}
        sx={{ maxWidth: 280 }}
      />
    </View>
  );
}

function hasContent(data: FragmentType<typeof fragment>) {
  const product = useFragment(fragment, data);
  if (product.aromaIntensity != null) return true;
  if (product.aromaFruit != null) return true;
  if (product.aromaFloral != null) return true;
  if (product.aromaSpice != null) return true;
  if (product.aromaWood != null) return true;
  return false;
}
ProductAromas.hasContent = hasContent;

// The scale is used to give each text value a ranking.
const intensityScale: { [key: string]: number } = {
  minimal: 1,
  restrained: 2,
  moderate: 3,
  intense: 4,
};
const fruitScale: { [key: string]: number } = {
  minimal: 1,
  restrained: 2,
  moderate: 3,
  intense: 4,
};
const floralScale: { [key: string]: number } = {
  minimal: 1,
  restrained: 2,
  moderate: 3,
  intense: 4,
};
const spicyScale: { [key: string]: number } = {
  minimal: 1,
  restrained: 2,
  moderate: 3,
  intense: 4,
};
const woodScale: { [key: string]: number } = {
  none: 0,
  minimal: 1,
  restrained: 2,
  moderate: 3,
  intense: 4,
};

/** @jsxImportSource theme-ui */
import { P } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";
import Image from "../Image/Image";

const fragment = graphql(`
  fragment BrandWinemaker on Registration {
    __typename
    profile {
      winemakerName
    }
    ... on SingleRegistration {
      overviewOfWinemakers: customFieldValue(key: "overviewOfWinemakers") {
        __typename
        ... on TextFieldValue {
          value
        }
      }
      imageOfWinemakers: customFieldValue(key: "imageOfWinemakers") {
        __typename
        ... on ImageFieldValue {
          value {
            publicId
          }
        }
      }
    }
  }
`);

export default function BrandWinemaker({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const registration = useFragment(fragment, data);
  if (registration.__typename !== "SingleRegistration") return null;
  const brandProfile = registration.profile;
  if (!brandProfile?.winemakerName) return null;
  return (
    <Section title={<Trans>Winemaker</Trans>} {...rest}>
      <P variant="title">{brandProfile.winemakerName}</P>
      {registration.imageOfWinemakers?.__typename === "ImageFieldValue" &&
      registration.imageOfWinemakers?.value?.publicId ? (
        <Image
          publicId={registration.imageOfWinemakers?.value?.publicId}
          alt={brandProfile.winemakerName}
          layout="fixed"
          options={{
            width: 780,
            height: 390,
            crop: "fill",
            gravity: "faces:auto",
            fit: "cover",
          }}
          sizes="(min-width: 48em) 780px, 100vw"
          sx={{
            maxWidth: "100%",
            height: "auto",
            marginY: 3,
            objectFit: "cover",
          }}
        />
      ) : null}
      {registration.overviewOfWinemakers?.__typename === "TextFieldValue"
        ? registration.overviewOfWinemakers?.value
            ?.split("\n\n")
            .map((p, index) => (
              <P variant="large" key={index} sx={{ color: "black" }}>
                {p}
              </P>
            ))
        : null}
    </Section>
  );
}

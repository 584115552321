/** @jsxImportSource theme-ui */
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import Gallery from "../Gallery/Gallery.next";
import Section from "../Section/Section";

const fragment = graphql(`
  fragment BrandGallery_Profile on Profile {
    galleryImages {
      ...Gallery
    }
  }
  # fragment BrandGallery on Bottlebooks_AbstractBrand {
  #   galleryImages {
  #     ...Gallery
  #   }
  # }
`);

export default function BrandGallery({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const brand = useFragment(fragment, data);
  if (!hasContent(data)) return null;
  return (
    <Section title={<Trans>Gallery</Trans>} {...rest}>
      <Gallery data={brand?.galleryImages} />
    </Section>
  );
}
BrandGallery.hasContent = hasContent;
function hasContent(brand) {
  if (!brand) return false;
  if (!brand.galleryImages?.filter(Boolean).length) return false;
  if (brand?.__typeName !== "Exhibitor") return true;
  return false;
}

/** @jsxImportSource theme-ui */
import { initializeApp } from "firebase/app";
import React from "react";
import firebaseConfig from "./firebaseConfig";

initializeApp(firebaseConfig);

export default function wrapRootElement({ children }) {
  return <RootFirebaseAppProvider>{children}</RootFirebaseAppProvider>;
}

// This Provider is modeled after reactfire - although it's not possible to use it currently (SSR issues), the API is good.
function RootFirebaseAppProvider({ children /* , firebaseConfig */ }) {
  return <>{children}</>;
}

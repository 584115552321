/** @jsxImportSource theme-ui */
import CoverImage from "../CoverImage";

export default function ListRowBackgroundImage(props) {
  return (
    <CoverImage
      {...props}
      withOverlay
      sx={{
        position: "absolute",
        transition: "transform 0.7s ease-out",
        "::after": { transition: "backgroundColor" },
      }}
    />
  );
}

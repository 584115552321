/** @jsxImportSource theme-ui */
import { Flex, Text } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { Envelope, GoogleLogo } from "@phosphor-icons/react";
import type { ComponentPropsWithoutRef, ReactNode } from "react";
import IconButton from "../IconButton";
import type { LoginMethod } from "./Login.useState";
import React from "react";

type SelectLoginMethodProps = ComponentPropsWithoutRef<typeof Flex> & {
  availableMethods: LoginMethod[];
  onContinue: (method: LoginMethod) => void;
  prepend?: ReactNode;
  append?: ReactNode;
};

export default function SelectLoginMethod({
  availableMethods,
  onContinue,
  prepend,
  append,
  children,
  ...rest
}: SelectLoginMethodProps) {
  return (
    <Flex direction="column" gap={3} sx={{ textAlign: "center" }} {...rest}>
      {prepend}
      {availableMethods.includes("GOOGLE") && (
        <IconButton
          variant="outline"
          sx={{ width: "100%" }}
          onClick={() => onContinue("GOOGLE")}
        >
          <GoogleLogo size={22} weight="fill" />
          <Trans>Log in with Google</Trans>
        </IconButton>
      )}
      {availableMethods.length > 1 && (
        <Text
          sx={{
            position: "relative",
            "::before": {
              content: '""',
              position: "absolute",
              display: "block",
              width: "100%",
              height: "1px",
              top: "15px",
              backgroundColor: "border",
            },
          }}
        >
          <span
            sx={{
              paddingX: 2.5,
              backgroundColor: "white",
              display: "inline-block",
              position: "relative",
            }}
          >
            <Trans>or</Trans>
          </span>
        </Text>
      )}
      {availableMethods.includes("EMAIL") && (
        <React.Fragment>
          <IconButton
            variant="primary"
            sx={{ width: "100%" }}
            onClick={() => onContinue("EMAIL")}
          >
            <Envelope size={22} weight="light" />
            <Trans>Log in with email</Trans>
          </IconButton>
          <Text
            sx={{
              position: "relative",
              "::before": {
                content: '""',
                position: "absolute",
                display: "block",
                width: "100%",
                height: "1px",
                top: "15px",
                backgroundColor: "border",
              },
            }}
          >
            <span
              sx={{
                paddingX: 2.5,
                backgroundColor: "white",
                display: "inline-block",
                position: "relative",
              }}
            >
              <Trans>or</Trans>
            </span>
          </Text>
          <IconButton
            variant="primary"
            sx={{ width: "100%" }}
            onClick={() => onContinue("SIGNIN_LINK")}
          >
            <Envelope size={22} weight="light" />
            <Trans>Request sign-in link</Trans>
          </IconButton>
        </React.Fragment>
      )}
      {children}
      {append}
    </Flex>
  );
}

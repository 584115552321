/** @jsxImportSource theme-ui */
import {
  ListContainer,
  useEnrichedFavorites,
  useFavorites,
} from "@bottlebooks/bottlebooks-site-bookmarks/src";
import { Box } from "@bottlebooks/gatsby-theme-base/src";
import ExploreAllProductsBanner from "./ExploreAllProductsBanner";
import List from "./ListsPage.List";

/**
 * @param {{ locale: string; }} props
 */
export default function Lists({ locale }) {
  const favorites = useEnrichedFavorites();
  const { lists } = useFavorites();
  if (!favorites?.length) return null;
  return (
    <Box sx={{ backgroundColor: "light" }}>
      <ListContainer sx={{ paddingTop: 3 }}>
        {lists?.map((list) => (
          <List
            key={list.listId}
            locale={locale}
            list={list}
            baseUrl={`/${locale}/my/lists/${list.listId}`}
            sx={{ marginBottom: 4 }}
          />
        ))}
      </ListContainer>
      <ExploreAllProductsBanner />
    </Box>
  );
}

/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  Flex,
  Grid,
  SemiTitle,
  Text,
  Title,
} from "@bottlebooks/gatsby-theme-base/src";
import { Global } from "@emotion/react";
import Dialog from "@reach/dialog";
import "@reach/dialog/styles.css";
// import RandomAvatar from './Avatar';
import AvatarSubstitute from "./AvatarSubstitute";

export default function BookAMeetingConfirmation({
  contact,
  onDismiss,
  ...rest
}) {
  return (
    <Dialog
      isOpen
      onDismiss={onDismiss}
      aria-label="Book a meeting"
      sx={{
        borderRadius: "default",
        "&&": {
          width: ["90vw", "80vw", "75vw", 768],
          padding: 2,
        },
      }}
      {...rest}
    >
      <Global styles={{ "[data-reach-dialog-overlay]": { zIndex: 1 } }} />
      <Grid align="flex-start" sx={{ gridTemplateColumns: "200px auto" }}>
        <Flex
          sx={{
            minWidth: 80,
            width: 80,
            height: 80,
            marginRight: 3,
            border: "3px solid",
            borderRadius: "round",
            borderColor: "primary",
            backgroundColor: "white",
            transition: "transform",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AvatarSubstitute contact={contact} />
        </Flex>
        {/* <RandomAvatar
          seed={contact.fullName}
          style={{ marginTop: -50, width: 200, height: 200, flex: '0 0 200px' }}
          eyeType="Wink"
          eyebrowType="UpDown"
          mouthType="Smile"
        /> */}

        <Box sx={{ marginBottom: 4 }}>
          <SemiTitle>Your booking request with</SemiTitle>
          <Title>{contact.fullName}</Title>
          <Text variant="small">{contact.companyName}</Text>
          <Text sx={{ marginY: 2 }}>is on its way!</Text>
          <Text sx={{ marginY: 4, maxWidth: "50ch" }}>
            Watch your inbox for updates – we'll notify you whenever{" "}
            {contact.fullName} confirms one of your call requests or posts an
            update for you.
          </Text>
          <Button
            sx={{ marginLeft: "auto" }}
            onClick={onDismiss}
            variant="primary"
          >
            Close
          </Button>
        </Box>
      </Grid>
    </Dialog>
  );
}

/** @jsxImportSource theme-ui */
import { Text, Title } from "@bottlebooks/gatsby-design-system";
import { Button, Dialog } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import type { DialogProps } from "@reach/dialog";
import { CheckCircle, X } from "@phosphor-icons/react";
import { Fragment } from "react";

type RequestMeetingSuccessProps = {
  recipient: { displayName?: string | null };
  onDismiss: DialogProps["onDismiss"];
};
export default function SubmissionSuccess({
  recipient,
  onDismiss,
}: RequestMeetingSuccessProps) {
  return (
    <Fragment>
      <Dialog.Header onDismiss={onDismiss}>
        {recipient.displayName ? (
          <Trans>Book a meeting with {recipient.displayName}</Trans>
        ) : (
          <Trans>Book a meeting</Trans>
        )}
      </Dialog.Header>
      <Dialog.Content sx={{ textAlign: "center" }}>
        <CheckCircle
          size={64}
          weight="light"
          sx={{ color: "brand.successGreen" }}
        />
        <Title variant="small">
          <Trans>Your request has been sent.</Trans>
        </Title>
        <Text>
          <Trans>
            You will receive an email with the next steps in a few minutes.
          </Trans>
        </Text>
      </Dialog.Content>
      <Dialog.Footer>
        <Button variant="primary" onClick={onDismiss}>
          <X size={22} weight="light" sx={{ marginRight: 1 }} />
          <Trans>Close</Trans>
        </Button>
      </Dialog.Footer>
    </Fragment>
  );
}

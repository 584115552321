/** @jsxImportSource theme-ui */
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";
import Video from "../Video/Video.next";

const fragment = graphql(`
  fragment EventVideos on Video {
    type
    videoId
    title
    ...Video
  }
`);

export default function EventVideos({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>[];
}) {
  const videos = useFragment(fragment, data);
  if (!hasContent(data)) return null;
  const needsTitle = !videos[0]?.title;
  return (
    <Section title={needsTitle && <Trans>Videos</Trans>} {...rest}>
      {videos.map((video) => (
        <Video key={video?.videoId} data={video} />
      ))}
    </Section>
  );
}
EventVideos.hasContent = hasContent;
function hasContent(data: FragmentType<typeof fragment>[]) {
  const videos = useFragment(fragment, data);
  if (!videos?.length) return false;
  return videos.some((video) => Video.hasContent(video));
}

/** @jsxImportSource theme-ui */
import React from "react";
import { FragmentType, graphql, useFragment } from "~/gql";
import useLink from "../../useLink";
import BrandHeader from "../Brand/BrandHeader.next";
import BrandSeekingDistribution from "../Brand/BrandSeekingDistribution.next";
import ProducerStand from "./ProducerStand.next";

const fragment = graphql(`
  fragment Registration_ProducerHeader on Registration {
    ...Registration_ProducerStand
  }
`);
const fragment2 = graphql(`
  fragment RegisteredBrand_ProducerHeader on RegisteredBrand {
    ...RegisteredBrand_SeekingDistribution
  }
`);

export default function ProducerHeader({
  exhibitor: exhibitorData,
  producer: producerData,
  onDark,
  gap = 2,
  as,
  ...rest
}: {
  exhibitor: FragmentType<typeof fragment>;
  producer: FragmentType<typeof fragment2>;
  onDark?: boolean;
  gap?: number;
  as?: React.ReactNode;
}) {
  const link = useLink();
  const exhibitor = useFragment(fragment, exhibitorData);
  const producer = useFragment(fragment2, producerData);
  return (
    <React.Fragment>
      <ProducerStand
        exhibitor={exhibitor}
        producer={producer || {}}
        to={link.exhibitor(exhibitor)}
        sx={{ paddingBottom: gap, color: onDark ? "onDark" : "lighterText" }}
      />
      <BrandHeader as={as} data={producer || {}} {...rest} />
      <BrandSeekingDistribution data={producer} sx={{ marginTop: 3 }} />
    </React.Fragment>
  );
}

/** @jsxImportSource theme-ui */
import {
  Container,
  Flex,
  SemiTitle,
  Text,
  Title,
  View,
} from "@bottlebooks/gatsby-design-system";
// import { graphql } from "gatsby";
import SliderImage from "./SliderImage";

/**
 * @param {object} props
 * @param {string} props.projectId
 * @param {string} [props.lead]
 * @param {string} [props.title]
 * @param {string} [props.subtitle]
 * @param {object} [props.image]
 * @param {boolean} [props.parallax=false]
 * @param {string} [props.id='slider']
 * @param {(number | string | null)[]} [props.height=[287, 325, 400, 550]]
 */
export default function Slider({
  id = "slider",
  projectId,
  parallax = false,
  image,
  lead,
  title,
  subtitle,
  height = [287, 325, 400, 550],
  ...rest
}) {
  return (
    <View id={id} sx={{ position: "relative", height }} {...rest}>
      <SliderImage
        projectId={projectId}
        image={image}
        height={height}
        parallax={parallax}
        alt={title}
        // Apply overlay if there are any values
        withOverlay={[lead, title, subtitle].filter(Boolean).length > 0}
      >
        {title && (
          <Container
            fluid
            sx={{
              maxWidth: "container.fluid",
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              height: "100%",
            }}
          >
            <Flex
              sx={{
                justifyContent: "space-around",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Flex direction="column" align="flex-start">
                <Text
                  sx={{
                    fontSize: 22,
                    color: "white",
                    textShadow: "0px 2px 3px rgb(0 0 0 / 60%)",
                    whiteSpace: "pre-line",
                    marginBottom: 2,
                  }}
                >
                  {lead}
                </Text>
                <Title
                  variant="largest"
                  sx={{
                    color: "white",
                    lineHeight: 1.2,
                    textShadow: "0px 2px 3px rgb(0 0 0 / 60%)",
                    whiteSpace: "pre-line",
                  }}
                >
                  {title}
                </Title>

                <SemiTitle
                  sx={{
                    color: "white",
                    fontWeight: 500,
                    lineHeight: 1,
                    textShadow: "0px 2px 3px rgb(0 0 0 / 60%)",
                    whiteSpace: "pre-line",
                  }}
                >
                  {subtitle}
                </SemiTitle>
              </Flex>
            </Flex>
          </Container>
        )}
      </SliderImage>
    </View>
  );
}

// export const fragment = graphql`
//   fragment SanitySliderSectionFragment on SanitySliderSection {
//     lead
//     title
//     subtitle
//     image {
//       ...ImageWithPreview
//     }
//   }
// `;

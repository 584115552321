/** @jsxImportSource theme-ui */
import {
  PersonalPageLayout,
  useFavorites,
} from "@bottlebooks/bottlebooks-site-bookmarks/src";
import { useExhibitors } from "@bottlebooks/bottlebooks-site-base/src/components/SiteSearch/useEventData";
import EmptyState from "./ListsPage.EmptyState";
import Error from "./ListsPage.Error";
import Loading from "./ListsPage.Loading";
import Lists from "./ListsPage.Lists";

export default function ListsPage({ locale, collectionId }) {
  return (
    <PersonalPageLayout locale={locale} collectionId={collectionId}>
      <Content locale={locale} />
    </PersonalPageLayout>
  );
}

function Content({ locale }) {
  const { favorites, isLoading, error } = useFavorites();
  if (isLoading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!favorites?.length) return <EmptyState />;
  return <Lists locale={locale} />;
}

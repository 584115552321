/** @jsxImportSource theme-ui */
import {
  BottleImagePlaceholder,
  Box,
  Button,
  Col,
  Flex,
  Link,
} from "@bottlebooks/gatsby-theme-base/src";
import React from "react";
import Image from "../Image/Image";
import ProductImage from "../Product/ProductImage";
import { DownloadSimple } from "@phosphor-icons/react";

export { ProductImageFragment_large } from "../Product/ProductImage";

export default function ProductImageCol({
  product,
  overlap = false,
  ...rest
}: {
  product: {
    bottleImage: {
      publicId: string;
      sourceUrl: string;
    };
    labelImage: {
      publicId: string;
      sourceUrl: string;
    };
    backLabelImage: {
      publicId: string;
      sourceUrl: string;
    };
  };
  overlap?: boolean;
} & React.HTMLAttributes<HTMLDivElement>) {
  const [activePublicId, setActivePublicId] = React.useState<
    string | undefined
  >(product.bottleImage?.publicId);
  const publicIds = [
    product.bottleImage?.publicId,
    product.labelImage?.publicId,
    product.backLabelImage?.publicId,
  ].filter(Boolean);
  const sourceUrlsIndex = {
    [product.bottleImage?.publicId]: product.bottleImage?.sourceUrl,
    [product.labelImage?.publicId]: product.labelImage?.sourceUrl,
    [product.backLabelImage?.publicId]: product.backLabelImage?.sourceUrl,
  };
  if (!product.bottleImage?.publicId)
    return (
      <BottleImagePlaceholder
        sx={{ color: "light", width: 300, height: 480 }}
      />
    );
  return (
    <Col {...rest}>
      <Box sx={{ position: "relative" }}>
        <ProductImage
          size="xlarge"
          product={{ bottleImage: { publicId: activePublicId } }}
          sx={{ backgroundColor: "background", padding: 2, marginBottom: 1 }}
        />
        {activePublicId && (
          <Link
            href={sourceUrlsIndex[activePublicId]}
            sx={{
              position: "absolute",
              bottom: 1,
              right: 0,
              color: "#aaa",
              "&:hover": { color: "primary" },
            }}
            target="_blank"
          >
            <DownloadSimple size={28} weight="light" />
          </Link>
        )}
      </Box>
      {publicIds.length > 1 ? (
        <Flex gap={2} sx={{ marginTop: 2 }}>
          {publicIds.map((publicId) => (
            <Button
              key={publicId}
              onClick={() => setActivePublicId(publicId)}
              sx={{
                display: "flex",
                boxShadow: `border`,
                width: 94,
                padding: 2,
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <Image
                publicId={publicId}
                layout="fixed"
                options={{
                  transformation: [{ effect: "trim:20" }],
                  width: 80,
                  height: 100,
                  crop: "pad",
                  background: "#fff0",
                }}
                sx={{
                  objectFit: "contain",
                  objectPosition: "center center",
                }}
              />
            </Button>
          ))}
        </Flex>
      ) : null}
    </Col>
  );
}

import {
  Anchor,
  AppShell,
  Badge,
  Button,
  Card,
  Container,
  Group,
  MantineProvider,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import "@mantine/core/styles.css";
import {
  IconArrowRight,
  IconCalendar,
  IconClock,
  IconMapPin,
  IconGlass,
} from "@tabler/icons-react";
import { queryOptions } from "@tanstack/react-query";
import { useState } from "react";
import env from "~/env";
import { graphql } from "~/gql";
import graphQLClient from "~/graphQLClient";
import queryClient from "~/queryClient";

function getQuery({ workspaceId }: { workspaceId: string }) {
  return queryOptions({
    queryKey: ["index", workspaceId],
    queryFn: async () => {
      const result = await graphQLClient.request(
        graphql(`
          query CollectionsList($workspaceId: ID!) {
            # TODO: Implement a public resolver to get a list of public collections
            # publishedCollections(workspaceId: $workspaceId) {
            #   nodes {
            #     collectionId
            #   }
            # }
            workspace(workspaceId: $workspaceId) {
              collections {
                nodes {
                  collectionId
                }
              }
            }
          }
        `),
        { workspaceId }
      );

      const workspace = result.workspace;
      if (!workspace) throw new Error("No workspace");
      if (!workspace.collections?.nodes)
        throw new Error("No collections in workspace");
      return workspace.collections.nodes;
    },
  });
}

export async function Loader() {
  return null;
  // Load the collections list based on environment variables
  const workspaceId = env.VITE_WORKSPACE_ID;
  if (!workspaceId) {
    throw new Error("VITE_WORKSPACE_ID is not set");
  }
  await queryClient.fetchQuery(getQuery({ workspaceId }));
  return null;
}

export default function LandingPage() {
  const [selectedRegion, setSelectedRegion] = useState("All");

  const regions = ["All", "North America", "Europe", "Asia", "United Kingdom"];

  const events = [
    {
      name: "Vinexpo New York",
      location: "New York, USA",
      date: "March 15-16, 2024",
      region: "North America",
    },
    {
      name: "ProWine Singapore",
      location: "Singapore",
      date: "May 23-26, 2024",
      region: "Asia",
    },
    {
      name: "London Wine Fair",
      location: "London, UK",
      date: "June 17-19, 2024",
      region: "United Kingdom",
    },
    {
      name: "Vinitaly",
      location: "Verona, Italy",
      date: "April 10-13, 2024",
      region: "Europe",
    },
    {
      name: "Wine Paris & Vinexpo Paris",
      location: "Paris, France",
      date: "February 14-16, 2024",
      region: "Europe",
    },
    {
      name: "ProWine Hong Kong",
      location: "Hong Kong",
      date: "May 7-11, 2024",
      region: "Asia",
    },
  ];

  const filteredEvents =
    selectedRegion === "All"
      ? events
      : events.filter((event) => event.region === selectedRegion);

  return (
    <MantineProvider>
      <AppShell header={{ height: 60 }} footer={{ height: 60 }} padding="md">
        <AppShell.Header>
          <Container size="lg" h="100%">
            <Group justify="space-between" h="100%">
              <Group>
                <IconGlass size={24} color="red" />
                <Text fw={700} size="lg">
                  Washington Wine Events
                </Text>
              </Group>
              <Group>
                <Anchor href="#" underline="hover">
                  Upcoming Events
                </Anchor>
                <Anchor href="#" underline="hover">
                  Past Events
                </Anchor>
                <Anchor href="#" underline="hover">
                  Regions
                </Anchor>
                <Anchor href="#" underline="hover">
                  About
                </Anchor>
              </Group>
            </Group>
          </Container>
        </AppShell.Header>

        <AppShell.Main>
          <Container size="lg" py="xl">
            <Stack align="center" gap="xl">
              <Title order={1} ta="center" size="h1">
                Discover Washington State Wineries
              </Title>
              <Text c="dimmed" size="xl" maw={700} ta="center">
                Explore the rich flavors and stunning landscapes of Washington's
                premier wineries. Connect with local vintners and experience the
                best of Pacific Northwest wine country.
              </Text>
              <Group>
                <Button rightSection={<IconArrowRight size={14} />}>
                  View Upcoming Events
                </Button>
                <Button
                  variant="outline"
                  rightSection={<IconClock size={14} />}
                >
                  Explore Past Events
                </Button>
              </Group>
            </Stack>
          </Container>

          <Container size="lg" py="xl">
            <Stack align="center" gap="xl">
              <Title order={2}>Featured Events</Title>
              <Group>
                {regions.map((region) => (
                  <Button
                    key={region}
                    variant={selectedRegion === region ? "filled" : "outline"}
                    onClick={() => setSelectedRegion(region)}
                    radius="xl"
                  >
                    {region}
                  </Button>
                ))}
              </Group>
              <SimpleGrid cols={{ base: 1, sm: 2, md: 3 }} spacing="md">
                {filteredEvents.map((event) => (
                  <Card
                    key={event.name}
                    shadow="sm"
                    padding="lg"
                    radius="md"
                    withBorder
                  >
                    <Card.Section h={160} bg="gray.1" />
                    <Group justify="space-between" mt="md" mb="xs">
                      <Text fw={500}>{event.name}</Text>
                      <Badge color="blue">{event.region}</Badge>
                    </Group>
                    <Text size="sm" c="dimmed">
                      <Group gap="xs">
                        <IconMapPin size={14} />
                        <span>{event.location}</span>
                      </Group>
                    </Text>
                    <Text size="sm" c="dimmed">
                      <Group gap="xs">
                        <IconCalendar size={14} />
                        <span>{event.date}</span>
                      </Group>
                    </Text>
                    <Button
                      variant="light"
                      color="blue"
                      fullWidth
                      mt="md"
                      radius="md"
                    >
                      Learn More
                    </Button>
                  </Card>
                ))}
              </SimpleGrid>
              <Button
                variant="outline"
                size="lg"
                rightSection={<IconClock size={18} />}
              >
                Explore Past Events
              </Button>
            </Stack>
          </Container>
        </AppShell.Main>

        <AppShell.Footer>
          <Container size="lg" h="100%">
            <Group justify="space-between" h="100%">
              <Text size="sm" c="dimmed">
                © 2023 Washington Wine Events. All rights reserved.
              </Text>
              <Group>
                <Anchor href="#" underline="hover" size="sm">
                  Terms of Service
                </Anchor>
                <Anchor href="#" underline="hover" size="sm">
                  Privacy
                </Anchor>
              </Group>
            </Group>
          </Container>
        </AppShell.Footer>
      </AppShell>
    </MantineProvider>
  );
}

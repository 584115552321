import EventProductPage from "@bottlebooks/bottlebooks-site-base/src/components/EventProductPage/EventProductPage.next";
import { LoaderFunctionArgs, useLoaderData, useParams } from "react-router-dom";
import { z } from "zod";
import { bbCollectionIdSchema } from "~/bbCollectionIdSchema";
import { graphql, useFragment } from "~/gql";
import graphQLClient from "~/graphQLClient";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
  collectionId: z.string(),
  exhibitorId: z.string(),
  productId: z.string(),
});

const fragment = graphql(/* GraphQL */ `
  fragment ProductDetail on RegisteredProduct {
    registrationId
    exhibitorId
    producerId
    productId
    product {
      producer {
        name
        logo {
          publicId
        }
      }
    }
    productId
    producer {
      profile {
        name
      }
      products {
        nodes {
          productId
        }
      }
    }
    product {
      productId
    }
    ...EventProductPage_RegisteredProduct
  }
`);

type LoaderData = Awaited<ReturnType<typeof Loader>>;
export async function Loader({ params }: LoaderFunctionArgs) {
  const {
    collectionId: collectionIdSegment,
    exhibitorId,
    productId,
    locale,
  } = paramsSchema.parse(params);
  const collectionId = bbCollectionIdSchema.parse(collectionIdSegment);
  const result = await graphQLClient.request(
    graphql(/* GraphQL */ `
      query ProductPage(
        $collectionId: ID!
        $companyId: ID!
        $productId: ID!
        $locale: ContentLocale
      ) {
        collection(collectionId: $collectionId, locale: $locale) {
          registrations(filter: { companyId: { eq: $companyId } }) {
            nodes {
              __typename
              registrationId
              ... on SingleRegistration {
                registeredProducts(filter: { productId: { eq: $productId } }) {
                  nodes {
                    ...ProductDetail
                  }
                }
              }
            }
          }
        }
      }
    `),
    {
      collectionId,
      companyId: exhibitorId,
      productId,
      locale,
    },
    { "bottlebooks-use-request-cache": "true" }
  );
  const registration = result.collection?.registrations.nodes[0];
  if (!registration) throw new Error("No registration found.");
  const singleRegistration =
    registration.__typename === "SingleRegistration" ? registration : null;
  if (!singleRegistration) throw new Error("No single registration found.");
  const registeredProduct = singleRegistration.registeredProducts?.nodes[0];
  if (!registeredProduct) throw new Error("No registered product found.");
  return registeredProduct;
}

export default function ProductPageTemplate() {
  const { locale, collectionId: collectionIdSegment } = paramsSchema.parse(
    useParams()
  );
  const collectionId = bbCollectionIdSchema.parse(collectionIdSegment);
  const data = useLoaderData() as LoaderData;
  const registeredProduct = useFragment(fragment, data);
  return (
    <EventProductPage
      key={`${registeredProduct.registrationId}-${registeredProduct.productId}`}
      eventId={collectionId}
      data={registeredProduct}
      product={{ ...registeredProduct, ...registeredProduct.product }}
      producer={{
        ...registeredProduct.producer?.profile,
        // This isn't pretty. It is because PresentedBrands is shared between Exhibitor and Product pages.
        registeredBrands: { nodes: [registeredProduct.producer] },
      }}
      otherProducts={registeredProduct.producer?.products.nodes?.filter(
        (product) => product.productId !== registeredProduct.productId
      )}
      otherProductsName={registeredProduct.producer?.profile?.name}
      relatedProducts={[]}
      // TODO: implement previous and next for paging through products.
      previous={undefined}
      next={undefined}
    />
  );
}

/** @jsxImportSource theme-ui */
import { Box, Container } from "@bottlebooks/gatsby-theme-base/src";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import SiteSearch from "../SiteSearch/SiteSearch.next";

export default function SiteSearchSection({
  locale,
  //   ...rest
}) {
  const { i18n } = useLingui();

  return (
    <Container>
      <Box
        sx={{
          transform: "translateY(-50%)",
          position: "relative",
        }}
      >
        <SiteSearch
          variant="largest"
          locale={locale}
          placeholder={i18n._(t`Search for producer, wine or region…`)}
        />
      </Box>
    </Container>
  );
}

/** @jsxImportSource theme-ui */
import { Grid } from "@bottlebooks/gatsby-design-system";
import { profileSchema } from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUserProfile";
import { Trans, t } from "@lingui/macro";
import { Field, useField } from "formik";
import { z } from "zod";
import { TextField } from "../Form/Field";
import { ImageField } from "../Form/ImageField";

import { attendeeProfileFormSchema } from "@bottlebooks/gatsby-plugin-firebase-auth/src/attendeeProfileSchema";

// We need to pass optional() at the end because toFormikValidationSchema() seems to use it. TODO Verify this.
export const attendeeProfileFieldsSchema = z.object({
  displayName: attendeeProfileFormSchema.shape.displayName,
  jobTitle: attendeeProfileFormSchema.shape.jobTitle.optional(),
  companyName: attendeeProfileFormSchema.shape.companyName.optional(),
  // Telephone is required because we use it for iMessage/WhatsApp.
  telephone: attendeeProfileFormSchema.shape.telephone.optional(),
  linkedInURL: attendeeProfileFormSchema.shape.linkedInURL.optional(),
  profileImageUrl: attendeeProfileFormSchema.shape.profileImageUrl.optional(),
  profileImage: attendeeProfileFormSchema.shape.profileImage.optional(),
});

export const userProfileFormSchema = profileSchema
  .pick({
    displayName: true,
    jobTitle: true,
    companyName: true,
    telephone: true,
    linkedInURL: true,
    profileImageUrl: true,
  })
  .passthrough()
  .and(
    z.object({
      profileImage: z
        .any()
        .refine((value) => !(value instanceof File) || value.size > 5000, {
          message: t`The image is too small. It should at least have 5kb.`,
        })
        .refine(
          (value) => !(value instanceof File) || value.size < 10_000_000,
          {
            message: t`The image is too large. Try one smaller than 10MB.`,
          }
        ),
    })
  );
export type UserProfileFormSchema = z.output<typeof userProfileFormSchema>;

type FieldsProps = Omit<React.ComponentProps<typeof Grid>, "sx" | "children">;

export default function UserProfileFormFields(props: FieldsProps) {
  const [field] = useField("profileImageUrl");
  return (
    <Grid
      sx={{ gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))" }}
      {...props}
    >
      <TextField name="displayName" label={<Trans>Your name</Trans>} />
      <TextField name="jobTitle" label={<Trans>Your job title</Trans>} />
      <TextField name="companyName" label={<Trans>Company</Trans>} />
      <TextField
        name="telephone"
        label={<Trans>Your telephone number</Trans>}
        type="tel"
      />
      <TextField name="linkedInURL" label={<Trans>LinkedIn URL</Trans>} />
      <ImageField
        name="profileImage"
        originalURL={field.value}
        label={<Trans>Profile image</Trans>}
        sx={{ gridColumn: "1" }}
      />
    </Grid>
  );
}

import { theme as baseTheme } from "@bottlebooks/gatsby-theme-base/src";
import filter from "../components/Filters/filterFieldVariants";
import quickLink from "../components/LandingPage/quickLinkVariants";

const theme = {
  ...baseTheme,
  // sectionVariants
  section: { default: { marginY: 4 } },
  shadows: {
    ...baseTheme.shadows,
    // Taken from Mantine UI https://mantine.dev/core/paper/
    xs: "rgb(0 0 0 / 5%) 0px 1px 3px, rgb(0 0 0 / 10%) 0px 1px 2px",
    sm: "rgb(0 0 0 / 5%) 0px 1px 3px, rgb(0 0 0 / 5%) 0px 10px 15px -5px, rgb(0 0 0 / 4%) 0px 7px 7px -5px",
    primary: `0 0 0 1px ${baseTheme.colors?.primary}`,
    secondary: `0 0 0 1px ${baseTheme.colors?.secondary}`,
    accent: `0 0 0 1px ${baseTheme.colors?.accent}`,
    border: `0 0 0 1px ${baseTheme.colors?.border}`,
    borderSecondary: `0 0 0 1px ${baseTheme.colors?.borderSecondary}`,
    borderNuanced: `0 0 0 1px ${baseTheme.colors?.borderNuanced}`,
    light: `0 0 0 1px ${baseTheme.colors?.light}`,
    background: `0 0 0 1px ${baseTheme.colors?.background}`,
  },

  // # Component variants
  filter,
  quickLink,
};

export default theme;

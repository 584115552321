/** @jsxImportSource theme-ui */
import { Title } from "@bottlebooks/gatsby-theme-base/src";

export default function AvatarSubstitute({ contact, ...rest }) {
  // This is a bit of a workaround until we have images or avatars
  const nameTokens = contact.fullName?.toUpperCase().split(" ") || [];
  const placeholder = nameTokens
    .filter(Boolean)
    .map((token) => token.substring(0, 1))
    .join("")
    .substring(0, 2);
  return (
    <Title variant="small" sx={{ fontWeight: "bold" }} {...rest}>
      {placeholder}
    </Title>
  );
}

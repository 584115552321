/** @jsxImportSource theme-ui */
import { useFavorite } from "@bottlebooks/bottlebooks-site-bookmarks/src";
import useAuthentication from "@bottlebooks/gatsby-plugin-firebase-auth/src/useAuthentication";
import {
  Badge,
  BookmarkIcon,
  Box,
  Button,
  Dialog,
  Flex,
  Text,
  Title,
} from "@bottlebooks/gatsby-theme-base/src";
import Login from "@bottlebooks/bottlebooks-site-base/src/components/UserProfile/Login";
import LoginBenefits from "@bottlebooks/bottlebooks-site-base/src/components/UserProfile/Login.Benefits";
import { Trans } from "@lingui/macro";
import React from "react";
import AddToListDialog from "./AddToListButton.Dialog";

export default function AddToListButton({ product }) {
  const favorite = useFavorite(
    product && { type: "product", id: product.productId }
  );
  const [state, setState] = React.useState("IDLE");
  const { isAuthenticating, isAuthenticated } = useAuthentication();
  const [isAddToListDialogOpen, setIsAddToListDialogOpen] =
    React.useState(false);
  function openDialog() {
    if (isAuthenticating || !isAuthenticated()) {
      setState("LOGIN");
      return;
    }
    setIsAddToListDialogOpen(true);
  }
  switch (state) {
    case "LOGIN":
      return (
        <Dialog>
          <Dialog.Header
            onDismiss={() => {
              setIsAddToListDialogOpen(false);
              setState("IDLE");
            }}
          />
          <Dialog.Content>
            <Login
              prepend={
                <Title as="h1" variant="small" sx={{ marginBottom: 3.5 }}>
                  <Trans>Sign in to personalize your visit</Trans>
                </Title>
              }
              availableMethods={["EMAIL", "GOOGLE"]}
              append={<LoginBenefits />}
            />
          </Dialog.Content>
        </Dialog>
      );
    case "IDLE":
      return (
        <Box>
          <Button
            variant="text"
            onClick={openDialog}
            sx={{ paddingX: 1, paddingY: 2 }}
          >
            <Flex align="center">
              {favorite.list ? (
                <Badge
                  variant="primary"
                  sx={{
                    backgroundColor: "primary",
                    color: "onPrimary",
                    paddingX: 3,
                    paddingY: 1,
                  }}
                >
                  {favorite.list.name}
                </Badge>
              ) : (
                <Flex align="center">
                  <Text variant="small">
                    <Trans>Add to list</Trans>
                  </Text>
                </Flex>
              )}
              <FavoriteIcon
                isToggled={Boolean(favorite.list)}
                sx={{ marginLeft: 1 }}
              />
            </Flex>
          </Button>
          {isAddToListDialogOpen && (
            <AddToListDialog
              onDismiss={() => setIsAddToListDialogOpen(false)}
              product={product}
            />
          )}
        </Box>
      );
    default:
      exhaustiveCheck(state);
  }
}

function exhaustiveCheck(x: string) {
  throw new Error("Exhaustive check failed: " + x);
}

function FavoriteIcon({ isToggled = false, ...rest }) {
  return (
    <Box
      as="span"
      sx={{
        display: "inline-grid",
        borderRadius: "round",
        color: isToggled ? "primary" : "lightText",
        transition: "color",
        ":hover": { color: "secondary" },
      }}
      {...rest}
    >
      <BookmarkIcon
        variant={isToggled ? "filled" : "hollow"}
        size="smallMedium"
      />
    </Box>
  );
}

import "@fontsource/cardo/400.css";
import "@fontsource/cardo/700.css";
import "@fontsource/montserrat/300.css";
import "@fontsource/montserrat/500.css";
import "@fontsource/prompt/400.css";
import merge from "lodash/merge";
import { lighten } from "polished";
import { Helmet } from "react-helmet";
import { ThemeUIProvider } from "theme-ui";
import { useCollectionLayout } from "../../CollectionLayoutProvider.next";
import baseTheme from "../../../theme";

export default function MMILayout({ children }: { children: React.ReactNode }) {
  return (
    <ThemeUIProvider theme={useTheme()}>
      <Helmet>
        <link
          rel="stylesheet"
          href="//fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap"
        ></link>
        <style type="text/css">
          {`
        // Mantine defaults to antialiased font smoothing, which looks
        // terrible on the Top Drop site
        :root {
          --mantine-webkit-font-smoothing: subpixel-antialiased;
        }
        html, body {
          -webkit-font-smoothing: subpixel-antialiased;
        }
    `}
        </style>
      </Helmet>
      {children}
    </ThemeUIProvider>
  );
}

function useTheme() {
  const collectionLayout = useCollectionLayout();
  const primaryColor = collectionLayout.primaryColor || "#540638";
  const accentColor = collectionLayout.accentColor || "#aa9059";
  return {
    ...baseTheme,
    colors: {
      ...baseTheme.colors,
      primary: primaryColor,
      secondary: lighten(0.1)(primaryColor || "#000000"),
      accent: accentColor,
      // primary: "#540638",
    },
    fonts: merge({}, baseTheme.fonts, {
      heading: "Cardo, sans-serif",
      body: "Prompt, serif",
    }),
    fontWeights: {
      ...baseTheme.fontWeights,
      heading: 700,
      body: 400,
    },
    title: merge({}, baseTheme.title, {
      _base: {
        fontFamily: "Cardo, sans-serif",
        WebkitFontSmoothing: "subpixel-antialiased",
      }, // Add antialiasing to titles.
    }),
    button: merge({}, baseTheme.button, {
      primary: {
        paddingX: 3,
        paddingY: 2,
        borderRadius: "round",
        // Previous settings from Gatsby
        // We are managing this now with theme settings in Sanity
        // background: "#353c44",
        // color: "#aa9059",
        // textTransform: 'uppercase',
        fontWeight: "700",
        fontSize: 14,
        letterSpacing: "0.05em",
        ":hover": {
          // boxShadow: (theme) => `inset 0 0 0 2px ${theme.colors.secondary}`,
          color: "#fff",
          // Previous settings from Gatsby
          // We are managing this now with theme settings in Sanity
          // background: "#aa9059",
        },
      },
      outline: {
        paddingX: 3,
        paddingY: 2,
        borderRadius: "round",
        color: "primary",
        fontWeight: "700",
        fontSize: 14,
        letterSpacing: "0.05em",
        boxShadow: (theme) => `inset 0 0 0 2px ${theme.colors.primary}`,
        ":hover": {
          color: "secondary",
          boxShadow: (theme) => `inset 0 0 0 2px ${theme.colors.secondary}`,
        },
        "&[disabled]": {
          color: "lightText",
          boxShadow: (theme) => `inset 0 0 0 2px ${theme.colors.lightText}`,
        },
        "&[disabled]:hover": {
          color: "lightText",
          boxShadow: (theme) => `inset 0 0 0 2px ${theme.colors.lightText}`,
        },
      },
    }),

    styles: {
      ...baseTheme.styles,
      root: {
        ...baseTheme.styles.root,
        WebkitFontSmoothing: "subpixel-antialiased",
      },
    },
  };
}

/** @jsxImportSource theme-ui */
import { Col, Row } from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";
import ProductAromas from "./ProductAromas.next";
import ProductColor from "./ProductColor.next";
import ProductPalate from "./ProductPalate.next";

const fragment = graphql(`
  fragment ProductSensory on Product {
    ...ProductColor
    ...ProductAromas
    ...ProductPalate
  }
`);

export default function ProductSensory({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const product = useFragment(fragment, data);
  if (!product) return null;
  const hasColor = ProductColor.hasContent(product);
  const hasAromas = ProductAromas.hasContent(product);
  const hasPalate = ProductPalate.hasContent(product);
  if (!hasColor && !hasAromas && !hasPalate) return null;
  return (
    <Section {...rest}>
      {hasColor && <ProductColor data={product} />}
      <Row>
        {hasAromas && (
          <Col sx={{ width: [null, "50%"] }}>
            {<ProductAromas data={product} />}
          </Col>
        )}
        {hasPalate && (
          <Col sx={{ width: [null, "50%"] }}>
            <ProductPalate data={product} />
          </Col>
        )}
      </Row>
    </Section>
  );
}

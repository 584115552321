import { gql, useQuery } from "@apollo/client";
import useUserProfile from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUserProfile";
import {
  Button,
  Container,
  Flex,
  Text,
} from "@bottlebooks/gatsby-theme-base/src";
import getOnAccentColor from "@bottlebooks/bottlebooks-site-base/src/helpers/getOnAccentColor";
import { Trans } from "@lingui/macro";
import React from "react";
import { useCollection } from "../SiteSearch/useEventData";
import UserProfileCard from "../UserProfile/UserProfileCard";

export default function ClaimRepresentativeProfileButton({ ...rest }) {
  const { userProfile } = useUserProfile();
  return null;
  if (!userProfile) return null;
  if (
    typeof window !== "undefined" &&
    window.location.pathname.toLowerCase().includes("onboarding")
  )
    return null;
  return <CanClaimRepresentativeProfile userProfile={userProfile} {...rest} />;
}

function CanClaimRepresentativeProfile({
  userProfile,
  ...rest
}: {
  userProfile: any;
}) {
  const { collectionId } = useCollection();
  const { data } = useQuery(query, {
    variables: {
      collectionId,
      userId: userProfile.uid,
    },
  });
  const canClaim = data?.collection?.representatives?.filter(
    (rep) => rep.mayClaim && rep.claimedState === "UNCLAIMED"
  );
  if (!canClaim || canClaim.length === 0) return null;
  return (
    <Flex
      sx={{
        width: "100%",
        backgroundColor: "primary",
        paddingY: 3,
        paddingX: 2,
      }}
    >
      <Container>
        <Flex align="center" gap={3}>
          <Text sx={{ color: "white" }}>
            <Trans>
              You appear to be a representative of {canClaim[0].companyName}
            </Trans>
          </Text>
          <Button
            variant="outline"
            to={"/representativeOnboarding"}
            sx={(theme) => ({
              color: getOnAccentColor(theme.colors.accent),
              borderColor: getOnAccentColor(theme.colors.accent),
              border: 1,
              transition: "all 0.3s ease-out",
              "&:hover": {
                backgroundColor: getOnAccentColor(theme.colors.accent),
                color: theme.colors.primary,
              },
            })}
            {...rest}
          >
            <Trans>Claim profile</Trans>
          </Button>
        </Flex>
      </Container>
    </Flex>
  );
}

const query = gql`
  query CanClaimRepresentativeProfile($collectionId: ID!, $userId: ID!) {
    collection(collectionId: $collectionId) {
      eventId: collectionId
      representatives(
        experiment: "california-networking"
        experimentToken: "Used in @bottlebooks/gatsby-theme-event, added by bernhard@unserwein.at at 2023-03-08"
      ) {
        collectionId
        mayClaim(userId: $userId)
        companyName
        registrationId
        representativeId
        claimedState
        ...UserProfileCard
      }
    }
  }
  ${UserProfileCard.fragment}
`;

/** @jsxImportSource theme-ui */
import { Trans } from "@lingui/macro";
import { CalendarBlank } from "@phosphor-icons/react";
import "@reach/dialog/styles.css";
import { useState } from "react";
import { FragmentType, graphql, useFragment } from "~/gql";
import useLocale from "../../useLocale";
import type { ActionButtonProps } from "../ActionButton";
import ActionButton from "../ActionButton";
import { useCollectionLayout } from "../CollectionLayoutProvider.next.js";
import useSiteConfig from "../useSiteConfig";
import type { RequestMeetingDialogData } from "./RequestMeetingButton.Dialog.next";
import RequestMeetingDialog from "./RequestMeetingButton.Dialog.next";

const fragment = graphql(`
  fragment RequestMeetingButton on SiteUserProfile {
    ...RequestMeetingDialog
  }
`);

export default function RequestMeetingButton({
  profile: data,
  ...rest
}: {
  profile: FragmentType<typeof fragment>;
}) {
  const profile = useFragment(fragment, data);
  const { collectionId } = useCollectionLayout();
  const { isMeetingBookingEnabled } = useSiteConfig();
  const locale = useLocale();
  const [isOpen, setOpen] = useState(false);
  if (!isMeetingBookingEnabled) return null;
  return (
    <ActionButton onClick={() => setOpen(true)} {...rest}>
      <CalendarBlank size={16} weight="light" />
      <Trans>Meet</Trans>
      {isOpen && (
        <RequestMeetingDialog
          collectionId={collectionId}
          locale={locale || "en"}
          profile={profile}
          onDismiss={() => setOpen(false)}
          isOpen={isOpen}
        />
      )}
    </ActionButton>
  );
}

export type RequestMeetingButtonData = RequestMeetingDialogData & {};

export function RequestMeetingButtonSkeleton(
  rest: Omit<ActionButtonProps, "children">
) {
  const { isChatEnabled } = useSiteConfig();
  if (!isChatEnabled) return null;
  return (
    <ActionButton disabled {...rest}>
      <CalendarBlank size={16} weight="light" />
      <Trans>Meet</Trans>
    </ActionButton>
  );
}

/** @jsxImportSource theme-ui */
import { Flex, Text } from "@bottlebooks/gatsby-design-system";
import { FragmentType, graphql, useFragment } from "~/gql";
import varietiesIcon from "../../assets/varieties.svg";

const fragment = graphql(`
  fragment ProductVarieties on Product {
    __typename
    ... on Wine {
      grapeVarieties {
        varietyName
      }
    }
  }
`);

export default function ProductVarieties({
  data,
  children,
}: {
  data: FragmentType<typeof fragment>;
  children: React.ReactNode;
}) {
  const product = useFragment(fragment, data);
  const wine = product.__typename === "Wine" ? product : null;
  if (!children && wine?.grapeVarieties) {
    children = wine.grapeVarieties
      .filter(Boolean)
      .map((v) => v.varietyName)
      .join(", ");
  }
  if (!children) return null;
  return (
    <Flex>
      <img
        src={varietiesIcon}
        width={16}
        height={16}
        alt=""
        sx={{ marginRight: 1, marginTop: "2px" }}
      />
      <Text variant="smallest" sx={{ color: "text" }}>
        {children}
      </Text>
    </Flex>
  );
}

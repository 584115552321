import { gql } from "urql";
import useMenuQuery from "../../../@bottlebooks/bottlebooks-site-base/components/Layout/mapToMenuItems";
import { useEventThemeFragment } from "../../../@bottlebooks/bottlebooks-site-base/components/Layout/useEventTheme";

export const siteQuery = gql`
  query ($collectionId: String!) {
    allSite(where: { collectionId: { eq: $collectionId } }) {
      frontpage {
        __typename
        id: _id
        title
        slug {
          current
        }
      }
      ...useEventThemeFragment
      # ...useFooterQueryFragment
      ...mapToMenuItems
      # ...TicketButtonFragment
    }
  }
  ${useMenuQuery.fragment}
  # $ {useFooterQueryFragment}
  # $ {TicketButtonFragment}
  ${useEventThemeFragment}
`;

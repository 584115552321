/** @jsxImportSource theme-ui */
import { Box } from "@bottlebooks/gatsby-theme-base/src";
import ClaimRepresentativeProfileButton from "./ClaimRepresentativeNotificationBar";
import Footer from "./Footer";
import FooterLogo from "./FooterLogo";
import Header from "./Header";
import MainMenu from "./MainMenu.next";
// import useEventTheme from "./useEventTheme";
// import useMenuQuery from "./useMenuQuery";
// import { useScroll } from './useScroll';
import useLink from "../../useLink";

export default function Layout({
  children,
  data,
  event,
  locale,
  hideSearch = false,
  ...rest
}: {
  children: React.ReactNode;

  event: any;
  locale: string;
  hideSearch?: boolean;
  [key: string]: any;
}) {
  const link = useLink();
  // TODO: make landing page configurable again.
  // At the moment, the landing page is always the overview page.
  // const landingPage = useLandingPage({ locale });
  const landingPage = link.default();

  // Scrolling is currently disabled because it causes significant
  // peformance degredation on Safari. It should only be reactivated
  // once this issue has been resolved.
  // const { showHeader, isScrolledDown } = useScroll();
  const isScrolledDown = false;
  const showHeader = true;
  return (
    <Box sx={{ minHeight: "100vh" }}>
      <Header
        className="bb-header"
        homePath={landingPage}
        isScrolledDown={isScrolledDown}
        showHeader={showHeader}
        locale={locale}
        sx={{ width: "100%" }}
      >
        <MainMenu
          locale={locale}
          hideSearch={hideSearch}
          isScrolledDown={isScrolledDown}
          landingPage={landingPage}
          event={event}
        />
      </Header>
      <ClaimRepresentativeProfileButton />

      <Box as="main">{children}</Box>

      <Footer sx={{ marginTop: "auto" }}>
        <FooterLogo to={landingPage} event={event} />
      </Footer>
    </Box>
  );
}

// function useLandingPage({ locale }) {
//   const { event } = useStaticQuery(query);
//   const menuItems = useMenuQuery({ locale });
//   if (event.landingPage === 'default') return '/';
//   const item = menuItems.find((item) => item.type === event.landingPage);
//   if (!item) return '/';
//   return item.url;
// }

// const query = graphql`
//   query LandingPageQuery {
//     event {
//       locale
//       landingPage
//     }
//   }
// `;

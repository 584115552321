/** @jsxImportSource theme-ui */
import { SemiTitle } from "@bottlebooks/gatsby-design-system";
import { Col, Container, Row } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";
import MarketingActivity from "./MarketingActivity.next";

const fragment = graphql(`
  fragment MarketingActivitiesSection on AbstractBrand {
    marketing {
      ...MarketingActivity
    }
  }
`);

export default function MarketingActivitiesSection({
  brand: data,
  ...rest
}: {
  brand: FragmentType<typeof fragment>;
}) {
  const brand = useFragment(fragment, data);
  if (!brand) return null;
  // Manually check for virtual event content.
  if (!brand.marketing?.length) return null;
  return (
    <Section
      sx={{ backgroundColor: "primary", color: "onPrimary", paddingY: 4 }}
      {...rest}
    >
      <Container>
        <Row
          sx={{
            paddingX: [null, `${100 / 12}%`], // 1 col spacing from medium width up.
          }}
        >
          <Col sx={{ width: ["100%", "70%"] }}>
            <SemiTitle sx={{ borderBottom: 1, paddingY: 3, marginY: 0 }}>
              <Trans>Marketing activities</Trans>
            </SemiTitle>
            {brand.marketing?.length
              ? brand.marketing?.map((marketingActivity) => (
                  <MarketingActivity
                    key={marketingActivity.type}
                    variant="onPrimary"
                    activity={marketingActivity}
                    sx={{ borderBottom: 1, borderColor: "currentColor" }}
                  />
                ))
              : null}
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

/** @jsxImportSource theme-ui */

export default function List({ children, ...rest }) {
  return (
    <div
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
      {...rest}
    >
      {children}
    </div>
  );
}

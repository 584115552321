import type {
  ApolloError,
  DocumentNode,
  FetchResult,
  MutationHookOptions,
  MutationResult,
  OperationVariables,
} from "@apollo/client";
import { useMutation } from "@tanstack/react-query";
import type { TypedDocumentNode } from "@graphql-typed-document-node/core";
import fetchGraphQL from "./fetchGraphQL";

export type MutationResponse<Data extends Record<string, unknown>> = {
  response:
    | { success: false; code: string; message: string; __typename: string }
    | ({
        success: true;
        code: string;
        __typename: string;
        message: string;
      } & Data);
};
export type ResponseCallbacks<Data extends MutationResponse<{}>> = {
  onSuccess?: (
    response: NonNullable<FetchResult<Data>["data"]>["response"],
    mutationResult: MutationResult<Data>
  ) => void | Promise<void>;
  onError?: (
    response: FetchResult<Data>,
    mutationResult: MutationResult<Data>
  ) => void | Promise<void>;
};

/**
 * A more opinionated mutation hook that returns a state object instead of the Apollo result.
 * Use this to get a predictable state object that can be used to show a loading indicator, error message, or success message.
 * It also adds success and error callbacks.
 *
 * @example
 * ```tsx
 * const [submit, state] = useMutationState(RESCHEDULE_MEETING);
 * switch (state.type) {
 *  case 'idle':
 *    return <button onClick={() => submit({ variables: { ... } })}>Submit</button>;
 *  case 'submitting':
 *    return <LoadingIndicator />;
 *  case 'error':
 *    return <ErrorMessage message={state.message} reset={state.reset} />;
 *  case 'success':
 *    return <SuccessMessage />;
 * }
 * ```
 *
 * @param query The query. The mutation needs to be aliased to 'response', and the response needs to have a 'success' flag.
 * @param options The mutation options as defined by Apollo.
 * @returns
 */
export default function useMutationState<
  Data extends MutationResponse<{}>,
  Variables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<Data, Variables>,
  options?: MutationHookOptions<Data, Variables>,
  callbacks: ResponseCallbacks<Data> = {
    onSuccess: () => {},
    onError: () => {},
  }
) {
  console.log("useMutationState", query);
  const mutation = useMutation({
    mutationFn: async (options: MutationHookOptions<Data, Variables>) => {
      const result = await fetchGraphQL(query, options);
      return result.data;
    },
    onSuccess: async (data) => {
      if (data?.response.success) {
        await callbacks?.onSuccess?.(data.response, data.response);
      }
      if (data?.response.success === false) {
        await callbacks?.onError?.(data.response, data.response);
      }

      return data;
    },
    onError: async (error) => {
      await callbacks?.onError?.(error, {});
    },
  });

  const { data, reset } = mutation;
  async function submit(options: MutationHookOptions<Data, Variables>) {
    console.log("submit", options);
    mutation.mutate(options);
  }
  if (mutation.isIdle) return [submit, { type: "idle" }] as const;
  if (mutation.isPending) return [submit, { type: "submitting" }] as const;
  if (mutation.isError) {
    // We add the types inline so the automcompletion is easier to read.
    return [
      submit,
      {
        type: "error",
        message: mutation.error.message,
        error: mutation.error,
        reset,
      } as {
        type: "error";
        message: string;
        error?: ApolloError;
        reset: () => void;
      },
    ] as const;
  }
  if (data) {
    if (data.response.success) {
      const { success, __typename, ...rest } = data.response;
      return [submit, { type: "success", ...rest }] as const;
    }
    if (data.response.success === undefined) {
      // We forgot to include the success flag in the query.
      // Maybe throw instead?
      return [
        submit,
        {
          type: "error",
          message: `The query didn't return the 'success' flag, so we cannot determine if the mutation was executed successfully or not. Please check the query and add 'response { success }'.`,
          reset,
        } as {
          type: "error";
          message: string;
          error?: ApolloError;
          reset: () => void;
        },
      ] as const;
    }
    return [
      submit,
      {
        type: "error",
        message: data.response?.message,
        reset,
      } as {
        type: "error";
        message: string;
        error?: ApolloError;
        reset: () => void;
      },
    ] as const;
  }
  throw new Error(`Unexpected result: ${JSON.stringify(result)}`);
}

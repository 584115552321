/** @jsxImportSource theme-ui */
import { Box, Flex, Label, Text } from "@bottlebooks/gatsby-theme-base/src";
import React from "react";
import Mark from "../Mark";
import ProductImage from "../Product/ProductImage";
import ListRowSmall from "./ListRowSmall";
import { graphql, useFragment } from "~/gql";

const fragment = graphql(/* GraphQL */ `
  fragment ProductListRowSmall on RegisteredProduct {
    productId
    registration {
      profile {
        name
      }
    }
    product {
      __typename
      ...ProductImageLarge_list
      shortName
      ... on Wine {
        vintage(removeNonVintage: true)
      }
      producer {
        name
      }
    }
  }
`);

/**
 * @param {object} props
 * @param {{ shortName: string?; vintage?: string?; producer: { name: string? }?; exhibitor: { name: string? }? }} props.product;
 * @param {string} [props.searchTerm]
 * @param {string} [props.to]
 */
export default function ProductListRowSmall({
  data,
  searchTerm,
  to,
  onClick,
  ...rest
}) {
  if (!data) return null;
  const registeredProduct = useFragment(fragment, data);
  const product = registeredProduct.product;
  const wine = product.__typename === "Wine" ? product : null;
  const producerName =
    product.producer?.name || registeredProduct.registration.profile?.name;
  const productName = `${product.shortName || ""} ${wine?.vintage || ""}`;
  return (
    <ListRowSmall sx={{ color: "gray" }} onClick={onClick} to={to} {...rest}>
      <Flex gap={2} align="center">
        <ProductImage size="smaller" product={product} />
        {searchTerm ? (
          <Box>
            {/* Try to find the search term in the product name first. */}
            <Mark
              text={productName}
              term={searchTerm}
              render={(match) => (
                <span sx={{ fontWeight: "bold", color: "black" }}>{match}</span>
              )}
              Wrapper={({ children }) => (
                <React.Fragment>
                  <SmallLabel>{producerName}</SmallLabel>
                  <Text sx={{ fontWeight: "regular", color: "gray" }}>
                    {children}
                  </Text>
                </React.Fragment>
              )}
              // As a fallback, find the search term in the producer name and the rest in the product name.
              fallback={() => (
                <Mark
                  text={producerName}
                  term={searchTerm}
                  render={(match) => (
                    <span sx={{ fontWeight: "bold", color: "black" }}>
                      {match}
                    </span>
                  )}
                  Wrapper={SmallLabel}
                  // appends the product name, searching for the remaining term there.
                  append={(remainingTerm) => (
                    <Text sx={{ fontWeight: "regular", color: "gray" }}>
                      <Mark
                        text={productName}
                        term={remainingTerm}
                        originalTerm={searchTerm}
                        render={(match) => (
                          <span sx={{ fontWeight: "bold", color: "black" }}>
                            {match}
                          </span>
                        )}
                      />
                    </Text>
                  )}
                />
              )}
            />
          </Box>
        ) : (
          <Box>
            <SmallLabel>{producerName}</SmallLabel>
            <Text>{productName}</Text>
          </Box>
        )}
      </Flex>
    </ListRowSmall>
  );
}

function SmallLabel(props) {
  return (
    <Label
      variant="small"
      sx={{ fontWeight: "regular", color: "gray" }}
      {...props}
    />
  );
}

/** @jsxImportSource theme-ui */
import { Link } from "@bottlebooks/gatsby-theme-base/src";
import { SanityImage } from "sanity-image";
import { useCollectionLayout } from "../CollectionLayoutProvider.next";

export default function Logo({
  to,
  isScrolledDown = undefined,
  width = 100,
  height = 100,
  ...rest
}: {
  to?: string;
  isScrolledDown?: boolean;
  width?: number;
  height?: number;
}) {
  const collection = useCollectionLayout();
  if (!collection.logo) return null;

  if (!collection.isSanity) {
    if (!to)
      return (
        <img
          src={`https://res.cloudinary.com/bottlebooks/image/upload/h_${height},w_${width},c_pad,g_west,q_auto,dpr_2,f_auto/${collection.logo?.publicId}`}
          alt="Bottlebooks Site"
          width={width}
          height={height}
        />
      );
    return (
      // On logos, it's allowed to use external links on 'to' (to possibly link to a main website).
      // The Link component takes care of that. We need to suppress the warning though.
      <Link to={to} _suppressExternalLinkWarning {...rest}>
        <img
          src={`https://res.cloudinary.com/bottlebooks/image/upload/h_${height},w_${width},c_pad,g_west,q_auto,dpr_2,f_auto/${collection.logo?.publicId}`}
          alt="Bottlebooks Site"
          width={width}
          height={height}
        />
      </Link>
    );
  }

  // This shouldn't happen
  if (!collection.projectId) throw new Error("No projectId");

  if (!to)
    return (
      <SanityLogoImage
        logo={collection.logo}
        projectId={collection.projectId}
        dataset="production"
        width={width}
        height={height}
        alt="Bottlebooks Site"
      />
    );
  return (
    // On logos, it's allowed to use external links on 'to' (to possibly link to a main website).
    // The Link component takes care of that. We need to suppress the warning though.
    <Link to={to} _suppressExternalLinkWarning {...rest}>
      <SanityLogoImage
        logo={collection.logo}
        projectId={collection.projectId}
        dataset="production"
        width={width}
        height={height}
        alt="Bottlebooks Site"
      />
    </Link>
  );
}
function SanityLogoImage({
  logo,
  projectId,
  width,
  height,
  alt,
  ...rest
}: {
  logo: any;
  projectId: string;
  width: number;
  height: number;
  alt: string;
  dataset: "production" | "development";
}) {
  return (
    <SanityImage
      {...toSanityImage({ image: logo, projectId })}
      {...logo}
      width={100}
      // height={Array.isArray(height) ? height[height.length - 1] : height} // TODO height doesn't work due to a bug in sanity/image-url upstream library: https://github.com/sanity-io/image-url/issues/32
      // alt={brand.name}
      sizes={`(max-width: 40em) 100px`}
      alt={alt}
      sx={{
        objectFit: "contain",
        // Always ensure the images are full-width
        width,
        height,
        borderRadius: "default",
      }}
      {...rest}
    />
  );
}

function toSanityImage({
  image,
  projectId,
  dataset = "production",
}: {
  image: any | null;
  projectId: string;
  dataset?: "production" | "development";
}) {
  if (!image) return null;
  return {
    ...image,
    id: image.asset._id,
    projectId,
    dataset,
  };
}

/** @jsxImportSource theme-ui */
import { Box } from "@bottlebooks/gatsby-theme-base/src";
import React from "react";
import { InterfaceLocale } from "~/gql/graphql";
import MainMenuDesktop from "./MainMenuDesktop.next";
import MainMenuMobile from "./MainMenuMobile";
import useMenuQuery from "./useMenuQuery";

const MainMenu = React.memo(function MainMenu({
  event,
  isScrolledDown = false,
  hideSearch,
  landingPage,
  locale,
  ...rest
}: {
  event: { site: { menuItems: any[] } };
  isScrolledDown?: boolean;
  hideSearch?: boolean;
  landingPage: string;
  locale: InterfaceLocale;
  [key: string]: any;
}) {
  // TODO: Move CMS query to Bottlebooks resolvers?
  const cmsMenuItems = useMenuQuery({ locale });
  const menuItems = cmsMenuItems?.length ? cmsMenuItems : event.site.menuItems;
  if (!menuItems?.length) return null;
  return (
    <Box sx={{ width: "100%" }}>
      <MainMenuDesktop
        sx={{ display: ["none", null, "inherit"] }}
        menuItems={menuItems}
        landingPage={landingPage}
        isScrolledDown={isScrolledDown}
        hideSearch={hideSearch}
        locale={locale}
        event={event}
        {...rest}
      />
      <MainMenuMobile
        sx={{ display: ["inherit", null, "none"] }}
        menuItems={menuItems}
        landingPage={landingPage}
        isScrolledDown={isScrolledDown}
        locale={locale}
        event={event}
        {...rest}
      />
    </Box>
  );
});
export default MainMenu;

/** @jsxImportSource theme-ui */
import { P, SemiTitle, Text } from "@bottlebooks/gatsby-design-system";
import { Col, Row } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";

const fragment = graphql(`
  fragment ProductAnalytics on Product {
    __typename
    alcohol
    residualSugar
    totalAcidity
    ... on Wine {
      designation
      pH
    }
  }
`);

const analyticFormat = {
  minimumFractionDigits: 1,
  maximumFractionDigits: 2,
};

export default function ProductAnalytics({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const { i18n } = useLingui();
  const product = useFragment(fragment, data);
  const wine = product.__typename === "Wine" ? product : null;
  if (!hasContent(data)) return null;
  const { alcohol, residualSugar, totalAcidity } = product;
  const { designation, pH } = wine || {};
  return (
    <Section {...rest}>
      <Row>
        {alcohol != null && (
          <Col sx={{ width: [null, "50%"] }}>
            <SemiTitle>
              <Trans>Alcohol</Trans>
            </SemiTitle>
            <P variant="title">{i18n.number(alcohol, analyticFormat)}%</P>
          </Col>
        )}
        {(residualSugar != null ||
          totalAcidity != null ||
          designation != null) && (
          <Col sx={{ width: [null, "50%"] }}>
            <SemiTitle>
              <Trans>Analytical data</Trans>
            </SemiTitle>
            {designation && <P variant="title">{designation}</P>}
            {residualSugar != null && (
              <Text>
                <Trans>
                  {i18n.number(residualSugar, analyticFormat)} g/l residual
                  sugar
                </Trans>
              </Text>
            )}
            {totalAcidity != null && (
              <Text>
                <Trans>
                  {i18n.number(totalAcidity, analyticFormat)} g/l acidity
                </Trans>
              </Text>
            )}
            {pH != null && (
              <Text>
                <Trans>{i18n.number(pH, analyticFormat)} pH</Trans>
              </Text>
            )}
          </Col>
        )}
      </Row>
    </Section>
  );
}

function hasContent(data: FragmentType<typeof fragment>) {
  const product = useFragment(fragment, data);
  if (!product) return false;
  if (product.alcohol != null) return true;
  if (product.residualSugar != null) return true;
  if (product.totalAcidity != null) return true;
  if (product.designation != null) return true;
  if (product.pH != null) return true;
  return false;
}

import { graphql } from "~/gql";
import { matchSorter, rankings } from "match-sorter";
import React from "react";

const getProducerAddress = (item) =>
  `${item.countryName || ""} ${item.region || ""} ${item.city || ""}`.trim();

export default function useSearch(items, query) {
  return React.useMemo(() => {
    if (!query) return items;
    return matchSorter(items, query, {
      keys: ["name", getProducerAddress, "stand.name"],
      threshold: rankings.ACRONYM,
      // This baseSort function will use the original index of items as the tie breaker
      baseSort: (a, b) => (a.index < b.index ? -1 : 1),
    });
  }, [items, query]);
}

export const fragment = graphql(`
  fragment Search_Producer on Producer {
    name
    countryName: country(full: true)
    region
    city
    stand {
      title
    }
  }
`);

/** @jsxImportSource theme-ui */
import { Box, SemiTitle } from "@bottlebooks/gatsby-design-system";

/**
 *
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {string | JSX.Element | false} [props.title]
 */
export default function Section({ children, title, ...rest }) {
  return (
    <Box as="section" sx={{ marginY: 4 }} {...rest}>
      {title && <SemiTitle>{title}</SemiTitle>}
      {children}
    </Box>
  );
}

Section.Title = SemiTitle;

/** @jsxImportSource theme-ui */
import ProductCard from "@bottlebooks/bottlebooks-site-base/src/components/ProductCard/ProductCard.next";
import ProductCardSmall from "@bottlebooks/bottlebooks-site-base/src/components/ProductCard/ProductCardSmall";
import React from "react";

/**
 * @param {object} props
 * @param {{ productId: string } | undefined} props.product
 * @param {'small' | 'large'} [props.variant]
 * @param {React.MouseEventHandler} [props.onClick]
 * @param {React.CSSProperties} [props.style]
 * @param {Parameters<ProductCard>[0]["elevation"]} [props.elevation]
 * @param {string} [props.to]
 */
export default function BookmarkedProduct({ product, variant }) {
  if (!product) return null;
  if (variant === "large") {
    return <ProductCard product={product} elevation="flat" />;
  }
  return <ProductCardSmall product={product} />;
}

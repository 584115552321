/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import {
  Button,
  Flex,
  Grid,
  Label,
  Text,
  Title,
} from "@bottlebooks/gatsby-theme-base/src";
import {
  ExhibitorCard,
  ExhibitorsList,
} from "@bottlebooks/bottlebooks-site-base/src/components/ExhibitorsList";
import useEventData from "@bottlebooks/bottlebooks-site-base/src/components/SiteSearch/useEventData";
import getOnAccentColor from "@bottlebooks/bottlebooks-site-base/src/helpers/getOnAccentColor";
import useLink from "@bottlebooks/bottlebooks-site-base/src/useLink";
import { Trans } from "@lingui/macro";
// import { graphql } from "gatsby";
import { RichText, Section } from ".";
import ImageBlock from "./Blocks/ImageBlock";
import Slider from "./Sections/Slider/Slider";

/**
 *
 * @param {object} props
 * @param {string} props.name
 * @param {object} props.mainImage
 * @returns
 */
export default function SanityRegion({
  title,
  locale,
  mainImage,
  name,
  statistics,
  characteristics,
  mapImage,
  description,
  grapeVarieties,
  regionCode,
  shortDescription,
}) {
  const link = useLink();
  const exhibitors = useExhibitorsFromRegion({ regionCode });

  return (
    <Box>
      <Slider image={mainImage} title={name} />
      <Section sx={{ paddingY: 6 }}>
        <Title sx={{ textAlign: "center" }}>{shortDescription}</Title>
      </Section>
      <Section variant="grey" sx={{ paddingY: 5 }}>
        <Title sx={{ paddingBottom: 4, textAlign: "center" }}>
          Key statistics
        </Title>
        <Flex
          justify="space-evenly"
          sx={{
            flexDirection: ["column", null, "row"],
            alignItems: [null, null, "flex-end"],
          }}
        >
          {statistics.map(({ value, label }) => (
            <Flex
              direction="column"
              align="center"
              sx={{ backgroundColor: "accent", padding: 3, margin: 2 }}
            >
              <Title
                sx={(theme) => {
                  return {
                    textAlign: "center",
                    // Avoid hover effect
                    color: getOnAccentColor(theme.colors.accent),
                  };
                }}
              >
                {value}
              </Title>
              <Label
                sx={(theme) => {
                  return {
                    textAlign: "center",
                    // Avoid hover effect
                    color: getOnAccentColor(theme.colors.accent),
                  };
                }}
                variant="large"
              >
                {label}
              </Label>
            </Flex>
          ))}
        </Flex>
      </Section>
      <Section sx={{ paddingTop: 5, paddingBottom: 5 }}>
        <Grid
          columns={[1, null, 2]}
          gap={4}
          sx={{ gridTemplateColumns: "repeat(1fr)" }}
        >
          <ImageBlock image={mapImage} />
          <Flex direction="column">
            <Title sx={{ marginBottom: 3 }}>Characteristics</Title>
            {characteristics.map(({ value, label }) => (
              <Flex direction="column" sx={{ marginBottom: 3 }}>
                <Label>{label}</Label>
                <Text>{value}</Text>
              </Flex>
            ))}
            <Flex direction="column" sx={{ marginBottom: 3 }}>
              <Label>Primary varieties</Label>
              <Text>
                {grapeVarieties
                  .map(({ varietyName, percentage }) =>
                    percentage
                      ? `${percentage}% ${varietyName}`
                      : `${varietyName}`
                  )
                  .join(", ")}
              </Text>
            </Flex>
          </Flex>
        </Grid>
      </Section>

      <Section variant="grey" sx={{ paddingBottom: 5, paddingTop: 5 }}>
        <Title sx={{ paddingBottom: 3 }}>About the region</Title>
        <Text
          sx={{
            columnCount: [1, null, 2],
            columnGap: [0, null, 3],
            padding: 0,
          }}
        >
          <RichText>{description}</RichText>
        </Text>
      </Section>
      {exhibitors.length > 0 ? (
        <Section sx={{ paddingBottom: 5, paddingTop: 5 }}>
          <Title sx={{ textAlign: "center", marginBottom: 4 }}>
            Exhibitors with wines from this region
          </Title>
          <ExhibitorsList>
            {exhibitors.map((exhibitor) => (
              <ExhibitorCard
                key={exhibitor.exhibitorId}
                to={link.exhibitor(exhibitor)}
                exhibitor={exhibitor}
              />
            ))}
          </ExhibitorsList>
        </Section>
      ) : (
        <Section sx={{ paddingBottom: 5, paddingTop: 5 }}>
          <Title sx={{ textAlign: "center", marginBottom: 4 }}>
            There are no exhibitors at this event with wines from this region
          </Title>
          <Flex justify="center">
            <Button variant="primary" to={"../"}>
              <Trans>Browse other regions</Trans>
            </Button>
          </Flex>
        </Section>
      )}
    </Box>
  );
}

function useExhibitorsFromRegion({ regionCode }) {
  const { products } = useEventData();
  const filteredProducts = products.filter(
    (product) => product.subregion === regionCode
  );
  const exhibitorsMap = filteredProducts?.reduce((acc, product) => {
    acc[product.exhibitor.exhibitorId] = product.exhibitor;
    return acc;
  }, {});
  return Object.values(exhibitorsMap);
}

// export const fragment = graphql`
//   fragment SanityRegion on SanityRegion {
//     id: _id
//     name
//     regionCode
//     mainImage {
//       ...ImageWithPreview
//     }
//     association {
//       state
//     }
//     mapImage {
//       ...ImageWithPreview
//     }
//     statistics {
//       value
//       label
//     }
//     characteristics {
//       value
//       label
//     }
//     description: _rawDescription
//     shortDescription
//     grapeVarieties {
//       varietyName
//       percentage
//     }
//     regionHierarchy {
//       countryCode
//       regionCode
//       subregionCodes
//     }
//   }
// `;

/**
 * This is for preview.
 *
 * Was not successful getting ${Component.fragment} pattern to work. Runtime complains of null.
 *
 */
SanityRegion.fragment = gql`
  fragment SanityRegion on Region {
    id: _id
    regionCode
    name
    mainImage {
      ...ImageWithPreview
    }
    association {
      state
    }
    mapImage {
      ...ImageWithPreview
    }
    description: descriptionRaw
    shortDescription
    grapeVarieties {
      varietyName
      percentage
    }
    statistics {
      value
      label
    }
    characteristics {
      value
      label
    }
    regionHierarchy {
      countryCode
      regionCode
      subregionCodes
    }
  }

  fragment ImageWithPreview on Image {
    asset {
      _id
    }
    hotspot {
      height
      width
      x
      y
    }
    crop {
      bottom
      left
      right
      top
    }
  }
`;

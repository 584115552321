// Generouted, changes to this file will be overriden
/* eslint-disable */

import { components, hooks, utils } from '@generouted/react-router/client'

export type Path =
  | `/`
  | `/:locale/collections/:collectionId`
  | `/:locale/collections/:collectionId/:slug`
  | `/:locale/collections/:collectionId/bb-admin`
  | `/:locale/collections/:collectionId/e/:exhibitorId`
  | `/:locale/collections/:collectionId/exhibitors`
  | `/:locale/collections/:collectionId/exhibitors/:exhibitorId`
  | `/:locale/collections/:collectionId/exhibitors/filters`
  | `/:locale/collections/:collectionId/my/bookmarks`
  | `/:locale/collections/:collectionId/my/lists`
  | `/:locale/collections/:collectionId/my/profile`
  | `/:locale/collections/:collectionId/my/tasting-notes`
  | `/:locale/collections/:collectionId/producers`
  | `/:locale/collections/:collectionId/producers/:producerId`
  | `/:locale/collections/:collectionId/products`
  | `/:locale/collections/:collectionId/products/by-exhibitor/:exhibitorId/:productId`
  | `/:locale/collections/:collectionId/products/filters`
  | `/:locale/workspaces/:workspaceId/collections`
  | `/collections/:collectionId/*`

export type Params = {
  '/:locale/collections/:collectionId': { locale: string; collectionId: string }
  '/:locale/collections/:collectionId/:slug': { locale: string; collectionId: string; slug: string }
  '/:locale/collections/:collectionId/bb-admin': { locale: string; collectionId: string }
  '/:locale/collections/:collectionId/e/:exhibitorId': { locale: string; collectionId: string; exhibitorId: string }
  '/:locale/collections/:collectionId/exhibitors': { locale: string; collectionId: string }
  '/:locale/collections/:collectionId/exhibitors/:exhibitorId': { locale: string; collectionId: string; exhibitorId: string }
  '/:locale/collections/:collectionId/exhibitors/filters': { locale: string; collectionId: string }
  '/:locale/collections/:collectionId/my/bookmarks': { locale: string; collectionId: string }
  '/:locale/collections/:collectionId/my/lists': { locale: string; collectionId: string }
  '/:locale/collections/:collectionId/my/profile': { locale: string; collectionId: string }
  '/:locale/collections/:collectionId/my/tasting-notes': { locale: string; collectionId: string }
  '/:locale/collections/:collectionId/producers': { locale: string; collectionId: string }
  '/:locale/collections/:collectionId/producers/:producerId': { locale: string; collectionId: string; producerId: string }
  '/:locale/collections/:collectionId/products': { locale: string; collectionId: string }
  '/:locale/collections/:collectionId/products/by-exhibitor/:exhibitorId/:productId': { locale: string; collectionId: string; exhibitorId: string; productId: string }
  '/:locale/collections/:collectionId/products/filters': { locale: string; collectionId: string }
  '/:locale/workspaces/:workspaceId/collections': { locale: string; workspaceId: string }
  '/collections/:collectionId/*': { collectionId: string; '*': string }
}

export type ModalPath = never

export const { Link, Navigate } = components<Path, Params>()
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>()
export const { redirect } = utils<Path, Params>()

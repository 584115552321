import * as XLSX from "xlsx/xlsx.mjs";
import { useProducts } from "@bottlebooks/bottlebooks-site-base/src/components/SiteSearch/useEventData";
import { useTastingNotes } from "./useTastingNote";
import { useEnrichedFavorites } from "@bottlebooks/bottlebooks-site-bookmarks/src";
import { uniq, sortBy } from "lodash";

/**
 * This export includes a union of favorite and as well as products with tasting notes.
 * While adding a tasting note automatically favorites a product, the user can also
 * unfavorite them. Since we are only exposing a single export in the interface,
 * we need to ensure the complete data set is exported.
 *
 * Important! This export does not include favorited exhibitors. It exports products only!
 */
export default function useTastingNoteExport() {
  const { all: tastingNotes = [] } = useTastingNotes();
  const favorites = useEnrichedFavorites();
  const isProductLedEvent = favorites?.some(
    (product) => !!product.productLabel
  );
  const { all: products } = useProducts();
  const myListMap = favorites?.reduce((acc, item) => {
    acc[item.id] = item.list?.name;
    return acc;
  }, {});

  // We are ignoring favorited exhibitors here!
  const usedProductIds = []
    .concat(tastingNotes, favorites)
    .map((item) => item?.productId)
    .filter(Boolean);

  const uniqueProductIds = uniq(usedProductIds);

  const mergedData = uniqueProductIds?.map((productId) => {
    const productData = products?.find((p) => p.productId === productId);
    const tastingNote = tastingNotes?.find((p) => p.productId === productId);
    const favorite = favorites?.find((p) => p.productId === productId);
    return {
      ...productData,
      ...tastingNote,
      ...favorite,
    };
  });

  const sortedProducts = sortBy(mergedData, [
    "list.name",
    "exhibitor.stand.name",
    "exhibitor.name",
    "producer.name",
    "shortName",
  ]);

  function exportAsExcel() {
    const header = [
      "My List",
      ...(isProductLedEvent
        ? ["Flight name", "Product Label"]
        : ["Stand", "Exhibitor"]),
      "Stand",
      "Exhibitor",
      "Producer",
      "Name",
      "Vintage",
      "Varieties",
      "Country",
      "Region",
      "Subregion",
      "Classification",
      "Special classification",
      "Tasting note",
      "Rating",
      "Product Id",
    ];
    const columnWidths = [30, 30, 30, 30, 30, 10, 30, 15, 15, 15, 20, 20, 20];

    const data = sortedProducts?.map(
      ({
        exhibitor,
        producer,
        shortName,
        vintage,
        grapeVarieties,
        countryName,
        region,
        subregion,
        classification,
        specialClassification,
        note,
        rating,
        productId,
        flightName,
        productLabel,
      }) => [
        myListMap[productId],
        ...(isProductLedEvent
          ? [flightName, productLabel]
          : [exhibitor.stand?.title, exhibitor.name]),
        exhibitor.stand?.title,
        exhibitor.name,
        producer.name,
        shortName,
        vintage,
        grapeVarieties?.map(({ varietyName }) => varietyName).join(", "),
        countryName,
        region,
        subregion,
        classification,
        specialClassification,
        note,
        rating,
        productId,
      ]
    );
    const ws = XLSX.utils.aoa_to_sheet([header, ...data]);
    const wb = XLSX.utils.book_new();
    var wscols = columnWidths.map((width) => {
      return {
        wch: width,
      };
    });

    ws["!cols"] = wscols;
    XLSX.utils.book_append_sheet(wb, ws, "Event catalog");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "Tasting notes.xlsx");
  }
  return { exportAsExcel };
}

/** @jsxImportSource theme-ui */
import { Box, Section, SemiTitle } from "@bottlebooks/gatsby-design-system";
import getOnAccentColor from "@bottlebooks/bottlebooks-site-base/src/helpers/getOnAccentColor";
// import { graphql } from "gatsby";

/**
 *
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.subtitle
 * @param {string} props.sectionVariant
 * @param {'left' | 'center' | 'right'} props.alignment
 * @returns
 */
export default function TitleBlock({
  title,
  subtitle,
  alignment,
  sectionVariant,
  ...rest
}) {
  return (
    <Box {...rest}>
      <Section.Title
        sx={(theme) => {
          return {
            whiteSpace: "pre-line",
            lineHeight: 1.2,
            textAlign: alignment,
            color:
              sectionVariant === "accent"
                ? getOnAccentColor(theme.colors.accent)
                : "inherit",
          };
        }}
      >
        {title}
      </Section.Title>
      <SemiTitle
        sx={(theme) => {
          return {
            whiteSpace: "pre-line",
            lineHeight: 1.2,
            textAlign: alignment,
            color:
              sectionVariant === "accent"
                ? getOnAccentColor(theme.colors.accent)
                : "inherit",
          };
        }}
      >
        {subtitle}
      </SemiTitle>
    </Box>
  );
}

// export const fragment = graphql`
//   fragment SanityTitleBlockFragment on SanityTitleBlock {
//     title
//     subtitle
//     alignment
//   }
// `;

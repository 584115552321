/** @jsxImportSource theme-ui */
import { gql, useMutation } from "@apollo/client";
import {
  Box,
  Button,
  CloseIcon,
  Flex,
  Grid,
  SemiTitle,
  Text,
  Title,
} from "@bottlebooks/gatsby-theme-base/src";
import { Global } from "@emotion/react";
import { Trans } from "@lingui/macro";
import Dialog from "@reach/dialog";
import "@reach/dialog/styles.css";
import { ErrorMessage, Field, Form, Formik } from "formik";
import createPersistedState from "use-persisted-state";
import * as yup from "yup";
// import RandomAvatar from './Avatar';
import AvatarSubstitute from "./AvatarSubstitute";
const useProfileState = createPersistedState("bottlebooks:profile");

const schema = yup.object().shape({
  name: yup
    .string()
    .required("Please enter a name.")
    .trim() // This is handy because when calling schema.cast() you can auto-trim the string.
    // It also checks the min length after trimming.
    .min(3, "Please provide at least 3 characters."),
  companyName: yup
    .string()
    .required("Please enter a company name.")
    .trim() // This is handy because when calling schema.cast() you can auto-trim the string.
    // It also checks the min length after trimming.
    .min(3, "Please provide at least 3 characters."),
  email: yup
    .string()
    .required("Please enter your email address.")
    .trim()
    .email(({ value }) => `"${value}" is not a valid email.`),
  message: yup
    .string()
    .required("Please enter a message.")
    .trim() // This is handy because when calling schema.cast() you can auto-trim the string.
    // It also checks the min length after trimming.
    .min(3, "Please provide at least 3 characters."),
  agreement: yup
    .bool()
    .required("We need your permission to contact the exhibitor.")
    .oneOf([true], "We need your permission to contact the exhibitor."),
});

const initialValues = {
  name: "",
  companyName: "",
  email: "",
  message: "",
  agreement: false,
};

export default function BookAMeetingDialog({
  contact,
  exhibitor,
  companyId,
  collectionId,
  onDismiss,
  onSubmit,
  ...rest
}) {
  // Manage profile state locally until authentication is enabled
  const [profile, setProfile] = useProfileState({});
  const [sendContactRequest] = useMutation(SEND_CONTACT_REQUEST);
  async function sendRequest({
    message,
    name,
    email,
    companyName,
    availability = "",
    agreement,
  }) {
    setProfile({ name, email, companyName, agreement });
    // TODO handle failure` let {success} =
    await sendContactRequest({
      variables: {
        collectionId,
        companyId: exhibitor.exhibitorId,
        contactId: contact.contactId,
        sender: {
          name: name,
          email: email,
          companyName: companyName,
        },
        message,
        suggestedAppointments: { description: availability },
      },
    });
    onSubmit();
  }
  return (
    <Dialog
      isOpen
      onDismiss={onDismiss}
      aria-label="Book a meeting"
      sx={{
        position: "relative",
        borderRadius: "default",
        "&&": {
          margin: ["auto", null, null, "10vh auto"],
          width: ["100vw", "80vw", "75vw", 768],
          padding: 2,
        },
      }}
      {...rest}
    >
      <Button
        onClick={onDismiss}
        sx={{ position: "absolute", top: 2, right: 2 }}
      >
        <CloseIcon />
      </Button>
      <Global styles={{ "[data-reach-dialog-overlay]": { zIndex: 1 } }} />
      <Formik
        initialValues={{ ...initialValues, ...profile }}
        validationSchema={schema}
        validateOnMount={true}
        onSubmit={sendRequest}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <Grid sx={{ gridTemplateColumns: "200px auto" }}>
              <Grid sx={{ gridColumn: ["1 / -1"] }}>
                <Flex direction={["column", null, "row"]}>
                  <Flex
                    className="bb-hover"
                    sx={{
                      minWidth: 80,
                      width: 80,
                      height: 80,
                      marginRight: 3,
                      border: "3px solid",
                      borderRadius: "round",
                      borderColor: "primary",
                      backgroundColor: "white",
                      transition: "transform",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <AvatarSubstitute contact={contact} />
                  </Flex>

                  <Box sx={{ marginBottom: 3, flexGrow: 1 }}>
                    <SemiTitle>
                      <Trans>Book a call with</Trans>
                    </SemiTitle>
                    <Title>{contact.fullName}</Title>
                    <Text variant="small">{contact.companyName}</Text>
                  </Box>
                </Flex>
              </Grid>

              <SectionTitle index="1" sx={{ gridColumn: "1 / -1" }}>
                <Trans>Provide some context.</Trans>
              </SectionTitle>
              <Grid sx={{ gridColumn: ["1 / -1", "2 / -1"] }}>
                <TextField
                  name="message"
                  as="textarea"
                  rows={5}
                  sx={{ width: "100%" }}
                  placeholder="Explain what you are looking for."
                >
                  <Trans>Your message to {contact.fullName}:</Trans>
                </TextField>
              </Grid>

              <SectionTitle index="2" sx={{ gridColumn: "1 / -1" }}>
                <Trans>
                  Suggest a couple times that work for you. This is optional.
                </Trans>
              </SectionTitle>
              <Grid sx={{ gridColumn: ["1 / -1", "2 / -1"] }}>
                <TextField
                  name="availability"
                  as="textarea"
                  rows={5}
                  sx={{ width: "100%" }}
                  placeholder={`Provide some options for ${contact.fullName} to contact you.`}
                >
                  <Trans>
                    Your availability to speak with {contact.fullName}:
                  </Trans>
                </TextField>
              </Grid>
              <SectionTitle index="3" sx={{ gridColumn: "1 / -1" }}>
                <Trans>Tell them about you.</Trans>
              </SectionTitle>

              <Grid sx={{ gridColumn: ["1 / -1", "2 / -1"] }}>
                <TextField
                  autoComplete="name"
                  name="name"
                  sx={{ width: "50ch" }}
                >
                  <Trans>Your name:</Trans>
                </TextField>

                <TextField
                  autoComplete="organization"
                  name="companyName"
                  sx={{ width: "50ch" }}
                >
                  <Trans>Your company:</Trans>
                </TextField>
                <TextField name="email" type="email" sx={{ width: "35ch" }}>
                  <Trans>Your email:</Trans>
                </TextField>
              </Grid>
              <Grid sx={{ gridColumn: ["1 / -1", "2 / -1"] }}>
                <CheckboxField name="agreement">
                  <Text variant="small">
                    <Trans>
                      I agree to share my contact details with{" "}
                      {contact.fullName} and the owner of the website for the
                      purpose of facilitating communications.
                    </Trans>
                  </Text>
                </CheckboxField>
              </Grid>
              <Grid sx={{ gridColumn: ["1 / -1", "2 / -1"] }}>
                <Button
                  sx={{
                    marginLeft: "auto",
                    ...(isSubmitting && { opacity: 0.5 }),
                  }}
                  type="submit"
                  variant="primary"
                  disabled={isSubmitting}
                >
                  <Trans>Send request</Trans>
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

function SectionTitle({ index, children, ...rest }) {
  return (
    <Flex
      gap={2}
      align="baseline"
      sx={{ borderBottom: 1, borderColor: "borderSecondary" }}
      {...rest}
    >
      <Title
        sx={{
          color: "lighterText",
          order: [0, 0],
          width: 25,
          flex: "0 0 auto",
        }}
      >
        {index}
      </Title>
      <SemiTitle sx={{ flex: "1 1 auto" }}>{children}</SemiTitle>
    </Flex>
  );
}

function TextField({ name, children, id = name, ...rest }) {
  return (
    <Box>
      <Box as="label" sx={{ display: "block" }}>
        <Text variant="small">{children}</Text>
        <Field
          id={id}
          name={name}
          sx={{
            border: 1,
            borderColor: "borderSecondary",
            paddingX: 2,
            paddingY: 1,
            display: "block",
            maxWidth: "100%",
            fontFamily: "body",
            fontSize: "small",
          }}
          {...rest}
        />
      </Box>
      <ErrorMessage name={name} component={Error} sx={{ marginBottom: 1 }} />
    </Box>
  );
}

function CheckboxField({ name, children, id = name, ...rest }) {
  return (
    <Box>
      <Text as="label" sx={{ display: "inline-block" }}>
        <Flex>
          <Field
            type="checkbox"
            id={id}
            name={name}
            sx={{
              border: 1,
              borderColor: "borderSecondary",
              padding: 2,
              marginTop: 1,
              marginRight: 1,
              flex: "0 0 auto",
              width: "20px",
            }}
            {...rest}
          />{" "}
          <Box>{children}</Box>
        </Flex>
      </Text>
      <ErrorMessage name={name} component={Error} sx={{ marginBottom: 1 }} />
    </Box>
  );
}

function Error({ children, ...rest }) {
  if (!children) return null;
  return (
    <Text sx={{ color: "red", fontSize: "small" }} {...rest}>
      {children}
    </Text>
  );
}

export const SEND_CONTACT_REQUEST = gql`
  mutation sendContactRequest(
    $collectionId: ID!
    $companyId: ID!
    $contactId: ID!
    $message: String!
    $suggestedAppointments: AppointmentInput
    $sender: SenderInput!
    $productIds: [ID]
  ) {
    result: sendContactRequest(
      input: {
        collectionId: $collectionId
        companyId: $companyId
        contactId: $contactId
        message: $message
        suggestedAppointments: $suggestedAppointments
        sender: $sender
        productIds: $productIds
      }
    ) {
      code
      success
    }
  }
`;

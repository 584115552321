/** @jsxImportSource theme-ui */
import { Badge, Label } from "@bottlebooks/gatsby-design-system";
import { Link } from "@bottlebooks/gatsby-theme-base/src";
import React from "react";
import { FragmentType, graphql, useFragment } from "~/gql";

export const fragment = graphql(`
  fragment ProductStand on SingleRegistration {
    profile {
      name
    }
    stand {
      name
      standTypeWithNumber
      roomName
    }
  }
`);

export default function ProductStand({
  data,
  to,
  href,
  onClick,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
  to?: string;
  href?: string;
  onClick?: () => void;
}) {
  const registration = useFragment(fragment, data);

  if (!hasContent(data)) return null;
  // This logic is used when using "Stand 3 (Room name)" and you want Room name to stand out.
  if (registration?.stand?.roomName)
    return (
      <Label {...rest}>
        <Link to={to} onClick={onClick} href={href}>
          {registration?.stand?.roomName ? (
            <Badge
              sx={{
                backgroundColor: "primary",
                color: "onPrimary",
                marginBottom: 1,
              }}
            >
              {registration?.stand?.roomName}
            </Badge>
          ) : null}
          {[registration.stand?.standTypeWithNumber, registration.profile?.name]
            .filter(Boolean)
            .join(" — ") || <React.Fragment>&nbsp;</React.Fragment>}
        </Link>
      </Label>
    );
  // Shows exactly what has been entered into the stand field.
  return (
    <Label {...rest}>
      <Link to={to} onClick={onClick} href={href}>
        {[registration.stand?.name, registration.profile?.name]
          .filter(Boolean)
          .join(" — ") || <React.Fragment>&nbsp;</React.Fragment>}
      </Link>
    </Label>
  );
}

function hasContent(data: FragmentType<typeof fragment>) {
  const registration = useFragment(fragment, data);
  if (registration.stand?.name) return true;
  if (registration.profile?.name) return true;
  return false;
}

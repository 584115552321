import ListsPage from "@bottlebooks/bottlebooks-site-lists/src/components/ListsPage";
import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import { z } from "zod";
import { bbCollectionIdSchema } from "~/bbCollectionIdSchema";
import { graphql } from "~/gql";
import { getFragment } from "~/gql-local/fragment-masking";
import graphQLClient from "~/graphQLClient";
import { useParams } from "~/router";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
  collectionId: z.string(),
});

export async function Loader(args: LoaderFunctionArgs) {
  const { locale, collectionId: collectionIdSegment } = paramsSchema.parse(
    args.params
  );
  const collectionId = bbCollectionIdSchema.parse(collectionIdSegment);
  const { event } = await graphQLClient.request(
    graphql(/* GraphQL */ `
      query ListsRoute($collectionId: ID!, $locale: ContentLocale) {
        event(eventId: $collectionId, locale: $locale) {
          collectionId: eventId
        }
      }
    `),
    { collectionId, locale }
  );
  return event;
}

export default function ProfileRoute({ ...rest }: {}) {
  const params = useParams("/:locale/collections/:collectionId/my/lists");
  const { locale, collectionId: collectionIdSegment } =
    paramsSchema.parse(params);
  const collectionId = bbCollectionIdSchema.parse(collectionIdSegment);

  const data = useLoaderData();
  // @ts-expect-error - not sure why this is not valid
  const event = getFragment(eventFragment, data);
  if (!event) return <div>No data found.</div>;
  return <ListsPage locale={locale} collectionId={collectionId} {...rest} />;
}

const eventFragment = graphql(/* GraphQL */ `
  fragment Lists_Event on Event {
    collectionId: eventId
  }
`);

import CollectionLayoutProvider from "@bottlebooks/bottlebooks-site-base/src/components/CollectionLayoutProvider.next";
import { load } from "@bottlebooks/bottlebooks-site-base/src/wrapElement/lingui/i18n.config";
import { FavoritesProvider } from "@bottlebooks/bottlebooks-site-bookmarks/src/useFavorite";
import Firebase from "@bottlebooks/gatsby-plugin-firebase-app/src/wrapRootElement";
import { AuthenticationProvider } from "@bottlebooks/gatsby-plugin-firebase-auth/src/useAuthentication";
import { UserProfileProvider } from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUserProfile";
import { i18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { QueryClientProvider } from "@tanstack/react-query";
import { Outlet, useParams } from "react-router-dom";
import { z } from "zod";
import { bbCollectionIdSchema } from "~/bbCollectionIdSchema";
import queryClient from "~/queryClient";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
  // This doesn't make sense....
  collectionId: z.string(),
});

export default function LocaleLayout() {
  const { locale, collectionId: collectionIdSegment } = paramsSchema.parse(
    useParams()
  );
  const collectionId = bbCollectionIdSchema.parse(collectionIdSegment);
  load(locale);
  i18n.activate(locale);
  // @ts-expect-error - not sure why we are setting this property
  i18n._locale = locale;
  return (
    <Firebase>
      <QueryClientProvider client={queryClient}>
        <CollectionLayoutProvider collectionId={collectionId}>
          <AuthenticationProvider>
            <UserProfileProvider>
              {/* Move this to collection _layout.tsx */}
              <FavoritesProvider>
                <I18nProvider i18n={i18n}>
                  <Outlet />
                </I18nProvider>
              </FavoritesProvider>
            </UserProfileProvider>
          </AuthenticationProvider>
        </CollectionLayoutProvider>
      </QueryClientProvider>
    </Firebase>
  );
}

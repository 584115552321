// Based on
// https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
export default function getOnAccentColor(accentColor) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(accentColor);
  const { red, green, blue } = result
    ? {
        red: parseInt(result[1], 16),
        green: parseInt(result[2], 16),
        blue: parseInt(result[3], 16),
      }
    : { red: 0, blue: 0, green: 0 };
  const factor = red * 0.299 + green * 0.587 + blue * 0.114;
  return factor > 186 ? 'black' : 'white';
}

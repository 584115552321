/** @jsxImportSource theme-ui */
import { Flex, OrganicIcon, Text } from "@bottlebooks/gatsby-theme-base/src";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";

const fragment = graphql(`
  fragment BrandCertifications on Profile {
    isBiodynamic
    isCertifiedOrganic
    hasSustainabilityCertification
    hasSocialComplianceProgram
  }
`);

export default function BrandCertifications({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const { i18n } = useLingui();
  const brand = useFragment(fragment, data);
  if (!hasContent(data)) return null;
  return (
    <Section title={<Trans>Certifications</Trans>} {...rest}>
      <Flex align="center" gap={3}>
        {brand.isCertifiedOrganic && (
          <OrganicIcon
            size="medium"
            alt={i18n._(t`Organic`)}
            title={i18n._(t`Organic`)}
            sx={{
              color: "lighterText",
              padding: 1,
              verticalAlign: "top",
              border: 1,
              borderRadius: "round",
              borderColor: "lighterText",
              width: 30,
              height: 30,
              marginY: "2px",
            }}
          />
        )}
        {brand.isBiodynamic && (
          <Text>
            <Trans>Biodnamic</Trans>
          </Text>
        )}
        {brand.hasSocialComplianceProgram && (
          <Text>
            <Trans>Social compliance program</Trans>
          </Text>
        )}
        {brand.hasSustainabilityCertification && (
          <Text>
            <Trans>Sustainability certification</Trans>
          </Text>
        )}
      </Flex>
    </Section>
  );
}

function hasContent(data: FragmentType<typeof fragment>) {
  const brand = useFragment(fragment, data);
  if (!brand) return false;
  // We don't display 'no' values at all, so we don't need to check for false vs. null.
  // But let's make this more explicit by checking for `true` specifically.
  if (brand.isBiodynamic === true) return true;
  if (brand.isCertifiedOrganic === true) return true;
  if (brand.hasSustainabilityCertification === true) return true;
  if (brand.hasSocialComplianceProgram === true) return true;
  return false;
}

/** @jsxImportSource theme-ui */
import { Box } from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";
import cssOverlay from "../../helpers/cssOverlay";
import Image from "../Image/Image";

const fragment = graphql(`
  fragment EventBannerImage on Event {
    name
    mainImage {
      publicId
      fluid(maxWidth: 2560, maxHeight: 720, crop: FILL, gravity: FACES_AUTO) {
        src
        srcSet
        sizes
        base64
        aspectRatio
      }
    }
  }
`);

/**
 *
 * @param {{
 * event?: { mainImage?: { fluid?: any } | null; name?: string | null };
 * children?: React.ReactNode;
 * className?: string;
 * withOverlay?: boolean;
 * }} props
 * @returns
 */
export default function EventBannerImage({
  event: data,
  children,
  className = undefined,
  withOverlay,
}: {
  event: FragmentType<typeof fragment>;
  children?: React.ReactNode;
  className?: string;
  withOverlay?: boolean;
}) {
  const event = useFragment(fragment, data);
  const image = event?.mainImage;
  const name = event?.name;
  const hasImage = Boolean(image);
  return (
    <Box
      sx={{
        color: hasImage ? "onDark" : undefined,
        backgroundColor: "nuanced",
        position: "relative",
      }}
      className={className}
    >
      {image?.publicId && (
        <div sx={{ "::after": withOverlay ? cssOverlay() : undefined }}>
          <Image
            publicId={image.publicId}
            alt={name || ""}
            width={2560}
            layout="fixed"
            options={{
              crop: "fill",
              gravity: "faces:auto",
            }}
            sx={{
              display: "block",
              maxWidth: "100%",
              objectFit: "cover",
              objectPosition: "center center",
              minHeight: 220,
              maxHeight: 360,
            }}
          />
        </div>
      )}
      {children}
    </Box>
  );
}

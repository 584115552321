/** @jsxImportSource theme-ui */
import { useState } from "react";
import { Box, Button, InfoIcon } from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";

const fragment = graphql(/* GraphQL */ `
  fragment ProductPriceRange on ProductPrice {
    priceRange
    publicPriceRangeImage {
      fixed(width: 375, height: 200, crop: PAD, gravity: WEST) {
        src
        srcSet
      }
    }
  }
`);

/**
 * @param {object} props
 * @param {object} props.pricing
 * @param {boolean} [props.showMorePricingInfo=false] Show more info toggle for price ranges
 * @returns
 */
export default function PriceRange({
  pricing: data,
  showMorePricingInfo = false,
  ...rest
}: {
  pricing: FragmentType<typeof fragment>;
  showMorePricingInfo?: boolean;
  [x: string]: any;
}) {
  const pricing = useFragment(fragment, data);
  const [isVisible, toggleVisible] = useState(false);
  return (
    <Box>
      <span {...rest}>{pricing.priceRange}</span>
      <Button
        variant="text"
        onClick={() => {
          toggleVisible(!isVisible);
        }}
      >
        {showMorePricingInfo &&
          Boolean(pricing?.publicPriceRangeImage?.fixed) && (
            <InfoIcon sx={{ marginTop: -1 }} size="small" />
          )}
      </Button>
      {isVisible && (
        <Box>
          <img
            alt="Price range details"
            srcSet={pricing?.publicPriceRangeImage?.fixed?.srcSet}
            sx={{ maxWidth: "100%" }} // Prevent from running off mobile devices
          ></img>
        </Box>
      )}
    </Box>
  );
}

import type {
  Aggregation,
  FilterDefinitions,
  Sorting,
} from "@bottlebooks/bottlebooks-site-base/src/components/Filters/useIndex";
import { defineMessage } from "@lingui/macro";
import { graphql } from "~/gql";
import { ProductFiltersFragment } from "~/gql/graphql";
import { useCollectionLayout } from "../CollectionLayoutProvider.next";

export const fragment = graphql(/* GraphQL */ `
  fragment productFilters on RegisteredProduct {
    # Required when sorting products by exhibitor (which we don't do at the moment)
    registration {
      registrationId
      profile {
        sortName
        name
      }
      stand {
        floorName
        roomName
      }
      ...ProductStand
    }
    # Required for sorting
    sortIndex
    flightName
    productId
    exhibitorId: companyId
    pricing {
      priceType
      price
      currencyCode: currency(format: RAW)
      priceStrategy
      priceRange
    }
    highlights: customFieldValue(key: "highlights") {
      ...FieldValue
    }
    product {
      __typename
      name
      featuredContentTypes
      shortName
      # For rendering the product details
      countryName: country(format: LOCALIZED)
      countryCode: country(format: RAW)
      producer {
        producerId
        sortName
        name
        # For rendering the producer header
        countryName: country(format: LOCALIZED)
        countryCode: country(format: RAW)
        city
      }
      productType
      bottleImage {
        publicId
      }
      regionHierarchy
      bottleVariants {
        closureType
      }
      ... on Wine {
        wineType
        characteristics
        classification
        designation
        specialClassification
        vintage(removeNonVintage: true)
        denomination
        grapeVarieties {
          varietyName
        }
      }
      # ... on Beer {
      #   beerType
      # }
      ... on Spirit {
        spiritType
      }
    }
  }
`);

// #region Custom Aggregations
const portWineFestFilters = {
  portWineType: asAggregation({
    title: defineMessage({ message: "Port types" }),
    conjunction: false,
    sort: "term",
    get: (registeredProduct) => {
      const product = registeredProduct.product;
      const wine = product.__typename === "Wine" ? product : null;
      if (!wine) return [];
      if (wine.wineType === "Fortified") {
        if (wine.classification === "D.O.C.") return "Other port";
        return wine.classification?.replace("Port - ", "");
      }
      return [];
    },
    size: 20,
    translations: {
      WINE: defineMessage({ message: "Other wine" }),
      SPIRIT: defineMessage({ message: "Spirit" }),
      Stillwein: defineMessage({ message: "Wine" }),
      BEER: defineMessage({ message: "Beer and more" }),
      Fortified: defineMessage({ message: "Port" }),
      "Still wine": defineMessage({ message: "Wine" }),
    },
  }),
} as const;
// #endregion

// #region Aggregations

// Create a function to provide type safety to the individual aggregation definitions
function asAggregation(aggregation: Aggregation<ProductFiltersFragment>) {
  return aggregation;
}
const aggregations = {
  highlights: asAggregation({
    title: defineMessage({ message: "Highlights" }),
    conjunction: false,
    get: (registeredProduct) => {
      const product = registeredProduct.product;
      const highlights =
        registeredProduct.highlights?.values?.map(
          (highlight: string) => highlight || ""
        ) || [];
      return [
        // Exclude 'None'
        ...highlights.filter((highlight: string) => highlight !== "None"),
        ...(product.featuredContentTypes?.filter(
          // Videos resolver doesn't filter out null video objects.
          // After that is fixed, we can remove this filter.
          // Most products don't have tasting videos, so this isn't a top priority.
          (value: string) => value !== "VIDEOS"
        ) ?? []),
      ].filter(Boolean);
    },
    size: 20,
    translations: {
      VIDEOS: defineMessage({ message: "Videos" }),
      WEBSHOP: defineMessage({ message: "Online shop" }),
    },
  }),
  location: asAggregation({
    title: defineMessage({ message: "Locations" }),
    // multiSelect: false, // TODO Implement
    size: 20,
    get: (registeredProduct) =>
      [
        registeredProduct.registration.stand?.floorName,
        registeredProduct.registration.stand?.roomName,
      ].filter(Boolean),
  }),
  flight: asAggregation({
    title: defineMessage({ message: "Flights" }),
    conjunction: false,
    multiSelect: false,
    size: 100,
    sort: "term",
    get: (product: ProductFiltersFragment) => product.flightName || [], // Excludes nulls
  }),
  productType: asAggregation({
    title: defineMessage({ message: "Product types" }),
    conjunction: false,
    get: (registeredProduct) => {
      const product = registeredProduct.product;
      const wine = product.__typename === "Wine" ? product : null;
      const value =
        wine?.wineType ||
        // product.spiritType ||
        // product.beerType ||
        product.productType;
      if (!value) return [];
      return [value];
    },
    size: 20,
    translations: {
      WINE: defineMessage({ message: "Other wine" }),
      SPIRIT: defineMessage({ message: "Spirit" }),
      Stillwein: defineMessage({ message: "Wine" }),
      BEER: defineMessage({ message: "Beer and more" }),
    },
  }),
  country: asAggregation({
    title: defineMessage({ message: "Countries" }),
    conjunction: false,
    multiSelect: false,
    size: 100,
    get: (registeredProduct) => {
      const product = registeredProduct.product;
      if (!product.countryName) return [];
      return [];
    },
  }),
  regionHierarchy: asAggregation({
    title: defineMessage({ message: "Winegrowing regions" }),
    conjunction: false,
    // multiSelect: false,
    size: 100,
    get: (registeredProduct) => {
      const product = registeredProduct.product;
      return product.regionHierarchy?.map((e) => e || "") || [];
    },
  }),
  producer: asAggregation({
    title: defineMessage({ message: "Producers" }),
    conjunction: false,
    sort: "term",
    // multiSelect: false,
    size: 500,
    get: (registeredProduct) => {
      const product = registeredProduct.product;
      return product.producer?.name || [];
    },
  }),
  vintage: asAggregation({
    title: defineMessage({ message: "Vintages" }),
    conjunction: false,
    size: 50,
    sort: "term",
    order: "desc",
    get: (registeredProduct) => {
      const product = registeredProduct.product;
      const wine = product.__typename === "Wine" ? product : null;
      if (!wine?.vintage) return [];
      return [wine.vintage];
    },
    translations: {
      NV: defineMessage({ message: "NV" }),
    },
  }),
  priceRanges: asAggregation({
    title: defineMessage({ message: "Price ranges" }),
    conjunction: false,
    sort: "term",
    // This comes from ProductsTemplate.afterQuery.
    get: (registeredProduct) => {
      return registeredProduct.pricing?.priceRange || [];
    },
  }),
  productionMethods: asAggregation({
    title: defineMessage({ message: "Production methods" }),
    conjunction: false,
    size: 20,
    get: (registeredProduct) => {
      const product = registeredProduct.product;
      const wine = product.__typename === "Wine" ? product : null;
      return (
        wine?.characteristics
          ?.filter((value) =>
            [
              "VEGAN",
              "ORGANIC",
              "VEGETARIAN",
              "BIODYNAMIC",
              "KOSHER",
              "SUSTAINABLE",
            ].includes(value || "")
          )
          .map((e) => e || "") || []
      );
    },
    translations: {
      VEGAN: defineMessage({ message: "vegan" }),
      ORGANIC: defineMessage({ message: "organic" }),
      VEGETARIAN: defineMessage({ message: "vegetarian" }),
      BIODYNAMIC: defineMessage({ message: "biodynamic" }),
      KOSHER: defineMessage({ message: "kosher" }),
      SUSTAINABLE: defineMessage({ message: "sustainable" }),
    },
  }),

  classification: asAggregation({
    title: defineMessage({ message: "Classifications" }),
    conjunction: false,
    size: 50,
    get: (registeredProduct) => {
      const product = registeredProduct.product;
      const wine = product.__typename === "Wine" ? product : null;
      if (!wine?.classification) return undefined; // Excludes nulls
      if (wine.classification === "----") return undefined;
      if (wine.classification === "No classification") return undefined;
      return wine.classification;
    },
  }),
  specialClassification: asAggregation({
    title: defineMessage({ message: "Special classifications" }),
    conjunction: false,
    size: 50,
    get: (registeredProduct) => {
      const product = registeredProduct.product;
      const wine = product.__typename === "Wine" ? product : null;
      if (!wine?.specialClassification) return undefined; // Excludes nulls
      if (wine.specialClassification === "----") return undefined;
      return wine.specialClassification;
    },
  }),
  designation: asAggregation({
    title: defineMessage({ message: "Designations" }),
    conjunction: false,
    size: 50,
    get: (registeredProduct) => {
      const product = registeredProduct.product;
      const wine = product.__typename === "Wine" ? product : null;
      return wine?.designation || undefined;
    }, // Excludes nulls
  }),
  grapeVarieties: asAggregation({
    title: defineMessage({ message: "Grape varieties" }),
    conjunction: false,
    size: 300,
    get: (registeredProduct) => {
      const product = registeredProduct.product;
      const wine = product.__typename === "Wine" ? product : null;
      return wine?.grapeVarieties
        ?.map((variety) => variety?.varietyName || "")
        .filter(Boolean);
    },
  }),
  closureTypes: asAggregation({
    title: defineMessage({ message: "Closure types" }),
    conjunction: false,
    size: 50,
    get: (registeredProduct) => {
      const product = registeredProduct.product;
      return product.bottleVariants?.[0]?.closureType || undefined;
    }, // Excludes nulls
  }),
  ...portWineFestFilters,
} as const;

type AggregationKey = keyof typeof aggregations;
// We have to force the key type. Otherwise, the type of the aggregation is inferred as
// the type of the key, which is a string.
const aggregationKeys = Object.keys(aggregations) as AggregationKey[];
export const aggregationList = aggregationKeys.map((key) => ({
  ...aggregations[key],
  key,
}));
// #endregion

// #region Sortings
const sortings: Record<string, Sorting<ProductFiltersFragment>> = {
  byProducerName: {
    title: defineMessage({ message: "Producer name" }),
    field: [
      (registeredProduct) => {
        return (
          registeredProduct?.product?.producer?.sortName ||
          registeredProduct?.product?.producer?.name
        );
      },
    ],
    order: "asc",
    group: "producer.producerId",
    groupHeader: "producer",
  },
  byProductName: {
    title: defineMessage({ message: "Product name" }),
    field: ["shortName", "vintage"],
    order: ["asc", "desc"],
  },
  bySortIndex: {
    title: defineMessage({ message: "Sort number" }),
    field: ["sortIndex"],
    group: "flightName",
    groupHeader: "flightName",
    order: ["asc"],
  },
  // byExhibitorName: {
  //   title: defineMessage({ message: 'Exhibitor name' }),
  //   field: [
  //     'exhibitor.sortName',
  //     'exhibitor.name',
  //     'producer.sortName',
  //     'producer.name',
  //   ],
  //   order: 'asc',
  //   group: 'exhibitorId',
  // },
  // byStandNumber: {
  //   title: defineMessage({ message: 'Table number' }),
  //   field: [
  //     sortBy.standName,
  //     'exhibitor.sortName',
  //     'exhibitor.name',
  //     'producer.sortName',
  //     'producer.name',
  //   ],
  //   order: 'asc',
  //   group: 'registration.registrationId',
  // },
};
// #endregion

const baseConfiguration = {
  aggregations,
  sortings,
};

// #region useProductFilters
export default function useProductFilters(): FilterDefinitions<ProductFiltersFragment> {
  const collection = useCollectionLayout();
  const selectedAggregations =
    window.bbProductFiltersPreview || collection.siteConfig.productFilters;
  if (!selectedAggregations?.length) return baseConfiguration;
  const pickedAggregations = selectedAggregations.reduce<
    Record<string, Aggregation<ProductFiltersFragment>>
  >((acc, aggregationKey) => {
    // @ts-expect-error - We need to coerse type from string to key
    const aggregation = baseConfiguration.aggregations[aggregationKey];
    // This shouldn't happen
    if (!aggregation) return acc;
    acc[aggregationKey] = aggregation;
    return acc;
  }, {});

  return { ...baseConfiguration, aggregations: pickedAggregations };
}
// #endregion

// Sort helper functions
const sortBy = {
  standName: (product) =>
    product?.stand?.name
      .split(/[\s,-]+/)
      .map((segment) =>
        segment === "Tisch"
          ? undefined
          : Number(segment)
          ? segment.padStart(3, "0")
          : segment
      )
      .filter(Boolean)
      .join(" ") || "999",
};

export function getFluidImage(image, options) {
  return applyOptions(image, options);
}

export function getFixedImage(image, options) {
  return applyOptions(image, options);
}

// Add options to the image that were not originally queried.
// TODO improve this.
function applyOptions(image, options) {
  if (!image) return image;
  if (!options || !image.src)
    return {
      srcSet: '', // Default to empty string for gatsby-image.
      ...image,
    };
  const { src } = image;
  const [, url, params, publicId] =
    src.match(
      /(https:\/\/res\.cloudinary\.com\/\w+\/\w+\/\w+\/)([\w_0-9,:]*)(.*)/
    ) || [];
  if (!url) return { srcSet: '', ...image };
  const newParams = [
    params,
    options.height && `h_${options.height}`,
    options.width && `w_${options.width}`,
  ]
    .filter(Boolean)
    .join(',');
  const aspectRatio =
    options.width && options.height
      ? options.width / options.height
      : image.aspectRatio;
  // TODO reconstruct the srcSet from src.
  return {
    srcSet: '', // Default to empty string for gatsby-image.
    ...image,
    aspectRatio,
    src: `${url}${newParams}${publicId}`,
  };
}

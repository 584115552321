/** @jsxImportSource theme-ui */
import { Flex } from "@bottlebooks/gatsby-design-system";
import { Box, Text, Title } from "@bottlebooks/gatsby-design-system";
import { Link } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import {
  CheckCircle,
  // FilmStrip
} from "@phosphor-icons/react";
import type { ComponentPropsWithoutRef } from "react";
import useSiteConfig from "../useSiteConfig";
import type { ContentLocale } from "../../useLocale";
import useLocale from "../../useLocale";

type BenefitsProps = ComponentPropsWithoutRef<typeof Flex> & {};

export default function LoginBenefits({ ...rest }: BenefitsProps) {
  const { isChatEnabled, isMeetingBookingEnabled } = useSiteConfig();
  const locale = useLocale();
  return (
    <Box
      sx={{
        padding: 3.5,
        borderRadius: "default",
        backgroundColor: "light",
        textAlign: "center",
      }}
      {...rest}
    >
      <Title variant="smallest" sx={{ marginBottom: 2.5 }}>
        <Trans>Save private tasting notes and make connections</Trans>
      </Title>
      <Flex
        direction="column"
        gap={2.5}
        as="ul"
        sx={{ listStyle: "none", margin: 0, padding: 0, textAlign: "left" }}
      >
        <Benefit>
          <Trans>Bookmark exhibitors and products to taste.</Trans>
        </Benefit>
        <Benefit>
          <Trans>
            Create private tasting notes and rate wines. Export them to PDF or
            Excel when you get home.
          </Trans>
        </Benefit>
        {isChatEnabled && isMeetingBookingEnabled ? (
          <Benefit>
            <Trans>Meet and chat with exhibitors.</Trans>
          </Benefit>
        ) : isChatEnabled ? (
          <Benefit>
            <Trans>Chat with exhibitors.</Trans>
          </Benefit>
        ) : isMeetingBookingEnabled ? (
          <Benefit>
            <Trans>Schedule meetings with exhibitors.</Trans>
          </Benefit>
        ) : null}
      </Flex>
      <Text
        variant="small"
        sx={{
          marginTop: 2.5,
          marginX: "auto",
          textAlign: "center",
          fontWeight: "bold",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
        }}
      >
        {/* We don't currently have a video */}
        {/* <Trans>
          <Link
            href="#"
            variant="text"
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              textDecoration: 'underline',
            }}
          >
            <FilmStrip size={16} weight="light" />
            Watch our video
          </Link>
        </Trans>{' '}
        ·{' '} */}
        <Trans>
          <Link
            href={getHelpLink({ locale })}
            variant="text"
            sx={{ textDecoration: "underline" }}
            target="help"
          >
            Learn more
          </Link>
        </Trans>
      </Text>
    </Box>
  );
}

const helpLinksByLocale = {
  en: "https://bottlebooks.freshdesk.com/support/solutions/articles/19000148551-how-to-use-a-bottlebooks-online-directory",
  fr: "https://bottlebooks.freshdesk.com/support/solutions/articles/19000148552-comment-utiliser-un-annuaire-de-vins-bottlebooks",
};

function getHelpLink({ locale }: { locale: ContentLocale }) {
  return helpLinksByLocale[locale] || helpLinksByLocale["en"];
}

function Benefit({
  children,
  ...rest
}: Omit<ComponentPropsWithoutRef<typeof Text>, "sx">) {
  return (
    <Text
      as="li"
      variant="small"
      sx={{ display: "grid", gap: 2, gridTemplateColumns: "24px auto" }}
      {...rest}
    >
      <CheckCircle
        size="24"
        weight="light"
        sx={{ color: "brand.successGreen", marginTop: "-3px" }}
      />
      {children}
    </Text>
  );
}

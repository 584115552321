/** @jsxImportSource theme-ui */
import sizes from "./sizes";

/**
 * A star icon.
 * @param {object} props
 * @param {string} [props.className]
 * @param {'filled' | 'hollow'} [props.variant]
 * @param {import('./sizes').IconSize} [props.size] The icon size.
 */
export default function StarIcon({
  size = "small",
  variant = "filled",
  ...rest
}) {
  if (variant === "hollow") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        sx={{
          width: sizes[size],
          height: sizes[size],
          verticalAlign: "sub",
        }}
        fill="none"
        viewBox="0 0 50 50"
        {...rest}
      >
        <path
          fill="white"
          fillRule="evenodd"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M25.084 40.028l-14.37 7.525 2.745-15.939L1.834 20.327 17.899 18 25.084 3.5l7.185 14.501 16.065 2.326-11.625 11.287 2.744 15.939-14.369-7.525z"
          clipRule="evenodd"
        ></path>
      </svg>
    );
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: sizes[size],
        height: sizes[size],
        verticalAlign: "sub",
      }}
      viewBox="0 0 20 20"
      fill="none"
      {...rest}
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.785 15.644l-5.738 3.017 1.096-6.39L.5 7.746l6.416-.932L9.785 1l2.87 5.814 6.415.932-4.643 4.525 1.096 6.39-5.738-3.017z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

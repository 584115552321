/** @jsxImportSource theme-ui */
import { gql, isApolloError, useMutation } from "@apollo/client";
import { Text } from "@bottlebooks/gatsby-design-system";
import { Link } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { Fragment } from "react";
import useImageUpload from "../../useImageUpload";
import type { ContentLocale } from "../../useLocale";

type UseSubmitOptions = {
  onSuccess: (
    data: SendMeetingData["requestMeeting"] | null | undefined
  ) => void;
  onError: (message: string | JSX.Element) => void;
};
export default function useSubmitMeetingRequest({
  onSuccess,
  onError,
}: UseSubmitOptions) {
  const { uploadImage } = useImageUpload();
  const [requestMeeting] = useMutation<SendMeetingData, SendMeetingVariables>(
    REQUEST_MEETING
  );

  return async function submit(variables: SendMeetingVariables) {
    try {
      const newProfileImageUrl = await uploadImage(
        // @ts-expect-error - this is in the form, but is not in the data submitted to the server.
        variables.input.profileImage
      );
      const { data, errors } = await requestMeeting({
        variables: {
          ...variables,
          input: {
            ...variables.input,
            profileImageUrl:
              newProfileImageUrl || variables.input.profileImageUrl,
          },
        },
      });
      // Even if we get errors back, the success flag is set to ok if we sent the message.
      // Errors might come from an invalid query on the mutation, but we don't want to handle this,
      // because it might confuse the user about why there was an error.
      if (data?.requestMeeting?.success) return onSuccess(data.requestMeeting);
      // Show the GraphQL errors.
      if (errors) {
        return onError(
          <Fragment>
            {errors.map((error, index) => (
              <Text key={index}>{error}</Text>
            ))}
          </Fragment>
        );
      }
      // Otherwise display the message returned from the server if there was an error.
      if (data?.requestMeeting?.message) {
        return onError(data.requestMeeting.message);
      }
      // If the server accepted the mutation, but hasn't implemented the mutation response,
      // we show an "empty message" error.
      return onError(
        <Trans>
          The server responded with an empty message.
          <br />
          Please contact{" "}
          <Link href="mailto:support@bottlebooks.me">
            support@bottlebooks.me
          </Link>
          .
        </Trans>
      );
    } catch (error) {
      console.log({ ...error });
      if (isApolloError(error)) {
        const networkError = error.networkError;
        if (networkError !== null && "result" in networkError) {
          return onError(
            <>
              <Text sx={{ marginBottom: 2 }}>
                Status code {networkError.response.status}
              </Text>
              {networkError.result.errors.map(
                (
                  error: { message: string; extensions?: { code: string } },
                  index: number
                ) => (
                  <Text variant="smallest" key={index}>
                    {error.message}
                  </Text>
                )
              )}
            </>
          );
        }
      }
      return onError(error?.message);
    }
  };
}
const REQUEST_MEETING = gql`
  mutation requestMeeting(
    $collectionId: ID!
    $locale: ContentLocale!
    $organizerId: ID!
    $updateToken: String!
    $useTestMode: Boolean = false
    $input: RequestMeetingInput!
  ) {
    requestMeeting(
      collectionId: $collectionId
      locale: $locale
      organizerId: $organizerId
      updateToken: $updateToken
      useTestMode: $useTestMode
      input: $input
    ) {
      code
      success
      message
      # TODO add when the API change is deployed:
      # meeting {
      #   uid
      #   status
      # }
    }
  }
`;
// The types for the GraphQL query could actually be generated from the GraphQL mutation.
// For now, we just use manual types.
//
// copied from @bottlebooks/graphql-server/src/index.d.ts:
export type SendMeetingVariables = {
  locale: ContentLocale;
  collectionId: string;
  organizerId: string;
  updateToken: string;
  useTestMode: boolean | undefined;
  input: {
    recipientId: string;
    start: string;
    displayName: string | null;
    jobTitle: string | null;
    companyName: string | null;
    telephone: string | null | undefined;
    linkedInURL: string | null;
    acceptTerms: boolean;
    makeVisibleToRepresentatives: boolean;
    profileImageUrl: string | null;
  };
};
type SendMeetingData = {
  requestMeeting: {
    code: string;
    success: boolean;
    message: string;
    meeting: {
      uid: string;
      status: "TENTATIVE" | "CONFIRMED" | "CANCELED";
    } | null;
  } | null;
};

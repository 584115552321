import ad_flag from "./ad.svg";
import ae_flag from "./ae.svg";
import af_flag from "./af.svg";
import ag_flag from "./ag.svg";
import ai_flag from "./ai.svg";
import al_flag from "./al.svg";
import am_flag from "./am.svg";
import ao_flag from "./ao.svg";
import ar_flag from "./ar.svg";
import as_flag from "./as.svg";
import at_flag from "./at.svg";
import au_flag from "./au.svg";
import aw_flag from "./aw.svg";
import az_flag from "./az.svg";
import ba_flag from "./ba.svg";
import bb_flag from "./bb.svg";
import bd_flag from "./bd.svg";
import be_flag from "./be.svg";
import bf_flag from "./bf.svg";
import bg_flag from "./bg.svg";
import bh_flag from "./bh.svg";
import bi_flag from "./bi.svg";
import bj_flag from "./bj.svg";
import bm_flag from "./bm.svg";
import bo_flag from "./bo.svg";
import br_flag from "./br.svg";
import bs_flag from "./bs.svg";
import bt_flag from "./bt.svg";
import bw_flag from "./bw.svg";
import by_flag from "./by.svg";
import bz_flag from "./bz.svg";
import ca_flag from "./ca.svg";
import cd_flag from "./cd.svg";
import cf_flag from "./cf.svg";
import cg_flag from "./cg.svg";
import ch_flag from "./ch.svg";
import ci_flag from "./ci.svg";
import ck_flag from "./ck.svg";
import cl_flag from "./cl.svg";
import cm_flag from "./cm.svg";
import cn_flag from "./cn.svg";
import co_flag from "./co.svg";
import cr_flag from "./cr.svg";
import cu_flag from "./cu.svg";
import cv_flag from "./cv.svg";
import cw_flag from "./cw.svg";
import cy_flag from "./cy.svg";
import cz_flag from "./cz.svg";
import de_flag from "./de.svg";
import dj_flag from "./dj.svg";
import dk_flag from "./dk.svg";
import dm_flag from "./dm.svg";
import do_flag from "./do.svg";
import dz_flag from "./dz.svg";
import ec_flag from "./ec.svg";
import ee_flag from "./ee.svg";
import eg_flag from "./eg.svg";
import er_flag from "./er.svg";
import es_flag from "./es.svg";
import et_flag from "./et.svg";
import fi_flag from "./fi.svg";
import fj_flag from "./fj.svg";
import fk_flag from "./fk.svg";
import fm_flag from "./fm.svg";
import fo_flag from "./fo.svg";
import fr_flag from "./fr.svg";
import ga_flag from "./ga.svg";
import gb_flag from "./gb.svg";
import gd_flag from "./gd.svg";
import ge_flag from "./ge.svg";
import gg_flag from "./gg.svg";
import gh_flag from "./gh.svg";
import gi_flag from "./gi.svg";
import gl_flag from "./gl.svg";
import gm_flag from "./gm.svg";
import gn_flag from "./gn.svg";
import gq_flag from "./gq.svg";
import gr_flag from "./gr.svg";
import gt_flag from "./gt.svg";
import gu_flag from "./gu.svg";
import gw_flag from "./gw.svg";
import hk_flag from "./hk.svg";
import hn_flag from "./hn.svg";
import hr_flag from "./hr.svg";
import ht_flag from "./ht.svg";
import hu_flag from "./hu.svg";
import id_flag from "./id.svg";
import ie_flag from "./ie.svg";
import il_flag from "./il.svg";
import im_flag from "./im.svg";
import in_flag from "./in.svg";
import io_flag from "./io.svg";
import iq_flag from "./iq.svg";
import ir_flag from "./ir.svg";
import is_flag from "./is.svg";
import it_flag from "./it.svg";
import je_flag from "./je.svg";
import jm_flag from "./jm.svg";
import jo_flag from "./jo.svg";
import jp_flag from "./jp.svg";
import ke_flag from "./ke.svg";
import kg_flag from "./kg.svg";
import kh_flag from "./kh.svg";
import ki_flag from "./ki.svg";
import km_flag from "./km.svg";
import kn_flag from "./kn.svg";
import kp_flag from "./kp.svg";
import kr_flag from "./kr.svg";
import kw_flag from "./kw.svg";
import ky_flag from "./ky.svg";
import kz_flag from "./kz.svg";
import la_flag from "./la.svg";
import lb_flag from "./lb.svg";
import li_flag from "./li.svg";
import lk_flag from "./lk.svg";
import lr_flag from "./lr.svg";
import ls_flag from "./ls.svg";
import lt_flag from "./lt.svg";
import lu_flag from "./lu.svg";
import lv_flag from "./lv.svg";
import ly_flag from "./ly.svg";
import ma_flag from "./ma.svg";
import mc_flag from "./mc.svg";
import md_flag from "./md.svg";
import me_flag from "./me.svg";
import mg_flag from "./mg.svg";
import mh_flag from "./mh.svg";
import mk_flag from "./mk.svg";
import ml_flag from "./ml.svg";
import mm_flag from "./mm.svg";
import mn_flag from "./mn.svg";
import mo_flag from "./mo.svg";
import mp_flag from "./mp.svg";
import mq_flag from "./mq.svg";
import mr_flag from "./mr.svg";
import ms_flag from "./ms.svg";
import mt_flag from "./mt.svg";
import mu_flag from "./mu.svg";
import mv_flag from "./mv.svg";
import mw_flag from "./mw.svg";
import mx_flag from "./mx.svg";
import my_flag from "./my.svg";
import mz_flag from "./mz.svg";
import na_flag from "./na.svg";
import nato_flag from "./nato.svg";
import ne_flag from "./ne.svg";
import nf_flag from "./nf.svg";
import ng_flag from "./ng.svg";
import ni_flag from "./ni.svg";
import nl_flag from "./nl.svg";
import no_flag from "./no.svg";
import np_flag from "./np.svg";
import nr_flag from "./nr.svg";
import nu_flag from "./nu.svg";
import nz_flag from "./nz.svg";
import om_flag from "./om.svg";
import pa_flag from "./pa.svg";
import pe_flag from "./pe.svg";
import pf_flag from "./pf.svg";
import pg_flag from "./pg.svg";
import ph_flag from "./ph.svg";
import pk_flag from "./pk.svg";
import pl_flag from "./pl.svg";
import pn_flag from "./pn.svg";
import pr_flag from "./pr.svg";
import ps_flag from "./ps.svg";
import pt_flag from "./pt.svg";
import pw_flag from "./pw.svg";
import py_flag from "./py.svg";
import qa_flag from "./qa.svg";
import ro_flag from "./ro.svg";
import rs_flag from "./rs.svg";
import ru_flag from "./ru.svg";
import rw_flag from "./rw.svg";
import sa_flag from "./sa.svg";
import sb_flag from "./sb.svg";
import sc_flag from "./sc.svg";
import sd_flag from "./sd.svg";
import se_flag from "./se.svg";
import sg_flag from "./sg.svg";
import si_flag from "./si.svg";
import sk_flag from "./sk.svg";
import sl_flag from "./sl.svg";
import sm_flag from "./sm.svg";
import sn_flag from "./sn.svg";
import so_flag from "./so.svg";
import sr_flag from "./sr.svg";
import ss_flag from "./ss.svg";
import st_flag from "./st.svg";
import sv_flag from "./sv.svg";
import sx_flag from "./sx.svg";
import sy_flag from "./sy.svg";
import sz_flag from "./sz.svg";
import tc_flag from "./tc.svg";
import td_flag from "./td.svg";
import tg_flag from "./tg.svg";
import th_flag from "./th.svg";
import tibet_flag from "./tibet.svg";
import tj_flag from "./tj.svg";
import tk_flag from "./tk.svg";
import tm_flag from "./tm.svg";
import tn_flag from "./tn.svg";
import to_flag from "./to.svg";
import tr_flag from "./tr.svg";
import tt_flag from "./tt.svg";
import tv_flag from "./tv.svg";
import tw_flag from "./tw.svg";
import tz_flag from "./tz.svg";
import ua_flag from "./ua.svg";
import ug_flag from "./ug.svg";
import us_flag from "./us.svg";
import uy_flag from "./uy.svg";
import uz_flag from "./uz.svg";
import ve_flag from "./ve.svg";
import vi_flag from "./vi.svg";
import vn_flag from "./vn.svg";
import vu_flag from "./vu.svg";
import ws_flag from "./ws.svg";
import ye_flag from "./ye.svg";
import za_flag from "./za.svg";
import zm_flag from "./zm.svg";
import zw_flag from "./zw.svg";

const flags = {
  ad: ad_flag,
  ae: ae_flag,
  af: af_flag,
  ag: ag_flag,
  ai: ai_flag,
  al: al_flag,
  am: am_flag,
  ao: ao_flag,
  ar: ar_flag,
  as: as_flag,
  at: at_flag,
  au: au_flag,
  aw: aw_flag,
  az: az_flag,
  ba: ba_flag,
  bb: bb_flag,
  bd: bd_flag,
  be: be_flag,
  bf: bf_flag,
  bg: bg_flag,
  bh: bh_flag,
  bi: bi_flag,
  bj: bj_flag,
  bm: bm_flag,
  bo: bo_flag,
  br: br_flag,
  bs: bs_flag,
  bt: bt_flag,
  bw: bw_flag,
  by: by_flag,
  bz: bz_flag,
  ca: ca_flag,
  cd: cd_flag,
  cf: cf_flag,
  cg: cg_flag,
  ch: ch_flag,
  ci: ci_flag,
  ck: ck_flag,
  cl: cl_flag,
  cm: cm_flag,
  cn: cn_flag,
  co: co_flag,
  cr: cr_flag,
  cu: cu_flag,
  cv: cv_flag,
  cw: cw_flag,
  cy: cy_flag,
  cz: cz_flag,
  de: de_flag,
  dj: dj_flag,
  dk: dk_flag,
  dm: dm_flag,
  do: do_flag,
  dz: dz_flag,
  ec: ec_flag,
  ee: ee_flag,
  eg: eg_flag,
  er: er_flag,
  es: es_flag,
  et: et_flag,
  fi: fi_flag,
  fj: fj_flag,
  fk: fk_flag,
  fm: fm_flag,
  fo: fo_flag,
  fr: fr_flag,
  ga: ga_flag,
  gb: gb_flag,
  gd: gd_flag,
  ge: ge_flag,
  gg: gg_flag,
  gh: gh_flag,
  gi: gi_flag,
  gl: gl_flag,
  gm: gm_flag,
  gn: gn_flag,
  gq: gq_flag,
  gr: gr_flag,
  gt: gt_flag,
  gu: gu_flag,
  gw: gw_flag,
  hk: hk_flag,
  hn: hn_flag,
  hr: hr_flag,
  ht: ht_flag,
  hu: hu_flag,
  id: id_flag,
  ie: ie_flag,
  il: il_flag,
  im: im_flag,
  in: in_flag,
  io: io_flag,
  iq: iq_flag,
  ir: ir_flag,
  is: is_flag,
  it: it_flag,
  je: je_flag,
  jm: jm_flag,
  jo: jo_flag,
  jp: jp_flag,
  ke: ke_flag,
  kg: kg_flag,
  kh: kh_flag,
  ki: ki_flag,
  km: km_flag,
  kn: kn_flag,
  kp: kp_flag,
  kr: kr_flag,
  kw: kw_flag,
  ky: ky_flag,
  kz: kz_flag,
  la: la_flag,
  lb: lb_flag,
  li: li_flag,
  lk: lk_flag,
  lr: lr_flag,
  ls: ls_flag,
  lt: lt_flag,
  lu: lu_flag,
  lv: lv_flag,
  ly: ly_flag,
  ma: ma_flag,
  mc: mc_flag,
  md: md_flag,
  me: me_flag,
  mg: mg_flag,
  mh: mh_flag,
  mk: mk_flag,
  ml: ml_flag,
  mm: mm_flag,
  mn: mn_flag,
  mo: mo_flag,
  mp: mp_flag,
  mq: mq_flag,
  mr: mr_flag,
  ms: ms_flag,
  mt: mt_flag,
  mu: mu_flag,
  mv: mv_flag,
  mw: mw_flag,
  mx: mx_flag,
  my: my_flag,
  mz: mz_flag,
  na: na_flag,
  nato: nato_flag,
  ne: ne_flag,
  nf: nf_flag,
  ng: ng_flag,
  ni: ni_flag,
  nl: nl_flag,
  no: no_flag,
  np: np_flag,
  nr: nr_flag,
  nu: nu_flag,
  nz: nz_flag,
  om: om_flag,
  pa: pa_flag,
  pe: pe_flag,
  pf: pf_flag,
  pg: pg_flag,
  ph: ph_flag,
  pk: pk_flag,
  pl: pl_flag,
  pn: pn_flag,
  pr: pr_flag,
  ps: ps_flag,
  pt: pt_flag,
  pw: pw_flag,
  py: py_flag,
  qa: qa_flag,
  ro: ro_flag,
  rs: rs_flag,
  ru: ru_flag,
  rw: rw_flag,
  sa: sa_flag,
  sb: sb_flag,
  sc: sc_flag,
  sd: sd_flag,
  se: se_flag,
  sg: sg_flag,
  si: si_flag,
  sk: sk_flag,
  sl: sl_flag,
  sm: sm_flag,
  sn: sn_flag,
  so: so_flag,
  sr: sr_flag,
  ss: ss_flag,
  st: st_flag,
  sv: sv_flag,
  sx: sx_flag,
  sy: sy_flag,
  sz: sz_flag,
  tc: tc_flag,
  td: td_flag,
  tg: tg_flag,
  th: th_flag,
  tibet: tibet_flag,
  tj: tj_flag,
  tk: tk_flag,
  tm: tm_flag,
  tn: tn_flag,
  to: to_flag,
  tr: tr_flag,
  tt: tt_flag,
  tv: tv_flag,
  tw: tw_flag,
  tz: tz_flag,
  ua: ua_flag,
  ug: ug_flag,
  us: us_flag,
  uy: uy_flag,
  uz: uz_flag,
  ve: ve_flag,
  vi: vi_flag,
  vn: vn_flag,
  vu: vu_flag,
  ws: ws_flag,
  ye: ye_flag,
  za: za_flag,
  zm: zm_flag,
  zw: zw_flag,
};
export default flags;

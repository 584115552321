/** @jsxImportSource theme-ui */
import DesktopLogo from "./DesktopLogo";

/**
 *
 * @param {object} props
 * @param {object} props.event
 * @param {object} props.event.logo
 * @param {object} props.event.logo.fixed
 * @param {string} props.event.logo.fixed.src
 * @param {string} props.event.name
 * @param {string } [props.to]
 * @param {boolean} [props.isScrolledDown]
 * @returns
 */
export default function Logo({ to, isScrolledDown, event, ...rest }) {
  return (
    <DesktopLogo
      to={to}
      isScrolledDown={isScrolledDown}
      event={event}
      width={60}
      height={40}
      {...rest}
    />
  );
}

/** @jsxImportSource theme-ui */
import { P } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";

const fragment = graphql(`
  fragment WineWinemaker on Product {
    __typename
    ... on Wine {
      winemaker
    }
  }
`);

export default function WineWinemaker({
  data: data,
  children,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
  children?: React.ReactNode;
}) {
  const wine = useFragment(fragment, data);
  if (wine?.__typename !== "Wine") return null;
  if (!wine.winemaker) return null;
  return (
    <Section title={<Trans>Winemaker</Trans>} {...rest}>
      <P variant="title">{wine.winemaker}</P>
      {children}
    </Section>
  );
}

/** @jsxImportSource theme-ui */
import { Row } from "@bottlebooks/gatsby-theme-base/src";

export default function RelatedList({ children, ...rest }) {
  return (
    <Row
      sx={{
        paddingX: [0, `${100 / 12}%`], // 1 col spacing from medium width up.
      }}
      {...rest}
    >
      {children}
    </Row>
  );
}

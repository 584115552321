/** @jsxImportSource theme-ui */
import { Flex, Grid, Text } from "@bottlebooks/gatsby-design-system";
import getOnAccentColor from "@bottlebooks/bottlebooks-site-base/src/helpers/getOnAccentColor";
// import { graphql } from "gatsby";
import CardBlock from "../Blocks/CardBlock";
import GridColumnBlock from "../Blocks/GridColumnBlock";
import IconBlock from "../Blocks/IconBlock";
import ImageBlock from "../Blocks/ImageBlock";
import MenuItemBlock from "../Blocks/MenuItemBlock";
import ProductBlock from "../Blocks/ProductBlock";
import ProfileBlock from "../Blocks/ProfileBlock";
import SocialMediaBlock from "../Blocks/SocialMediaBlock";
import TextBlock from "../Blocks/TextBlock";
import TitleBlock from "../Blocks/TitleBlock";
import VideoBlock from "../Blocks/VideoBlock";
import CallToAction from "../Elements/CallToAction";
import Section from "../Layout/Section";
import UserHelp from "../UserHelp";

export default function SimpleGridSection({
  title,
  blocks = [],
  style,
  projectId,
  columnLayout,
  gridColumnCount,
  callToAction,
}) {
  if (!blocks || blocks?.length === 0)
    return (
      <Section>
        <UserHelp>
          <Text>Add blocks to section</Text>
        </UserHelp>
      </Section>
    );
  const { gridTemplateColumns, totalColumnUnits } =
    getGridTemplateColumns({ blocks, columnLayout, gridColumnCount }) || {};
  return (
    <Section variant={style.variant} sx={style}>
      {title && (
        <Section.Title
          sx={(theme) => ({
            textAlign: style.alignment || "left",
            marginBottom: 4, // Avoid hover effect
            color:
              style?.variant === "accent"
                ? getOnAccentColor(theme.colors.accent)
                : "inherit",
          })}
        >
          {title}
        </Section.Title>
      )}
      <Section.Body>
        <Grid gap={3.5} sx={{ gridTemplateColumns }}>
          {blocks?.map((block) => {
            const gridColumnDesktop = block.style?.gridColumns || "auto";
            const gridColumn = ["auto", null, gridColumnDesktop];
            switch (block.__typename) {
              case "GridColumnBlock":
              case "SanityGridColumnBlock":
                return (
                  <GridColumnBlock
                    key={block._key}
                    sectionVariant={style.variant}
                    projectId={projectId}
                    {...block}
                    sx={{ gridColumn }}
                  />
                );
              default:
                return (
                  <ContentBlock
                    key={block._key}
                    projectId={projectId}
                    block={block}
                    blocks={blocks}
                    sectionVariant={style.variant}
                    totalColumnUnits={totalColumnUnits}
                  />
                );
            }
          })}
        </Grid>
        {callToAction && (
          <Flex align="center" justify="center">
            <CallToAction
              {...callToAction}
              sx={{ marginTop: 5, marginBottom: 2 }}
            />
          </Flex>
        )}
      </Section.Body>
    </Section>
  );
}

export function ContentBlock({
  block,
  blocks,
  projectId,
  sectionVariant,
  totalColumnUnits,
}) {
  const gridColumnDesktop = block.style?.gridColumns || "auto";
  const gridColumn = ["auto", null, gridColumnDesktop];
  switch (block.__typename) {
    case "CardBlock":
    case "SanityCardBlock":
      return (
        <CardBlock
          key={block._key}
          projectId={projectId}
          {...block}
          width={getBlockWidth({
            block,
            totalColumnUnits,
          })}
          itemsInRowCount={blocks?.length}
          sx={{ gridColumn }}
        />
      );

    case "IconBlock":
    case "SanityIconBlock":
      return (
        <IconBlock
          key={block._key}
          {...block}
          projectId={projectId}
          sectionVariant={sectionVariant}
          sx={{ gridColumn }}
        />
      );

    case "MenuItemBlock":
    case "SanityMenuItemBlock":
      return (
        <MenuItemBlock
          key={block._key}
          sectionVariant={sectionVariant}
          {...block}
          sx={{ gridColumn }}
        />
      );

    case "SocialMediaBlock":
    case "SanitySocialMediaBlock":
      return (
        <SocialMediaBlock key={block._key} {...block} sx={{ gridColumn }} />
      );

    case "TextBlock":
    case "SanityTextBlock":
      return (
        <TextBlock
          key={block._key}
          {...block}
          sectionVariant={sectionVariant}
          sx={{ gridColumn }}
        />
      );

    case "TitleBlock":
    case "SanityTitleBlock":
      return (
        <TitleBlock
          key={block._key}
          {...block}
          sectionVariant={sectionVariant}
          sx={{ gridColumn }}
        />
      );

    case "ImageBlock":
    case "SanityImageBlock":
      return (
        <ImageBlock
          key={block._key}
          {...block}
          projectId={projectId}
          width={getBlockWidth({
            block,
            totalColumnUnits,
          })}
          sx={{ gridColumn }}
        />
      );
    case "ProductBlock":
    case "SanityProductBlock":
      return (
        <ProductBlock
          key={block._key}
          {...block}
          sectionVariant={sectionVariant}
          sx={{ gridColumn }}
        />
      );
    case "ProfileBlock":
    case "SanityProfileBlock":
      return (
        <ProfileBlock
          key={block._key}
          {...block}
          sectionVariant={sectionVariant}
          sx={{ gridColumn }}
        />
      );
    case "VideoBlock":
    case "SanityVideoBlock":
      return <VideoBlock key={block._key} {...block} sx={{ gridColumn }} />;
    default:
      return <Text>Not supported</Text>;
  }
}

/**
 * This function helps the blocks with images know how to size them
 * so that the vertical/horizontal ratio is good.
 */
function getBlockWidth({ block, totalColumnUnits }) {
  const gridColumns = block.style?.gridColumns || "1/2";
  const [columnStart = 1, columnEnd = totalColumnUnits] =
    gridColumns.split("/");
  const gridColumnCount = columnEnd - columnStart;
  const proportionOfWidth = gridColumnCount / totalColumnUnits;
  return [
    480,
    null,
    Math.floor(960 * proportionOfWidth),
    Math.floor(1170 * proportionOfWidth),
  ];
}

function getGridTemplateColumns({ blocks, columnLayout, gridColumnCount }) {
  const totalColumnUnits =
    columnLayout === "custom" ? gridColumnCount : blocks.length;
  return {
    gridTemplateColumns: [
      "repeat(1, 1fr)",
      null,
      `repeat(${totalColumnUnits}, 1fr)`,
    ],
    totalColumnUnits,
  };
}

// export const fragment = graphql`
//   fragment SanitySimpleGridSectionFragment on SanitySimpleGridSection {
//     title
//     callToAction {
//       ...CallToAction
//     }
//     columnLayout
//     gridColumnCount
//     blocks {
//       __typename
//       ... on SanityGridColumnBlock {
//         _key
//         ...SanityGridColumnBlockFragment
//         style {
//           ...BlockStyle
//         }
//       }
//       ...BlocksFragment
//     }
//     style {
//       ...SectionStyle
//     }
//   }

//   fragment BlocksFragment on SanityCardBlockOrGridColumnBlockOrIconBlockOrImageBlockOrMenuItemBlockOrProductBlockOrProfileBlockOrSocialMediaBlockOrTextBlockOrTitleBlockOrVideoBlock {
//     __typename
//     ... on SanityCardBlock {
//       _key
//       ...SanityCardBlockFragment
//       style {
//         ...BlockStyle
//       }
//     }
//     ... on SanityProductBlock {
//       _key
//       ...SanityProductBlockFragment
//       style {
//         ...BlockStyle
//       }
//     }
//     ... on SanityProfileBlock {
//       _key
//       ...SanityProfileBlockFragment
//       style {
//         ...BlockStyle
//       }
//     }
//     ... on SanityIconBlock {
//       _key
//       ...SanityIconBlockFragment
//       style {
//         ...BlockStyle
//       }
//     }
//     ... on SanityMenuItemBlock {
//       _key
//       ...SanityMenuItemBlockFragment
//       style {
//         ...BlockStyle
//       }
//     }
//     ... on SanitySocialMediaBlock {
//       _key
//       ...SocialMediaBlockFragment
//       style {
//         ...BlockStyle
//       }
//     }
//     ... on SanityTextBlock {
//       _key
//       ...SanityTextBlockFragment
//       style {
//         ...BlockStyle
//       }
//     }
//     ... on SanityTitleBlock {
//       _key
//       ...SanityTitleBlockFragment
//       style {
//         ...BlockStyle
//       }
//     }
//     ... on SanityImageBlock {
//       _key
//       ...SanityImageBlockFragment
//       style {
//         ...BlockStyle
//       }
//     }
//     ... on SanityVideoBlock {
//       _key
//       ...SanityVideoBlockFragment
//       style {
//         ...BlockStyle
//       }
//     }
//   }
// `;

/** @jsxImportSource theme-ui */
import { Box, Grid, Label } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { User } from "@phosphor-icons/react";
import type { ComponentPropsWithoutRef, ElementType, ReactNode } from "react";
import UserProfileCard from "../UserProfile/UserProfileCard";
import { UserProfileCardAvatarSkeleton } from "../UserProfile/UserProfileCard.Avatar.next";
import { UserProfileCardCompanySkeleton } from "../UserProfile/UserProfileCard.Company.next";
import { UserProfileCardDisplayNameSkeleton } from "../UserProfile/UserProfileCard.DisplayName.next";

type MeetingPartnerProps = Omit<
  ComponentPropsWithoutRef<typeof Grid>,
  "children"
> & {
  profile: MeetingPartnerProfile | null | undefined;
  label?: ReactNode;
  as?: ElementType;
};

export default function MeetingPartner({
  profile,
  label = <Trans>You will meet</Trans>,
  ...rest
}: MeetingPartnerProps) {
  if (!hasContent(profile)) return null;
  return (
    <Grid
      sx={{ gridTemplateColumns: "16px auto", columnGap: 2, rowGap: 0 }}
      {...rest}
    >
      <User size={16} weight="light" sx={{ marginTop: 0.5 }} />
      <Box>
        {label && (
          <Label variant="small" sx={{ color: "inherit", fontWeight: "bold" }}>
            {label}
          </Label>
        )}
        <Grid sx={{ gridTemplateColumns: "30px auto", marginTop: 1 }}>
          <UserProfileCard.Avatar variant="compact" profile={profile} />
          <Box>
            <UserProfileCard.DisplayName
              profile={profile}
              sx={{ fontWeight: "normal" }}
            />
            <UserProfileCard.Company profile={profile} />
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
}

function hasContent(
  profile: MeetingPartnerProps["profile"]
): profile is MeetingPartnerProfile {
  if (!profile) return false;
  if (profile.displayName?.trim()) return true;
  if (profile.companyName?.trim()) return true;
  if (profile.jobTitle?.trim()) return true;
  return false;
}

export type MeetingPartnerProfile = ComponentPropsWithoutRef<
  typeof UserProfileCard.Avatar
>["profile"] & {
  displayName?: string | null | undefined;
  companyName?: string | null | undefined;
  jobTitle?: string | null | undefined;
};

export function MeetingPartnerSkeleton({
  label = <Trans>You will meet</Trans>,
  ...rest
}: Omit<MeetingPartnerProps, "profile">) {
  return (
    <Grid
      sx={{ gridTemplateColumns: "16px auto", columnGap: 2, rowGap: 0 }}
      {...rest}
    >
      <User size={16} weight="light" sx={{ marginTop: 0.5 }} />
      <Box>
        {label && (
          <Label variant="small" sx={{ color: "inherit", fontWeight: "bold" }}>
            {label}
          </Label>
        )}
        <Grid sx={{ gridTemplateColumns: "30px auto", marginTop: 1 }}>
          <UserProfileCardAvatarSkeleton variant="compact" />
          <Box>
            <UserProfileCardDisplayNameSkeleton />
            <UserProfileCardCompanySkeleton />
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
}

/** @jsxImportSource theme-ui */
import { ListContainer } from "@bottlebooks/bottlebooks-site-bookmarks/src";
import { Text } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import ExploreAllProductsBanner from "./ExploreAllProductsBanner";
import React from "react";

/**
 * @param {JSX.IntrinsicAttributes & { [x: string]: any; }} rest
 */
export default function Loading(rest) {
  return (
    <>
      <ListContainer {...rest}>
        <Text>
          <Trans>Loading…</Trans>
        </Text>
      </ListContainer>
      <ExploreAllProductsBanner />
    </>
  );
}

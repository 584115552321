/** @jsxImportSource theme-ui */
import { ErrorMessage, RadioField } from "@bottlebooks/form";
import { Box, Stack, Text } from "@bottlebooks/gatsby-theme-base/src";
import { getIn, useFormikContext } from "formik";
import StarIcon from "./StarIcon";

export default function RatingField({ name, ...rest }) {
  return (
    <Stack spacing={2}>
      <Stack
        direction="row"
        spacing={0}
        sx={{ marginRight: "auto", ":hover > * svg": { color: "secondary" } }}
        {...rest}
      >
        <SingleRatingField name={name} value="1" sx={{ paddingRight: 4 }} />
        <SingleRatingField name={name} value="2" sx={{ paddingRight: 4 }} />
        <SingleRatingField name={name} value="3" sx={{ paddingRight: 4 }} />
        <SingleRatingField name={name} value="4" sx={{ paddingRight: 4 }} />
        <SingleRatingField name={name} value="5" />
      </Stack>
      <Text>
        <ErrorMessage name={name} />
      </Text>
    </Stack>
  );
}

function SingleRatingField({ name, value, ...rest }) {
  const { values } = useFormikContext();
  const fieldValue = getIn(values, name);
  console.log("SingleRatingField", name, fieldValue);
  return (
    <Box
      sx={{
        transition: "transform",
        ":hover": { transform: "scale(1.1)" },
        "&:hover ~ * svg": { color: "lightestText" },
      }}
      {...rest}
    >
      <RadioField
        name={name}
        value={value}
        label={
          <StarIcon
            size="medium"
            variant={fieldValue >= value ? "filled" : "hollow"}
            aria-hidden
            sx={{
              color: fieldValue >= value ? "primary" : "lightestText",
              transition: "color",
              ":hover": { color: "secondary" },
            }}
          />
        }
        ignoreErrors
        sx={{ position: "absolute", top: 0, left: 0, opacity: 0 }} // Hide the radio buttons visually.
      />
    </Box>
  );
}

/** @jsxImportSource theme-ui */
import { Button, DownloadIcon } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";

const fragment = graphql(`
  fragment RegistrationInfoSheetButton on SingleRegistration {
    registrationInfoSheet {
      url
    }
  }
`);

export default function RegistrationInfoSheetButton({
  exhibitor: data,
  ...rest
}: {
  exhibitor: FragmentType<typeof fragment>;
}) {
  const exhibitor = useFragment(fragment, data);
  if (!exhibitor?.registrationInfoSheet?.url) return null;
  return (
    <Button
      href={exhibitor?.registrationInfoSheet?.url}
      target="_blank"
      rel="noopener noreferrer"
      variant="outline"
      sx={{ textAlign: "center" }}
      {...rest}
    >
      <DownloadIcon
        size="small"
        sx={{ marginRight: 2, color: "currentColor" }}
      />
      <Trans>Download info sheet</Trans>
    </Button>
  );
}

/** @jsxImportSource theme-ui */
import { Flex } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";
import PackagingVariant from "./PackagingVariant.next";

const fragment = graphql(`
  fragment ProductPackagingVariants on Product {
    packagingVariants {
      ...PackagingVariant
    }
  }
`);

export default function ProductPackagingVariants({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const product = useFragment(fragment, data);
  if (!hasContent(data)) return null;
  return (
    <Section title={<Trans>Packaging</Trans>} {...rest}>
      <Flex gap={3} wrap="wrap">
        {product.packagingVariants?.map((packagingVariant, index) => (
          // We only have the index as an identifier - it's ok here because the order can't be changed.
          <PackagingVariant key={index} data={packagingVariant} />
        ))}
      </Flex>
    </Section>
  );
}

function hasContent(data: FragmentType<typeof fragment>) {
  const product = useFragment(fragment, data);
  if (!product.packagingVariants?.length) return false;
  return product.packagingVariants.some((variant) =>
    PackagingVariant.hasContent(variant)
  );
}

const quickLinkVariants = {
  default: {
    card: {
      borderRadius: 'default',
      transition: 'backgroundColor',
    },
    content: {
      padding: [3, 4],
    },
    sublink: {
      padding: [3, 4],
    },
  },

  text: {},

  primary: {
    card: {
      variant: 'quickLink.default.card',
      backgroundColor: 'primary',
      color: 'onPrimary',
      minHeight: [1, null, null, 350],
      'a:hover': { color: 'onSecondary', backgroundColor: 'secondary' },
    },
    content: {
      variant: 'quickLink.default.content',
      color: 'onPrimary',
      transition: 'backgroundColor',
      flex: '1 0',
    },
    title: { variant: 'quickLink.default.title' },
    text: {
      variant: 'quickLink.default.text',
      display: ['none', 'inline'],
    },
    button: {
      display: ['none', 'flex'],
      marginTop: [0, 'auto'],
      textAlign: 'left',
      verticalAlign: 'center',
      variant: 'quickLink.default.button',
    },
    sublink: {
      variant: 'quickLink.default.sublink',
      color: 'onPrimary',
      transition: 'backgroundColor',
      paddingY: [3, 3],
      borderColor: 'background',
      borderTop: 1,
      borderRadius: 0,
    },
  },
};

export default quickLinkVariants;

/**
 * @typedef {keyof typeof quickLinkVariants} QuickLinkVariant
 */

/** @jsxImportSource theme-ui */
import type { Transformation } from "cloudinary-core";
import { Cloudinary } from "cloudinary-core";
import React from "react";

const CLOUDINARY_IMAGE_MAX_SIZE = 4000; // Maximum width or height.
const cl = new Cloudinary({ cloud_name: "bottlebooks", secure: true });

type ImageLayout = "fixed" | "constrained" | "fullWidth";

interface Props {
  publicId: string;
  cloudName?: string;
  layout?: ImageLayout;
  alt?: string;
  sizes?: string;
  options?: Transformation.Options;
}

export default function Image({
  publicId,
  cloudName = "bottlebooks",
  layout,
  alt,
  sizes,
  options,
  ...rest
}: Omit<React.ComponentPropsWithRef<"img">, "style"> & Props) {
  if (!publicId) return null;
  switch (layout) {
    case "fixed":
      return (
        <picture style={{ justifySelf: "center" }}>
          <source
            type="image/avif"
            {...getFixedImage(publicId, { fetchFormat: "avif", ...options })}
            sizes={sizes}
          />
          <source
            type="image/webp"
            {...getFixedImage(publicId, { fetchFormat: "webp", ...options })}
            sizes={sizes}
          />
          <img
            alt={alt}
            {...getFixedImage(publicId, { fetchFormat: "png", ...options })}
            {...rest}
            sizes={sizes}
          />
        </picture>
      );
    case "constrained":
    case "fullWidth":
    default:
      throw new Error(`Image layout: ${layout} isn't implemented yet.`);
  }
}

function getFixedImage(publicId: string, options: Transformation.Options) {
  return {
    width: options.width,
    height: options.height,
    src: cl.url(publicId, options),
    srcSet: getFixedSrcSet(publicId, options),
  };
}

// This is copied and adapted from the CloudinaryAsset.fixed resolver.
function getFixedSrcSet(publicId: string, options: Transformation.Options) {
  let desiredAspectRatio = Number(options.aspectRatio) || 1;
  // If we're cropping, calculate the specified aspect ratio.
  if (typeof options.width === "number" && typeof options.height === "number") {
    desiredAspectRatio = options.width / options.height;
  }
  // Create sizes (in width) for the image. If the width of the
  // image is 800px, the sizes would then be: 800, 1200, 1600,
  // 2400.
  //
  // This is enough sizes to provide close to the optimal image size for every
  // device size / screen resolution

  const fixedSizes = (
    typeof options.width === "number"
      ? [
          options.width,
          options.width * 1.5,
          options.width * 2,
          options.width * 3,
        ]
      : []
  )
    .map(Math.round)
    // Filter out sizes larger than the image's width and the Cloudinary image's max size.
    .filter((width) => {
      const calculatedHeight = Math.round(width / desiredAspectRatio);
      return (
        width <= CLOUDINARY_IMAGE_MAX_SIZE &&
        calculatedHeight <= CLOUDINARY_IMAGE_MAX_SIZE
      );
    });

  // Sort sizes for prettiness.
  // const sortedSizes = sortBy(fixedSizes);

  // Create the srcSet.
  const srcSet = fixedSizes
    .map((width, i) => {
      let resolution;
      switch (i) {
        case 0:
          resolution = `1x`;
          break;
        case 1:
          resolution = `1.5x`;
          break;
        case 2:
          resolution = `2x`;
          break;
        case 3:
          resolution = `3x`;
          break;
        default:
      }
      const height = Math.round(width / desiredAspectRatio);
      return `${cl.url(publicId, { ...options, width, height })} ${resolution}`;
    })
    .join(`,\n`);
  return srcSet;
}

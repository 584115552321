/** @jsxImportSource theme-ui */
import { Row } from "@bottlebooks/gatsby-theme-base/src";

export default function ContentRow({ children, ...rest }) {
  return (
    <Row
      // 1 col spacing left/right from medium width up.
      sx={{ paddingX: [0, `${100 / 12}%`] }}
      {...rest}
    >
      {children}
    </Row>
  );
}

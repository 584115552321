/** @jsxImportSource theme-ui */
import { gql } from "urql";

export default gql`
  fragment CollectionFlightSectionFragment on CollectionFlightSection {
    title
    collectionFlight {
      flightName
      collectionId
    }
    style {
      ...SectionStyle
    }
  }
`;

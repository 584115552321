/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  ChevronDownIcon,
  ChevronUpIcon,
  Flex,
  Link,
  Text,
  Title,
} from "@bottlebooks/gatsby-theme-base/src";
import { Plural } from "@lingui/macro";
import React from "react";
import { PencilSimple } from "@phosphor-icons/react";
import EditNameDialog from "./ListsPage.EditNameDialog";

/**
 *
 * @param {{
 *  children?: React.ReactNode;
 *  [x: string]: any;
 * }} props
 * @returns
 */
export default function ListHeader({
  list,
  isSectionExpanded,
  setIsSectionExpanded,
  children,
  items,
  ...rest
}) {
  const [isEditNameDialogOpen, setIsEditNameDialogOpen] = React.useState(false);
  return (
    <Box sx={{ width: "100%", cursor: "pointer" }}>
      <Flex>
        <Flex
          onClick={() => setIsSectionExpanded(!isSectionExpanded)}
          gap={2}
          sx={{
            paddingX: 2,
            paddingLeft: 3,
            paddingTop: 2,
            paddingBottom: 2,
            flexGrow: 1,
          }}
          {...rest}
        >
          <Box sx={{ marginRight: 3, marginTop: 2 }}>
            {isSectionExpanded ? (
              <Link
                variant="text"
                onClick={() => setIsSectionExpanded(!isSectionExpanded)}
              >
                <ChevronDownIcon size="small" />
              </Link>
            ) : (
              <Link
                variant="text"
                onClick={() => setIsSectionExpanded(!isSectionExpanded)}
              >
                <ChevronUpIcon size="small" />
              </Link>
            )}
          </Box>
          <Box>
            <Title variant="small">{children}</Title>
            <Text variant="small">
              <Plural
                value={items?.length}
                one="# product"
                other="# products"
              />
            </Text>
          </Box>
        </Flex>
        <Box sx={{ paddingX: 2, paddingTop: 3 }}>
          <Button
            variant="text"
            onClick={() => setIsEditNameDialogOpen(true)}
            sx={{
              width: 44,
              height: 44,
              textAlign: "center",
            }}
          >
            <PencilSimple
              size={24}
              weight="light"
              // "lighterText"
              color="#888"
            />
          </Button>
        </Box>
      </Flex>

      {isEditNameDialogOpen && (
        <EditNameDialog
          onDismiss={() => setIsEditNameDialogOpen(false)}
          list={list}
        />
      )}
    </Box>
  );
}

/** @jsxImportSource theme-ui */
import Card from "@bottlebooks/gatsby-design-system/src/components/Card/Card";
import { Box, Title } from "@bottlebooks/gatsby-theme-base/src";
import ExhibitorsPageCards from "../ExhibitorsPage/ExhibitorsPageCards";
import ExhibitorsList from "./ExhibitorsList";

export default function GroupedExhibitorsCard({ type, exhibitors, ...rest }) {
  return (
    <Card sx={{ backgroundColor: "nuanced" }} {...rest}>
      {exhibitors[0]?.stand?.roomName != "" && (
        <RoomHeader roomName={exhibitors[0]?.stand?.roomName} />
      )}
      <ExhibitorsList sx={{ gridAutoFlow: "dense", padding: [2, 3] }}>
        <ExhibitorsPageCards
          exhibitors={exhibitors}
          sx={{ boxShadow: "borderSecondary" }}
        />
      </ExhibitorsList>
    </Card>
  );
}

function RoomHeader({ roomName, ...rest }) {
  return (
    <Box
      sx={{
        color: "onPrimary",
        backgroundColor: "primary",
        borderTopLeftRadius: "default",
        borderRadius: "default",
        paddingX: [2, 3],
        paddingTop: 5,
        paddingBottom: 0,
        zIndex: "modal",
        position: "sticky",
        top: 0,
        transition: "opacity",
        // This is not currently a link. If it becomes a link, we can add hover styles.
        // ':hover': {
        //   opacity: 0.8,
        //   color: 'onPrimary',
        // },
      }}
      {...rest}
    >
      <Title variant="small">{roomName || "Other"}</Title>
    </Box>
  );
}

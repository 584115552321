import { graphql } from "~/gql";
import gql from "graphql-tag";
import React from "react";
import getLink from "./getLink";

import { z } from "zod";

const LinkContext = React.createContext(
  /** @type {getLink.LinkContext | undefined}*/ undefined
);

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
  collectionId: z.string(),
});

export function LinkProvider({ value, children, locale, collectionId }) {
  const context = React.useContext(LinkContext);

  const base = `/${locale}/collections/${collectionId}`;
  if (typeof value === "function") {
    return (
      <LinkContext.Provider value={{ ...value(context), base }}>
        {children}
      </LinkContext.Provider>
    );
  }
  if (context) {
    return (
      <LinkContext.Provider value={{ ...context, ...value, base }}>
        {children}
      </LinkContext.Provider>
    );
  }

  return <LinkContext.Provider value={value}>{children}</LinkContext.Provider>;
}

export default function useLink() {
  const context = React.useContext(LinkContext);
  return getLink(context);
}

// export const fragments = graphql(`
//   fragment useLink_exhibitor_bb_Brand on Bottlebooks_Brand {
//     exhibitorId: brandId
//   }

//   fragment useLink_exhibitor_Brand on Brand {
//     exhibitorId: brandId
//   }

//   fragment useLink_exhibitor_AbstractBrand on AbstractBrand {
//     exhibitorId: brandId
//   }

//   fragment useLink_exhibitor_bb_AbstractBrand on Bottlebooks_AbstractBrand {
//     exhibitorId: brandId
//   }

//   fragment useLink_bb_Exhibitor on Bottlebooks_Exhibitor {
//     exhibitorId
//   }

//   fragment useLink_Exhibitor on Exhibitor {
//     exhibitorId
//   }

//   fragment useLink_bb_RegisteredProduct on Bottlebooks_RegisteredProduct {
//     exhibitorId
//     productId
//   }

//   fragment useLink_bb_Product on Bottlebooks_Product {
//     productId
//     ... on Bottlebooks_ExtendedProduct {
//       exhibitorId
//     }
//   }

//   fragment useLink_Product on Product {
//     exhibitorId
//     productId
//   }

//   fragment useLink_bb_Producer on Bottlebooks_Producer {
//     producerId
//   }

//   fragment useLink_Producer on Producer {
//     producerId
//   }

//   fragment useLink_bb_RegisteredBrand on Bottlebooks_RegisteredBrand {
//     producerId: brandId
//   }

//   fragment useLink_ProductList on Event {
//     productListId: eventId
//   }
// `);

useLink.productFragment = gql`
  fragment useLink_Product on Product {
    ... on ExtendedProduct {
      exhibitorId
    }
    productId
  }
`;

useLink.registeredProductFragment = gql`
  fragment useLink_RegisteredProduct on Product {
    exhibitorId
    productId
  }
`;
useLink.producerFragment = gql`
  fragment useLink_Producer on Producer {
    producerId
  }
`;

useLink.exhibitorFragment = gql`
  fragment useLink_Exhibitor on Exhibitor {
    exhibitorId
  }
`;

/** @jsxImportSource theme-ui */
import { Flex, Label } from "@bottlebooks/gatsby-design-system";

/**
 *
 * @param {object} props
 * @param {React.ReactNode} [props.children]
 * @param {string} [props.className]
 * @param {import('csstype').Property.FlexDirection} [props.direction] The flex-direction CSS attribute.
 * @param {React.ReactElement} [props.icon] An optional icon.
 */
export default function Badge({ icon, children, direction, ...rest }) {
  return (
    <Flex gap={[0, 2]} align="center" direction={direction} {...rest}>
      {icon}
      {children && (
        <Label sx={{ display: ["none", "block"] }}>{children}</Label>
      )}
    </Flex>
  );
}

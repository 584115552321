/** @jsxImportSource theme-ui */
import React from "react";

export default function Mark({
  text,
  term,
  originalTerm = term,
  render,
  Wrapper = React.Fragment,
  fallback,
  renderRest = (text, term) => (
    <Mark
      text={text}
      term={term}
      originalTerm={originalTerm}
      render={render}
      fallback={fallback}
    />
  ),
  restTerm = "",
  append = (_rest) => null,
}) {
  if (!text) return "";
  if (!term) {
    return (
      <React.Fragment>
        <Wrapper>{text}</Wrapper>
        {append(restTerm)}
      </React.Fragment>
    );
  }
  const position = text.toLowerCase().indexOf(term.toLowerCase());
  if (position === -1) {
    if (term.length === 1) {
      // The search term wasn't found.
      // If we have a fallback render it instead.
      if (originalTerm === `${term}${restTerm}` && fallback) return fallback();
      // Otherwise render the text without any marker.
      return (
        <React.Fragment>
          <Wrapper>{text}</Wrapper>
          {append(term + restTerm)}
        </React.Fragment>
      );
    }

    // Try with complete phrases if the searchTerm has spaces.
    // This results in a smaller component tree.
    const words = term.split(/\s+/);
    const newTerm =
      words.length > 1 ? words.slice(0, -1).join(" ") : term.slice(0, -1);
    const rest =
      words.length > 1
        ? ` ${words.slice(-1).join(" ")}${restTerm}`
        : `${term.slice(-1)}${restTerm}`;
    // Use the marker recursively with two separate terms.
    return (
      <Mark
        text={text}
        term={newTerm}
        originalTerm={originalTerm}
        render={render}
        renderRest={(text) => (
          <Mark
            text={text}
            term={rest}
            originalTerm={originalTerm}
            render={render}
            fallback={fallback}
          />
        )}
        restTerm={rest}
        Wrapper={Wrapper}
        append={append}
        fallback={fallback}
      />
    );
  }
  // The search term was found.
  // Render the three segments:
  // 1) the text before the found term.
  // 2) the found term (in the original casing).
  // 3) the rest of the text.
  // The append function allows us to attempt finding the rest of the term.
  // This can be used for finding part of the term in the producer name, and the rest in the product name.
  return (
    <React.Fragment>
      <Wrapper>
        {text.slice(0, position)}
        {render(text.slice(position, position + term.length))}
        {renderRest(text.slice(position + term.length), restTerm)}
      </Wrapper>
      {append(restTerm)}
    </React.Fragment>
  );
}

/** @jsxImportSource theme-ui */
import { P, Text } from "@bottlebooks/gatsby-design-system";
import { Button, Dialog } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import type { DialogProps } from "@reach/dialog";
import { Form, Formik } from "formik";
import type * as yup from "yup";
import { FragmentType, graphql, useFragment } from "~/gql";
import type { RequestMeetingButtonInfoData } from "./RequestMeetingButton.Info.next";
import RequestMeetingButtonInfo from "./RequestMeetingButton.Info.next";
import Timeslot from "./Timeslot.next";

const meetingTimeFormSchema = Timeslot.schema;
export type RequestMeetingButtonTimeFormSchema = yup.InferType<
  typeof meetingTimeFormSchema
>;

const fragment = graphql(`
  fragment RequestMeetingButtonTimeForm on SiteUserProfile {
    displayName
    ...RequestMeetingButtonInfo
    collection {
      meetingTimeslots(
        experiment: "california-networking"
        experimentToken: "Used in @bottlebooks/gatsby-theme-event, added by bernhard@unserwein.at at 2023-02-25"
      ) {
        ...Timeslot
      }
    }
  }
`);

export default function RequestMeetingButtonTimeForm({
  recipient: data,
  initialValues,
  onDismiss,
  onContinue,
}: {
  recipient: FragmentType<typeof fragment>;
  onDismiss: DialogProps["onDismiss"];
  initialValues: RequestMeetingButtonTimeFormSchema;
  onContinue: (values: RequestMeetingButtonTimeFormSchema) => void;
}) {
  const recipient = useFragment(fragment, data);
  return (
    <Formik<RequestMeetingButtonTimeFormSchema>
      initialValues={initialValues}
      validationSchema={meetingTimeFormSchema}
      validateOnMount={true}
      onSubmit={(values) => onContinue(values)}
    >
      <Form>
        <Dialog.Header onDismiss={onDismiss}>
          <Trans>Book a meeting with {recipient.displayName}</Trans>
        </Dialog.Header>
        <Dialog.Content withPanels>
          <Dialog.InfoPanel>
            <P>
              <Trans>
                To book a meeting with {recipient.displayName}, please first
                select a date and time. On the next step, you will be able to
                provide more details about yourself before sending the request.
              </Trans>
            </P>
            <P>
              <Trans>
                After submitting you will receive an email with further
                information.
              </Trans>
            </P>

            <RequestMeetingButtonInfo recipient={recipient} />
          </Dialog.InfoPanel>
          <Dialog.MainPanel>
            {!recipient.collection.meetingTimeslots?.length ? (
              <Text>
                <Trans>Loading...</Trans>
              </Text>
            ) : (
              <Timeslot
                timeslots={recipient?.collection.meetingTimeslots || []}
              />
            )}
          </Dialog.MainPanel>
        </Dialog.Content>
        <Dialog.Footer onDismiss={onDismiss}>
          <Button variant="primary" type="submit">
            <Trans>Continue</Trans>
          </Button>
        </Dialog.Footer>
      </Form>
    </Formik>
  );
}

export type RequestMeetingButtonTimeFormData = RequestMeetingButtonInfoData;

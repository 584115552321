/** @jsxImportSource theme-ui */
import { Box, Flex, Grid, Text } from "@bottlebooks/gatsby-design-system";
import ProductCard from "@bottlebooks/bottlebooks-site-base/src/components/ProductCard/ProductCard.next";
import useEventData from "@bottlebooks/bottlebooks-site-base/src/components/SiteSearch/useEventData";
// import { graphql } from "gatsby";
import Section from "../Layout/Section";

/**
 * @param {object} props
 * @param {string} props.title
 * @param {object} props.collectionFlight
 * @param {string} props.collectionFlight.flightName
 * @param {string} props.collectionFlight.collectionId
 * @param {object} props.style
 * @returns
 */
export default function CollectionFlightSection({
  title,
  collectionFlight,
  style,
  ...rest
}) {
  const { products } = useEventData();
  if (!collectionFlight) return null;
  if (!collectionFlight.flightName) return null;
  const flightProducts = products
    .filter((p) => p.flightName === collectionFlight.flightName)
    .sort((a, b) => {
      const aSortIndex = a.sortIndex || 0;
      const bSortIndex = b.sortIndex || 0;
      return aSortIndex - bSortIndex;
    });
  console.log(flightProducts);
  if (!flightProducts)
    return (
      <Box {...rest}>
        <Text>No products for flight {collectionFlight.flightName}.</Text>
      </Box>
    );

  return (
    <Section variant={style.variant} sx={style}>
      {title && (
        <Section.Title
          sx={{ textAlign: style.alignment || "left", marginBottom: 4 }}
        >
          {title}
        </Section.Title>
      )}
      <Grid
        gap={3.5}
        sx={{
          gridTemplateColumns: [
            "1fr",
            null,
            "repeat(2, 1fr)",
            "repeat(3, 1fr)",
          ],
        }}
      >
        {flightProducts.map((product) => (
          <ProductCard key={product.productId} product={product} />
        ))}
      </Grid>
    </Section>
  );
}

// export const fragment = graphql`
//   fragment SanityCollectionFlightSectionFragment on SanityCollectionFlightSection {
//     title
//     collectionFlight {
//       flightName
//       collectionId
//     }
//     style {
//       ...SectionStyle
//     }
//   }
// `;

/** @jsxImportSource theme-ui */
import useUser from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUser";
import {
  Box,
  Container,
  Stack,
  Text,
  Title,
} from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import type { User } from "firebase/auth";
import type { BoxProps } from "theme-ui";

export default function PersonalHeader() {
  const [user, isLoading, error] = useUser();
  if (isLoading) return <Loading />;
  if (error) return <Error error={error} />;
  if (user) return <LoggedIn user={user} />;
  return <LoggedOut />;
}

export function Header({ children, ...rest }) {
  return (
    <Box
      sx={{
        paddingY: [3.5, 4.5],
        backgroundColor: "white",
        borderBottom: 1,
        borderColor: "border",
      }}
      {...rest}
    >
      {/* @ts-expect-error incorrect typing */}
      <Container sx={{ maxWidth: ["container.fluid", "container.fluid"] }}>
        {children}
      </Container>
    </Box>
  );
}

function Loading({ ...rest }) {
  return (
    <Header {...rest}>
      <Title>
        <Trans>Welcome</Trans>
      </Title>
      <Text variant="small" sx={{ color: "lightText", paddingBottom: 4 }}>
        <Trans>Loading…</Trans>
      </Text>
    </Header>
  );
}

function Error({ error, ...rest }) {
  return (
    <Header {...rest}>
      <Title>
        <Trans>Welcome</Trans>
      </Title>
      <Text
        variant="small"
        sx={{ color: "brand.warningRed", paddingBottom: 4 }}
      >
        <Trans>There was an error logging in. Try refreshing the page.</Trans>
      </Text>
    </Header>
  );
}

function LoggedIn({ user, ...rest }: BoxProps & { user: User }) {
  const userName = user.displayName;
  return (
    <Header {...rest}>
      <Stack spacing={1}>
        <Title>
          {user.isAnonymous ? (
            <Trans>
              Welcome, <span sx={{ color: "primary" }}>visitor!</span>
            </Trans>
          ) : !userName ? (
            // Show visitor if no name is set. This can happen if user sets up an account using an email and password but doesn't add details to profile.
            <Trans>
              Welcome, <span sx={{ color: "primary" }}>visitor!</span>
            </Trans>
          ) : (
            <Trans>
              Welcome, <span sx={{ color: "primary" }}>{userName}!</span>
            </Trans>
          )}
        </Title>

        <Text variant="small" sx={{ color: "lightText", marginBottom: 2 }}>
          {user.email}
        </Text>
      </Stack>
    </Header>
  );
}

function LoggedOut({ ...rest }) {
  return (
    <Header {...rest}>
      <Title>
        <Trans>Welcome</Trans>
      </Title>
    </Header>
  );
}

/** @jsxImportSource theme-ui */
import { Button, DownloadIcon } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";

const fragment = graphql(`
  fragment ProductInfoSheetButton on RegisteredProduct {
    infoSheet(templateId: "Bottlebooks/InfoSheetWithQRCodes") {
      url
    }
  }
`);

export default function ProductInfoSheetButton({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const registeredProduct = useFragment(fragment, data);
  if (!registeredProduct?.infoSheet?.url) return null;
  return (
    <Button
      href={registeredProduct.infoSheet.url}
      target="_blank"
      rel="noopener noreferrer"
      variant="outline"
      sx={{ textAlign: "center" }}
      {...rest}
    >
      <DownloadIcon
        size="small"
        sx={{ marginRight: 2, color: "currentColor" }}
      />
      <Trans>Download info sheet</Trans>
    </Button>
  );
}

/** @jsxImportSource theme-ui */
import { Title } from "@bottlebooks/gatsby-design-system";
import { graphql } from "~/gql";
import ListRow from "../List/ListRow";
import ListRowBody from "../List/ListRowBody";

export default function ProductListListRow({ productList, ...rest }) {
  return (
    <ListRow sx={{ alignItems: "center" }} {...rest}>
      <ListRowBody sx={{ position: "relative", marginBottom: 3 }}>
        <Title variant="small" sx={{ paddingBottom: 3 }}>
          {productList.name}
        </Title>
      </ListRowBody>
    </ListRow>
  );
}

export const fragment = graphql(`
  fragment ProductListListRow on Event {
    name
  }
`);

/** @jsxImportSource theme-ui */
import { Text } from "@bottlebooks/gatsby-design-system";
import { Link } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { LinkedinLogo } from "@phosphor-icons/react";
import { FragmentType, graphql, useFragment } from "~/gql";
import Skeleton from "../Skeleton";
import type { TextProps } from "./UserProfileCard";

const fragment = graphql(`
  fragment UserProfileCardLinkedIn on SiteUserProfile {
    linkedInURL
  }
`);

export default function UserProfileCardLinkedIn({
  profile: data,
  ...rest
}: {
  profile: FragmentType<typeof fragment>;
}) {
  const profile = useFragment(fragment, data);
  if (!profile?.linkedInURL) return null;
  return (
    <Text variant="smallest" sx={{ color: "lighterText" }} {...rest}>
      <Link
        href={profile.linkedInURL}
        target="_blank"
        rel="noopener noreferrer"
        variant="text"
        sx={{ display: "flex", alignItems: "center", gap: 1 }}
      >
        <LinkedinLogo size={20} weight="fill" />
        <Trans>See on LinkedIn</Trans>
      </Link>
    </Text>
  );
}

UserProfileCardLinkedIn.fragment = fragment;

export type UserProfileCardLinkedInData = {
  linkedInURL: string | null;
};

export function UserProfileCardLinkedInSkeleton(rest: TextProps) {
  return (
    <Text variant="smallest" {...rest}>
      <Skeleton width="4em" height="1em" />
    </Text>
  );
}

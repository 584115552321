/** @jsxImportSource theme-ui */
// eslint-disable-next-line import/no-unresolved
import { useFavorite } from "@bottlebooks/bottlebooks-site-bookmarks/src";
import ToggleFavorite from "@bottlebooks/bottlebooks-site-bookmarks/src/components/ToggleFavorite";
import AddToListButton from "@bottlebooks/bottlebooks-site-lists/src/components/AddToListButton";
import {
  Box,
  Flex,
  Label,
  Link,
  SemiTitle,
  Text,
} from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";
import Card from "../../Card";
import useLink from "../../useLink";
import ListRowBody from "../List/ListRowBody";
import { ProductHeaderDetails } from "../Product/ProductHeader.next";
import ProductImage from "../Product/ProductImage";
import ProductLabel from "../Product/ProductLabel.next";
import BaseProductName from "../Product/ProductName.next";
import useSiteConfig from "../useSiteConfig";
import ProductCardActions from "./ProductCardActions";
// import ProductCardFeatures from "./ProductCardFeatures.next";
import ProductCardTastingNoteAction from "./ProductCardTastingNoteAction";
import ProductStand from "../Product/ProductStand.next";
// HEADSUP: Product features have to be queried separately by the parent.

/** Used by some components that should be refactored:
 * - GroupedProductsCard
 */
const miniFragment = graphql(/* GraphQL */ `
  fragment ProductCard on Product {
    #...ProductHeader
    ...ProductImage
  }
`);

const fragment = graphql(/* GraphQL */ `
  fragment ProductCard_RegisteredProduct on RegisteredProduct {
    productId
    exhibitorId: companyId
    producerId
    ...ProductLabel_RegisteredProduct
    ...ProductHeaderDetails_RegisteredProduct
    ...ProductHeaderDetails
    registration {
      ...ProductStand
      profile {
        name
      }
    }
    producer {
      name
    }
    product {
      productId
      ...ProductName
      ...ProductImage
      # ...ProductCardFeatures
    }
  }

  # fragment ProductCard on Product {
  #   ...ProductHeader
  # }

  # A fragment for the first page of products. It has more detailed images.
  # fragment ProductCard_first on Product {
  #   ...ProductImageLarge
  # }
`);

/** @param {{ product: object; className?: string; style?: React.CSSProperties; elevation?: Parameters<Card>[0]["elevation"]; [x: string]: any; }} props */
export default function ProductCard({
  product: data,
  className,
  style,
  elevation,
  ...rest
}: {
  product: FragmentType<typeof fragment>;
  className?: string;
  style?: React.CSSProperties;
  elevation?: Parameters<Card>[0]["elevation"];
  [x: string]: any;
}) {
  const registeredProduct = useFragment(fragment, data);
  const product = registeredProduct?.product;
  const favorite = useFavorite(
    product && { type: "product", id: product.productId }
  );
  const link = useLink();
  const { listsEnabled } = useSiteConfig();
  return (
    <Card
      // 1fr for the first row lets the card take up the full height.
      sx={{ gridTemplateRows: "1fr", padding: 2.5 }}
      elevation={elevation}
      className={className}
      style={style}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          color: "inherit",
          // Align the product features to the bottom of the main content.
          position: "relative",
        }}
        {...rest}
      >
        <Box
          sx={{
            width: 150,
            maxWidth: "35%",
            padding: 2,
            paddingRight: 0,
            boxSizing: "content-box",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <Link to={link.product(product)}>
            <ProductImage
              product={product}
              size="large"
              sx={{
                objectFit: "cover",
                objectPosition: "center center",
                opacity: 1,
                width: "100%",
                transition: "all 500ms ease 0s",
                ":hover": { transform: "scale(1.02)" },
              }}
            />
          </Link>
        </Box>
        <ListRowBody sx={{ marginTop: 4 }}>
          <ProductLabel data={registeredProduct} sx={{ marginBottom: 2 }} />
          <ProductStand data={registeredProduct.registration} to="" />
          {registeredProduct?.registration?.profile?.name !==
            registeredProduct?.producer?.name && (
            <Text variant="smallest" sx={{ marginTop: 2 }}>
              {registeredProduct?.producer?.name}
            </Text>
          )}
          <SemiTitle sx={{ paddingTop: 1, paddingBottom: 2 }}>
            <Link
              variant="text"
              to={link.product({
                exhibitorId: registeredProduct.exhibitorId,
                productId: product.productId,
              })}
              sx={{ transition: "all 0.3s ease-out" }}
            >
              <BaseProductName {...product} />
            </Link>
          </SemiTitle>
          <ProductHeaderDetails data={registeredProduct} />
        </ListRowBody>
        {/* ProductCardFeatures does not listen to form settings and reports features that are not active */}
        {/* <ProductCardFeatures
          data={product}
          sx={{ position: "absolute", bottom: 2.5, right: 2.5 }}
        /> */}
      </Box>
      {!favorite.isLoading && (
        <Flex align="center" sx={{ position: "absolute", top: 0, right: 0 }}>
          {listsEnabled ? (
            <AddToListButton product={product} />
          ) : (
            // Legacy approach to bookmarks
            <ToggleFavorite
              isToggled={favorite.isSet}
              variant="small"
              onClick={() => favorite.toggle()}
            />
          )}
        </Flex>
      )}

      <ProductCardTastingNoteAction
        product={product}
        sx={{ marginY: 2.5, paddingTop: 3 }}
      />
      <ProductCardActions product={registeredProduct} />
    </Card>
  );
}

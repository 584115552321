/** @jsxImportSource theme-ui */
import Card from "../../Card";
import { Flex } from "@bottlebooks/gatsby-theme-base/src";
import { graphql as gatsbyGraphQL } from "~/gql";
import {
  ToggleFavorite,
  useFavorite,
} from "@bottlebooks/bottlebooks-site-bookmarks/src";
import { getFluidImage } from "../../helpers/getImageProps";
import BrandLogo from "../Brand/BrandLogo";
import OfferingBadge from "../Brand/OfferingBadge";
import ExhibitorHeader from "../Exhibitor/ExhibitorHeader.next";
import ListRow from "../List/ListRow";
import ListRowBackgroundImage from "../List/ListRowBackgroundImage";
import ListRowBody from "../List/ListRowBody";

/**
 *
 * @param {object} props
 * @param {object} props.exhibitor
 * @param {string} [props.to]
 * @param {Parameters<Card>[0]["elevation"]} [props.elevation]
 * @param {React.MouseEventHandler} [props.onClick]
 * @param {React.ReactNode} [props.children]
 * @param {import('react-beautiful-dnd').DraggableProvidedDragHandleProps} [props.dragHandleProps]
 * @returns
 */
export default function ExhibitorListRow({
  exhibitor,
  children,
  elevation = "none",
  dragHandleProps,
  ...rest
}) {
  // TODO use registrationId: exhibitor.registrationId,
  const favorite = useFavorite(
    exhibitor && { type: "registration", id: exhibitor.exhibitorId }
  );
  const mainImage = exhibitor?.mainImage?.fluid;
  const hasBackground = Boolean(mainImage);
  return (
    <Card elevation={elevation}>
      <ListRow
        onDark={hasBackground}
        sx={{
          overflow: "visible", // Show the shadow around the logo.
          flexDirection: "row",
          // Only add hover to links. The `&` is replaced by the class name automatically.
          "a[href]&:hover": {
            " > .bottlebooks-bgimg": {
              transform: "scale(1.01)",
              "::after": {
                backgroundColor: "overlaySecondary",
              },
            },
          },
        }}
        {...rest}
      >
        {hasBackground && (
          <ListRowBackgroundImage
            fluid={getFluidImage(mainImage)}
            className="bottlebooks-bgimg"
          />
        )}
        <BrandLogo
          brand={exhibitor}
          onDark={hasBackground}
          sx={{ backgroundColor: "background" }}
          {...dragHandleProps}
        />
        <ListRowBody sx={{ position: "relative", marginRight: 5, flexGrow: 1 }}>
          <ExhibitorHeader data={exhibitor} onDark={hasBackground} />
        </ListRowBody>

        <ToggleFavorite
          sx={{ position: "absolute", top: 0, right: 0 }}
          isToggled={favorite.isSet}
          variant="small"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            favorite.toggle();
          }}
        />
      </ListRow>
      <Flex
        direction="column"
        sx={(theme) => ({
          position: "absolute",
          bottom: 3,
          right: 3,
          color: hasBackground ? "onDark" : "inherit",
          textShadow: hasBackground
            ? `1px 1px ${theme.colors?.black}, 0 0 20px ${theme.colors?.black}`
            : undefined,
        })}
      >
        <BrandFeatures brand={exhibitor} />
      </Flex>
    </Card>
  );
}

export function BrandFeatures({ brand }) {
  if (!brand?.offeringTypes?.length) return null;
  return (
    <Flex
      justify="flex-end"
      align="flex-end"
      gap={2}
      sx={{
        width: "100%",
        paddingRight: 2,
        paddingBottom: 2,
        position: "relative", // Needed to stay above image backgrounds
        marginLeft: "auto",
      }}
    >
      {brand.offeringTypes?.map((type) => (
        <OfferingBadge key={type} variant="onPrimary" type={type} />
      ))}
    </Flex>
  );
}

export const fragment = gatsbyGraphQL(/* GraphQL */ `
  fragment bb_ExhibitorListRow on Bottlebooks_Exhibitor {
    offeringTypes
  }
  fragment bb_ExhibitorListRow_Registration on Bottlebooks_Registration {
    registrationId
  }
  fragment ExhibitorListRow_mainImage on Exhibitor {
    mainImage {
      fluid(maxWidth: 700, maxHeight: 200, crop: FILL, gravity: FACES_AUTO) {
        src
        sizes
        aspectRatio
      }
    }
  }
  fragment ExhibitorListRow on Exhibitor {
    ...BrandLogoLarge
    ...ExhibitorHeader
    ...ExhibitorListRow_mainImage
  }

  # A fragment for the first page of exhibitors. It has more detailed images.
  fragment ExhibitorListRow_first on Exhibitor {
    ...BrandLogoLarge
    mainImage {
      fluid(maxWidth: 700, maxHeight: 200, crop: FILL, gravity: FACES_AUTO) {
        src
        srcSet
        sizes
        base64
        aspectRatio
      }
    }
  }
`);

/** @jsxImportSource theme-ui */
import { Box } from "@bottlebooks/gatsby-design-system";

export default function Header({
  children,
  // Scrolling is currently disabled because it causes significant
  // peformance degredation on Safari. It should only be reactivated
  // once this issue has been resolved.
  // isSticky = true,

  // Remove unwanted props from rest::
  homePath,
  isScrolledDown,
  showHeader,
  locale,
  ...rest
}) {
  return (
    <Box
      as="header"
      sx={{
        display: "flex",
        variant: "styles.Header",
        height: [56, null, 128],
        backgroundColor: "background",
        borderBottom: "1px solid",
        borderColor: "border",
        paddingX: 0,
        paddingY: [2, null, 0],
        // Scrolling is currently disabled because it causes significant
        // peformance degredation on Safari. It should only be reactivated
        // once this issue has been resolved.
        // ...(isSticky && {
        //   position: [undefined, 'sticky'],
        //   zIndex: [undefined, 'header'], // Use the z-index from the theme definition.
        //   top: [undefined, '0'],
        //   transition: [undefined, 'transform 0.3s ease-in-out'],
        //   transform: showHeader ? undefined : [undefined, `translateY(-72px)`],
        // }),
        alignItems: "center",
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}

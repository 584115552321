/** @jsxImportSource theme-ui */

export default function VirtualTastingIcon({
  backgroundColor = "#fff",
  ...rest
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="29"
      fill="none"
      viewBox="0 0 28 29"
      {...rest}
    >
      <rect
        width="24"
        height="24"
        x="1.479"
        y="2.987"
        fill={backgroundColor}
        rx="12"
      ></rect>
      <path
        fill="currentColor"
        fillOpacity="0.5"
        fillRule="evenodd"
        d="M9.423 12.671a3.71 3.71 0 00.724 4.62c1.522 1.37 4.049 1.094 5.465-.39 1.26-1.319 1.74-2.201 2.23-3.271-2.14.776-4.946.456-8.42-.959z"
        clipRule="evenodd"
      ></path>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M20.795 11.258c-1.069 2.285-2.522 4.7-3.409 5.708-1.714 1.948-4.6 2.123-6.452.418-1.858-1.712-1.99-4.687-.27-6.64.883-1.005 3.07-2.729 5.173-4.053l4.958 4.567zm.915.403a.971.971 0 00-.22-1.123l-5.004-4.61a.945.945 0 00-1.145-.106c-2.144 1.346-4.445 3.144-5.428 4.26-2.017 2.292-1.934 5.775.186 7.886l-3.168 3.52-2.104-1.895a.5.5 0 00-.67.743l4.73 4.258a.5.5 0 00.669-.743l-1.882-1.694 3.195-3.55c2.262 1.555 5.375 1.171 7.268-.98.982-1.117 2.49-3.644 3.573-5.966z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

/** @jsxImportSource theme-ui */
import { Box, Grid, Label, Text } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { Clock } from "@phosphor-icons/react";
import type { ComponentPropsWithoutRef, ElementType, ReactNode } from "react";
import Skeleton from "../Skeleton";

type MeetingTimeslotProps = Omit<
  ComponentPropsWithoutRef<typeof Grid>,
  "children" | "sx"
> & {
  label?: ReactNode;
  dateLabel: string | null | undefined;
  timeslotLabel: string | null | undefined;
  as?: ElementType;
};

export default function MeetingTimeslot({
  label = <Trans>Date &amp; Time</Trans>,
  dateLabel,
  timeslotLabel,
  ...rest
}: MeetingTimeslotProps) {
  if (!dateLabel) return null;
  return (
    <Grid
      sx={{ gridTemplateColumns: "16px auto", columnGap: 2, rowGap: 0 }}
      {...rest}
    >
      <Clock size={16} weight="light" sx={{ marginTop: 0.5 }} />
      <Box>
        {label && (
          <Label variant="small" sx={{ color: "inherit", fontWeight: "bold" }}>
            {label}
          </Label>
        )}
        <Text variant="smallest">{dateLabel}</Text>
        <Text variant="smallest">{timeslotLabel}</Text>
      </Box>
    </Grid>
  );
}

export function MeetingTimeslotSkeleton({
  label = <Trans>Date &amp; Time</Trans>,
  ...rest
}: Omit<MeetingTimeslotProps, "dateLabel" | "timeslotLabel">) {
  return (
    <Grid
      sx={{ gridTemplateColumns: "16px auto", columnGap: 2, rowGap: 0 }}
      {...rest}
    >
      <Clock size={16} weight="light" sx={{ marginTop: 0.5 }} />
      <Box>
        {label && (
          <Label variant="small" sx={{ color: "inherit", fontWeight: "bold" }}>
            {label}
          </Label>
        )}
        <Text variant="smallest" sx={{ paddingBottom: 1 }}>
          <Skeleton width="8em" height="1em" />
        </Text>
        <Text variant="smallest" sx={{ paddingBottom: 1 }}>
          <Skeleton width="5em" height="1em" />
        </Text>
      </Box>
    </Grid>
  );
}

/** @jsxImportSource theme-ui */
import { CloseIcon, Flex, Title } from "@bottlebooks/gatsby-design-system";
import { Button } from "@bottlebooks/gatsby-theme-base/src";
import type { DialogProps } from "@reach/dialog";
import type { ComponentProps } from "react";

type Props = ComponentProps<typeof Flex> & {
  onDismiss: DialogProps["onDismiss"];
};

export default function DialogHeader({ children, onDismiss, ...rest }: Props) {
  return (
    <Flex
      sx={{
        paddingX: 3.5,
        paddingY: 3,
        position: "sticky",
        top: 0,
        backgroundColor: "white",
        borderBottom: 1,
        borderColor: "border",
      }}
      {...rest}
    >
      <Title variant="smallest" sx={{ flexGrow: 1 }}>
        {children}
      </Title>
      <Button onClick={onDismiss} sx={{ marginRight: -2 }}>
        <CloseIcon />
      </Button>
    </Flex>
  );
}

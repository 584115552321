/** @jsxImportSource theme-ui */
import { Form, TextAreaField } from "@bottlebooks/form";
import { Button, Stack, Text, Title } from "@bottlebooks/gatsby-theme-base/src";
import { ProductName } from "@bottlebooks/bottlebooks-site-base/src/components/Product/ProductHeader.next";
import ProductProducerName from "@bottlebooks/bottlebooks-site-base/src/components/Product/ProductProducerName.next";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
// import ProductName from '../Product/ProductName';
import { date, number, object, string } from "yup";
import RatingField from "./RatingField";

const schema = object().shape({
  addedAt: date().required(),
  updatedAt: date().required(),
  rating: number().oneOf([1, 2, 3, 4, 5]).required(),
  note: string(),
});

export default function TastingNoteForm({
  product,
  tastingNote,
  onSubmit,
  onDelete,
}) {
  const { i18n } = useLingui();
  const initialValues = {
    addedAt: tastingNote?.addedAt ?? new Date(),
    updatedAt: new Date(),
    rating: String(tastingNote?.rating ?? ""),
    note: tastingNote?.note ?? "",
  };
  return (
    <Form
      onSubmit={(values, formikHelpers) => {
        return onSubmit(schema.cast(values), formikHelpers);
      }}
      initialValues={initialValues}
      validationSchema={schema}
      sx={{ paddingY: [0, 5] }}
    >
      <Stack spacing={4}>
        <Stack spacing={[4, 5]}>
          <TastingNoteFormHeader product={product} />
          <RatingField name="rating" />
          <TextAreaField
            name="note"
            label={<Trans>Notes</Trans>}
            placeholder={i18n._("What are your thoughts on this product?")}
            rows={6}
          />
        </Stack>
        <TastingNoteFormFooter
          onDelete={onDelete}
          hasTastingNote={!tastingNote}
        />
      </Stack>
    </Form>
  );
}

function TastingNoteFormHeader({ product }) {
  return (
    <Stack as="header" spacing={2}>
      <Title variant="large">
        <Trans>Your note on</Trans>
      </Title>
      <Stack spacing={-1}>
        <ProductProducerName
          producer={product?.producer}
          sx={{ "&&>*": { color: "primary" } }} // override the inner Link color.
        />
        <ProductName
          product={product}
          variant="large"
          sx={{ color: "primary" }}
        />
      </Stack>
    </Stack>
  );
}

function TastingNoteFormFooter({ onDelete, hasTastingNote }) {
  return (
    <Stack
      direction={["column", "row-reverse"]}
      justifyContent="space-between"
      alignItems={["stretch", "flex-end"]}
      gap={0}
      sx={{ gap: 4 }}
    >
      <Stack
        alignItems={["stretch", "flex-end"]}
        sx={{ textAlign: [null, "right"] }}
      >
        <SubmitButton>
          <Trans>Done</Trans>
        </SubmitButton>
        {hasTastingNote && (
          <HintText>
            <Trans>
              We will also automatically add this to your bookmarks.
            </Trans>
          </HintText>
        )}
      </Stack>
      <Stack sx={{ marginBottom: 4 }}>
        {onDelete && (
          <Button
            onClick={onDelete}
            variant="text"
            sx={{ color: "lightText", paddingLeft: 2, marginLeft: -2 }}
          >
            <Trans>Remove tasting note</Trans>
          </Button>
        )}
      </Stack>
    </Stack>
  );
}

function SubmitButton(props) {
  return (
    <Button
      type="submit"
      variant="primary"
      sx={{ paddingX: 5, variant: "text.default", display: "block" }}
      {...props}
    />
  );
}

function HintText(props) {
  return <Text variant="smallest" sx={{ color: "lightText" }} {...props} />;
}

/** @jsxImportSource theme-ui */

import { Trans } from "@lingui/macro";
import { Fragment } from "react";
import * as yup from "yup";
import { FragmentType, graphql, useFragment } from "~/gql";
import useLocale from "../../useLocale";
import { SelectField } from "../Form/Field";
import type { MeetingData } from "./Meeting.State";
import type { TimeslotData } from "./Timeslot.Field.next";
import TimeslotField from "./Timeslot.Field.next";

const fragment = graphql(`
  fragment Timeslot on MeetingTimeslot {
    dateLabel
    durationInMinutes
    ...TimeslotField
  }
`);

export default function Timeslot({
  timeslots: data,
}: {
  timeslots: FragmentType<typeof fragment>[];
}) {
  const locale = useLocale();
  const timeslots = useFragment(fragment, data);
  // Sanity check to ensure we have a locale.
  if (!locale) throw new Error("Locale is not defined");
  const dates = [...new Set(timeslots.map((timeslot) => timeslot.dateLabel))];
  return (
    <Fragment>
      <SelectField name="day" label="Select day">
        <option disabled value="">
          <Trans>Select a day</Trans>
        </option>
        {dates.map((date) => (
          <option key={date} value={date}>
            {date}
          </option>
        ))}
      </SelectField>
      <TimeslotField
        name="start"
        timeslots={timeslots}
        locale={locale}
        sx={{ marginTop: 1.5 }}
      />
    </Fragment>
  );
}

Timeslot.parse = function (meeting: MeetingData, timeslots: TimeslotData[]) {
  const initialValues = Timeslot.schema.cast(meeting);
  const dates = [...new Set(timeslots.map((timeslot) => timeslot.dateLabel))];
  return {
    ...initialValues,
    // If there's just one day, select it by default.
    day: meeting.dateLabel || (dates.length === 1 ? dates[0] : ""),
  };
};

Timeslot.schema = yup
  .object({
    day: yup.string().required().default(""),
    start: yup.string().required().default(""),
  })
  .required()
  .transform((values) => ({
    ...values,
    start: values.start ? values.start : "",
  }));

export type TimeslotSchema = yup.InferType<typeof Timeslot.schema>;

import { useCollectionLayout } from "./CollectionLayoutProvider.next";

export default function useSiteConfig() {
  return useCollectionLayout().siteConfig;
}

// function afterQuery({ site }) {
//   return {
//     tastingNotesEnabled: Boolean(site?.siteMetadata?.tastingNotesEnabled),
//     listsEnabled: Boolean(site?.siteMetadata?.listsEnabled),
//     /** @type {'inMarket' | 'global' | 'all' | 'none'} */
//     showDistributionDetails:
//       site?.siteMetadata?.showDistributionDetails || "inMarket",
//     myListDataUri:
//       // Where my list loads data from.
//       import.meta.env.GATSBY_MY_LIST_DATA_URI ||
//       "https://api.bottlebooks.me/graphql",
//     bottlebooksApiRoot:
//       import.meta.env.BOTTLEBOOKS_API_ROOT || "https://app.bottlebooks.me",
//     isChatEnabled: import.meta.env.GATSBY_CHAT_ENABLED === "true",
//     isMeetingBookingEnabled:
//       import.meta.env.GATSBY_MEETING_BOOKING_ENABLED === "true",
//   };
// }

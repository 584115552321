/**
 * @param {object} props
 * @param {React.FunctionComponent | string} [props.component]
 * @param {boolean | number} [props.nowrap]
 * @param {React.ReactNode} props.children
 */
export default function NoWrap({
  component: Component = "div",
  nowrap = false,
  ...rest
}) {
  if (!nowrap) return <Component {...rest} />;
  if (typeof nowrap === "number") {
    return (
      <Component
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          boxOrient: "vertical",
          whiteSpace: "normal",
          WebkitLineClamp: nowrap,
          lineClamp: nowrap,
        }}
        {...rest}
      />
    );
  }
  return (
    <Component
      sx={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }}
      {...rest}
    />
  );
}

/** @jsxImportSource theme-ui */
import { FragmentType, graphql, useFragment } from "~/gql";
import ProductShopExternalButton from "./ProductShopExternalButton.next";

export const fragment = graphql(`
  fragment ProductShopButton on RegisteredProduct {
    ...ProductShopExternalButton
  }
`);

/** A shop button for an external online shop.*/
export default function ProductShopButton({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const registeredProduct = useFragment(fragment, data);
  return <ProductShopExternalButton data={registeredProduct} {...rest} />;
}

/** @jsxImportSource theme-ui */
import { Text } from "@bottlebooks/gatsby-design-system";
import { FragmentType, graphql, useFragment } from "~/gql";
import Skeleton from "../Skeleton";
import type { TextProps } from "./UserProfileCard";

const fragment = graphql(`
  fragment UserProfileCardDisplayName on SiteUserProfile {
    displayName
  }
`);

export default function UserProfileCardDisplayName({
  profile: data,
  ...rest
}: TextProps & { profile: FragmentType<typeof fragment> }) {
  const profile = useFragment(fragment, data);
  if (!profile) return null;
  return (
    <Text variant="small" sx={{ fontWeight: "bold" }} {...rest}>
      {profile.displayName}
    </Text>
  );
}

UserProfileCardDisplayName.fragment = fragment;

export type UserProfileCardDisplayNameData = {
  displayName: string | null | undefined;
};

export function UserProfileCardDisplayNameSkeleton(rest: TextProps) {
  return (
    <Text sx={{ paddingBottom: 1 }} {...rest}>
      <Skeleton width="10em" height="1em" />
    </Text>
  );
}

/** @jsxImportSource theme-ui */
import { Box } from "@bottlebooks/gatsby-theme-base/src";
import GenericFacet from "./GenericFacet";
import React from "react";
import FiltersToggleButton from "./FiltersToggleButton";

export default function Filters({
  aggregations,
  definitions,
  onToggleFilter,
  totalCount,
  count,
  isVisibleOnMobile,
  isVisibleOnDesktop,
  ...rest
}) {
  return (
    <Box
      sx={{
        display: [
          isVisibleOnMobile ? "block" : "none",
          isVisibleOnDesktop ? "block" : "none",
        ],
      }}
      {...rest}
    >
      {Object.values({ ...aggregations }).map((aggregation) => (
        <GenericFacet
          key={aggregation.name}
          aggregation={aggregation}
          totalCount={totalCount}
          count={count}
          definitions={definitions}
          onToggle={onToggleFilter}
          multiSelect={
            definitions?.[aggregation.name]?.multiSelect ??
            !definitions?.[aggregation.name]?.conjunction
          }
        />
      ))}
    </Box>
  );
}

Filters.ToggleMobile = function FiltersToggleMobile({
  onToggle,
  isVisibleOnDesktop,
  isVisibleOnMobile,
  ...rest
}) {
  return (
    <FiltersToggleButton
      sx={{ display: [null, "none"] }}
      onToggle={() => onToggle(!isVisibleOnMobile)}
      filtersHidden={!isVisibleOnMobile}
      {...rest}
    />
  );
};

Filters.ToggleDesktop = function FiltersToggleDesktop({
  onToggle,
  isVisibleOnDesktop,
  isVisibleOnMobile,
  ...rest
}) {
  return (
    <FiltersToggleButton
      sx={{ display: ["none", "inline"] }}
      onToggle={() => onToggle(!isVisibleOnDesktop)}
      filtersHidden={!isVisibleOnDesktop}
      {...rest}
    />
  );
};

export function useFiltersDisplay() {
  const [isVisible, setVisible] = React.useState([false, true]);
  function onToggle(isVisible) {
    setVisible([isVisible, isVisible]);
  }
  return {
    getToggleProps: () => ({
      isVisibleOnMobile: isVisible[0],
      isVisibleOnDesktop: isVisible[1],
      onToggle,
    }),
    getFiltersProps: () => ({
      isVisibleOnMobile: isVisible[0],
      isVisibleOnDesktop: isVisible[1],
    }),
    isVisible,
    isVisibleOnMobile: isVisible[0],
    isVisibleOnDesktop: isVisible[1],
    onToggle,
  };
}

/** @jsxImportSource theme-ui */
import { Box, Container, Title } from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";
import EventBannerImage from "./EventBannerImage.next";

const fragment = graphql(`
  fragment EventBanner on Event {
    name
    ...EventBannerImage
  }
`);

export default function EventBanner({
  event: data,
  withOverlay = true,
  ...rest
}: {
  event: FragmentType<typeof fragment>;
  withOverlay?: boolean;
}) {
  const event = useFragment(fragment, data);
  return (
    <EventBannerImage event={event} withOverlay={withOverlay}>
      {withOverlay && event?.name && (
        <Box
          sx={{
            position: "absolute",
            inset: 0,
            display: "flex",
            flexDirection: "column",
            justifyContent: "stretch",
            alignItems: "stretch",
          }}
        >
          <Container sx={{ marginY: "auto", width: "100%" }}>
            <Title variant="banner">{event.name}</Title>
          </Container>
        </Box>
      )}
    </EventBannerImage>
  );
}

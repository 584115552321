/** @jsxImportSource theme-ui */
import { SemiTitle, Text } from "@bottlebooks/gatsby-design-system";
import { Col, Container, Link, Row } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import { Map, Marker } from "../Map/Map";
import BrandSocialMedia from "./BrandSocialMedia.next";

export const fragment = graphql(`
  fragment BrandAddressSection_Profile on Profile {
    address
    telephone
    email
    website
    name
    latitude
    longitude
    ...BrandSocialMedia_Profile
  }
  # fragment bb_BrandAddressSection on AbstractBrand {
  #   address
  #   telephone
  #   email
  #   website
  #   name
  #   latitude
  #   longitude
  #   ...bb_BrandSocialMedia
  # }
`);

export default function BrandAddressSection({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment> | null | undefined;
}) {
  const brand = useFragment(fragment, data);
  if (!brand) return null;
  const { address, telephone, email, website, name, latitude, longitude } =
    brand;

  const shortAddress = address?.join(", ").trim();
  // Normalizes URLs by adding http:// in front.
  const websiteUrl =
    website && `http://${website.trim()}`.replace(/^http:\/\/http/, "http");

  return (
    <Container fluid sx={{ backgroundColor: "light", marginY: 5 }} {...rest}>
      <Row>
        <Col sx={{ padding: 0, width: ["100%", "50%"], bg: "hover" }}>
          {latitude != null && longitude != null && (
            <Map
              initialCenter={{ lat: latitude, lng: longitude }}
              zoom={13}
              sx={{ minHeight: ["50vw", "25vw"] }}
            >
              <Marker
                // @ts-ignore
                title={name}
                position={{ lat: latitude, lng: longitude }}
              />
            </Map>
          )}
        </Col>
        <Col
          sx={{
            paddingX: [null, `${100 / 12}%`],
            width: ["100%", "50%"],
            paddingTop: 4,
            paddingBottom: 5,
            alignSelf: "center",
          }}
        >
          <SemiTitle>
            <Trans>Contact</Trans>
          </SemiTitle>
          <AddressSegment>{shortAddress}</AddressSegment>
          <AddressSegment>{telephone}</AddressSegment>
          <AddressSegment>
            {email && (
              <TouchableLink href={`mailto:${email}`}>{email}</TouchableLink>
            )}
          </AddressSegment>
          <AddressSegment>
            {website && (
              <TouchableLink
                href={websiteUrl}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "100%",
                }}
              >
                {website}
              </TouchableLink>
            )}
          </AddressSegment>
          <BrandSocialMedia brand={brand} />
        </Col>
      </Row>
    </Container>
  );
}

function TouchableLink(props: React.ComponentPropsWithoutRef<typeof Link>) {
  // Returns a link with enough padding to be touched with a finger (at least ~40px).
  return (
    <Link sx={{ padding: 1, margin: -1, display: "inline-block" }} {...props} />
  );
}

function AddressSegment({ children }: { children: React.ReactNode }) {
  if (!children) return null;
  return (
    <div
      sx={{
        display: "flex",
        paddingBottom: 2,
      }}
    >
      <Text sx={{ width: "100%" }}>{children}</Text>
    </div>
  );
}

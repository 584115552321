/** @jsxImportSource theme-ui */
import { gql } from "urql";

export default gql`
  fragment SliderSectionFragment on SliderSection {
    lead
    title
    subtitle
    image {
      ...ImageWithPreview
    }
  }
`;

/** @jsxImportSource theme-ui */
import { Flex } from "@bottlebooks/gatsby-design-system";
import type { ComponentPropsWithRef, ReactNode } from "react";

type DialogMainPanelProps = Omit<
  ComponentPropsWithRef<typeof Flex>,
  "children" | "sx"
> & {
  children: ReactNode;
};

/**
 * A panel inside of a dialog.
 * Shows the main content, with a gap.
 */
export default function DialogMainPanel({ children }: DialogMainPanelProps) {
  return (
    <Flex direction="column" gap={2} sx={{ paddingLeft: [0, 3] }}>
      {children}
    </Flex>
  );
}

/** @jsxImportSource theme-ui */
import { Dialog } from "@bottlebooks/gatsby-theme-base/src";
import type { DialogProps } from "@reach/dialog";
import { FragmentType, graphql, useFragment } from "~/gql";
import type { ContentLocale } from "../../useLocale";
import type { RequestChatButtonFormData } from "./RequestChatButton.Form.next";
import RequestChatButtonForm from "./RequestChatButton.Form.next";
import NotLoggedIn from "./RequestChatButton.NotLoggedIn";
import RequestChatStateMachine from "./RequestChatButton.StateMachine";
import SubmissionError from "./RequestChatButton.SubmissionError";
import SubmissionSuccess from "./RequestChatButton.SubmissionSuccess";

const fragment = graphql(`
  fragment RequestChatButtonDialog on SiteUserProfile {
    ...RequestChatButtonForm
  }
`);

type RequestChatButtonDialogProps = Omit<DialogProps, "children"> & {
  collectionId: string;
  locale: ContentLocale;
  profile: FragmentType<typeof fragment>;
};

// import { useForm, FormProvider, useFormContext, get } from 'react-hook-form';
// import { zodResolver } from '@hookform/resolvers/zod';

/** The dialog containing the user flow for a chat request. */
export default function RequestChatButtonDialog({
  collectionId,
  locale,
  profile: data,
  onDismiss,
  ...rest
}: RequestChatButtonDialogProps) {
  const profile = useFragment(fragment, data);
  return (
    <RequestChatStateMachine
      collectionId={collectionId}
      locale={locale}
      profile={profile}
      renderLoading={() => (
        <Dialog aria-label="Start a chat" onDismiss={onDismiss} {...rest}>
          {/* TODO Add loading screen */}
        </Dialog>
      )}
      // TODO Handle login flow.
      renderLogin={() => (
        <Dialog
          aria-label="Log in to start a chat"
          onDismiss={onDismiss}
          sx={{ "&&": { maxWidth: 480 } }}
          {...rest}
        >
          <NotLoggedIn profile={profile} onDismiss={onDismiss} />
        </Dialog>
      )}
      renderForm={(props) => (
        <Dialog aria-label="Start a chat" onDismiss={onDismiss} {...rest}>
          <RequestChatButtonForm
            profile={profile}
            onDismiss={onDismiss}
            {...props}
          />
        </Dialog>
      )}
      renderSuccess={() => (
        <Dialog aria-label="Started a chat" onDismiss={onDismiss} {...rest}>
          <SubmissionSuccess onDismiss={onDismiss} profile={profile} />
        </Dialog>
      )}
      renderError={(props) => (
        <Dialog
          aria-label="Error starting chat"
          onDismiss={onDismiss}
          {...rest}
        >
          <SubmissionError onDismiss={onDismiss} profile={profile} {...props} />
        </Dialog>
      )}
    />
  );
}

export type RequestChatButtonDialogData = RequestChatButtonFormData & {};

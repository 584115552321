import React from "react";
import itemsjs from "itemsjs";
import get from "lodash/get";
import type { MessageDescriptor } from "@lingui/core";

export interface Aggregation<Type> {
  /** The title of the aggregation. Use plural for it. */
  title?: string | MessageDescriptor;
  /** If true, behaves like AND (multiple filters must all match on a single item). */
  conjunction?: boolean;
  /** How should the buckets be sorted? */
  sort?: "term" | "count";
  /** The sort order of the buckets. */
  order?: "asc" | "desc";
  /** Determines if the user can select multiple items, or if only any selection unselects the previous selection. */
  multiSelect?: boolean;
  /**  The number of maximum items to show. This is passed to itemsjs. */
  size?: number;
  /**  The number of items that are shown initially. */
  pageSize?: number;
  /** An optional getter to retrieve the value from each item. */
  get?:
    | string
    | ((item: Type) => string | string[] | number | undefined | null);
  /** Translation values for indiviual values. */
  translations?: Record<string, string | MessageDescriptor>;
}

export interface Sorting<Type> {
  /** The title of the sorting. */
  title?: string | MessageDescriptor;
  field:
    | string
    | ((item: Type) => string | string[] | number | undefined | null)
    | (
        | string
        | ((item: Type) => string | string[] | number | undefined | null)
      )[];
  order?: "asc" | "desc" | ("asc" | "desc")[];
  group?:
    | string
    | ((item: Type) => string | string[] | number | undefined | null);
  groupHeader?: GroupType;
}

export type GroupType = "producer" | "exhibitor" | "room" | "flightName";

export interface FilterDefinitions<Type extends object = {}> {
  aggregations: Record<string, Aggregation<Type>>;
  sortings: Record<string, Sorting<Type>>;
  search?: import("match-sorter").MatchSorterOptions<Type>;
}

export default function useIndex<Item extends object>(
  items: readonly Item[],
  config: FilterDefinitions
) {
  // Index the items and memoize the result.
  return React.useMemo(() => {
    // TODO Use a proxy to make sure the items are not modified.
    const boxedItems = items.map((item) =>
      Object.entries(config.aggregations).reduce(
        (acc, [key, aggregation]) => ({
          ...acc,
          get [key]() {
            if (typeof aggregation.get === "function") {
              return aggregation.get(item);
            }
            // @ts-ignore
            return get(item, aggregation.get || key);
          },
        }),
        { ...item, item }
      )
    );
    const index = itemsjs(boxedItems, config);
    return {
      ...index,
      search({ per_page, sort, filters }) {
        try {
          const result = index.search({ per_page, sort, filters });
          return {
            ...result,
            data: {
              ...result.data,
              items: result.data.items.map(({ item }) => item),
            },
          };
        } catch (err) {
          // If a facet wasn't found itemsjs throws an error. In that case we return an empty list.
          return {
            data: { items: [], aggregations: {} },
            pagination: { total: 0, page: 1, per_page },
            timings: {},
          };
        }
      },
    };
  }, [config, items]);
}

/** @jsxImportSource theme-ui */
import { Grid } from "@bottlebooks/gatsby-design-system";
import type { ComponentPropsWithoutRef } from "react";

export type MeetingWrapperProps = ComponentPropsWithoutRef<typeof Grid>;

export default function MeetingWrapper({
  children,
  ...rest
}: MeetingWrapperProps) {
  return (
    <Grid
      // @ts-expect-error TODO Fix Grid component props.
      as="ul"
      gap={3}
      sx={{ listStyle: "none", padding: 0, margin: 0 }}
      {...rest}
    >
      {children}
    </Grid>
  );
}

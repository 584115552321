/** @jsxImportSource theme-ui */
import { useEffect } from "react";
import React from "react";

export default function EventBrite({ eventId }) {
  // Following this pattern:
  // https://rangen.medium.com/dynamically-load-google-scripts-with-react-and-the-useeffect-hook-3700b908e50f
  const [loaded, setLoaded] = React.useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.eventbrite.co.uk/static/widgets/eb_widgets.js";
    script.id = "eb_widgets";
    script.addEventListener("load", () => setLoaded(true));
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    if (!loaded) return;
    loadEvent({ eventId });
  }, [loaded, eventId]);

  return <div id="eventbrite-widget-container"></div>;
}

function loadEvent({ eventId, onOrderComplete = () => {} }) {
  window.EBWidgets.createWidget({
    // Required
    widgetType: "checkout",
    eventId,
    iframeContainerId: "eventbrite-widget-container",

    // Optional
    iframeContainerHeight: 425, // Widget height in pixels. Defaults to a minimum of 425px if not provided
    onOrderComplete, // Method called when an order has successfully completed
  });
}

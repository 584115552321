import React from 'react';
// import type { ContentLocale } from '@bottlebooks/graphql-server/src/index';

export type ContentLocale =
  | 'cn'
  | 'de'
  | 'en'
  | 'es'
  | 'fr'
  | 'it'
  | 'nl'
  | 'pt'
  | 'sk';

const LocaleContext = React.createContext<ContentLocale | undefined>(undefined);

export function LocaleProvider({
  children,
  value,
}: {
  value: ContentLocale;
  children: React.ReactNode;
}) {
  return (
    <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>
  );
}

export default function useLocale() {
  const locale = React.useContext(LocaleContext);
  // if (locale === undefined) {
  //   throw new Error('useLocale must be used within a LocaleProvider');
  // }
  return locale;
}

/** @jsxImportSource theme-ui */
import {
  Menu as BaseMenu,
  MenuButton as BaseMenuButton,
  MenuItem as BaseMenuItem,
  MenuLink as BaseMenuLink,
  MenuPopover,
} from "@reach/menu-button";
import Button from "../Button/Button";
import React from "react";

export function Menu({ children, ...rest }) {
  return <BaseMenu {...rest}>{children}</BaseMenu>;
}

export function MenuButton({ children, ...rest }) {
  return (
    <Button
      variant="primary"
      // @ts-expect-error `as` isn't typed correctly
      as={BaseMenuButton}
      {...rest}
    >
      {children}
    </Button>
  );
}

export function MenuList({ children, ...rest }) {
  return (
    <MenuPopover
      sx={{
        display: "block",
        whiteSpace: "nowrap",
        border: 1,
        borderColor: "border",
        backgroundColor: "nuanced",
        outline: "none",
        boxShadow: "overlay",
        variant: "text.small",
        zIndex: "elevated",
      }}
      {...rest}
      // @ts-ignore `portal` is not available in the version we use.
      portal={true}
    >
      {children}
    </MenuPopover>
  );
}
export function MenuItem({ children, onSelect, ...rest }) {
  return (
    <BaseMenuItem
      sx={{
        display: "block",
        userSelect: "none",
        cursor: "pointer",
        color: "inherit",
        textDecoration: "initial",
        paddingY: 1,
        paddingX: 3,
        "&[data-selected]": {
          backgroundColor: "primary",
          color: "onPrimary",
          outline: "none",
        },
      }}
      onSelect={onSelect}
      {...rest}
    >
      {children}
    </BaseMenuItem>
  );
}
export function MenuLink({ children, ...rest }) {
  return (
    <BaseMenuLink
      sx={{
        display: "block",
        userSelect: "none",
        cursor: "pointer",
        color: "inherit",
        textDecoration: "initial",
        paddingY: 1,
        paddingX: 3,
        "&[data-selected]": {
          backgroundColor: "primary",
          color: "onPrimary",
          outline: "none",
        },
      }}
      {...rest}
    >
      {children}
    </BaseMenuLink>
  );
}

/** @jsxImportSource theme-ui */
import { Box, Link, SemiTitle } from "@bottlebooks/gatsby-theme-base/src";

export default function InstagramVideo({ videoId, title, url, ...rest }) {
  // See https://stackoverflow.com/questions/24739663/embebing-instagram-webpage-inside-an-iframe
  return (
    <Box {...rest}>
      <SemiTitle>
        <Link
          href={url}
          variant="text"
          target="_blank"
          rel="noopener noreferrer"
        >
          {title}
        </Link>
      </SemiTitle>
      <Box sx={{ maxWidth: 320, margin: "auto" }}>
        <Box
          sx={{
            paddingBottom: `${(3 / 2) * 100}%`,
            position: "relative",
          }}
        >
          <iframe
            title={title}
            src={`https://instagram.com/p/${videoId}/embed`}
            width="320"
            height="480"
            frameBorder="0"
            allow="autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            sx={{
              width: "100%",
              height: "100%",
              position: "absolute",
            }}
          ></iframe>
        </Box>
      </Box>
    </Box>
  );
}

// Create a provider that will be used to pass the event data to the pages.

import React, { useContext } from "react";
import { z } from "zod";
import { FragmentType, useFragment as getFragment, graphql } from "~/gql";
import MMILayout from "./Layout/CustomLayouts/MMILayout";
import PortWineFestLayout from "./Layout/CustomLayouts/PortWineFestLayout";
import TopDropLayout from "./Layout/CustomLayouts/TopDropLayout";

const varsSchema = z
  .array(
    z.object({
      name: z.string(),
      value: z.string(),
    })
  )
  // Transform to object
  .transform((vars) =>
    vars.reduce((acc, { name, value }) => ({ ...acc, [name]: value }), {})
  );
export const layouts = [
  "BOTTLEBOOKS",
  "PORTWINEFEST",
  "TOPDROP",
  "LONDON_WINE_FAIR",
  "MMI",
] as const;

const siteConfigSchema = z.object({
  tastingNotesEnabled: z
    .string()
    .optional()
    .default("true")
    .transform((v) => v === "true"),
  listsEnabled: z
    .string()
    .optional()
    .default("true")
    .transform((v) => v === "true"),
  showDistributionDetails: z
    .enum(["inMarket", "global", "all", "none"])
    .optional()
    .default("inMarket"),
  isChatEnabled: z
    .string()
    .optional()
    .transform((v) => v === "true"),
  isMeetingBookingEnabled: z
    .string()
    .optional()
    .transform((v) => v === "true"),
  layout: z
    // These are the layouts that are supported.
    .enum(layouts)
    .optional()
    .default("BOTTLEBOOKS"),
  productFilters: z
    .string()
    .nullish()
    .transform((value) => {
      if (!value) return [];
      return value
        .split(",")
        .map((v) => v.trim())
        .filter(Boolean);
    }),
  googleMeasurementId: z.string().nullish(),
  exhibitorFilters: z
    .string()
    .nullish()
    .transform((value) => {
      if (!value) return [];
      return value
        .split(",")
        .map((v) => v.trim())
        .filter(Boolean);
    }),
});

const CollectionLayoutContext = React.createContext<{
  workspaceId: string;
  collectionId: string;
  logo?: {
    publicId?: string;
    asset?: {
      path?: string;
    };
  };
  primaryColor?: string;
  accentColor?: string;
  isSanity: boolean;
  projectId?: string;
  siteConfig: z.infer<typeof siteConfigSchema>;
}>({
  workspaceId: "",
  collectionId: "",
  logo: undefined,
  primaryColor: undefined,
  accentColor: undefined,
  isSanity: false,
  projectId: undefined,
  siteConfig: siteConfigSchema.parse({}),
});

const fragment = graphql(/* GraphQL */ `
  fragment CollectionLayoutProvider on Collection {
    workspaceId
    collectionId
    site {
      templateSettings {
        name
        value
      }
    }
    collectionId
    logo {
      publicId
    }
  }
`);

export default function CollectionLayoutProvider({
  children,
  data,
  collectionId,
}: {
  children: React.ReactNode;
  data: FragmentType<typeof fragment>;
  collectionId: string;
}) {
  const collection = getFragment(fragment, data);
  const value = collection
    ? {
        workspaceId: collection.workspaceId,
        collectionId,
        logo: collection.logo,
        isSanity: collection.isSanity,
        projectId: collection.projectId,
        // Primary color is not available on the Bottlebooks GraphQL. Only in Sanity.
        primaryColor: collection.primaryColor,
        // Accent color is not available on the Bottlebooks GraphQL. Only in Sanity.
        accentColor: collection.accentColor,
        siteConfig: resolveSiteConfig(data),
      }
    : {
        workspaceId: null,
        collectionId,
        logo: null,
        primaryColor: null,
        accentColor: null,
        isSanity: false,
        projectId: null,
        siteConfig: {},
      };

  return (
    <CollectionLayoutContext.Provider value={value}>
      <CollectionLayout>{children}</CollectionLayout>
    </CollectionLayoutContext.Provider>
  );
}

type Layout = typeof layouts;

function CollectionLayout({ children }: { children: React.ReactNode }) {
  const { siteConfig } = useContext(CollectionLayoutContext);
  switch (siteConfig.layout) {
    case "MMI":
      return <MMILayout>{children}</MMILayout>;
    case "PORTWINEFEST":
      return <PortWineFestLayout>{children}</PortWineFestLayout>;
    case "TOPDROP":
      return <TopDropLayout>{children}</TopDropLayout>;
    default:
      return children;
  }
}

function resolveSiteConfig(data: FragmentType<typeof fragment>) {
  const collection = getFragment(fragment, data);
  const templateSettings = collection.site?.templateSettings ?? [];
  const varsAsObject = varsSchema.parse(templateSettings);
  return siteConfigSchema.parse(varsAsObject);
}

export function useCollectionLayout() {
  const data = React.useContext(CollectionLayoutContext);
  return data;
}

import { gql } from "@apollo/client";
import {
  Flex,
  Image,
  SkeletonBox,
  SkeletonText,
  Text,
} from "@bottlebooks/gatsby-design-system";
import NoWrap from "@bottlebooks/gatsby-design-system/src/components/NoWrap/NoWrap";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import React from "react";
import ClickableFile from "./ClickableFile";
import ThumbnailContainer from "./ThumbnailContainer";

/**
 * @typedef {object} CloudinaryFixed
 * @property {string} src
 *
 * @param {object} props
 * @param {{ name: string, thumbnail: CloudinaryFixed, isLive?: boolean }} props.item
 * @param {string} [props.to]
 * @param {string} [props.href]
 * @param {number} [props.width]
 * @param {number} [props.height]
 * @param {number | boolean} [props.nowrap]
 * @param {'_blank' | string} [props.target]
 * @param {string} [props.variant] The Link variant.
 * @param {number | boolean} [props.nowrap]
 * @param {string} [props.target]
 * @param {React.ReactNode} [props.children] Additional items to add, such as a Premium badge or explanatory text.
 * @param {() => void} [props.onClick]
 */
export default function File({
  item,
  to,
  href,
  onClick,
  children,
  width = 96,
  height = 136,
  nowrap,
  ...rest
}) {
  const { i18n } = useLingui();
  if (!item) return null;
  return (
    <ClickableFile
      to={to}
      href={href}
      width={width}
      onClick={onClick}
      {...rest}
    >
      <ThumbnailContainer height={height} sx={{ marginBottom: 2 }}>
        <Thumbnail thumbnail={item.thumbnail} name={item.name} />
      </ThumbnailContainer>
      <NoWrap nowrap={nowrap}>
        <Text variant="smallest">{i18n._(`${item["name"]}`)} </Text>
      </NoWrap>
      {children}
    </ClickableFile>
  );
}

export function FileSkeleton({
  offset = 0,
  width = 96,
  height = 136,
  ...rest
}) {
  return (
    <ClickableFile to="" href="" onClick={() => {}} width={width} {...rest}>
      <ThumbnailContainer height={height} sx={{ marginBottom: 2 }}>
        <SkeletonBox sx={{ width: 96, height: 136 }} offset={offset} />
      </ThumbnailContainer>
      <SkeletonText variant="smallest" />
    </ClickableFile>
  );
}

function Thumbnail({ thumbnail, name }) {
  const [hasError, setHasError] = React.useState(false);
  if (!thumbnail) return <ThumbnailMissing name={name} />;
  if (hasError) return <ThumbnailBroken />;

  return (
    <Image
      alt={name}
      sx={{
        maxWidth: "100%",
        maxHeight: "100%",
        width: "auto",
        height: "auto",
        boxShadow: "smallest",
        transition: "boxShadow",
        "&:hover": { boxShadow: "medium" },
      }}
      fixed={thumbnail}
      onError={() => setHasError(true)}
    />
  );
}

function ThumbnailMissing({ name }) {
  return (
    <Flex
      align="center"
      sx={{
        width: "100%",
        height: "100%",
        textAlign: "center",
        boxShadow: "smallest",
        transition: "boxShadow",
        "&:hover": { boxShadow: "medium" },
      }}
    >
      <Text variant="smallest" sx={{ color: "lighterText", padding: 3 }}>
        <Trans>No preview available</Trans>
      </Text>
    </Flex>
  );
}

function ThumbnailBroken() {
  return (
    <Flex
      align="center"
      sx={{
        width: "100%",
        height: "100%",
        textAlign: "center",
        boxShadow: "smallest",
        transition: "boxShadow",
        "&:hover": { boxShadow: "medium" },
      }}
    >
      <Text variant="smallest" sx={{ color: "lighterText", padding: 3 }}>
        <Trans>Preview not available</Trans>
      </Text>
    </Flex>
  );
}

File.fragment = gql`
  fragment File on File {
    fileId
    name
    languages {
      code
    }
    # TODO isLive will only be defined on LiveFiles.
    #... on LiveFile {
    isLive
    #}
    # TODO Query thumbnail on other File implementations too?
    ... on UploadedFile {
      thumbnail(width: 96, height: 136) {
        width
        height
        src
        srcSet
      }
    }
    ... on LiveInfoSheet {
      thumbnail(width: 96, height: 136) {
        width
        height
        src
        srcSet
      }
    }
  }
`;

export const FileFragment = File.fragment;

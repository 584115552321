const buttonVariants = {
  default: {
    backgroundColor: 'inherit',
    border: 0,

    color: 'lightText',
    variant: 'text.small',
    transition: 'color',
    ':hover': { color: 'secondary' },
  },

  base: {
    variant: 'text.small',
    paddingX: 4,
    paddingY: 2,
    display: 'inline-flex',
    gap: 2,
    alignItems: 'center',
    transition: (theme) =>
      `${theme.transition.boxShadow}, ${theme.transition.color}`,
  },

  outline: {
    variant: 'button.base',
    backgroundColor: 'inherit',
    border: 0,
    // Border doesn't work for combinations of primary and default buttons, so we have to use an inset shadow.
    boxShadow: (theme) => `inset 0 0 0 1px ${theme.colors.borderSecondary}`,
    color: 'lightText',
    ':hover': {
      color: 'secondary',
      boxShadow: (theme) => `inset 0 0 0 1px ${theme.colors.borderNuanced}`,
    },
  },

  empty: {},

  text: {
    variant: 'text.small',
    border: 0,
    color: 'inherit',
    backgroundColor: 'transparent',
    transition: 'color',
  },

  primary: {
    variant: 'button.base',
    border: 0,
    backgroundColor: 'primary',
    color: 'onPrimary',
    ':hover': { color: 'onSecondary', backgroundColor: 'secondary' },
  },

  secondary: {
    variant: 'button.base',
    border: 0,
    backgroundColor: 'nuanced',
    color: 'text',
    ':hover': { color: 'onPrimary', backgroundColor: 'primary' },
  },

  accent: {
    variant: 'button.base',
    border: 0,
    backgroundColor: 'accent',
    color: 'onAccent',
  },
};

export default buttonVariants;

export type ButtonVariant = keyof typeof buttonVariants;

/** @jsxImportSource theme-ui */
import { Box } from "@bottlebooks/gatsby-design-system";
// import { graphql } from "gatsby";
import GridImage from "./GridImage";

export default function ImageBlock({ image, width, projectId, ...rest }) {
  return (
    <Box
      sx={{
        position: "relative",
      }}
      {...rest}
    >
      <GridImage
        projectId={projectId}
        image={image}
        sx={{
          display: "block",
          marginBottom: 2,
          marginX: "auto",
          // The next 3 lines are a responsive hack
          // This is so that images always respect the width boundaries of the parent
          maxWidth: "100%",
          // These 2 lines change how the image renders in mobile vs. desktop
          // On desktop, we can manage height indirectly through the use gridTemplateColumns
          // On mobile, if we need to tweak the height, we use a GridColumnBlock with gridTemplateRows
          maxHeight: ["100%", null, "none"],
          width: [null, null, "100%"],
        }}
        width={width}
        alt={"Image"}
      />
    </Box>
  );
}

// export const fragment = graphql`
//   fragment SanityImageBlockFragment on SanityImageBlock {
//     image {
//       ...ImageWithPreview
//     }
//   }
// `;

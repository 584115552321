/** @jsxImportSource theme-ui */
import { gql } from "urql";

export default gql`
  fragment SocialMediaBlockFragment on SocialMediaBlock {
    facebookUrl
    instagramUrl
    twitterUrl
    youtubeUrl
    alignment
    style {
      ...BlockStyle
    }
  }
`;

/** @jsxImportSource theme-ui */
import { Link } from "@bottlebooks/gatsby-theme-base/src";
import useLink from "../../useLink";

/**
 *
 * @param {object} props
 * @param {string} [props.url]
 * @param {string} [props.type]
 * @param {string} [props.name]
 * @param {() => void} [props.onClick]
 * @param {React.ReactNode} [props.children]
 * @param {number} [props.counter] An optional number that is displayed as a superscript
 * @returns
 */
export default function MenuItem({
  url,
  type,
  name,
  counter,
  onClick,
  children,
  ...rest
}) {
  const link = useLink();
  // If there is no custom URL, use the paths to go to the correct URLs.
  // onClick overrides links
  const to = onClick ? undefined : url || link[type]?.() || `/${type}`;
  return (
    <Link
      // We rely on the Link component to figure out if the link is internal or external.
      to={to}
      onClick={onClick}
      _suppressExternalLinkWarning
      variant="menu"
      // activeClassName="bb-active"
      // partiallyActive={to !== "/"} // If we link to the root, we don't want the menu item to be marked as active. For other pages, we want the whole section to be highlighted.
      sx={{
        position: "relative",
        "&.bb-active": { color: "primary" },
      }}
      {...rest}
    >
      {name}
      {children}
      {Number(counter) > 0 && <sup sx={{ color: "primary" }}>{counter}</sup>}
    </Link>
  );
}

// import useBaseEventTheme from '@bottlebooks/bottlebooks-site-base/src/components/Layout/useEventTheme';
// import { graphql, useStaticQuery } from 'gatsby';
// import { lighten } from 'polished';
import { gql } from "urql";
// import usePreviewData from '../../../../usePreviewData';

// export default function useEventTheme() {
//   const baseTheme = useBaseEventTheme();

//   const result = useStaticQuery(query);
//   const collectionId = result?.collection?.collectionId;
//   const sites = result?.allSanitySite?.nodes;

//   const cmsSite = sites?.find((site) => site.collectionId === collectionId);
//   const [previewData] = usePreviewData();
//   const site = previewData?.data?.site || cmsSite;

//   const { primaryColor, accentColor, logo } = site || {};
//   return site
//     ? {
//         // Make it obvious where the data is coming from
//         // because images from Sanity must be rendered differently
//         isSanity: true,
//         logo,
//         colors: {
//           primary: primaryColor,
//           secondary: lighten(0.1)(primaryColor),
//           accent: accentColor,
//         },
//       }
//     : baseTheme;
// }

// const query = graphql`
//   {
//     collection: event {
//       collectionId: eventId
//     }
//     allSanitySite {
//       nodes {
//         collectionId
//         primaryColor
//         accentColor
//         logo {
//           asset {
//             path
//           }
//         }
//       }
//     }
//   }
// `;

export const useEventThemeFragment = gql`
  fragment useEventThemeFragment on Site {
    collectionId
    primaryColor
    accentColor
    logo {
      asset {
        _id
        path
      }
    }
  }
`;

/** @jsxImportSource theme-ui */
import {
  ArrowRightIcon,
  Flex,
  Label,
  Link,
  P,
  Title,
} from "@bottlebooks/gatsby-theme-base/src";
import { graphql } from "~/gql";
import React from "react";

const VariantContext = React.createContext("primary");

/**
 * Renders a big quickLink.
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {import('./quickLinkVariants').QuickLinkVariant} [props.variant]
 */
export function QuickLinkCard({ children, variant = "primary", ...rest }) {
  return (
    <VariantContext.Provider value={variant}>
      <Flex
        direction="column"
        align="stretch"
        sx={{ variant: `quickLink.${variant}.card` }}
        {...rest}
      >
        {children}
      </Flex>
    </VariantContext.Provider>
  );
}

function AnyLink(props) {
  return <Link _suppressExternalLinkWarning {...props} />;
}

export function QuickLinkContent({ children, to, part = "content", ...rest }) {
  const variant = React.useContext(VariantContext);
  return (
    <Flex
      direction="column"
      align="stretch"
      gap={[0, 2]}
      as={AnyLink}
      to={to}
      sx={{ variant: `quickLink.${variant}.${part}` }}
      {...rest}
    >
      {children}
    </Flex>
  );
}

export function QuickLinkTitle({ children, to, ...rest }) {
  const variant = React.useContext(VariantContext);
  if (!children) return null;
  return (
    <Title
      variant="small"
      sx={{ variant: `quickLink.${variant}.title` }}
      {...rest}
    >
      <Flex as="span" align="center" justify="space-between" gap={2}>
        <span>{children}</span>{" "}
        {to && <ArrowRightIcon sx={{ display: ["inline", "none"] }} />}
      </Flex>
    </Title>
  );
}

export function QuickLinkText({ children, ...rest }) {
  const variant = React.useContext(VariantContext);
  if (!children) return null;
  return (
    <P variant="small" sx={{ variant: `quickLink.${variant}.text` }} {...rest}>
      {children}
    </P>
  );
}

export function QuickLinkButton({ children, to, ...rest }) {
  const variant = React.useContext(VariantContext);
  if (!children) return null;
  return (
    <Flex
      gap={2}
      align="center"
      as={Label}
      sx={{
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        variant: `quickLink.${variant}.button`,
      }}
      {...rest}
    >
      <span>{children}</span> {to && <ArrowRightIcon />}
    </Flex>
  );
}

export function SubLink({ to, children, ...rest }) {
  if (!children) return null;
  return (
    <QuickLinkContent part="sublink" to={to}>
      <Flex
        gap={2}
        align="center"
        justify={["space-between", "flex-start"]}
        as={Label}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          width: "100%",
        }}
        {...rest}
      >
        <span>{children}</span> <ArrowRightIcon />
      </Flex>
    </QuickLinkContent>
  );
}

export const fragment = graphql(`
  fragment QuickLink on QuickLink {
    title
    text
    callToAction
    url
    links {
      callToAction
      url
    }
  }
`);

/** @jsxImportSource theme-ui */
import { Box } from "@bottlebooks/gatsby-design-system";
import { Link as BaseLink } from "react-router-dom";
import { OutboundLink } from "gatsby-plugin-google-gtag";
import React from "react";
import type { HTMLProps } from "react";
import type { LinkVariant } from "./linkVariants";

type LinkProps = {
  children: React.ReactNode;
  to?: string;
  href?: string;
  /** Don't show a warning if an external link was supplied to `to`. */
  _suppressExternalLinkWarning?: boolean;
  onClick?: () => void;
  variant?: LinkVariant;
  fallback?: string;
  /** A className to set if the link is active. */
  // activeClassName?: string;
  /** If set, the activeClassName is used even when a sub-route is active. */
  // partiallyActive?: boolean;
  /** In case of external links, it opens a new tab. This also sets rel="noopener noreferrer" automatically. */
  openInNewTab?: boolean;
  /**  Sets the type for a button. */
  type?: "submit" | "reset" | "button";
  className?: string;
};

function Link<T>(
  {
    to,
    // If `to` starts with http, we convert it to an external link automatically.
    href = to && (/^https?:\/\//.test(to) || /^mailto?:/.test(to))
      ? to
      : undefined,
    _suppressExternalLinkWarning = false,
    onClick,
    variant = "default",
    fallback = "span",
    openInNewTab,
    ...rest
  }: LinkProps & Omit<HTMLProps<T>, keyof LinkProps>,
  ref: React.ForwardedRef<T>
) {
  // External links first (in case the `to` param was set to an external URL by accident).
  if (!_suppressExternalLinkWarning && to && /^https?:\/\//.test(to)) {
    console.warn(`${to} was used on an internal link. Use 'href' instead!`);
  }
  const hasLink = Boolean(to || onClick || href);
  // `href` needs to be checked first because `to` might contain an external link
  // that is then converted to an href.
  if (href) {
    return (
      <Box
        // @ts-expect-error the type definitions for OutboundLink don't match.
        as={OutboundLink}
        href={href}
        onClick={onClick}
        target={openInNewTab ? "_blank" : undefined}
        rel={openInNewTab ? "noopener noreferrer" : undefined}
        ref={ref}
        sx={{
          // These colors are the link defaults.
          // They can be overridden by the variant or via the sx prop on the component instance.
          color: "primary",
          ":hover": hasLink ? { color: "secondary" } : undefined,
          variant: `link.${variant}`,
        }}
        {...rest}
      />
    );
  }
  if (to) {
    return (
      <BaseLink
        to={to}
        onClick={onClick}
        // @ts-ignore We hope that Gatsby forwards refs.
        ref={ref}
        sx={{
          // These colors are the link defaults.
          // They can be overridden by the variant or via the sx prop on the component instance.
          color: "primary",
          ":hover": hasLink ? { color: "secondary" } : undefined,
          variant: `link.${variant}`,
        }}
        // activeClassName={activeClassName}
        {...rest}
      />
    );
  }
  if (onClick) {
    return (
      <Box
        // @ts-expect-error the type definitions for LinkButton don't match.
        as={LinkButton}
        onClick={onClick}
        ref={ref}
        sx={{
          // These colors are the button defaults.
          // They can be overridden by the variant or via the sx prop on the component instance.
          color: "primary",
          ":hover": hasLink ? { color: "secondary" } : undefined,
          variant: `link.${variant}`,
        }}
        {...rest}
      />
    );
  }
  // If we don't have a `to`, `href` or `onClick` prop, we don't add colors and hover.
  return (
    <Box
      // @ts-expect-error the type definitions for the fallback don't match.
      as={fallback}
      ref={ref}
      sx={{ variant: `link.${variant}` }}
      {...rest}
    />
  );
}

export const LinkButton = React.forwardRef<HTMLButtonElement>(
  function LinkButton(props, ref) {
    return (
      <button
        ref={ref}
        sx={{
          fontSize: "inherit",
          lineHeight: "inherit",
          fontWeight: "inherit",
          fontFamily: "inherit",
          border: 0,
          backgroundColor: "transparent",
          padding: 0,
          textAlign: "left",
        }}
        {...props}
      />
    );
  }
);

export default React.forwardRef(Link);

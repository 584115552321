import { graphql, useFragment } from "~/gql";

import LandingPage from "@bottlebooks/bottlebooks-site-base/src/components/LandingPage/LandingPage";
import { LoaderFunctionArgs, useLoaderData } from "react-router-dom";
import { z } from "zod";
import fetchGraphQL from "~/fetchGraphQL";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
  workspaceId: z.string(),
});

export default function LandingPageTemplate() {
  // const parsed = paramsSchema.parse(useParams());
  const data = useLoaderData();
  const workspace = useFragment(facetsFragment, data);
  const openCollections = workspace.collections.nodes.filter(
    (collection) => collection.visibilityStatus === "OPEN"
  );
  return (
    <div style={{ display: "flex" }}>
      <div style={{ width: 400, flexGrow: 0 }}>
        {openCollections.map((collection) => (
          <div key={collection.collectionId}>
            <a
              href={`/en/collections/${collection.collectionId}`}
              target="preview"
            >
              {collection.name}
            </a>
            {/* <img src={collection.logo.publicId} /> */}
          </div>
        ))}
      </div>
      <div style={{ flexGrow: 1 }}>
        <iframe
          name="preview"
          style={{
            position: "absolute",
            height: "100%",
            width: "calc(100vw - 415px)",
          }}
        />
      </div>
    </div>
  );
}

export async function Loader({ params }: LoaderFunctionArgs) {
  const { locale, workspaceId } = paramsSchema.parse(params);
  const data = await fetchGraphQL(
    graphql(/* GraphQL */ `
      query WorkspaceCollections($workspaceId: ID!, $locale: ContentLocale) {
        workspace(workspaceId: $workspaceId, locale: $locale) {
          ...WorkspaceCollections_Workspace
        }
      }
    `),
    {
      workspaceId,
      locale,
    }
  );
  return data.data.workspace;
}

const facetsFragment = graphql(/* GraphQL */ `
  fragment WorkspaceCollections_Workspace on Workspace {
    collections {
      nodes {
        __typename
        visibilityStatus
        collectionId
        name

        logo {
          publicId
        }
      }
    }
  }
`);

/** @jsxImportSource theme-ui */
import { Text, Title } from "@bottlebooks/gatsby-design-system";
import { Button, Dialog } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import type { DialogProps } from "@reach/dialog";
import { CheckCircle, X } from "@phosphor-icons/react";
import { Fragment } from "react";

type SuccessProps = {
  onDismiss: DialogProps["onDismiss"];
  profile: { displayName?: string | null };
};
export default function SubmissionSuccess({
  onDismiss,
  profile,
}: SuccessProps) {
  return (
    <Fragment>
      <Dialog.Header onDismiss={onDismiss}>
        <Trans>Start a chat with {profile.displayName}</Trans>
      </Dialog.Header>
      <Dialog.Content sx={{ textAlign: "center" }}>
        <CheckCircle
          size={64}
          weight="light"
          sx={{ color: "brand.successGreen" }}
        />
        <Title variant="small">
          <Trans>Your request has been sent.</Trans>
        </Title>
        <Text>
          <Trans>
            You will receive an email in the next minutes with the next steps.
          </Trans>
        </Text>
      </Dialog.Content>
      <Dialog.Footer>
        <Button variant="primary" onClick={onDismiss}>
          <X size={22} weight="light" sx={{ marginRight: 1 }} />
          <Trans>Close</Trans>
        </Button>
      </Dialog.Footer>
    </Fragment>
  );
}

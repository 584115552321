/** @jsxImportSource theme-ui */
import { Text } from "@bottlebooks/gatsby-design-system";
import getOnAccentColor from "@bottlebooks/bottlebooks-site-base/src/helpers/getOnAccentColor";
// import { graphql } from graphql;
import { gql } from "urql";
import { RichText, Section } from "..";
import UserHelp from "../UserHelp";

export default function IframeSection({ title, style, _rawText, url, height }) {
  if (!url)
    return (
      <UserHelp>
        <Text>Add destination url.</Text>
      </UserHelp>
    );
  return (
    <Section variant={style.variant} sx={style}>
      <Section.Title
        // Force black when against accent
        sx={(theme) => {
          return {
            textAlign: style?.alignment,
            // Avoid hover effect
            color:
              style?.variant === "accent"
                ? getOnAccentColor(theme.colors.accent)
                : "inherit",
          };
        }}
      >
        {title}
      </Section.Title>
      <Section.Body>
        {_rawText && (
          <Text
            // Force black when against accent
            sx={(theme) => {
              return {
                textAlign: style?.alignment,
                // Avoid hover effect
                color:
                  style?.variant === "accent"
                    ? getOnAccentColor(theme.colors.accent)
                    : "inherit",
                padding: 0,
              };
            }}
          >
            <RichText>{_rawText}</RichText>
          </Text>
        )}
        <iframe
          style={{
            width: "100%",
            height: height ? `${height}px` : "500px",
            marginTop: 3,
            border: 0,
          }}
          src={url}
        />
      </Section.Body>
    </Section>
  );
}

// export const fragment = graphql`
//   fragment SanityIframeSectionFragment on SanityIframeSection {
//     title
//     _rawText
//     url
//     height: iframeHeight
//     style {
//       ...SectionStyle
//     }
//   }
// `;

IframeSection.fragment = gql`
  fragment IframeSectionFragment on IframeSection {
    title
    _rawText: textRaw
    url
    height: iframeHeight
    style {
      ...SectionStyle
    }
  }
`;

import { matchSorter } from 'match-sorter';

/**
 * Search for multiple words in any order.
 * @see https://github.com/kentcdodds/match-sorter#match-many-words-across-multiple-fields-table-filtering
 * @template Item
 * @param {readonly Item[]} items
 * @param {string} value
 * @param {import('match-sorter').MatchSorterOptions<Item>} options
 */

export default function search(
  items,
  value,
  options // The matchSorter options
) {
  const optionsWithBaseSort = {
    // This baseSort function will use the original index of items as the tie breaker
    baseSort: (a, b) => (a.index < b.index ? -1 : 1),
    ...options,
  };
  if (!value || !value.length) {
    return matchSorter(items, value, optionsWithBaseSort); // Sort the items.
  }

  const terms = value.split(/\s+/);
  if (!terms) {
    return matchSorter(items, value, optionsWithBaseSort); // Sort the items.
  }

  // reduceRight will mean sorting is done by score for the _first_ entered word.
  return terms.reduceRight(
    (results, term) => matchSorter(results, term, optionsWithBaseSort),
    items
  );
}

/** @jsxImportSource theme-ui */
import { Text } from "@bottlebooks/gatsby-design-system";
// import { graphql } from "gatsby";
import { gql } from "urql";
import { RichText, Section } from "../..";
import EventBrite from "./EventBrite";
import TryBooking from "./TryBooking";

export default function RegistrationSection({
  title,
  style,
  _rawText,
  registrationProvider,
}) {
  return (
    <Section sx={style}>
      <Section.Title>{title}</Section.Title>
      <Section.Body>
        <RichText>{_rawText}</RichText>
        {registrationProvider.map((provider, index) => {
          switch (provider.__typename) {
            case "TryBookingBlock":
            case "SanityTryBookingBlock":
              return (
                <TryBooking
                  key={`${provider}_${index}`}
                  eventId={provider.eventId}
                />
              );
            case "EventBriteBlock":
            case "SanityEventBriteBlock":
              return (
                <EventBrite
                  key={`${provider}_${index}`}
                  eventId={provider.eventId}
                />
              );
            default:
              return (
                <Text key={`${provider}_${index}`} sx={{ color: "red" }}>
                  Registration provider not supported: {provider.__typename}
                </Text>
              );
          }
        })}
      </Section.Body>
    </Section>
  );
}

// export const fragment = graphql`
//   fragment SanityRegistrationFragment on SanityRegistrationSection {
//     title
//     _rawText
//     registrationProvider {
//       __typename
//       ... on SanityEventBriteBlock {
//         eventId
//       }
//       ... on SanityTryBookingBlock {
//         eventId
//       }
//     }
//     style {
//       ...SectionStyle
//     }
//   }
// `;

RegistrationSection.fragment = gql`
  fragment RegistrationSectionFragment on RegistrationSection {
    title
    _rawText: textRaw
    registrationProvider {
      ... on EventBriteBlock {
        eventId
      }
      ... on TryBookingBlock {
        eventId
      }
    }
    style {
      ...SectionStyle
    }
  }
`;

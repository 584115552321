/** @jsxImportSource theme-ui */
import EducationalContentDialog, {
  useEducationalContentDialog,
} from "@bottlebooks/bottlebooks-site-bookmarks/src/components/EducationalContentDialog";
import { Dialog, Title } from "@bottlebooks/gatsby-theme-base/src";
import Login from "@bottlebooks/bottlebooks-site-base/src/components/UserProfile/Login";
import LoginBenefits from "@bottlebooks/bottlebooks-site-base/src/components/UserProfile/Login.Benefits";
import { Trans } from "@lingui/macro";
import "@reach/dialog/styles.css";
import useAuthentication, { useLoginDialog } from "./useAuthentication";

export default function LoginDialog() {
  const [isOpen, setIsOpen] = useLoginDialog();
  const { shouldShow: shoudShowEducationalContent } =
    useEducationalContentDialog();
  const { isAuthenticated } = useAuthentication();

  function closeDialog() {
    setIsOpen(false);
  }

  if (!isOpen) return null;
  if (isAuthenticated()) return null;

  // Educational content should be displayed only 1 time
  // It should always have a link to login
  if (shoudShowEducationalContent)
    return <EducationalContentDialog closeDialog={closeDialog} />;

  return <LoginDialogProviders closeDialog={closeDialog} />;
}

function LoginDialogProviders({ closeDialog }) {
  return (
    <Dialog
      onDismiss={closeDialog}
      aria-label="login"
      sx={{
        width: ["100vw", 600],
      }}
    >
      <Dialog.Header onDismiss={closeDialog}></Dialog.Header>
      <Dialog.Content>
        <Login
          prepend={
            <Title as="h1" variant="small" sx={{ marginBottom: 3.5 }}>
              <Trans>Sign in to personalize your visit</Trans>
            </Title>
          }
          availableMethods={["EMAIL", "GOOGLE"]}
          append={<LoginBenefits />}
        />
      </Dialog.Content>
    </Dialog>
  );
}

/** @jsxImportSource theme-ui */
import { Badge, Box } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import getOnAccentColor from "../../helpers/getOnAccentColor";

const variants = {
  default: {
    paddingX: 3,
    paddingY: 1,
  },
  small: {
    paddingX: 2,
    paddingY: 0,
  },
};

const fragment = graphql(`
  fragment RegisteredBrand_SeekingDistribution on RegisteredBrand {
    isSeekingDistribution
  }
`);

export default function SeekingDistribution({
  data,
  variant = "default",
  ...rest
}: {
  data: FragmentType<typeof fragment>;
  variant?: "default" | "small";
}) {
  const brand = useFragment(fragment, data);
  if (!brand) return null;
  if (!brand.isSeekingDistribution) return null;
  return (
    <Box {...rest}>
      <Badge
        sx={(theme) => {
          return {
            backgroundColor: "accent",
            // Avoid hover effect
            color: getOnAccentColor(theme.colors.accent),
            ...variants[variant],
          };
        }}
      >
        <Trans>Seeking distribution</Trans>
      </Badge>
    </Box>
  );
}

/** @jsxImportSource theme-ui */
import {
  CheckboxField,
  Form,
  TextAreaField,
  TextField,
} from "@bottlebooks/form";
import { Box, Button, Grid, Text } from "@bottlebooks/gatsby-theme-base/src";
// import { graphql, navigate } from "gatsby";
import React from "react";
import { gql } from "urql";
import { boolean, object, string } from "yup";
import { RichText } from "..";
import Section from "../Layout/Section";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

export default function ContactFormSection({
  id = "contact",
  title,
  subtitle,
  _rawText,
  to,
  subject: subjectPrefix,
  ...rest
}) {
  const [serverError, setServerError] = React.useState(null);
  const { i18n } = useLingui();
  const schema = object().shape({
    name: string().required(i18n._(t`This is a required field`)),
    email: string()
      .email(i18n._(t`Please enter a valid email address.`))
      .required(i18n._(t`This is a required field`)),
    subject: string(),
    contactId: string(),
    message: string().required(i18n._(t`This is a required field`)),
    acceptPrivacy: boolean().oneOf(
      [true],
      "Please accept the data privacy policy."
    ),
  });
  async function handleSubmit(values) {
    try {
      const response = await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          "form-name": id,
          to,
          ...values,
          ...(subjectPrefix && {
            subject: `[${subjectPrefix}] ${values.subject}`,
          }),
        }).toString(),
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      alert("TODO: implement navigation to thank you page");
      // navigate(`/thankyou`);
    } catch (error) {
      setServerError(error.message);
    }
  }

  return (
    <Section id={id} {...rest}>
      <Section.Title>{title}</Section.Title>
      <Text>{subtitle}</Text>
      <Grid gap={4} columns={[1, null, 2]}>
        {_rawText?.length > 0 && (
          <Section.Body>
            <RichText>{_rawText}</RichText>
          </Section.Body>
        )}
        <Form
          name={id}
          initialValues={{
            name: "",
            email: "",
            subject: "",
            contactId: "",
            message: "",
            acceptPrivacy: false,
          }}
          onSubmit={handleSubmit}
          validationSchema={schema}
          data-netlify="true"
          netlify-honeypot="bot-field"
          // data-netlify-recaptcha="true"
        >
          <input type="hidden" name="form-name" value={id} />
          <input type="hidden" name="to" value={to} />
          <label sx={{ display: "none" }}>
            Don't fill this out if you're human: <input name="bot-field" />
          </label>
          <TextField
            name="name"
            label={i18n._(t`Your name`)}
            sx={{ width: "100%" }}
          />
          <TextField
            name="email"
            label={i18n._(t`Your email address`)}
            sx={{ width: "100%" }}
          />
          <TextField
            name="subject"
            label={i18n._(t`Subject`)}
            sx={{ width: "100%" }}
          />
          {/* <TextField
            name="contactId"
            label={i18n._(t`Kontakt-ID`)}
            length={30}
            //   description="Wenn Sie bei einem Anschreiben eine Kontakt-ID erhalten haben, geben Sie diese bitte hier an."
          /> */}
          <TextAreaField
            name="message"
            label={i18n._(t`Your message`)}
            rows={10}
          />
          <CheckboxField
            name="acceptPrivacy"
            label={i18n._(
              t`I accept the data privacy policy for sending my message.`
            )}
          />
          <div data-netlify-recaptcha="true" />
          {serverError && (
            <Box sx={{ marginY: 2 }}>
              <Text sx={{ color: "brand.warningRed" }}>
                <Trans>An error occured. Please try again.</Trans>
              </Text>
              <Text variant="small" sx={{ color: "brand.warningRed" }}>
                {serverError}
              </Text>
            </Box>
          )}
          <Button type="submit" variant="primary">
            <Trans>Send message</Trans>
          </Button>
        </Form>
      </Grid>
    </Section>
  );
}

// export const fragment = graphql`
//   fragment SanityContactSectionFragment on SanityContactFormSection {
//     title
//     subtitle
//     subject
//     to
//     _rawText
//   }
// `;

ContactFormSection.fragment = gql`
  fragment ContactFormSectionFragment on ContactFormSection {
    title
    subtitle
    subject
    to
    _rawText: textRaw
  }
`;

/** @jsxImportSource theme-ui */
import { Container, Grid, Title } from "@bottlebooks/gatsby-design-system";
import useAuthentication from "@bottlebooks/gatsby-plugin-firebase-auth/src/useAuthentication";
import { Box } from "@bottlebooks/gatsby-theme-base/src";
import ContainerDialog from "@bottlebooks/bottlebooks-site-base/src/components/ContainerDialog";
import Layout from "@bottlebooks/bottlebooks-site-base/src/components/Layout";
import Login from "@bottlebooks/bottlebooks-site-base/src/components/UserProfile/Login";
import LoginBenefits from "@bottlebooks/bottlebooks-site-base/src/components/UserProfile/Login.Benefits";
import PersonalPageHeader from "./PersonalPageLayout.Header";
import PersonalPageNav from "./PersonalPageLayout.Nav";
import { Trans } from "@lingui/macro";
export default function PersonalPageLayout({
  locale,
  collectionId,
  children,
  ...rest
}) {
  const { isAuthenticating, isAuthenticated } = useAuthentication();
  if (isAuthenticating || !isAuthenticated())
    return (
      <ContainerDialog p={3}>
        <Login
          prepend={
            <Title as="h1" variant="small" sx={{ marginBottom: 3.5 }}>
              <Trans>Sign in to personalize your visit</Trans>
            </Title>
          }
          availableMethods={["EMAIL", "GOOGLE"]}
          append={<LoginBenefits />}
        />
      </ContainerDialog>
    );

  return (
    <Box>
      <PersonalPageHeader />
      {/* @ts-expect-error The sx prop is missing in the type */}
      <Container sx={{ maxWidth: ["container.fluid", "container.fluid"] }}>
        <Grid
          // @ts-expect-error The sx prop is missing in the type
          sx={{
            gridTemplateColumns: ["1fr", "210px 1fr", "300px 1fr"],
            paddingY: [0, 4.5],
            columnGap: 3.5,
            rowGap: 0,
          }}
        >
          <PersonalPageNav locale={locale} sx={{ marginY: [2.5, 0] }} />
          <Box
            sx={{
              marginX: ["-gutter", 0],
              backgroundColor: "white",
              boxShadow: "xs",
              borderRadius: "default",
            }}
            {...rest}
          >
            {children}
          </Box>
        </Grid>
      </Container>
    </Box>
  );
}

export function Head() {
  return <title>Log in</title>;
}

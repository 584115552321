/** @jsxImportSource theme-ui */
import { Container } from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";
import React from "react";
import EventBanner from "../Event/EventBanner.next";
import EventHeader from "../Event/EventHeader.next";
import EventVideos from "./EventVideos.next";
import FeaturedExhibitorsSection from "./FeaturedExhibitorsSection.next";
import FeaturedProducersSection from "./FeaturedProducersSection";
import FilesSection from "./FilesSection.next";
import QuickLinks from "./QuickLinks";
import SiteSearchSection from "./SiteSearchSection";

const fragment = graphql(`
  fragment LandingPageSections on Event {
    ...EventBanner
    ...EventHeader
    videos {
      ...EventVideos
    }
    files {
      nodes {
        ...FilesSection
      }
    }
  }

  # fragment LandingPageSections on LocaleConfig {
  #   ...QuickLinks
  # }

  fragment LandingPageSections_FeaturedExhibitors on Registration {
    ...FeaturedExhibitorsSection
  }
`);

export default function LandingPageSections({
  event: data,
  localeConfig,
  videos,
  files,
  shuffledExhibitors,
  shuffledProducers,
  withOverlay,
  locale,
  segments,
}: {
  event: FragmentType<typeof fragment>;
  localeConfig: any;
  videos: any;
  files: any;
  shuffledExhibitors: any;
  shuffledProducers: any;
  withOverlay: boolean;
  locale: string;
  segments: any;
}) {
  const event = useFragment(fragment, data);
  return (
    <React.Fragment>
      <EventBanner event={event} withOverlay={withOverlay} />

      <SiteSearchSection locale={locale} />

      <Container sx={{ paddingY: [4, 5] }}>
        <EventHeader data={event} />
      </Container>

      <Container sx={{ paddingBottom: [4, 5] }}>
        <QuickLinks localeConfig={localeConfig} />
      </Container>

      {EventVideos.hasContent(videos) && (
        <Container sx={{ paddingBottom: [4, 5] }}>
          <EventVideos data={videos} />
        </Container>
      )}

      {Boolean(files.length) && (
        <Container sx={{ paddingBottom: [4, 5] }}>
          <FilesSection data={files} />
        </Container>
      )}

      {Boolean(shuffledExhibitors?.length) && (
        <FeaturedExhibitorsSection
          exhibitors={shuffledExhibitors}
          locale={locale}
          sx={{ paddingBottom: [4, 5] }}
        />
      )}

      {Boolean(shuffledProducers?.length) && (
        <FeaturedProducersSection
          producers={shuffledProducers}
          locale={locale}
          sx={{ paddingBottom: [4, 5] }}
        />
      )}
    </React.Fragment>
  );
}

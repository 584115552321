/** @jsxImportSource theme-ui */
import { Box } from "@bottlebooks/gatsby-design-system";
import useAuthentication from "@bottlebooks/gatsby-plugin-firebase-auth/src/useAuthentication";
import { Link } from "@bottlebooks/gatsby-theme-base/src";
import useSiteConfig from "@bottlebooks/bottlebooks-site-base/src/components/useSiteConfig";
import useLink from "@bottlebooks/bottlebooks-site-base/src/useLink";
import { Trans } from "@lingui/macro";
import {
  BookmarkSimple,
  CalendarCheck,
  FileArrowDown,
  ListChecks,
  SignOut,
  Star,
  User,
} from "@phosphor-icons/react";
import type { ComponentPropsWithoutRef } from "react";
import { useState } from "react";
import type { BoxProps } from "theme-ui";
import CatalogExportDialog from "./CatalogExportDialog";

export default function PersonalPageNav(props: BoxProps & { locale: string }) {
  const config = useSiteConfig();
  const link = useLink();
  const { signOut } = useAuthentication();
  const [showDialog, toggleDialog] = useState(false);
  return (
    <Box as="nav" {...props}>
      <Box
        as="ul"
        sx={{
          listStyle: "none",
          padding: 0,
          margin: 0,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        {config.listsEnabled && (
          <MenuItem to={link.tastingList()}>
            <ListChecks size={22} weight="light" />
            <Trans>My Tasting List</Trans>
          </MenuItem>
        )}
        <MenuItem to={link.bookmarks()}>
          <BookmarkSimple size={22} weight="light" />
          <Trans>Bookmarks</Trans>
        </MenuItem>
        {config.tastingNotesEnabled && (
          <MenuItem to={link.tastingNotes()}>
            <Star size={22} weight="light" />
            <Trans>Ratings</Trans>
          </MenuItem>
        )}
        <MenuItem onClick={() => toggleDialog(true)}>
          <FileArrowDown size={22} weight="light" />
          <Trans>Download my tasting booklet</Trans>
        </MenuItem>
        <MenuSpacer />
        <MenuItem to={link.myProfile()}>
          <User size={22} weight="light" />
          <Trans>My Profile</Trans>
        </MenuItem>

        {config.isMeetingBookingEnabled && (
          <MenuItem to={link.mySchedule()}>
            <CalendarCheck size={22} weight="light" />
            <Trans>My Schedule</Trans>
          </MenuItem>
        )}
        <MenuItem onClick={signOut}>
          <SignOut size={22} weight="light" />
          <Trans>Log out</Trans>
        </MenuItem>
      </Box>
      {showDialog && (
        <CatalogExportDialog
          locale={props.locale}
          onClose={() => toggleDialog(false)}
        />
      )}
    </Box>
  );
}

function MenuItem({
  children,
  to,
  ...rest
}: ComponentPropsWithoutRef<typeof Link>) {
  return (
    <Box as="li">
      <Link
        sx={{
          variant: "text.small",
          display: "flex",
          width: "100%",
          alignItems: "center",
          paddingX: 2.5,
          paddingY: 1.5,
          gap: 2.5,
          borderRadius: "large",
          color: "lightText",
          "&.bb-active, &:hover": {
            backgroundColor: "white",
            color: "primary",
          },
        }}
        to={to}
        // activeClassName="bb-active"
        {...rest}
      >
        {children}
      </Link>
    </Box>
  );
}

function MenuSpacer({ ...rest }) {
  return (
    <Box
      as="li"
      sx={{
        height: "1px",
        paddingTop: [2, 3.5],
        marginBottom: [2.5, 3.5],
        borderBottom: 1,
        borderColor: "borderSecondary",
      }}
      {...rest}
    >
      &nbsp;
    </Box>
  );
}

/** @jsxImportSource theme-ui */
import {
  Badge,
  Box,
  CloseIcon,
  Container,
  Flex,
  HamburgerIcon,
  Text,
} from "@bottlebooks/gatsby-design-system";
import { useFavorites } from "@bottlebooks/bottlebooks-site-bookmarks/src";
import useUserProfile from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUserProfile";
import { Button } from "@bottlebooks/gatsby-theme-base/src";
import { Trans, t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { SignIn } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import useLink from "../../useLink";
import SiteSearch from "../SiteSearch/SiteSearch.next";
import {
  default as UserProfileAvatar,
  default as UserProfileCardAvatar,
} from "../UserProfile/UserProfileCard.Avatar.next";
import useSiteConfig from "../useSiteConfig";
import Logo from "./Logo";
import MenuActions from "./MenuActions";
import MenuItem from "./MenuItem";

/**
 * @param {object} opt
 * @param {object[]} opt.menuItems
 * @param {string} opt.landingPage
 * @param {boolean} [opt.isScrolledDown]
 * @param {string} opt.locale
 * @returns
 */
export default function MainMenuMobile({
  menuItems,
  landingPage,
  isScrolledDown,
  locale,
  event,
  ...rest
}) {
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const [searchIsVisible, toggleSearchIsVisible] = useState(false);
  // Hide menu when route changes
  useEffect(() => {
    setMenuIsVisible(false);
  }, [window.location.pathname]);
  return (
    <Container {...rest}>
      <Flex>
        <Flex direction="column" sx={{ width: "100%" }}>
          <Flex align="center">
            <Box>
              <Button
                variant="text"
                onClick={() => setMenuIsVisible(!menuIsVisible)}
              >
                {menuIsVisible ? <CloseIcon /> : <HamburgerIcon />}
              </Button>
            </Box>
            <Box sx={{ marginLeft: 3 }}>
              <Logo
                to={landingPage}
                isScrolledDown={isScrolledDown}
                event={event}
              />
            </Box>

            <MenuActions
              searchIsVisible={searchIsVisible}
              toggleSearchIsVisible={toggleSearchIsVisible}
            />
          </Flex>
        </Flex>
      </Flex>
      {searchIsVisible && (
        <Search
          locale={locale}
          onClick={() => toggleSearchIsVisible(!searchIsVisible)}
        />
      )}
      {menuIsVisible && (
        <Menu
          menuItems={menuItems}
          onClick={() => setMenuIsVisible(!menuIsVisible)}
        />
      )}
    </Container>
  );
}

function Menu({ onClick, menuItems }) {
  const { userProfile } = useUserProfile();
  const config = useSiteConfig();
  const { favorites } = useFavorites();
  return (
    <Box
      className="bb-mobile-menu"
      // @ts-expect-error No idea why sx isn't recognized
      sx={{
        position: "fixed",
        paddingX: 2, // Match header padding
        paddingBottom: 3,
        zIndex: "modal",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "light",
        overflowY: "scroll",
      }}
    >
      <Flex direction="column">
        <Button
          variant="text"
          onClick={onClick}
          sx={{
            marginRight: "auto",
            marginTop: 2.5,
            marginLeft: 2,
          }}
        >
          <CloseIcon />
        </Button>
        <Flex
          direction="column"
          sx={{
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          {menuItems.map((item, index) => (
            <MenuItem key={index} {...item} />
          ))}
          {config.isMeetingBookingEnabled && (
            <MenuItem
              type="schedule"
              sx={{ paddingRight: 1, paddingBottom: 1, paddingTop: 1 }}
            >
              <Flex gap={2} align="center">
                <Text>
                  <Trans>My schedule</Trans>
                </Text>
              </Flex>
            </MenuItem>
          )}
          <MenuItem
            type="bookmarks"
            sx={{ paddingRight: 1, paddingBottom: 1, paddingTop: 1 }}
          >
            <AccountButton />
          </MenuItem>
        </Flex>
      </Flex>
    </Box>
  );
}
function AccountButton() {
  const { userProfile } = useUserProfile();
  const { favorites } = useFavorites();
  const link = useLink();
  if (userProfile) {
    return (
      <Button to={link.bookmarks()} variant="text" sx={{ padding: 1 }}>
        <Flex align="center" gap={2} sx={{ position: "relative" }}>
          <Text>My List</Text>
          <UserProfileCardAvatar variant="small" profile={userProfile} />
          {/* {Boolean(favorites?.length) && ( */}
          <Badge
            sx={{
              position: "absolute",
              paddingY: 0.5,
              backgroundColor: "primary",
              color: "onPrimary",
              right: -3,
              top: "2px",
              transform: "translate(-25%, -25%)",
              zIndex: 999,
            }}
          >
            {favorites?.length}
          </Badge>
          {/* )} */}
        </Flex>
      </Button>
    );
  }

  return (
    <Button to={link.bookmarks()} variant="primary">
      <Trans>Log in</Trans>
    </Button>
  );
}

function Search({ onClick, locale }) {
  const { i18n } = useLingui();
  return (
    <Box
      // @ts-expect-error No idea why sx isn't recognized
      sx={{
        position: "fixed",
        paddingBottom: 3,
        zIndex: "modal",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "light",
        overflowY: "scroll",
      }}
    >
      <Flex
        gap={1}
        align="center"
        sx={{
          backgroundColor: "background",
          padding: 2,
          borderBottom: 1,
          borderColor: "border",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <SiteSearch
            variant="mobile"
            locale={locale}
            placeholder={i18n._(t`Search for producer or wine…`)}
            autoFocus
            sx={{ border: 0 }}
          />
        </Box>
        <Button
          variant="text"
          onClick={onClick}
          sx={{ padding: 2, display: "flex", alignItems: "center" }}
        >
          <CloseIcon size="small" />
        </Button>
      </Flex>
    </Box>
  );
}

function AccountItem() {
  const { userProfile } = useUserProfile();
  const link = useLink();
  if (userProfile) {
    return (
      <MenuItem type="bookmarks" sx={{ position: "relative" }}>
        <UserProfileAvatar variant="small" profile={userProfile} />
      </MenuItem>
    );
  }

  return (
    <Button to={link.bookmarks()} variant="text" sx={{ padding: 1 }}>
      <SignIn size={22} weight="light" />
    </Button>
  );
}

/** @jsxImportSource theme-ui */
import { gql } from "urql";
import PageReference from "../PageReference";

export default gql`
  fragment MenuItemBlockFragment on MenuItemBlock {
    title
    menuItems {
      _key
      name
      page {
        ...PageReference
      }
    }
    style {
      ...BlockStyle
    }
  }
  ${PageReference.fragment}
`;

/** @jsxImportSource theme-ui */
import MeetingError from "./Meeting.Error";
import MeetingLocation, { MeetingLocationSkeleton } from "./Meeting.Location";
import type { MeetingPartnerProfile } from "./Meeting.Partner";
import MeetingPartner, { MeetingPartnerSkeleton } from "./Meeting.Partner";
import MeetingTimeslot, { MeetingTimeslotSkeleton } from "./Meeting.Timeslot";
import type { MeetingWrapperProps } from "./Meeting.Wrapper";
import MeetingWrapper from "./Meeting.Wrapper";

type MeetingInfoProps = MeetingWrapperProps & {
  meeting: MeetingData | null | undefined;
};

export type MeetingData = {
  attendee?: MeetingPartnerProfile | null | undefined;
  organizer?: MeetingPartnerProfile | null | undefined;
  recipient?: MeetingPartnerProfile | null | undefined;
  dateLabel: string | null | undefined;
  timeslotLabel: string | null | undefined;
  location?: string | null | undefined;
};

export default function Meeting({ meeting, ...rest }: MeetingInfoProps) {
  if (!meeting) return null;
  return (
    <Meeting.Wrapper {...rest}>
      <Meeting.Partner as="li" profile={meeting.recipient} />
      <Meeting.Location as="li" location={meeting.location} />
      <Meeting.Timeslot
        as="li"
        dateLabel={meeting.dateLabel}
        timeslotLabel={meeting.timeslotLabel}
      />
    </Meeting.Wrapper>
  );
}
Meeting.Wrapper = MeetingWrapper;
Meeting.Partner = MeetingPartner;
Meeting.Location = MeetingLocation;
Meeting.Timeslot = MeetingTimeslot;
Meeting.Error = MeetingError;
Meeting.Skeleton = MeetingSkeleton;

export function MeetingSkeleton(rest: Omit<MeetingWrapperProps, "children">) {
  return (
    <Meeting.Wrapper {...rest}>
      <MeetingSkeleton.Partner as="li" />
      <MeetingSkeleton.Location as="li" />
      <MeetingSkeleton.Timeslot as="li" />
    </Meeting.Wrapper>
  );
}

MeetingSkeleton.Partner = MeetingPartnerSkeleton;
MeetingSkeleton.Location = MeetingLocationSkeleton;
MeetingSkeleton.Timeslot = MeetingTimeslotSkeleton;

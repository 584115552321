import { gql } from "urql";
import { SanityPage } from "../../../components";

export const allPageQuery = gql`
  query allPageQuery {
    allPage {
      ...SanityPage
    }
  }
  ${SanityPage.fragment}
`;

/** @jsxImportSource theme-ui */
import { Box } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import FilterField from "../FilterField";
import FacetBox from "./FacetBox";
import FacetValue from "./FacetValue";
import useDefaultFacet from "./useDefaultFacet";

const indents = [0, 3, 4];

export default function DefaultFacet({
  title,
  allValues,
  selectedValues,
  onToggle,
  multiSelect = false,
  pageSize = 5,
  totalCount,
  count,
  placeholder = "Filter",
  children = null,
}) {
  const facet = useDefaultFacet({
    pageSize,
    allValues,
    selectedValues,
    count,
    totalCount,
  });
  if (!facet) return null;

  const handleToggle = (value, isSelected) => {
    onToggle && onToggle(value, isSelected);
    facet.setFilter("");
  };

  return (
    <FacetBox title={title} multiSelect={multiSelect}>
      {children ? (
        <Box
          sx={{
            paddingBottom: 1,
            borderBottom: 1,
            borderColor: "border",
            marginBottom: 3,
          }}
        >
          {children}
        </Box>
      ) : null}
      {facet.hasFilter && (
        <FilterField
          placeholder={placeholder}
          value={facet.filter}
          onChange={(filter) => facet.setFilter(filter)}
          sx={{
            marginBottom: 3,
            marginRight: 3,
            position: "sticky",
            top: 0,
          }}
        />
      )}
      {facet.values.map(
        ({ value, label = value, key = value, level, count }) => {
          const isSelected = selectedValues?.includes?.(value);
          return (
            <FacetValue
              key={key}
              count={count}
              isSelected={isSelected}
              onClick={() => handleToggle(value, !isSelected)}
              sx={{
                marginLeft: indents[level],
                marginRight: 3,
                marginBottom: 3,
              }}
            >
              {label}
            </FacetValue>
          );
        }
      )}
      <Box sx={{ marginTop: 3, display: "flex" }}>
        {facet.hasMore && (
          <FacetValue
            isSelected={false}
            count={undefined}
            onClick={() => facet.collapse(false)}
            sx={{
              color: "primary",
              backgroundColor: "transparent",
              variant: "label.default",
              marginBottom: 3,
            }}
          >
            <Trans>Show all</Trans>
          </FacetValue>
        )}
        {!facet.isCollapsed && (
          <FacetValue
            isSelected={false}
            count={undefined}
            onClick={() => facet.collapse(true)}
            sx={{
              color: "primary",
              backgroundColor: "transparent",
              variant: "label.default",
              marginBottom: 3,
            }}
          >
            <Trans>Show less</Trans>
          </FacetValue>
        )}
      </Box>
    </FacetBox>
  );
}

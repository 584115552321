/** @jsxImportSource theme-ui */
import { Box, P, Text, Title } from "@bottlebooks/gatsby-design-system";
import useUserProfile from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUserProfile";
import { Button } from "@bottlebooks/gatsby-theme-base/src";
import UserProfileFormFields from "@bottlebooks/bottlebooks-site-base/src/components/UserProfile/UserProfileForm.Fields";
import { attendeeProfileFormSchema } from "@bottlebooks/gatsby-plugin-firebase-auth/src/attendeeProfileSchema";
import type { ContentLocale } from "@bottlebooks/bottlebooks-site-base/src/useLocale";
import { Trans } from "@lingui/macro";
import { Form, Formik } from "formik";
import { graphql } from "~/gql";
import type { ComponentPropsWithoutRef, ReactNode } from "react";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import PersonalPageLayout from "./PersonalPageLayout";
import useProfileSubmission from "./ProfilePage.useSubmission";
import { useFormikContext } from "formik";
import React from "react";

type Props = {
  locale: ContentLocale;
  collection: ProfilePageData;
};

export default function ProfilePage({ locale, collection }: Props) {
  return (
    <PersonalPageLayout collectionId={collection.collectionId} locale={locale}>
      <ProfileForm locale={locale}>
        <Box sx={{ padding: 3.5 }}>
          <Title variant="small" as="h1">
            <Trans>Your profile data</Trans>
          </Title>
          {/* @ts-expect-error The sx prop is missing in the type */}
          <P sx={{ color: "lighterText", marginBottom: 3.5 }}>
            <Trans>
              Please take a moment to complete your profile so that you can
              start networking on the website.
            </Trans>
          </P>
          <UserProfileFormFields />
        </Box>
      </ProfileForm>
    </PersonalPageLayout>
  );
}

export const fragment = graphql(`
  fragment ProfilePage on Collection {
    collectionId
  }
`);

export interface ProfilePageData {
  collectionId: string;
}

// We need to pass optional() at the end because toFormikValidationSchema() seems to use it. TODO Verify this.
const profileFormSchema = z.object({
  displayName: attendeeProfileFormSchema.shape.displayName,
  jobTitle: attendeeProfileFormSchema.shape.jobTitle.optional(),
  companyName: attendeeProfileFormSchema.shape.companyName.optional(),
  telephone: attendeeProfileFormSchema.shape.telephone.optional(),
  linkedInURL: attendeeProfileFormSchema.shape.linkedInURL.optional(),
  profileImageUrl: attendeeProfileFormSchema.shape.profileImageUrl.optional(),
  profileImage: attendeeProfileFormSchema.shape.profileImage.optional(),
});

function ProfileForm({
  locale,
  children,
}: {
  locale: ContentLocale;
  children: React.ReactNode;
}) {
  const { userProfile } = useUserProfile();
  const [submit, state] = useProfileSubmission({
    uid: userProfile?.uid || "",
    locale,
  });
  return (
    <Formik<z.infer<typeof profileFormSchema>>
      key={userProfile?.uid}
      initialValues={{
        displayName: userProfile?.displayName || "",
        jobTitle: userProfile?.jobTitle || "",
        companyName: userProfile?.companyName || "",
        telephone: userProfile?.telephone || "",
        linkedInURL: userProfile?.linkedInURL || "",
        profileImageUrl: userProfile?.profileImageUrl || "",
        profileImage: null,
      }}
      validationSchema={toFormikValidationSchema(profileFormSchema)}
      onSubmit={async (raw, helpers) => {
        const values = profileFormSchema.parse(raw);
        await submit(values);
        helpers.setSubmitting(false);
      }}
    >
      <Form>
        {children}
        <Footer>
          <Message state={state} />
          <SubmitButton>
            <Trans>Save</Trans>
          </SubmitButton>
        </Footer>
      </Form>
    </Formik>
  );
}

function Message({
  state,
}: {
  state: ReturnType<typeof useProfileSubmission>[1];
}) {
  switch (state.type) {
    case "idle":
      return null;
    case "submitting":
      return null;
    case "success":
      return (
        <Text
          variant="small"
          sx={{
            borderRadius: "border",
            padding: 2.5,
            backgroundColor: "brand.successGreen",
            color: "white",
            flex: 1,
          }}
        >
          <Trans>Your profile data has been updated.</Trans>
        </Text>
      );
    case "error":
      return (
        <Text
          variant="small"
          sx={{
            borderRadius: "border",
            padding: 2.5,
            backgroundColor: "brand.errorRed",
            color: "white",
            flex: 1,
          }}
        >
          <Trans>We couldn't save your profile data. {state.message}</Trans>
        </Text>
      );
  }
}

function SubmitButton({
  children,
  ...rest
}: ComponentPropsWithoutRef<typeof Button> & { children: React.ReactNode }) {
  const { isSubmitting } = useFormikContext();
  if (isSubmitting) {
    return (
      <Button
        variant="primary"
        type="submit"
        disabled
        sx={{ opacity: 0.7 }}
        {...rest}
      >
        <Trans>Saving…</Trans>
      </Button>
    );
  }
  return (
    <Button variant="primary" type="submit" {...rest}>
      {children}
    </Button>
  );
}

function Footer({
  children,
  ...rest
}: ComponentPropsWithoutRef<typeof Box> & { children: ReactNode }) {
  return (
    <Box
      sx={{
        backgroundColor: "nuanced",
        paddingX: 3.5,
        paddingY: 2.5,
        borderTop: 1,
        borderColor: "borderSecondary",
        display: "flex",
        justifyContent: "flex-end",
        gap: 2,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}

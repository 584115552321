/** @jsxImportSource theme-ui */
import { Box } from "@bottlebooks/gatsby-design-system";
import Video from "@bottlebooks/bottlebooks-site-base/src/components/Video/Video.next";
// import { graphql } from "gatsby";

export default function VideoBlock({ title, url, ...rest }) {
  const videoType = getVideoType(url);
  return (
    <Box {...rest}>
      <Video data={{ ...videoType, title, url }} />
    </Box>
  );
}

// This logic copied from the videos resolver
function getVideoType(videoUrl) {
  if (!videoUrl) return undefined;

  // YouTube
  {
    const [, , , , , videoId] =
      videoUrl.match(
        /(youtu\.be\/|youtube\.com\/(watch\?(.+&)?v=|(embed|v)\/))([^?&"'>]+)/
      ) || [];
    if (videoId) return { type: "YOUTUBE", videoId };
  }

  // Vimeo
  {
    const [, videoId] = videoUrl.match(/vimeo\.com\/(.+)/) || [];
    if (videoId) return { type: "VIMEO", videoId };
  }

  // Facebook
  {
    // Example: https://www.facebook.com/InSignoLeonis/videos/839714083213069/
    const [, videoId] =
      videoUrl.match(/\bfacebook\.com\/.+\/videos\/(\d+)/) || [];
    if (videoId) return { type: "FACEBOOK", videoId };
  }

  // Instagram
  {
    // Example: https://www.instagram.com/tv/B-FTMmkJqiu/
    // with tracking: https://www.instagram.com/tv/B-FTMmkJqiu/?utm_source=ig_web_copy_link
    // The page itself: https://www.instagram.com/p/B-UYwq3FSi2/
    const [, videoId] =
      videoUrl.match(/\binstagram\.com\/(?:tv|p)\/([A-Za-z0-9-_]+)\b\/?\??/) ||
      [];
    if (videoId) return { type: "INSTAGRAM", videoId };
  }

  // Poly video
  {
    // Example: https://go.plvideo.cn/front/video/preview?vid=d539cf356fa519da2a2f747e40f153ca_d
    const [, videoId] =
      videoUrl.match(/\bplvideo\.cn\/.+preview\?vid=(.+)/) || [];
    if (videoId) return { type: "POLYVIDEO", videoId };
  }
}

// export const fragment = graphql`
//   fragment SanityVideoBlockFragment on SanityVideoBlock {
//     title
//     url
//   }
// `;

/** @jsxImportSource theme-ui */
import { Button, Flex, SearchIcon } from "@bottlebooks/gatsby-theme-base/src";
import React from "react";

/**
 * Renders a filter field.
 * @param {Omit<import('react').InputHTMLAttributes<HTMLInputElement>, 'sx' | 'onChange'> & {
 *  value?: string;
 *  onChange?: (value: string) => void;
 *  onEnter?: () => void;
 *  variant?: import('./filterFieldVariants').FilterFieldVariant;
 *  inputRef?: import('react').Ref<HTMLInputElement>;
 * }} props
 */
export default function FilterField({
  value,
  onChange,
  onEnter = () => {},
  variant = "default",
  className,
  inputRef,
  ...rest
}) {
  return (
    <Flex
      className={className}
      sx={{
        alignItems: "center",
        border: 1,
        borderColor: "border",
        backgroundColor: rest.disabled ? "nuanced" : "background",
        variant: `filter.${variant}.wrapper`,
      }}
    >
      <SearchIcon
        width="16"
        height="16"
        alt=""
        sx={{ margin: 2, variant: `filter.${variant}.icon` }}
      />
      <input
        ref={inputRef}
        type="text"
        value={value}
        onChange={(e) => onChange && onChange(e.target.value)}
        onKeyPress={(ev) => {
          if (ev.key === "Enter") {
            ev.preventDefault();
            onEnter();
          }
        }}
        sx={{
          flex: 1,
          paddingX: 2,
          paddingY: 1,
          border: 0,
          outline: "none",
          variant: `filter.${variant}.input`,
          backgroundColor: "transparent",
        }}
        {...rest}
      />
      {value && (
        <Button
          onClick={() => onChange && onChange("")}
          variant="text"
          sx={{ color: "lightText", variant: `filter.${variant}.button` }}
        >
          ✕
        </Button>
      )}
    </Flex>
  );
}

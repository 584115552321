/** @jsxImportSource theme-ui */
import { Dialog } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import type { DialogProps } from "@reach/dialog";
import { Fragment } from "react";
import LoginState from "../UserProfile/Login";
import LoginBenefits from "../UserProfile/Login.Benefits";

type RequestMeetingLoginProps = {
  withProfile: { displayName?: string | null };
  onDismiss: DialogProps["onDismiss"];
};
export default function RequestMeetingLogin({
  withProfile,
  onDismiss,
}: RequestMeetingLoginProps) {
  return (
    <Fragment>
      <Dialog.Header onDismiss={onDismiss}>
        {withProfile.displayName ? (
          <Trans>Book a meeting with {withProfile.displayName}</Trans>
        ) : (
          <Trans>Book a meeting</Trans>
        )}
      </Dialog.Header>
      <Dialog.Content sx={{ textAlign: "center" }}>
        <LoginState
          availableMethods={["GOOGLE", "EMAIL"]}
          append={<LoginBenefits sx={{ marginTop: 3 }} />}
        />
      </Dialog.Content>
    </Fragment>
  );
}

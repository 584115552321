/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  ChevronDownIcon,
  ChevronUpIcon,
  Flex,
  Label,
  SemiTitle,
} from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import React, { useState } from "react";

export default function FacetBox({ title, multiSelect, children, ...rest }) {
  const [isOpen, toggleIsOpen] = useState(false);
  return (
    <Box
      sx={{ borderTop: 1, borderColor: "borderSecondary", paddingY: 3 }}
      {...rest}
    >
      <Button onClick={() => toggleIsOpen(!isOpen)} sx={{ width: "100%" }}>
        <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <SemiTitle sx={{ padding: 0 }}>{title}</SemiTitle>
          {isOpen ? (
            <ChevronUpIcon size="small" />
          ) : (
            <ChevronDownIcon size="small" />
          )}
        </Flex>
      </Button>

      {isOpen && (
        <Box>
          {multiSelect && (
            <Label
              variant="small"
              sx={{ color: "lighterText", marginBottom: 3, marginLeft: 2 }}
            >
              <Trans>Select multiple</Trans>
            </Label>
          )}
          {children}
        </Box>
      )}
    </Box>
  );
}

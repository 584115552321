/** @jsxImportSource theme-ui */
import { useLingui } from "@lingui/react";
import { graphql } from "~/gql";
import Badge from "../Badge/Badge";
import LocalEventIcon from "../Badge/LocalEventIcon";
import OnlineShopIcon from "../Badge/OnlineShopIcon";
import PhysicalEventIcon from "../Badge/PhysicalEventIcon";
import VideosIcon from "../Badge/VideosIcon";
import VirtualTastingIcon from "../Badge/VirtualTastingIcon";

/**
 * Displays an icon and text for an offering.
 * @param {object} props
 * @param {keyof icons} props.type
 * @param {keyof iconVariants} props.variant
 * @param {'row'|'column'|'row-reverse'|'column-reverse'} [props.direction] The flex-direction CSS attribute.
 */
export default function OfferingBadge({ type, variant, direction, ...rest }) {
  const { i18n } = useLingui();
  const Icon = icons[type] || icons.default;
  return (
    <Badge
      direction={direction}
      {...rest}
      icon={<Icon {...(iconVariants[variant] || iconVariants.default)} />}
    >
      {i18n._(`offering.${type}`)}
    </Badge>
  );
}

const iconVariants = {
  onDark: { sx: { color: "onPrimary" } },
  onPrimary: { sx: { color: "primary" }, backgroundColor: "#fff" },
  default: { sx: { color: "primary" }, backgroundColor: "red" },
};

const icons = {
  default: VirtualTastingIcon,
  virtualTasting: VirtualTastingIcon,
  virtualTastings: VirtualTastingIcon,
  localEvent: LocalEventIcon,
  inDusseldorf: PhysicalEventIcon,
  inVerona: PhysicalEventIcon,
  onSite: PhysicalEventIcon,
  onlineShop: OnlineShopIcon,
  videos: VideosIcon,
};

export const fragment = graphql(`
  fragment MarketingActivityBadge on MarketingActivity {
    type
  }
`);

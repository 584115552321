/** @jsxImportSource theme-ui */
import { BookmarkedProduct } from "@bottlebooks/bottlebooks-site-bookmarks/src";
import { Box, Grid } from "@bottlebooks/gatsby-theme-base/src";
import { useProducts } from "@bottlebooks/bottlebooks-site-base/src/components/SiteSearch/useEventData";
import React from "react";
import ListHeader from "./ListsPage.ListHeader";

/**
 *
 * @param {{
 *  name: string | React.ReactElement;
 *  items: ReturnType<typeof useEnrichedFavorites>;
 *  baseUrl: string;
 *  locale: string;
 *  listId?: string;
 *  [x: string]: any;
 * }} props
 * @returns
 */
export default function List({ list, baseUrl, locale, ...rest }) {
  const products = useProducts();
  const [isSectionExpanded, setIsSectionExpanded] = React.useState(false);
  if (!list.items) return null;
  // const byExhibitor = groupBy(list.items, 'exhibitorId');
  const noDetails = baseUrl.endsWith("/my");
  const bookmarkedProducts = products.filter((product) => {
    return list.items?.some((item) => item.id === product.productId);
  });
  return (
    <Box sx={{ backgroundColor: "white" }} {...rest}>
      <ListHeader
        to={noDetails ? undefined : baseUrl}
        isSectionExpanded={isSectionExpanded}
        setIsSectionExpanded={setIsSectionExpanded}
        list={list}
        items={bookmarkedProducts}
        sx={{
          // Sticky conflicts with tasting note modal
          // position: 'sticky',
          // top: 0,
          backgroundColor: "white",
          // zIndex: 'elevated',
          // paddingTop: 2,
          paddingY: 3,
          // boxShadow: 'light', // Covers the box shadows of the list items.
          // paddingLeft: [40, 40], // 40 + 2*8
        }}
      >
        {list.name}
      </ListHeader>
      <Box
        sx={{
          height: isSectionExpanded ? "auto" : 0,
          overflow: "hidden",
        }}
      >
        <Grid columns={[1, 2]} sx={{ margin: 1 }}>
          {bookmarkedProducts?.map((favorite) => {
            return (
              <Box key={favorite.productId}>
                <BookmarkedProduct
                  product={favorite}
                  variant="large"
                  to={`${baseUrl}/exhibitors/${favorite.exhibitorId}/${favorite.productId}`}
                />
              </Box>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
}

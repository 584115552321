/** @jsxImportSource theme-ui */
// @ts-ignore TS doesn't like direct image imports.
import { useCollectionLayout } from "../CollectionLayoutProvider.next";
import PortWineFestFooter from "./Footer.PortWineFest";
import StandardFooter from "./Footer.Standard";

export default function Footer({ children, homePath = "", ...rest }) {
  const collectionLayout = useCollectionLayout();
  switch (collectionLayout.siteConfig.layout) {
    case "PORTWINEFEST":
      return (
        <PortWineFestFooter homePath={homePath} {...rest}>
          {children}
        </PortWineFestFooter>
      );
    default:
      return (
        <StandardFooter homePath={homePath} {...rest}>
          {children}
        </StandardFooter>
      );
  }
}

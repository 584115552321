/** @jsxImportSource theme-ui */
import type { PropsWithChildren } from "react";
import type { SuccessContext } from "./Login.useState";
import { Box } from "@bottlebooks/gatsby-design-system";
import { LoginContext } from "./Login";
import type { UserProfileSchema } from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUserProfile";

type SuccessProps = PropsWithChildren<{
  context: SuccessContext;
  profile: UserProfileSchema;
}>;

export default function LoginSuccess({
  context,
  profile,
  children,
  ...rest
}: SuccessProps) {
  return (
    <LoginContext.Provider value={context}>
      <Box {...rest}>{children}</Box>
    </LoginContext.Provider>
  );
}

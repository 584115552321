/** @jsxImportSource theme-ui */
import { Button, Flex, Text } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import React from "react";
import { FragmentType, graphql, useFragment } from "~/gql";
import checkIcon from "../../assets/check.svg";
import Section from "../Section/Section";

const fragment = graphql(`
  fragment BrandTourism_Profile on Profile {
    wineExperienceOfferings
    hospitalityOfferings
    lifestyleOfferings
    outdoorExperienceOfferings
    openingHours {
      dayOfWeek(format: LOCALIZED)
      openingTime
      closingTime
    }
    languagesSpoken
    bookingUrl
  }
`);

export default function BrandTourism({
  data,
}: {
  data: FragmentType<typeof fragment>;
}) {
  const brand = useFragment(fragment, data);
  return (
    <React.Fragment>
      <Experiences
        title={<Trans>Wine Experiences</Trans>}
        experiences={brand.wineExperienceOfferings}
      />
      <Experiences
        title={<Trans>Hospitality</Trans>}
        experiences={brand.hospitalityOfferings}
      />
      <Experiences
        title={<Trans>Lifestyle</Trans>}
        experiences={brand.lifestyleOfferings}
      />
      <Experiences
        title={<Trans>Outdoor Experiences</Trans>}
        experiences={brand.outdoorExperienceOfferings}
      />
      <OpeningHours data={data} />
      <LanguagesSpoken data={data} />
      <Booking data={data} />
    </React.Fragment>
  );
}

function Experiences({
  experiences,
  title,
  ...rest
}: {
  experiences: string[] | null | undefined;
  title: string | React.ReactElement | false;
}) {
  if (!experiences?.length) return null;

  return (
    <Section title={title} {...rest}>
      <Flex wrap="wrap">
        {experiences.map((experience) => (
          <Experience
            key={experience}
            sx={{ width: ["100%", "50%"], marginBottom: 2 }}
          >
            {experience}
          </Experience>
        ))}
      </Flex>
    </Section>
  );
}

function Experience({ children, ...rest }: { children: React.ReactNode }) {
  return (
    <Flex align="center" {...rest}>
      <CheckIcon sx={{ marginRight: 3 }} />
      <Text>{children}</Text>
    </Flex>
  );
}

function CheckIcon(props: any) {
  return (
    <img
      src={checkIcon}
      alt=""
      sx={{ display: "block", width: 16, height: 16 }}
      {...props}
    />
  );
}

function OpeningHours({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const brand = useFragment(fragment, data);
  if (!brand.openingHours?.length) return null;
  return (
    <Section title={<Trans>Opening Hours</Trans>} {...rest}>
      <Text>
        {brand.openingHours.map(({ dayOfWeek, openingTime, closingTime }) => (
          <Text key={dayOfWeek}>
            {dayOfWeek}
            {(openingTime || closingTime) && (
              <React.Fragment>
                : {openingTime}–{closingTime}
              </React.Fragment>
            )}
          </Text>
        ))}
      </Text>
    </Section>
  );
}

function LanguagesSpoken({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const brand = useFragment(fragment, data);
  if (!brand.languagesSpoken?.length) return null;
  return (
    <Section title={<Trans>Languages Spoken</Trans>} {...rest}>
      <Text>{brand.languagesSpoken.join(", ")}</Text>
    </Section>
  );
}

function Booking({ data }: { data: FragmentType<typeof fragment> }) {
  const brand = useFragment(fragment, data);
  if (!brand.bookingUrl) return null;
  return (
    <Section title="">
      <Button variant="outline" href={brand.bookingUrl}>
        <Trans>Book online</Trans>
      </Button>
    </Section>
  );
}

/** @jsxImportSource theme-ui */
import { FormattedText } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import React from "react";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";

const fragment = graphql(`
  fragment ProductRange on Product {
    __typename
    productRange
    ... on Wine {
      rangeDescription
    }
  }
`);

export default function ProductRange({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const product = useFragment(fragment, data);
  const wine = product.__typename === "Wine" ? product : null;
  if (!hasContent(data)) return null;
  return (
    <Section
      title={
        <React.Fragment>
          <Trans>Product Range</Trans>
          {product.productRange && (
            <React.Fragment>: {product.productRange}</React.Fragment>
          )}
        </React.Fragment>
      }
      {...rest}
    >
      <FormattedText variant="large" text={wine?.rangeDescription} />
    </Section>
  );
}

function hasContent(data: FragmentType<typeof fragment>) {
  const product = useFragment(fragment, data);
  if (!product) return false;
  const wine = product.__typename === "Wine" ? product : null;
  if (product.productRange) return true;
  if (wine?.rangeDescription) return true;
  return false;
}

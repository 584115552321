/** @jsxImportSource theme-ui */
import {
  Box,
  ChevronDownIcon,
  Flex,
  Label,
  Menu,
  MenuButton,
  MenuLink,
  MenuList,
  Text,
} from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { print } from "graphql";
import { DocumentNode } from "graphql/language/ast";
import { gql } from "urql";
import env from "~/env";
import type { ContentLocale } from "~/gql/graphql";
import { useCollectionLayout } from "../CollectionLayoutProvider.next";

function getQuery({
  locale,
  projectId,
  collectionId,
}: {
  locale: ContentLocale;
  collectionId: string;
  projectId?: string;
}) {
  return queryOptions({
    queryKey: ["collection", locale, collectionId, "multisite-switcher"],
    queryFn: async () => {
      if (!projectId) return null;
      const sanityResult = await fetchSanity<{
        allSite?: {
          collectionId: string;
          name: string;
          sites?: { name: string; url: string }[];
        };
      }>(
        // This needs to be anonymous so that it is not picked up by codegen.
        // This is to Sanity via proxy and not via Bottlebooks GraphQL, which means it won't compile.
        gql`
          query ($collectionId: String) {
            allSite(where: { collectionId: { eq: $collectionId } }) {
              collectionId
              name
              sites {
                name
                url
              }
            }
          }
        `,
        { collectionId },
        {
          projectId,
          "bottlebooks-use-request-cache": "true",
        }
      );
      const site = sanityResult.data?.allSite?.[0];
      if (!site) throw new Error("No CMS site configured for this collection.");
      return site;
    },

    throwOnError: true,
  });
}

export default function MultisiteSwitcher({ ...rest }) {
  const collection = useCollectionLayout();
  const { data } = useQuery(
    getQuery({
      locale: "en",
      projectId: collection.projectId,
      collectionId: collection.collectionId,
    })
  );
  if (!data) return null;
  const collectionId = collection.collectionId;
  const site = data;
  const otherSites = site.sites?.filter(
    (site) => site.collectionId !== collectionId
  );
  if (otherSites?.length > 0)
    return (
      <Box className="bb-multisite-swicher">
        <Menu>
          <MenuButton variant="text" {...rest}>
            <Flex align="center">
              <Box sx={{ textAlign: "left" }}>
                <Label
                  variant="small"
                  sx={{ color: "lighterText", lineHeight: "12px" }}
                >
                  <Trans>Event</Trans>
                </Label>
                <Text variant="small" sx={{ lineHeight: "16px" }}>
                  {site.name}
                </Text>
              </Box>
              <ChevronDownIcon
                sx={{ marginLeft: 1, color: "lighterText", marginTop: 2.5 }}
                size="xsmall"
              />
            </Flex>
          </MenuButton>
          <MenuList>
            {otherSites.map((site) => (
              <MenuLink key={site.name} href={site.url}>
                {site.name}
              </MenuLink>
            ))}
          </MenuList>
        </Menu>
      </Box>
    );
  return null;
}

/**
 * This has been copied in from the root project.
 * It is a bit funny that we are loading data at this point
 * instead of consuming data from somewhere else. Perhaps this
 * can be resolved when basic sites can be configured in
 * Bottlebooks again.
 */
async function fetchSanity<GraphQLType>(
  query: DocumentNode,
  variables: {
    // This is required for caching
    collectionId: string;
  } & Record<string, any>,
  options?: {
    projectId: string;
    "bottlebooks-use-request-cache"?: string;
    "bottlebooks-request-cache-invalidation-key"?: string;
  }
) {
  const projectId = options?.projectId;
  if (!projectId) throw new Error("No projectId was provided");
  if (!query) throw new Error("No query was provided");
  const parsedQuery = print(query);
  const response = await fetch(`${env.VITE_API_URL}/pub/sanity/${projectId}`, {
    method: "POST",
    credentials: "include",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      ...getHeaders(options),
    },
    body: JSON.stringify({
      query: parsedQuery,
      variables,
      projectId,
    }),
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json() as Promise<{
    data: GraphQLType;
    errors: { message: string; path: string }[];
  }>;
}

function getHeaders(options?: {
  "bottlebooks-use-request-cache"?: string;
  "bottlebooks-request-cache-invalidation-key"?: string;
}):
  | {
      "bottlebooks-use-request-cache": string;
      "bottlebooks-request-cache-invalidation-key": string;
    }
  | {
      "bottlebooks-use-request-cache": string;
    }
  | null {
  if (!options) return null;
  if (!options["bottlebooks-use-request-cache"]) {
    return null;
  }
  if (options["bottlebooks-request-cache-invalidation-key"]) {
    return {
      "bottlebooks-use-request-cache": "true",
      "bottlebooks-request-cache-invalidation-key":
        options["bottlebooks-request-cache-invalidation-key"],
    };
  }
  return {
    "bottlebooks-use-request-cache": "true",
  };
}

/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import { Link } from "@bottlebooks/gatsby-theme-base/src";
import { graphql } from "~/gql";
// @ts-ignore SVG isn't supported by TS
import previous from "../../assets/arrow-left.svg";
// @ts-ignore SVG isn't supported by TS
import next from "../../assets/arrow-right.svg";
import React from "react";

const icons = { next, previous };

function getName({ name, shortName, vintage }) {
  if (name) return name;
  if (!vintage) return shortName;
  if (!shortName) return vintage;
  return `${shortName} ${vintage}`;
}

export default function ChangeProductLink({
  product,
  direction = "next",
  to,
  ...rest
}) {
  if (!product) return null;
  return (
    <Link
      to={to}
      title={getName(product)}
      style={{ display: "inline-block" }}
      {...rest}
    >
      <img
        src={icons[direction]}
        alt={getName(product)}
        style={{ padding: 3, boxSizing: "content-box" }}
        width={16}
        height={16}
      />
    </Link>
  );
}

export const fragment = graphql(`
  fragment ChangeProductLink on Product {
    shortName
    vintage(removeNonVintage: true)
    ...useLink_Product
  }
`);

export const ChangeProductLinkFragment = gql`
  fragment ChangeProductLink on Product {
    name
  }
`;

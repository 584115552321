export const FILTERS_CLEARED = 'FILTERS_CLEARED';
const FULL_TEXT_SEARCH_CHANGED = 'FULL_TEXT_SEARCH_CHANGED';
const SORT_CHANGED = 'SORT_CHANGED';
const FILTER_TOGGLED = 'FILTER_TOGGLED';
const STATE_SET = 'STATE_SET';

/**
 * @param {string} name
 * @param {string} key
 * @param {boolean} [replace]
 * @returns {[typeof FILTER_TOGGLED, { name: string, key: string, replace?: boolean }]}
 */
export function filterToggled(name, key, replace) {
  return [FILTER_TOGGLED, { name, key, replace }];
}

/**
 *
 * @param {string} queryString The term to search.
 * @returns {[typeof FULL_TEXT_SEARCH_CHANGED, string]}
 */
export function searchTermChanged(queryString) {
  return [FULL_TEXT_SEARCH_CHANGED, queryString];
}
/**
 *
 * @param {string} sort The sorting.
 * @returns {[typeof SORT_CHANGED, string]}
 */
export function sortChanged(sort) {
  return [SORT_CHANGED, sort];
}

/**
 *
 * @param {object} state The sorting.
 * @returns {[typeof STATE_SET, object]}
 */
export function stateChanged(state) {
  return [STATE_SET, state];
}

/**
 *
 * @returns {[typeof FILTERS_CLEARED]}
 */
export function filtersCleared() {
  return [FILTERS_CLEARED];
}

const initialFilterState = {
  searchTerm: '',
  sort: '',
};

// The complete state could be different, e.g. have the sort order or grouping in it.
export const initialState = { ...initialFilterState };

export default function reducer(state, event) {
  const [type, payload] = event;
  switch (type) {
    case FULL_TEXT_SEARCH_CHANGED: {
      return { ...state, searchTerm: payload };
    }
    case SORT_CHANGED: {
      return { ...state, sort: payload };
    }

    case STATE_SET: {
      return payload;
    }

    case FILTER_TOGGLED: {
      const { name, key, replace } = payload;
      if (state[name]?.includes(key)) {
        return {
          ...state,
          [name]: state[name].filter((filter) => filter !== key),
        };
      }
      if (replace) return { ...state, [name]: [key] };
      return {
        ...state,
        [name]: [...(state[name] || []), key],
      };
    }

    case FILTERS_CLEARED: {
      return {
        ...initialFilterState,
        // Keep the sort order.
        sort: state.sort,
      };
    }

    default:
      return state;
  }
}

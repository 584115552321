import { useFavorites } from "@bottlebooks/bottlebooks-site-bookmarks/src/useFavorite";
import useFirestore from "@bottlebooks/gatsby-plugin-firebase-app/src/useFirestore";
import useAuthentication from "@bottlebooks/gatsby-plugin-firebase-auth/src/useAuthentication";
import useUser from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUser";
import { useCollectionLayout } from "@bottlebooks/bottlebooks-site-base/src/components/CollectionLayoutProvider.next.js";
import useSiteConfig from "@bottlebooks/bottlebooks-site-base/src/components/useSiteConfig";
import type {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
  Timestamp,
} from "firebase/firestore";
import {
  collection,
  deleteDoc,
  doc,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import * as React from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";

export interface TastingNoteSubject {
  productId: string;
}

export interface TastingNote {
  productId: string;
  rating: 1 | 3 | 5;
  note?: string;
  addedAt: Date;
  updatedAt?: Date;
}

type FirestoreTastingNote = Omit<
  TastingNote & {
    addedAt: Timestamp;
    updatedAt: Timestamp;
  },
  "productId"
>;

type TastingNotesData = ReturnType<typeof useTastingNotesData>;

const TastingNotesContext = React.createContext<TastingNotesData | undefined>(
  undefined
);

export const TastingNotesProvider = ({ children }) => {
  const value = useTastingNotesData();
  return (
    <TastingNotesContext.Provider value={value}>
      {children}
    </TastingNotesContext.Provider>
  );
};

export function useTastingNotes() {
  const data = React.useContext(TastingNotesContext);
  const { tastingNotesEnabled } = useSiteConfig();
  if (!tastingNotesEnabled) return {} as TastingNotesData;
  if (!data) {
    throw new Error(
      "useTastingNotes() was used without a <TastingNotesProvider>. Add it to your app."
    );
  }
  return {
    ...data,
    findById(productId: string) {
      return data.byId[productId];
    },
  };
}

export function useTastingNote(product: TastingNoteSubject | undefined) {
  const data = React.useContext(TastingNotesContext);
  const { requireAuthentication } = useAuthentication();
  const { tastingNotesEnabled } = useSiteConfig();
  if (!tastingNotesEnabled)
    return {
      status: "error",
      isLoading: false,
      set: () => {
        throw new Error("Setting tasting notes is not implemented.");
      },
      delete: () => {
        throw new Error("Deleting is not implemented.");
      },
      tastingNote: undefined,
    } as {
      tastingNote: TastingNote | undefined;
      status: "error";
      isLoading: false;
      delete: () => {};
      set: () => {};
    };
  if (!data) {
    throw new Error(
      `useTastingNote() hook was used without a <TastingNotesProvider>. Add it to your App.`
    );
  }
  const { all, isLoading, error, status, set, unset } = data;
  const tastingNote = all?.find(
    (note) => note.productId === product?.productId
  );
  return {
    tastingNote,
    status,
    error,
    isLoading,
    async set(newTastingNote: TastingNote) {
      if (!requireAuthentication()) return;
      if (!product) return;
      return set(product, newTastingNote);
    },
    delete() {
      if (!requireAuthentication()) return;
      if (!product) return;
      unset(product);
    },
  };
}

function useTastingNotesRef(collectionId: string, userId: string | undefined) {
  const firestore = useFirestore();
  const tastingNotesRef = collection(
    firestore,
    "collections",
    collectionId,
    "users",
    userId || "-",
    "tastingNotes"
  ).withConverter(converter);
  return tastingNotesRef;
}

function useTastingNotesData() {
  const { collectionId } = useCollectionLayout();
  const [user, isLoadingUser] = useUser();
  const userId = user?.uid;
  const tastingNotesRef = useTastingNotesRef(collectionId, userId);
  const [all, isLoadingFirestore, error] = useCollectionData(
    query(tastingNotesRef, orderBy("addedAt", "desc"))
  );
  const favorites = useFavorites();
  const isLoading = isLoadingFirestore || isLoadingUser;
  const byId =
    all?.reduce((byId, item) => {
      byId[item.productId] = item;
      return byId;
    }, {} as { [key: string]: TastingNote }) || {};
  return {
    all,
    byId,
    status: isLoading ? "loading" : error ? "error" : "success",
    isLoading,
    error,
    async set(
      item: { productId: string | undefined },
      newTastingNote: TastingNote
    ) {
      if (!item.productId) return;
      const tastingNoteRef = doc(tastingNotesRef, item?.productId);
      if (!tastingNoteRef) return;
      // Save the tasting note and add to favorites.
      setDoc(tastingNoteRef, newTastingNote);
      favorites.set({ type: "product", id: item.productId });
    },
    unset(item) {
      const tastingNoteRef = doc(tastingNotesRef, item?.productId);
      if (!tastingNoteRef) return;
      deleteDoc(tastingNoteRef);
    },
  };
}

const converter = {
  // This is only needed to get the documents typed.
  toFirestore: (tastingNote: TastingNote): DocumentData => tastingNote,
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<FirestoreTastingNote>,
    options: SnapshotOptions | undefined
  ): TastingNote => {
    const tastingNote = snapshot.data(options);
    return {
      productId: snapshot.id,
      ...tastingNote,
      addedAt: tastingNote.addedAt?.toDate(),
      updatedAt: tastingNote.updatedAt?.toDate(),
    };
  },
};

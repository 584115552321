import Gallery from "@bottlebooks/bottlebooks-site-base/src/components/Gallery/Gallery.next";
import Section from "@bottlebooks/bottlebooks-site-base/src/components/Section/Section";
import { Container } from "@bottlebooks/gatsby-design-system";
import React from "react";

const publicIds = [
  "portwinefest.com/Chicago2024/Chicago-01.jpg",
  "portwinefest.com/Chicago2024/Chicago-02.jpg",
  "portwinefest.com/Chicago2024/Chicago-03.jpg",
  "portwinefest.com/Chicago2024/Chicago-04.jpg",
  "portwinefest.com/Chicago2024/Chicago-05.jpg",
  "portwinefest.com/Chicago2024/Chicago-06.jpg",
  "portwinefest.com/Chicago2024/Chicago-07.jpg",
  "portwinefest.com/Chicago2024/Chicago-08.jpg",
  "portwinefest.com/Chicago2024/Chicago-09.jpg",
];

const images = publicIds.map((id) => ({
  sourceUrl: `//res.cloudinary.com/bottlebooks/image/upload/${id}`,
  // Thumbnail
  image: {
    src: `//res.cloudinary.com/bottlebooks/image/upload/c_fill,w_360,h_360/${id}`,
    sizes: "(max-width: 360px) 100vw, 360px",
    width: null,
    height: null,
    aspectRatio: 1,
  },
  fullPreview: {
    src: `//res.cloudinary.com/bottlebooks/image/upload/w_360,h_360/${id}`,
  },
  full: {
    src: `//res.cloudinary.com/bottlebooks/image/upload/w_1920/${id}`,
  },
}));

export default function PortWineFestImageGalleryChicago2024() {
  return (
    <Container>
      <Section
        title="Gallery"
        sx={{
          paddingY: 3,
          margin: "auto",
          position: "relative",
          width: "100%",
          paddingBottom: "56.25%",
        }}
      >
        <Gallery data={images} />
      </Section>
    </Container>
  );
}

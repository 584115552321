/** @jsxImportSource theme-ui */
import { Flex, Text } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import type { ComponentPropsWithoutRef, ReactNode } from "react";
import { z } from "zod";
import type {
  SignInWithEmailContext,
  SignInWithEmailOptions,
} from "./Login.useState";

type RequestSigninLinkProps = ComponentPropsWithoutRef<typeof Flex> &
  SignInWithEmailOptions &
  SignInWithEmailContext & {
    prepend?: ReactNode;
    append?: ReactNode;
  };

export const emailInitialSchema = z
  .object({
    email: z.string().trim().default(""),
    password: z.string().default(""),
  })
  .passthrough();

export const emailSchema = z.object({
  ...emailInitialSchema.shape,
  email: z.string().trim().email(),
});

export type EmailSchema = z.infer<typeof emailSchema>;

export default function SigninLinkSent({
  prepend,
  append,
  initialValues,
  ...rest
}: RequestSigninLinkProps) {
  return (
    <Flex direction="column" gap={3} sx={{ textAlign: "center" }} {...rest}>
      {/* {prepend} */}
      <Text>
        <Trans>
          A sign-in link has been sent. Please check your email, including your
          spam.
        </Trans>
      </Text>
      {/* {append} */}
    </Flex>
  );
}

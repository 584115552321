/** @jsxImportSource theme-ui */
import { ListContainer } from "@bottlebooks/bottlebooks-site-bookmarks/src";
import { Text } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import ExploreAllProductsBanner from "./ExploreAllProductsBanner";
import React from "react";

/** @param {{ error: Error; [x: string]: any }} props */
export default function Error({ error, ...rest }) {
  return (
    <>
      <ListContainer>
        <Text sx={{ color: "brand.warningRed" }}>
          <Trans>An error has occurred.</Trans>
        </Text>
        <Text variant="small">{error.message}</Text>
      </ListContainer>
      <ExploreAllProductsBanner />
    </>
  );
}

// Create a provider that will be used to pass the event data to the pages.

import React from "react";
import {
  FragmentType,
  useFragment as getFragment,
  graphql,
  useFragment,
} from "~/gql";

const EventDataContext = React.createContext<{
  exhibitors: Array<ReturnType<typeof toExhibitor>>;
  products: Array<ReturnType<typeof toProduct>>;
  producers: Array<ReturnType<typeof toProducer>>;
}>({
  exhibitors: [],
  products: [],
  producers: [],
});

const fragment = graphql(/* GraphQL */ `
  fragment useEventData on Collection {
    collectionId
    ...SiteSearch
    registrations {
      nodes {
        ...toExhibitor
      }
    }
    registeredProducts {
      nodes {
        ...toProduct
        ...toProducer
      }
    }
  }
`);

export default function EventDataProvider({
  children,
  data,
}: {
  children: React.ReactNode;
  data: FragmentType<typeof fragment>;
}) {
  const collection = getFragment(fragment, data);
  const value = collection
    ? {
        exhibitors: collection.registrations.nodes.map(toExhibitor),
        products: collection.registeredProducts.nodes.map(toProduct),
        producers: getProducers(collection.registeredProducts.nodes),
      }
    : {
        exhibitors: [],
        products: [],
        producers: [],
      };
  return (
    <EventDataContext.Provider value={value}>
      {children}
    </EventDataContext.Provider>
  );
}

const toExhibitorFragment = graphql(/* GraphQL */ `
  fragment toExhibitor on SingleRegistration {
    registrationId
    exhibitorId: companyId
    profile {
      name
      countryCode: country(format: RAW)
      stateProvince
      region
      city
      ...BrandLogoLarge
    }
  }
`);

function toExhibitor(data: FragmentType<typeof toExhibitorFragment>) {
  const registration = getFragment(toExhibitorFragment, data);
  return {
    brandId: registration.exhibitorId,
    ...registration,
    ...registration.profile,
  };
}

const toProductFragment = graphql(/* GraphQL */ `
  fragment toProduct on RegisteredProduct {
    productId
    exhibitorId: companyId
    producerId
    registration {
      stand {
        name
      }
      profile {
        name
      }
    }
    product {
      productId
      shortName
      countryName: country
      countryCode: country(format: RAW)
      region
      subregion
      denomination
      producer {
        name
        producerId
      }
      ...ProductImageLarge_list
      ...Search_Product
    }
  }
`);

function toProduct(data: FragmentType<typeof toProductFragment>) {
  const product = getFragment(toProductFragment, data);
  return {
    ...product,
    ...product.product,
    exhibitor: {
      brandId: product.exhibitorId,
      name: product.registration.profile?.name,
    },
  };
}

const toProducerFragment = graphql(/* GraphQL */ `
  fragment toProducer on RegisteredProduct {
    productId
    exhibitorId: companyId
    companyId
    producerId
    product {
      producer {
        name
        producerId
      }
    }
  }
`);

function getProducers(data: Array<FragmentType<typeof toProducerFragment>>) {
  const registeredProducts = useFragment(toProducerFragment, data);
  const producers = registeredProducts.map(toProducer);
  const deduped = producers.reduce<
    Record<string, FragmentType<typeof toProducer>>
  >((acc, producer) => {
    if (!producer?.producerId) return acc;
    if (!acc[producer.producerId]) {
      acc[producer.producerId] = producer;
    }
    return acc;
  }, {});
  return Object.values(deduped);
}

function toProducer(data: FragmentType<typeof toProducerFragment>) {
  const registeredProduct = getFragment(toProducerFragment, data);
  return {
    ...registeredProduct,
    ...registeredProduct.product,
  };
}

export function useEventData() {
  const data = React.useContext(EventDataContext);
  return data;
}

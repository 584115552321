/** @jsxImportSource theme-ui */
import { Box, Grid, Label, Text } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { XCircle } from "@phosphor-icons/react";
import type { ComponentPropsWithoutRef, ElementType, ReactNode } from "react";

type MeetingErrorProps = Omit<ComponentPropsWithoutRef<typeof Grid>, "sx"> & {
  label?: ReactNode;
  as?: ElementType;
};
export default function MeetingError({
  label = <Trans>There was an error</Trans>,
  children,
  ...rest
}: MeetingErrorProps) {
  return (
    <Grid
      sx={{ gridTemplateColumns: "16px auto", columnGap: 2, rowGap: 0 }}
      {...rest}
    >
      <XCircle
        size={16}
        weight="light"
        sx={{ marginTop: 0.5, color: "brand.warningRed" }}
      />
      <Box>
        {label && (
          <Label variant="small" sx={{ color: "inherit", fontWeight: "bold" }}>
            {label}
          </Label>
        )}
        <Text variant="smallest">{children}</Text>
      </Box>
    </Grid>
  );
}

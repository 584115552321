/** @jsxImportSource theme-ui */
import {
  Box,
  Grid,
  Label,
  SemiTitle,
} from "@bottlebooks/gatsby-theme-base/src";
import { Plural } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import useSiteConfig from "../../useSiteConfig";
import BookAMeetingButton from "./BookAMeetingButton";
import Contact from "./Contact.next";

export const fragment = graphql(`
  fragment DistributionContacts on RegisteredBrand {
    companyId
    collectionId
    distributionContacts {
      contactId
      companyId
      fullName
      ...Contact
    }
  }
`);

/**
 * Distribution contacts has 2 modes:
 * - on its own
 * - as part of a card
 * When it is part of a card, the SemiTitle becomes a label and the avatar isn't rendered.
 */
export default function DistributionContacts({
  brand: data,
  variant = "default",
  ...rest
}: {
  brand: FragmentType<typeof fragment>;
  variant?: "default" | "card";
}) {
  const brand = useFragment(fragment, data);
  const { isMeetingBookingEnabled } = useSiteConfig();
  if (!hasContent(data)) return null;
  const count = brand.distributionContacts?.length || 0;
  return (
    <Box {...rest}>
      {variant === "default" ? (
        <SemiTitle sx={{ marginTop: 2 }}>
          <Plural
            value={count}
            one="Distribution Contact"
            other="Distribution Contacts"
          />
        </SemiTitle>
      ) : (
        <Label sx={{ marginTop: 2 }}>
          <Plural
            value={count}
            one="Distribution Contact"
            other="Distribution Contacts"
          />
        </Label>
      )}
      <Grid sx={{ gridTemplateColumns: ["1fr", "1fr 1fr"], gap: ["1px", 3] }}>
        {brand.distributionContacts?.map((contact) => (
          <Contact
            key={contact.contactId}
            variant={variant === "card" ? "simple" : "default"}
            data={contact}
          >
            {isMeetingBookingEnabled ? (
              <BookAMeetingButton
                collectionId={brand.collectionId}
                contact={contact}
                exhibitor={{ exhibitorId: brand.companyId }}
                visibility="ENABLED"
                authentication="OPTIONAL"
                sx={{ marginTop: 2, backgroundColor: "white" }}
              />
            ) : null}
          </Contact>
        ))}
      </Grid>
    </Box>
  );
}

DistributionContacts.hasContent = hasContent;

function hasContent(data: FragmentType<typeof fragment>) {
  const brand = useFragment(fragment, data);
  if (!brand?.distributionContacts?.length) return false;
  return brand?.distributionContacts.some((contact) => {
    // If the brand is also the distribution contact with no further details,
    // we don't count it as having content.
    // We will render it though if there is more than a single contact.
    if (contact.companyId === brand.companyId && !contact.fullName) {
      return false;
    }
    return Contact.hasContent(contact);
  });
}

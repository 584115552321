/** @jsxImportSource theme-ui */
import { P } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import Image from "../Image/Image";
import Section from "../Section/Section";

const fragment = graphql(`
  fragment BrandViticulturist on Registration {
    __typename
    ... on SingleRegistration {
      viticulturistNames: customFieldValue(key: "viticulturistNames") {
        __typename
        ... on TextFieldValue {
          value
        }
      }
      overviewOfViticulturists: customFieldValue(
        key: "overviewOfViticulturists"
      ) {
        __typename
        ... on TextFieldValue {
          value
        }
      }
      imageOfViticulturists: customFieldValue(key: "imageOfViticulturists") {
        __typename
        ... on ImageFieldValue {
          value {
            publicId
          }
        }
      }
    }
  }
`);

function useData(data: FragmentType<typeof fragment>) {
  const registration = useFragment(fragment, data);
  if (registration.__typename !== "SingleRegistration") return null;
  if (registration.viticulturistNames?.__typename !== "TextFieldValue")
    return null;
  if (!registration.viticulturistNames.value) return null;
  return {
    ...registration,
    viticulturistNames: registration.viticulturistNames.value,
  };
}

export default function BrandViticulturist({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const registration = useData(data);
  if (!registration) return null;
  return (
    <Section title={<Trans>Viticulturist</Trans>} {...rest}>
      {<P variant="title">{registration.viticulturistNames}</P>}
      {registration.imageOfViticulturists?.__typename === "ImageFieldValue" &&
      registration.imageOfViticulturists?.value?.publicId ? (
        <Image
          publicId={registration.imageOfViticulturists?.value?.publicId}
          alt={registration.viticulturistNames}
          layout="fixed"
          options={{
            width: 780,
            height: 390,
            crop: "fill",
            gravity: "faces:auto",
            fit: "cover",
          }}
          sizes="(min-width: 48em) 780px, 100vw"
          sx={{
            maxWidth: "100%",
            height: "auto",
            marginY: 3,
            objectFit: "cover",
          }}
        />
      ) : null}
      {registration.overviewOfViticulturists?.__typename === "TextFieldValue"
        ? registration.overviewOfViticulturists?.value
            ?.split("\n\n")
            .map((p, index) => (
              <P variant="large" key={index} sx={{ color: "black" }}>
                {p}
              </P>
            ))
        : null}
    </Section>
  );
}

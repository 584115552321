/** @jsxImportSource theme-ui */
import React from "react";
import { FragmentType, graphql, useFragment } from "~/gql";
import BrandName from "./BrandName.next";
import BrandRegion from "./BrandRegion.next";

const fragment = graphql(`
  fragment BrandHeader on Profile {
    ...BrandName
    ...BrandRegion
  }
  # fragment BrandHeader on AbstractBrand {
  #   ...BrandName
  #   ...BrandRegion
  # }
  # fragment bb_BrandHeader_RegisteredBrand on Bottlebooks_RegisteredBrand {
  #   profile {
  #     ...BrandName
  #     ...BrandRegion
  #   }
  # }
`);

export default function BrandHeader({
  data,
  onDark = false,
  titleVariant = "small",
  gap = 2,
  as,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
  onDark?: boolean;
  titleVariant?: string;
  gap?: number;
  as?: React.ReactNode;
}) {
  const brand = useFragment(fragment, data);
  return (
    <React.Fragment>
      <BrandName
        as={as}
        data={brand || {}}
        variant={titleVariant}
        sx={{ paddingBottom: gap }}
        {...rest}
      />
      <BrandRegion data={brand} sx={{ color: "text" }} />
    </React.Fragment>
  );
}

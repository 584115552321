import { defineMessage } from "@lingui/macro";
import { graphql } from "~/gql";
import type {
  FilterDefinitions,
  Aggregation,
} from "@bottlebooks/bottlebooks-site-base/src/components/Filters/useIndex";
import { rankings } from "match-sorter";
import useSiteConfig from "../useSiteConfig";

// TODO generate this automatically from the fragment.
interface Producer {
  event?: { name?: string } | null;
  region?: string | null;
  city?: string | null;
  countryName?: string | null;
  isSeekingDistribution: boolean | null;
  profile?: {
    distributionInCountries?: (string | null)[] | null;
    seekingDistributionInCountries?: (string | null)[] | null;
  } | null;
  stand?: { name?: string | null } | null;
}

export default function useProducerFilters(): FilterDefinitions<Producer> {
  const { showDistributionDetails } = useSiteConfig();
  return {
    aggregations: {
      event: {
        title: defineMessage({ message: "Events" }),
        // multiSelect: false, // TODO Implement
        size: 20,
        get: (producer) => producer.event?.name,
      },
      highlights: {
        title: defineMessage({ message: "Highlights" }),
        // multiSelect: false, // TODO Implement
        size: 20,
        get: "offeringTypes",
        translations: {
          videos: defineMessage({ message: "Videos" }),
          onlineShop: defineMessage({ message: "Online shop" }),
          imageGallery: defineMessage({ message: "Image gallery" }),
        },
      },
      countryName: {
        title: defineMessage({ message: "Countries" }),
        size: 100,
        conjunction: false,
        // Remove null values
        get: (producer) => producer.countryName || [],
      },
      ...getDistributionFilters(showDistributionDetails),
      priceRanges: {
        title: defineMessage({ message: "Price ranges" }),
        size: 100,
        conjunction: false,
      },
    },

    sortings: {
      byStandName: {
        title: defineMessage({ message: "Table number" }),
        field: [sortBy.standName, "profile.sortName", "name"],
        order: "asc",
      },
      // byDataCompleteness: {
      //   title: defineMessage({ message: 'By data completeness' }),
      //   field: [
      //     sortBy.standName,
      //     sortBy.dataCompleteness,
      //     'profile.sortName',
      //     'name',
      //   ],
      //   order: 'asc',
      // },
      byProducerName: {
        title: defineMessage({ message: "Producer name" }),
        field: ["profile.sortName", "name"],
        order: "asc",
      },
    },
    search: {
      keys: [
        { key: "name", threshold: rankings.CONTAINS },
        (producer) =>
          `${producer.countryName || ""} ${producer.region || ""} ${
            producer.city || ""
          }`.trim(),
        (producer) => producer?.stand?.name?.replace(/[()]/g, "") || "",
      ],
    },
  };
}

function getDistributionFilters(
  showDistributionDetails: "inMarket" | "global" | "all" | "none"
): Record<string, Aggregation<Producer>> {
  switch (showDistributionDetails) {
    case "inMarket":
      return { isSeekingDistribution };

    case "global":
      return {
        distributionInCountries,
        seekingDistributionInCountries,
      };

    case "all":
      return {
        isSeekingDistribution,
        distributionInCountries,
        seekingDistributionInCountries,
      };

    case "none":
    default:
      return {};
  }
}

const distributionInCountries: Aggregation<Producer> = {
  title: defineMessage({ message: "Distribution in countries" }),
  size: 200,
  conjunction: true,
  multiSelect: true,
  get: (exhibitor) =>
    (exhibitor.profile?.distributionInCountries?.filter(Boolean) as string[]) ||
    [],
};

const seekingDistributionInCountries: Aggregation<Producer> = {
  title: defineMessage({ message: "Seeking distribution" }),
  size: 200,
  conjunction: true,
  multiSelect: true,
  get: (exhibitor) =>
    (exhibitor.profile?.seekingDistributionInCountries?.filter(
      Boolean
    ) as string[]) || [],
};

const isSeekingDistribution: Aggregation<Producer> = {
  title: defineMessage({ message: "Distribution in Market" }),
  size: 100,
  conjunction: false,
  get: (exhibitor) => String(exhibitor.isSeekingDistribution || false),
  translations: {
    true: defineMessage({ message: "Seeking Distribution" }),
    false: defineMessage({ message: "Has Distribution" }),
  },
};

const completeness = {
  ORGANIZER_REVIEWED: 1,
  STEP_COMPLETED: 2,
  STEP_PAYMENT: 2,
  STEP_REVIEW: 3,
};

// Sort helper functions
const sortBy = {
  standName: (producer: Producer) => {
    return (
      (producer?.stand?.name
        ?.split(/[\s,-]+/)
        .map((segment) =>
          segment === "Tisch"
            ? undefined
            : Number(segment)
            ? segment.padStart(3, "0")
            : segment
        )
        .filter(Boolean) as string[]) || []
    );
  },
  dataCompleteness: (producer) =>
    completeness[producer?.registrationStatus] -
    (producer?.registeredProducts?.totalCount > 0 ? 1 : 0),
};

export const fragment = graphql(/* GraphQL */ `
  fragment producerMultiEventFilter on Producer {
    event {
      eventId
      name
    }
  }
  fragment producerFilter_Producer on Producer {
    isSeekingDistribution
  }
  fragment producerFilters on Bottlebooks_SingleRegistration {
    companyId
    stand {
      name
    }
    registrationStatus
    registeredProducts {
      totalCount
    }
    profile {
      name
      sortName
      countryName: country(format: LOCALIZED)
      region
      city
      distributionInCountries
      seekingDistributionInCountries
      # priceRanges
      sortName
    }
  }
  #fragment producerFilters_RegisteredBrand on Bottlebooks_RegisteredBrand {
  #  isSeekingDistribution
  #}
`);

/** @jsxImportSource theme-ui */
import {
  Badge,
  Box,
  Container,
  Flex,
  Text,
  Title,
} from "@bottlebooks/gatsby-design-system";
import badgeVariants from "@bottlebooks/gatsby-design-system/src/components/Badge/badgeVariants";
import {
  Clock,
  Coffee,
  ForkKnife,
  MapPin,
  Users,
  Wine,
} from "@phosphor-icons/react";
import React from "react";
import { CallToAction } from "../components";

export default function Schedule() {
  const schedules = [day1Schedule, day2Schedule, day3Schedule];
  const [days, setDays] = React.useState<number[]>([
    ...Array(schedules.length).keys(),
  ]);
  return (
    <Container>
      <Flex justify="center" gap={2} sx={{ marginY: 3 }}>
        <Title>Schedule</Title>
      </Flex>

      <Flex
        justify="center"
        gap={2}
        sx={{ marginY: 3, flexGrow: 1, flexWrap: "wrap" }}
      >
        {days.length && (
          <Badge
            onClick={() => setDays([...Array(schedules.length).keys()])}
            sx={{
              display: "block",
              flexGrow: 0,
              ...(days.length === schedules.length
                ? badgeVariants.primary
                : badgeVariants.default),
              margin: 0,
              paddingX: 3,
              paddingY: 2,
              fontSize: 14,
              marginBottom: 2,
            }}
          >
            All days
          </Badge>
        )}
        {schedules.map((schedule, i) => (
          <Badge
            key={schedule.day}
            onClick={() => setDays([i])}
            sx={{
              display: "block",
              flexGrow: 0,
              ...(days.length === 1 && days[0] === i
                ? badgeVariants.primary
                : badgeVariants.default),
              margin: 0,
              paddingX: 3,
              paddingY: 2,
              fontSize: 14,
              marginBottom: 2,
            }}
          >
            {schedule.day}
          </Badge>
        ))}
      </Flex>

      <Flex
        direction={["column", null, "row"]}
        justify="center"
        align={["center", null, "flex-start"]}
        sx={{ marginY: 3 }}
      >
        {days.map((day, i) => {
          const schedule = schedules[day];
          return (
            <Flex
              direction="column"
              align="center"
              gap={2}
              sx={{
                marginY: 3,
                maxWidth: 500,
                flexGrow: 1,
                width: "100%",
                height: "100%",
                // Work around for gap not working for Flex in responsive design
                marginRight: [0, null, i < days.length - 1 ? 2 : 0],
              }}
            >
              <Box
                sx={{
                  position: "sticky",
                  top: 0,
                  zIndex: 100,
                  background: "white",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Title variant="small">{schedule.day}</Title>
              </Box>
              {schedule.slots.map((slot, i) => {
                switch (slot.type) {
                  case "coffee":
                    return <StandardSlot slot={slot} key={i} />;
                  default:
                    return <StandardSlot slot={slot} key={i} />;
                }
              })}
            </Flex>
          );
        })}
      </Flex>
    </Container>
  );
}

function StandardSlot({ slot }: { slot: ScheduleSlot }) {
  return (
    <SessionSlot
      sx={{
        width: "100%",
        backgroundColor: ["lunch", "coffee"].includes(slot.type ?? "")
          ? "light"
          : undefined,
      }}
    >
      <Box sx={{ marginBottom: 2 }}>
        <Flex gap={1} align="flex-start">
          <TypeIcon type={slot.type} sx={{ marginTop: "3px" }} />
          <Text variant="large" sx={{ fontWeight: 600 }}>
            {slot.title}
          </Text>
        </Flex>
        <Text>{slot.description}</Text>
      </Box>
      <Flex direction="column" gap={1}>
        {slot.time && (
          <Flex align="center" gap={2}>
            <Clock size={16} />
            <Text>{slot.time}</Text>
          </Flex>
        )}
        {slot.location && (
          <Flex align="center" gap={2}>
            <MapPin size={16} />
            <Text>{slot.location}</Text>
          </Flex>
        )}
        {slot.speakers?.length > 0 && (
          <Box>
            <Flex align="center" gap={2}>
              <Users size={16} />
              <Text>Speakers</Text>
            </Flex>
            <Flex sx={{ paddingLeft: 24, flexWrap: "wrap", marginTop: -1 }}>
              {slot.speakers.map((speaker, i) => (
                <Box
                  key={i}
                  sx={{ width: ["100%", null, "50%"], marginTop: 1 }}
                >
                  <Text sx={{ fontWeight: 600 }}>{speaker.displayName}</Text>
                  {speaker.jobTitle && (
                    <Text variant="smallest" sx={{ color: "lightText" }}>
                      {speaker.jobTitle}
                    </Text>
                  )}
                  {speaker.companyName && (
                    <Text variant="smallest" sx={{ color: "lightText" }}>
                      {speaker.companyName}
                    </Text>
                  )}
                </Box>
              ))}
            </Flex>
          </Box>
        )}
        {slot.page && (
          <Box sx={{ marginLeft: "24px" }}>
            <CallToAction
              page={slot.page}
              label="See wines"
              variant="outline"
            />
          </Box>
        )}
      </Flex>
    </SessionSlot>
  );
}

function TypeIcon({ type, ...rest }: { type: ScheduleSlot["type"] }) {
  switch (type) {
    case "coffee":
      return <Coffee size={20} {...rest} weight="light" />;
    case "lunch":
      return <ForkKnife size={20} {...rest} weight="light" />;
    case "tasting":
      return <Wine size={20} {...rest} weight="light" />;
    default:
      return null;
  }
}

function SessionSlot({ children, ...rest }: { children: React.ReactNode }) {
  return (
    <Box
      sx={{ border: "1px solid #efefef", padding: 2, borderRadius: 6 }}
      {...rest}
    >
      {children}
    </Box>
  );
}

const day1Schedule: Schedule = {
  day: "September 4th, 2024",
  slots: [
    {
      title: "Welcome Reception",
      time: "6:30 - 8:30 pm",
      location: "Palme d'Or",
      page: {
        __typename: "Page",
        slug: {
          current: "welcome-reception",
        },
      },
    },
  ],
};
const day2Schedule: Schedule = {
  day: "September 5th, 2024",
  slots: [
    {
      title: "Meet the Family",
      time: "9:00 - 9:30 am",
      location: "Granada",
      speakers: [
        {
          displayName: "Christopher Jackson",
          jobTitle: "Proprietor",
        },
        {
          displayName: "Bill Blais",
          jobTitle: "VP of Sales and Distribution",
        },
      ],
    },
    {
      title: "Beyond the Headlines, Industry Update",
      time: "9:30 - 10:00 am",
      location: "Granada",
      speakers: [
        {
          displayName: "Shilah Salmon",
          jobTitle: "SVP Brand Marketing",
        },
      ],
    },
    {
      title: "Break",
      type: "coffee",
      time: "10:15 - 10:45 am",
      location: "Majorca",
    },
    {
      title: "Sonoma County Terroir",
      time: "10:45 - 12:00 pm",
      location: "Granada",
      speakers: [
        {
          displayName: "Christopher Jackson",
          jobTitle: "Proprietor",
        },
        {
          displayName: "Dimitri Mesnard",
          jobTitle: "Master Sommelier",
        },
        {
          displayName: "Sally Burton",
          jobTitle: "SVP International Marketing",
        },
        {
          displayName: "Shilah Salmon",
          jobTitle: "SVP Brand Marketing",
        },
      ],
      page: {
        __typename: "Page",
        slug: {
          current: "sonoma-county-terroir-wines-of-pierre-and-helene-seillan",
        },
      },
    },
    {
      title: "Rooted for Good",
      time: "12:00 - 12:30 pm",
      location: "Granada",
      speakers: [
        {
          displayName: "Sean Carroll",
          jobTitle: "Director of Communications",
        },
      ],
    },
    {
      title: "Lunch",
      type: "lunch",
      time: "12:45 - 1:45 pm",
      location: "Palme d'Or",
    },
    {
      title: "Mountain Winemaking",
      time: "1:45 - 3:15 pm",
      location: "Granada",
      speakers: [
        {
          displayName: "Christopher Jackson",
          jobTitle: "Proprietor",
        },
        {
          displayName: "Dimitri Mesnard",
          jobTitle: "Master Sommelier",
        },
        {
          displayName: "Sally Burton",
          jobTitle: "SVP International Marketing",
        },
        {
          displayName: "Shilah Salmon",
          jobTitle: "SVP Brand Marketing",
        },
      ],
      page: {
        __typename: "Page",
        slug: {
          current: "mountain-winemaking-wines-of-christopher-carpenter",
        },
      },
    },
    {
      title: "Point of Sale Showcase",
      time: "3:00 - 3:45 pm",
      location: "Granada",
      speakers: [],
    },
    {
      title: "Stonestreet Mountain Estate, Dinner",
      time: "Board buses 5:30 pm",
      location: "Lafayette",
      page: {
        __typename: "Page",
        slug: {
          current: "stonestreet-mountain-estate",
        },
      },
    },
  ],
};
const day3Schedule: Schedule = {
  day: "September 6th, 2024",
  slots: [
    {
      title: "Coastal Expressions",
      time: "10:00 - 11:00 am",
      location: "Granada",
      page: {
        __typename: "Page",
        slug: {
          current:
            "coastal-expressions-of-chardonnay-and-pinot-noir-oregon-and-california",
        },
      },
      speakers: [
        {
          displayName: "Dimitri Mesnard",
          jobTitle: "Master Sommelier",
        },
        {
          displayName: "Peter Repole",
          jobTitle: "SVP International Sales",
        },
        {
          displayName: "Sally Burton",
          jobTitle: "SVP International Marketing",
        },
        {
          displayName: "Julieta Pinon",
          jobTitle: "Sales Director",
        },
      ],
    },
    {
      title: "Break",
      type: "coffee",
      time: "11:00 - 11:30 am",
      location: "Majorca",
    },
    {
      title: "Freemark Abbey, Napa Valley Original",
      time: "11:30 - 1:00 pm",
      location: "Granada",
      speakers: [
        {
          displayName: "Dimitri Mesnard",
          jobTitle: "Master Sommelier",
        },
        {
          displayName: "Peter Repole",
          jobTitle: "SVP International Sales",
        },
        {
          displayName: "Sally Burton",
          jobTitle: "SVP International Marketing",
        },
        {
          displayName: "Julieta Pinon",
          jobTitle: "Sales Director",
        },
      ],
      page: {
        __typename: "Page",
        slug: {
          current: "freemark-abbey-a-napa-valley-original-est-1886",
        },
      },
    },
    {
      title: "Lunch",
      type: "lunch",
      time: "1:00 - 2:00 pm",
      location: "Granada Loggia",
    },
    {
      title: "International Brand Update",
      time: "2:00 - 3:00 pm",
      location: "Granada",
      speakers: [
        {
          displayName: "Sally Burton",
          jobTitle: "SVP International Marketing",
        },
      ],
    },
    {
      title: "Passport to the World",
      time: "3:00 - 4:00 pm",
      location: "Granada",
      description: "Walk Around Tasting",
      page: {
        __typename: "Page",
        slug: {
          current: "passport-to-the-world-international-walk-around-tasting",
        },
      },
    },
    {
      title: "Spritz & Big Bottle Party",
      time: "Board buses at 5:30 pm",
      location: "Bayshore Club",
      page: {
        __typename: "Page",
        slug: {
          current: "farewell-big-bottle-party",
        },
      },
    },
  ],
};

// Type definitions

type Schedule = {
  day: string;
  startTime: string;
  endTime: string;
  slots: ScheduleSlot[];
};

type ScheduleSlot = {
  type?: "coffee" | "session" | "tasting" | "lunch";
  title: string;
  description?: string;
  time: string;
  location: string;
  page?: {
    __typename: "Page";
    slug: { current: string };
  };
  speakers?: {
    displayName: string;
    jobTitle?: string;
    companyName?: string;
  }[];
};

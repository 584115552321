/** @jsxImportSource theme-ui */
import { Button, Grid } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { InView } from "react-intersection-observer";

/**
 *
 * @param {{
 * children: React.ReactNode;
 * hasMore?: boolean;
 * showMore?: () => void;
 * width?: number;
 * }} props
 * @returns
 */
export default function ProducersList({
  children,
  hasMore,
  showMore,
  ...rest
}) {
  return (
    <Grid gap={3} repeat="fill" width={250} {...rest}>
      {children}
      {hasMore && showMore && (
        <InView
          onChange={(inView) => {
            if (inView) showMore();
          }}
          sx={{ marginTop: "-100vh", paddingTop: "100vh", gridColumn: "1/-1" }}
        >
          <Button variant="primary" onClick={showMore} sx={{ paddingY: 3 }}>
            <Trans>Show more</Trans>
          </Button>
        </InView>
      )}
    </Grid>
  );
}

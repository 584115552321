/** @jsxImportSource theme-ui */
import type { Flex, Text } from "@bottlebooks/gatsby-design-system";
import { Box } from "@bottlebooks/gatsby-design-system";
import type { ComponentProps, ReactNode } from "react";
import UserProfileCardActions from "./UserProfileCard.Actions";
import UserProfileCardAvatar, {
  UserProfileCardAvatarSkeleton,
} from "./UserProfileCard.Avatar.next";
import UserProfileCardCompany, {
  UserProfileCardCompanySkeleton,
} from "./UserProfileCard.Company.next";
import UserProfileCardDisplayName, {
  UserProfileCardDisplayNameSkeleton,
} from "./UserProfileCard.DisplayName.next";
import UserProfileCardHorizontal, {
  UserProfileCardHorizontalSkeleton,
} from "./UserProfileCard.Horizontal.next";
import UserProfileCardLinkedIn, {
  UserProfileCardLinkedInSkeleton,
} from "./UserProfileCard.LinkedIn.next";
import UserProfileCardMain from "./UserProfileCard.Main";
import { graphql } from "~/gql";
import { gql } from "@apollo/client";

export type UserProfileCardProps = BoxProps &
  UserProfileProps & {
    shadow?: "none" | "smallest" | "small" | "xs" | "sm";
    direction?: keyof typeof cardDirections;
    children: ReactNode;
  };

const cardDirections = {
  horizontal: {
    display: "grid",
    gridTemplateColumns: "100px 1fr",
    padding: 3.5,
    textAlign: "left",
    columnGap: 3.5,
    rowGap: 0,
  },
  vertical: {
    display: "flex",
    flexDirection: "column",
    rowGap: 2,
    padding: 2.5,
    paddingTop: 3.5,
    textAlign: "center",
    alignItems: "center",
  },
} as const;

export type FlexProps = Omit<ComponentProps<typeof Flex>, "children" | "sx">;
type BoxProps = Omit<ComponentProps<typeof Box>, "children" | "sx">;
export type TextProps = Omit<ComponentProps<typeof Text>, "children" | "sx">;

/** A composable card for a user profile. */
export default function UserProfileCard({
  profile,
  shadow,
  direction = "vertical",
  children,
  ...rest
}: UserProfileCardProps) {
  if (!UserProfileCard.hasContent(profile)) return null;
  return (
    <Box
      sx={{
        ...cardDirections[direction],
        transition: "backgroundColor",
        boxShadow: shadow,
        borderRadius: "default",
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}

UserProfileCard.hasContent = function hasContent(
  profile: UserProfileCardData | null | undefined
) {
  if (!profile) return false;
  if (profile.displayName) return true;
  if (profile.companyName) return true;
  return false;
};

// UserProfileCard.fragment = gql`
//   fragment UserProfileCard on SiteUserProfile {
//     displayName
//     companyName
//   }
// `;

export type UserProfileCardData = {
  displayName?: string | null;
  companyName?: string | null;
};

export interface UserProfileProps {
  profile: UserProfileCardData | null | undefined;
}
UserProfileCard.Avatar = UserProfileCardAvatar;
UserProfileCard.Main = UserProfileCardMain;
UserProfileCard.DisplayName = UserProfileCardDisplayName;
UserProfileCard.Company = UserProfileCardCompany;
UserProfileCard.LinkedIn = UserProfileCardLinkedIn;
UserProfileCard.Actions = UserProfileCardActions;
UserProfileCard.Horizontal = UserProfileCardHorizontal;

export function UserProfileCardSkeleton({
  shadow,
  direction = "vertical",
  children,
  ...rest
}: Omit<UserProfileCardProps, "profile">) {
  return (
    <Box
      sx={{
        ...cardDirections[direction],
        transition: "backgroundColor",
        boxShadow: shadow,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}
UserProfileCardSkeleton.Avatar = UserProfileCardAvatarSkeleton;
UserProfileCardSkeleton.Main = UserProfileCardMain;
UserProfileCardSkeleton.DisplayName = UserProfileCardDisplayNameSkeleton;
UserProfileCardSkeleton.Company = UserProfileCardCompanySkeleton;
UserProfileCardSkeleton.LinkedIn = UserProfileCardLinkedInSkeleton;
UserProfileCardSkeleton.Actions = UserProfileCardActions;
UserProfileCardSkeleton.Horizontal = UserProfileCardHorizontalSkeleton;

// export const fragment = graphql(`
//   fragment UserProfileCard on Bottlebooks_SiteUserProfile {
//     uid
//     displayName
//     jobTitle
//     companyName
//     linkedInURL
//     profileImageUrl
//     profileImage {
//       url
//       publicId
//     }
//   }
// `);

UserProfileCard.fragment = gql`
  fragment UserProfileCard on SiteUserProfile {
    uid
    displayName
    jobTitle
    companyName
    linkedInURL
    profileImageUrl
    profileImage {
      url
      publicId
    }
  }
`;

export type Role = "REPRESENTATIVE" | "VISITOR" | "ANONYMOUS";

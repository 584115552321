import React from "react";
import { graphql } from "~/gql";
import useExhibitorSearch from "../ExhibitorsPage/useExhibitorSearch.next";
import useProductSearch from "../ProductsPage/useProductSearch.next";
import { useExhibitors, useProducts } from "./useEventData";

const fragment = graphql(/* GraphQL */ `
  fragment useSiteSearch on Collection {
    registrations {
      nodes {
        __typename
        companyId
        ... on SingleRegistration {
          ...Search_Exhibitor
          profile {
            name
            logo {
              publicId
            }
          }
        }
      }
    }
    registeredProducts {
      nodes {
        companyId
        productId
        product {
          ...Search_Product
          shortName
          ...ProductImageLarge_list
          ... on Wine {
            vintage(removeNonVintage: true)
          }
        }
      }
    }
  }
`);

export default function useSiteSearch({
  initialTerm,
}: {
  initialTerm: string;
}) {
  const [term, setTerm] = React.useState(initialTerm);
  const exhibitors = useExhibitors();
  const products = useProducts();
  const filteredExhibitors = useExhibitorSearch(exhibitors.all, term);
  const filteredProducts = useProductSearch(products.all, term);
  return {
    exhibitors: filteredExhibitors,
    products: filteredProducts,
    term,
    setTerm,
  };
}

/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  Flex,
  Link,
  SemiTitle,
  Text,
} from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";

const fragment = graphql(`
  fragment BrandShopsSection on Profile {
    onlineShopUrl: onlineShop(format: URL)
    telephoneOrders(format: INTERNATIONAL)
    telephoneOrdersUrl: telephoneOrders(format: URL)
    emailOrdersUrl: emailOrders(format: URL)
    onlineShops {
      name
      url: url(format: URL)
      linkText: url(format: TEXT)
      country
    }
    physicalShops {
      name
      url(format: URL)
      linkText: url(format: TEXT)
      street
      postalCode
      city
      country
    }
  }
`);

export default function BrandShopsSection({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const brand = useFragment(fragment, data);
  if (!brand) return null;
  if (!hasOwnShop(data) && !hasOtherShops(data)) return null;
  return (
    <Section title="" {...rest}>
      <OwnShop data={data} />
      <OtherShops data={data} />
    </Section>
  );
}

function hasOwnShop(data: FragmentType<typeof fragment>) {
  const brand = useFragment(fragment, data);
  const { telephoneOrders, emailOrdersUrl } = brand;
  return Boolean(telephoneOrders || emailOrdersUrl);
}

function OwnShop({ data }: { data: FragmentType<typeof fragment> }) {
  const brand = useFragment(fragment, data);
  if (!hasOwnShop(data)) return null;
  const { telephoneOrders: phoneNumber, emailOrdersUrl } = brand;

  return (
    <Box>
      <SemiTitle>
        <Trans>Consumer sales</Trans>
      </SemiTitle>
      <Flex wrap="wrap" gap={3}>
        {phoneNumber && (
          <Button
            variant="outline"
            href={brand.telephoneOrdersUrl}
            sx={{ marginY: 2 }}
          >
            <Trans>Call {phoneNumber}</Trans>
          </Button>
        )}
        {emailOrdersUrl && (
          <Button variant="outline" href={emailOrdersUrl} sx={{ marginY: 2 }}>
            <Trans>Order via email</Trans>
          </Button>
        )}
      </Flex>
    </Box>
  );
}

function hasOtherShops(data: FragmentType<typeof fragment>) {
  const brand = useFragment(fragment, data);
  const { onlineShops, physicalShops } = brand;
  return Boolean(onlineShops?.length || physicalShops?.length);
}
function OtherShops({ data }: { data: FragmentType<typeof fragment> }) {
  const brand = useFragment(fragment, data);
  if (!hasOtherShops(data)) return null;

  return (
    <Flex wrap="wrap">
      <OnlineShops
        onlineShops={brand.onlineShops}
        sx={{ width: ["100%", "50%"], paddingRight: [0, 3] }}
      />
      <PhysicalShops
        physicalShops={brand.physicalShops}
        sx={{ width: ["100%", "50%"] }}
      />
    </Flex>
  );
}

function OnlineShops({ onlineShops, ...rest }) {
  if (!onlineShops?.length) return null;
  return (
    <Box {...rest}>
      <SemiTitle>
        <Trans>Buy online</Trans>
      </SemiTitle>
      {onlineShops.map(({ name, url, linkText, country }) => (
        <Box key={url} sx={{ marginY: 3 }}>
          <Text>{name}</Text>
          <Text>
            <Link href={url}>{linkText}</Link>
          </Text>
          <Text>{country}</Text>
        </Box>
      ))}
    </Box>
  );
}

function PhysicalShops({ physicalShops, ...rest }) {
  if (!physicalShops?.length) return null;
  return (
    <Box {...rest}>
      <SemiTitle>
        <Trans>Buy near you</Trans>
      </SemiTitle>
      {physicalShops.map(
        ({ name, url, linkText, street, postalCode, city, country }) => (
          <Box key={name} sx={{ marginY: 3 }}>
            <Text>{name}</Text>
            <Text>
              <Link href={url}>{linkText}</Link>
            </Text>
            <Text>{street}</Text>
            <Text>
              {postalCode} {city}
            </Text>
            <Text>{country}</Text>
          </Box>
        )
      )}
    </Box>
  );
}

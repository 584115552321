/** @jsxImportSource theme-ui */
import React, { ComponentProps, HTMLProps } from "react";
import Link from "../Link/Link";
import { ButtonVariant } from "./buttonVariants";
type ButtonProps = Omit<ComponentProps<typeof Link>, "variant"> & {
  variant?: ButtonVariant;
};

function Button<T>(
  {
    onClick,
    to,
    href,
    children,
    variant = "default",
    fallback = "button",
    ...rest
  }: ButtonProps,
  ref: React.ForwardedRef<T>
) {
  return (
    <Link
      onClick={onClick}
      to={to}
      href={href}
      ref={ref}
      sx={{ variant: `button.${variant}` }}
      variant="button"
      fallback={fallback}
      type="button"
      {...rest}
    >
      {children}
    </Link>
  );
}

export default React.forwardRef(Button);

/** @jsxImportSource theme-ui */
import { Grid, Section } from "@bottlebooks/gatsby-theme-base/src";
import { Plural } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import DistributionContacts from "./BrandContacts/DistributionContacts.next";
import PresentedBrand from "./PresentedBrand.next";

export const fragment = graphql(`
  fragment PresentedBrands on RegisteredBrand {
    companyId
    brandId
    ...DistributionContacts
    ...RegisteredBrand_PresentedBrand
  }
`);

export default function PresentedBrands({
  data,
}: {
  data: readonly FragmentType<typeof fragment>[];
}) {
  const brands = useFragment(fragment, data);
  // This should only happen if there are no products registered.
  if (!hasContent(brands)) return null;
  // We don't need to show the brand if it's the only brand and also the exhibitor.
  // Also, this only displays when there are distribution contacts active for an event.
  if (brands.length === 1 && brands[0]?.companyId === brands[0]?.brandId) {
    return (
      <Section title="">
        <DistributionContacts brand={brands[0]} />
      </Section>
    );
  }
  return (
    <Section
      title={
        <Plural
          comment="If there is a single brand only, we want to hide the title. So leave the title empty for a single brand."
          value={brands.length}
          one=""
          other="Our Brands"
        />
      }
    >
      <Grid sx={{ gridTemplateColumns: ["1fr", "1fr 1fr"], gap: ["1px", 3] }}>
        {brands?.map((brand) => (
          <PresentedBrand key={brand.brandId} data={brand} />
        ))}
      </Grid>
    </Section>
  );
}
PresentedBrands.hasContent = hasContent;

function hasContent(brands) {
  if (!brands?.length) return false;
  // We assume the brands always have some valid data (the brand name should be mandatory).
  return true;
}

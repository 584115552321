/** @jsxImportSource theme-ui */
import { Box } from "@bottlebooks/gatsby-design-system";
import type { ComponentPropsWithRef, ReactNode } from "react";

type DialogInfoPanelProps = Omit<
  ComponentPropsWithRef<typeof Box>,
  "children" | "sx"
> & {
  children: ReactNode;
};

/**
 * An info panel inside of a dialog.
 * Used in the Networking dialogs (RequestMeeting, RequestChat, etc.).
 */
export default function DialogInfoPanel({ children }: DialogInfoPanelProps) {
  return (
    <Box
      sx={{
        paddingRight: [0, 3.5],
        borderRight: [0, 1],
        borderBottom: [1, 0],
        borderColor: ["border", "border"],
        marginBottom: [3.5, 0],
        paddingBottom: [3.5, 0],
      }}
    >
      {children}
    </Box>
  );
}

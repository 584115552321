/** @jsxImportSource theme-ui */
import { ListContainer } from "@bottlebooks/bottlebooks-site-bookmarks/src";
import { Button, P, Title } from "@bottlebooks/gatsby-theme-base/src";
import useLink from "@bottlebooks/bottlebooks-site-base/src/useLink";
import { Trans } from "@lingui/macro";

/**
 * @param {JSX.IntrinsicAttributes} rest
 */
export default function EmptyState(rest) {
  const link = useLink();
  return (
    <ListContainer
      sx={{ textAlign: [null, "center"], paddingX: "gutter" }}
      {...rest}
    >
      <Title sx={{ paddingTop: 5, marginBottom: 4 }}>
        <Trans>No products saved.</Trans>
      </Title>
      <P variant="large" sx={{ margin: "auto" }}>
        <Trans>
          You haven't saved any products to taste. Start your favorites list by
          saving some products.
        </Trans>
      </P>
      <Button variant="primary" to={link.products()} sx={{ marginTop: 4 }}>
        <Trans>Explore all products</Trans>
      </Button>
    </ListContainer>
  );
}

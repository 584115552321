import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { z } from "zod";
import { bbCollectionIdSchema } from "~/bbCollectionIdSchema";
import { graphql } from "~/gql";
import graphQLClient from "~/graphQLClient";

const paramsSchema = z.object({
  collectionId: z.string(),
  "*": z.string(),
});

const localeSchema = z.enum(["en", "de", "es", "fr"]);

export async function Loader(args: LoaderFunctionArgs) {
  const parsedBrowserLocale = localeSchema.safeParse(
    navigator.language.split("-")[0]
  );
  const browserLocale = parsedBrowserLocale.success
    ? parsedBrowserLocale.data
    : undefined;
  const params = paramsSchema.parse(args.params);
  const { collectionId: collectionIdSegment } = params;
  const collectionId = bbCollectionIdSchema.parse(collectionIdSegment);
  const result = await graphQLClient.request(
    graphql(/* GraphQL */ `
      query CollectionLocale($collectionId: ID!) {
        collection(collectionId: $collectionId) {
          collectionId
          locales(format: RAW)
        }
      }
    `),
    { collectionId },
    { "bottlebooks-use-request-cache": "true" }
  );

  const isSupportedLocale =
    browserLocale && result.collection?.locales.includes(browserLocale);
  const resolvedLocale = isSupportedLocale
    ? browserLocale
    : result.collection?.locales[0];
  return redirect(
    `/${resolvedLocale}/collections/${collectionIdSegment}/${params["*"]}`
  );
}

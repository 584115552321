/** @jsxImportSource theme-ui */
import { Box, Link, SemiTitle, Text } from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";
import FacebookVideo from "./FacebookVideo";
import InstagramVideo from "./InstagramVideo";
import PolyvideoVideo from "./PolyvideoVideo";
import VimeoVideo from "./VimeoVideo";
import YouTubeVideo from "./YouTubeVideo";

const fragment = graphql(`
  fragment Video on Video {
    title
    url(format: URL)
    type
    videoId
  }
`);

/**
 *
 * @param {object} props
 * @param {'YOUTUBE' | 'VIMEO' | 'FACEBOOK' | 'INSTAGRAM' | 'POLYVIDEO' | 'OTHER'} props.type
 * @param {string} props.videoId
 * @param {string} [props.title]
 * @param {string} props.url
 * @returns
 */
export default function Video({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment> | null;
}) {
  if (!hasContent) return null;
  const video = useFragment(fragment, data);
  if (!video) return null;
  const { type, videoId, title, url } = video;
  switch (type) {
    case "YOUTUBE":
      return (
        <YouTubeVideo videoId={videoId} title={title} url={url} {...rest} />
      );
    case "VIMEO":
      return <VimeoVideo videoId={videoId} title={title} url={url} {...rest} />;
    case "FACEBOOK":
      return (
        <FacebookVideo videoId={videoId} title={title} url={url} {...rest} />
      );
    case "INSTAGRAM":
      return (
        <InstagramVideo videoId={videoId} title={title} url={url} {...rest} />
      );
    case "POLYVIDEO":
      return (
        <PolyvideoVideo videoId={videoId} title={title} url={url} {...rest} />
      );
    default:
      if (!url) return null;
      return <OtherVideo title={title} url={url} {...rest} />;
  }
}
Video.hasContent = hasContent;
function hasContent(data: FragmentType<typeof fragment> | null) {
  const video = useFragment(fragment, data);
  if (!video) return false;
  if (video.videoId) return true;
  if (video.url) return true;
  return false;
}

function OtherVideo({
  url,
  title,
  ...rest
}: {
  url: string;
  title?: string | null;
}) {
  return (
    <Box {...rest}>
      <SemiTitle>{title}</SemiTitle>
      <Text>
        <Link href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </Link>
      </Text>
    </Box>
  );
}

import React from 'react';
import getLink from './getLink';

const LinkContext = React.createContext(
  /** @type {getLink.LinkContext | undefined}*/ (undefined)
);

export function LinkProvider({ value, children }) {
  const context = React.useContext(LinkContext);
  if (typeof value === 'function') {
    return (
      <LinkContext.Provider value={value(context)}>
        {children}
      </LinkContext.Provider>
    );
  }
  if (context) {
    return (
      <LinkContext.Provider value={{ ...context, ...value }}>
        {children}
      </LinkContext.Provider>
    );
  }

  return <LinkContext.Provider value={value}>{children}</LinkContext.Provider>;
}

export default function useLink() {
  const context = React.useContext(LinkContext);
  return getLink(context);
}

/** @jsxImportSource theme-ui */
import { Title } from "@bottlebooks/gatsby-design-system";
import { Plural } from "@lingui/macro";
import React from "react";

export default function ExhibitorsPageTitle({ totalCount, count, ...rest }) {
  if (count === totalCount) {
    return (
      <Title {...rest}>
        <Plural
          value={totalCount}
          one={
            <React.Fragment>
              <Em>#</Em> exhibitor
            </React.Fragment>
          }
          other={
            <React.Fragment>
              <Em>#</Em> exhibitors
            </React.Fragment>
          }
        />
      </Title>
    );
  }

  return (
    <Title {...rest}>
      <Plural
        value={totalCount}
        one={
          <React.Fragment>
            <Em>{count}</Em> of <Em>#</Em> exhibitors
          </React.Fragment>
        }
        other={
          <React.Fragment>
            <Em>{count}</Em> of <Em>#</Em> exhibitors
          </React.Fragment>
        }
      />
    </Title>
  );
}

export function Em({ children, ...rest }) {
  return (
    <em sx={{ color: "primary", fontStyle: "normal" }} {...rest}>
      {children}
    </em>
  );
}

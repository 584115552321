import merge from "lodash/merge";
import { lighten } from "polished";
import { Helmet } from "react-helmet";
import { ThemeUIProvider } from "theme-ui";
import { useCollectionLayout } from "../../CollectionLayoutProvider.next";
import baseTheme from "../../../theme";
import sohoBold from "./fonts/Soho/SohoStd_Bold.ebfe6e996039.otf";
import sohoMedium from "./fonts/Soho/SohoStd_Medium.bd8b58ef5918.otf";
import sohoRegular from "./fonts/Soho/SohoStd_Regular.cc01175b16cf.otf";

export default function PortWineFestLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <ThemeUIProvider theme={useTheme()}>
      <Helmet>
        <link rel="stylesheet" href="//use.typekit.net/slj6gjy.css"></link>
        <style type="text/css">
          {`
            @font-face {
                font-family: "Soho";
                src: url(${sohoRegular}) format("opentype");
                font-weight: 400;
                font-display: swap;
                font-style: normal;
            }
            @font-face {
                font-family: "Soho";
                src: url(${sohoMedium}) format("opentype");;
                font-weight: 500;
                font-display: swap;
                font-style: normal;
            }
            @font-face {
                font-family: "Soho";
                src: url(${sohoBold}) format("opentype");;
                font-weight: 700;
                font-display: swap;
                font-style: normal;
            }
        `}
        </style>
      </Helmet>
      {children}
    </ThemeUIProvider>
  );
}

function useTheme() {
  const collectionLayout = useCollectionLayout();
  return {
    ...baseTheme,
    colors: {
      ...baseTheme.colors,
      primary: collectionLayout.primaryColor,
      secondary: lighten(0.1)(collectionLayout.primaryColor || "#000000"),
      accent: collectionLayout.accentColor,
    },
    fonts: merge({}, baseTheme.fonts, {
      heading: "Soho",
      body: "hero-new",
    }),
    fontWeights: {
      ...baseTheme.fontWeights,
      heading: "500",
      body: "400",
    },
    text: merge({}, baseTheme.text, {
      semiTitle: {
        ...baseTheme.text.semiTitle,
        fontSize: "body",
        fontFamily: "Soho",
      },
    }),
  };
}

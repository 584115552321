/** @jsxImportSource theme-ui */
import { Flex } from "@bottlebooks/gatsby-design-system";
import type { FlexProps } from "./UserProfileCard";
import type { ReactNode } from "react";

export default function UserProfileCardMain({
  children,
  ...rest
}: FlexProps & { children: ReactNode }) {
  return (
    <Flex direction="column" gap={1} sx={{ flexGrow: 1 }} {...rest}>
      {children}
    </Flex>
  );
}

/** @jsxImportSource theme-ui */
import { Button } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { Wine } from "@phosphor-icons/react";
import React from "react";
import useLink from "../../useLink";

export default function ProductCardActions({
  product,
  ...rest
}: {
  product:
    | {
        productId: string;
        exhibitorId: string;
      }
    | { productId: string; producerId: string };
}) {
  const link = useLink();
  return (
    <Button
      variant="outline"
      sx={{ display: "flex", justifyContent: "center" }}
      to={link.product(product)}
    >
      <Wine size={20} />
      <Trans>Go to product</Trans>
    </Button>
  );
}

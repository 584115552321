/** @jsxImportSource theme-ui */
// import { Label } from '@bottlebooks/gatsby-theme-base/src';
import { Box, Button } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import FacetValue from "./DefaultFacet/FacetValue";
import useFilterTranslations from "./useFilterTranslations";
// import { useLingui } from '@lingui/react';

/**
 * The facets bar on the top of the page. Selected facet values are shown here.
 * These shouldn't need to be customized – all the possible facets should be included though.
 * @param {*} props
 */
export default function ActiveFiltersBar({
  aggregations,
  definitions,
  onToggleFilter,
  onClearFilters,
  ...rest
}) {
  // const { i18n } = useLingui();
  const translate = useFilterTranslations(definitions);
  if (!aggregations) return null;
  const activeBuckets = Object.values(aggregations).flatMap(
    (aggregation) =>
      aggregation.buckets
        ?.filter(({ selected }) => selected)
        .map((bucket) => ({ aggregation, bucket })) || []
  );
  if (activeBuckets.length === 0) {
    return (
      <Box {...rest}>
        <Box sx={{ marginBottom: -3 }}>
          <FacetValue
            variant="small"
            sx={{ visibility: "hidden", marginRight: 3, marginBottom: 3 }}
          >
            &nbsp;
          </FacetValue>
        </Box>
      </Box>
    );
  }
  return (
    <Box {...rest}>
      <Box sx={{ marginBottom: -3 }}>
        {activeBuckets.map(({ aggregation, bucket }) => (
          // <Box sx={{ display: 'inline-block', marginRight: 2 }}>
          //   <Label variant="small" sx={{ color: 'primary', marginBottom: 1 }}>
          //     {i18n._(definitions[aggregation.name]?.title)}
          //   </Label>
          <FacetValue
            key={`${aggregation.name}.${bucket.key}`}
            isSelected
            onClick={() => onToggleFilter(aggregation, bucket)}
            variant="small"
            sx={{ marginRight: 3, marginBottom: 3 }}
          >
            {translate(aggregation.name, bucket.key)}
          </FacetValue>
          // </Box>
        ))}
        {activeBuckets.length > 1 && (
          <Button
            variant="text"
            onClick={onClearFilters}
            sx={{ color: "primary", variant: "label.default" }}
          >
            <Trans>Clear all filters</Trans>
          </Button>
        )}
      </Box>
    </Box>
  );
}

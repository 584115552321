/** @jsxImportSource theme-ui */
import { Link } from "@bottlebooks/gatsby-theme-base/src";

/**
 *
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {string} [props.to]
 * @param {boolean} [props.onDark]
 * @param {string} [props.className]
 */
export default function ListRow({ children, onDark, ...rest }) {
  return (
    <Link
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        borderRadius: "default",
        textDecoration: "none",
        backgroundColor: onDark ? "overlay" : undefined,
        color: onDark ? "onDark" : "inherit",
        textShadow: onDark
          ? `1px 1px ${theme.colors?.black}, 0 0 20px ${theme.colors?.black}`
          : undefined,
        position: "relative", // Put absolutely positioned images inside the row.
        overflow: "hidden", // Cut off background images that might overflow the row when hovering.
        transition: "all 0.3s ease-out",

        // Only add hover to links. The `&` is replaced by the class name automatically.
        "a[href]&:hover, button&:hover": {
          color: onDark ? "onDark" : "primary",
        },
      })}
      fallback="div"
      {...rest}
    >
      {children}
    </Link>
  );
}

/** @jsxImportSource theme-ui */
import { Title } from "@bottlebooks/gatsby-design-system";
import { Col } from "@bottlebooks/gatsby-theme-base/src";

export default function RelatedTitle({ children, ...rest }) {
  return (
    <Col flex sx={{ minWidth: ["100%", "auto"] }} {...rest}>
      <Title variant="small">{children}</Title>
    </Col>
  );
}

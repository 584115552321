/** @jsxImportSource theme-ui */
import groupBy from "lodash/groupBy";
import React from "react";
import useLink from "../../useLink";
import ExhibitorCard from "../ExhibitorsList/ExhibitorCard.next";
import GroupedExhibitorsCard from "../ExhibitorsList/GroupedExhibitorsCard";

/** @typedef {{  exhibitorId: string; }} Exhibitor */
/**
 * A list of exhibitors, optionally grouped or enhanced.
 * Shadow this to implement grouping and other customizations, like ads.
 *
 * @param {{
 *  exhibitors: Exhibitor[];
 *  group?: any;
 *  groupHeader?: import('../Filters/useIndex').GroupType;
 * }} props
 * */

export default function ExhibitorsPageCards({
  exhibitors,
  group,
  groupHeader,
}) {
  const link = useLink();
  const groupedData = groupData({ exhibitors, group });
  if (groupedData) {
    return (
      <React.Fragment>
        {groupedData.map(([key, exhibitors]) => (
          <GroupedExhibitorsCard
            key={key}
            exhibitors={exhibitors}
            type={groupHeader}
          />
        ))}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {exhibitors.map((exhibitor) => (
        <ExhibitorCard
          key={exhibitor.exhibitorId}
          to={link.exhibitor(exhibitor)}
          exhibitor={exhibitor}
        />
      ))}
    </React.Fragment>
  );
}

/**
 * exhibitorFilters has a default grouping logic for rooms.
 * Instead of checking if grouping logic exists, we need to check if it
 * returns any groups.
 */
export function groupData({ exhibitors, group }) {
  if (!group) return undefined;
  // Ensure stands have a room name because group will return undefined, which is different than ""
  const ensureStand = exhibitors.map((exhibitor) => {
    if (!exhibitor.stand)
      return {
        ...exhibitor,
        stand: { roomName: "", name: "", standTypeWithNumber: "" },
      };
    return exhibitor;
  });
  const groupByResults = groupBy(ensureStand, group);
  const grouped = Object.entries(groupByResults);
  const groupedKeys = Object.keys(groupByResults);
  // No meaningful grouped results
  // In the base implementation, this means that no rooms have been declared for any stand.
  // This is done in the base implementation by Stand 19 (Room name)
  if (
    groupedKeys.length === 1 &&
    ["undefined", "null"].includes(groupedKeys[0])
  )
    return undefined;
  return grouped;
}

// ts-check
/** @jsxImportSource theme-ui */
import { Text } from "@bottlebooks/gatsby-theme-base/src";
import { TastingNoteButton } from "../../../../bottlebooks-site-tastingnotes/src";
import useSiteConfig from "../useSiteConfig";

export default function ProductCardTastingNoteAction({ product, ...rest }) {
  const { getButtonProps, getIconProps, tastingNote } = TastingNoteButton.use({
    productId: product.productId,
  });
  const { hasContent } = useContent(product);
  if (!hasContent) return null;

  if (tastingNote) {
    return (
      <TastingNoteButton
        {...getButtonProps()}
        sx={{ width: "100%", padding: 0 }}
        {...rest}
      >
        <TastingNoteButton.Icon {...getIconProps()} />
        {tastingNote?.note && (
          <Text
            variant="small"
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "wrap",
              backgroundColor: "brand.lightGray",
              marginTop: 2.5,
              padding: 2,
              position: "relative",
              "::before": {
                content: '""',
                position: "absolute",
                bottom: "100%",
                left: "1.5em",
                width: 0,
                height: 0,
                border: "0.75rem solid transparent",
                borderTop: "none",
                borderBottomColor: "brand.lightGray",
              },
            }}
          >
            {tastingNote.note}
          </Text>
        )}
      </TastingNoteButton>
    );
  }
  return (
    <TastingNoteButton.Large
      productId={product.productId}
      sx={{ marginBottom: 2 }}
    />
  );
}

export function useContent(product) {
  const { tastingNotesEnabled } = useSiteConfig();
  return {
    hasContent: tastingNotesEnabled,
  };
}

ProductCardTastingNoteAction.useContent = useContent;

/** @jsxImportSource theme-ui */
import { Button } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import "@reach/dialog/styles.css";
import { CalendarBlank } from "@phosphor-icons/react";
import React from "react";
import BookAMeetingConfirmation from "./BookAMeetingConfirmation";
import BookAMeetingDialog from "./BookAMeetingDialog";

/**
 * Ability to book a meeting with an exhibitor
 * @param {object} props
 * @param {object} props.contact
 * @param {object} props.exhibitor
 * @param {string} [props.collectionId]
 * @param {string} [props.companyId]
 * @param {'DISABLED' | 'ENABLED'} [props.visibility]
 * @param {'DISABLED' | 'OPTIONAL' | 'REQUIRED'} [props.authentication]
 * @returns
 */
export default function BookAMeetingButton({
  contact,
  exhibitor,
  collectionId,
  companyId,
  visibility = "DISABLED",
  authentication = "OPTIONAL",
  ...rest
}) {
  const [visibleDialogName, toggleDialog] = React.useState("");
  if (visibility === "DISABLED") return null;
  return (
    <React.Fragment>
      <Button
        onClick={() => toggleDialog("form")}
        variant="secondary"
        sx={{
          display: "inline-flex",
          gap: 2,
          alignItems: "center",
          paddingX: 3,
          fontSize: "smallest",
          lineHeight: 1,
        }}
        {...rest}
      >
        <CalendarBlank size={16} weight="light" />
        {
          // @ts-ignore
          <Trans>Meet</Trans>
        }
      </Button>
      {visibleDialogName === "form" && (
        <BookAMeetingDialog
          collectionId={collectionId}
          exhibitor={exhibitor}
          contact={contact}
          companyId={companyId}
          onDismiss={() => toggleDialog("")}
          onSubmit={() => toggleDialog("success")}
        />
      )}
      {visibleDialogName === "success" && (
        <BookAMeetingConfirmation
          contact={contact}
          onDismiss={() => toggleDialog("")}
        />
      )}
    </React.Fragment>
  );
}

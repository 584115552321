/** @jsxImportSource theme-ui */
import useUserProfile from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUserProfile";
import { Grid } from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";
import UserProfileCard from "../UserProfile/UserProfileCard";

export const fragment = graphql(`
  fragment Representatives on SingleRegistration {
    representatives(
      experiment: "california-networking"
      experimentToken: "Used in @bottlebooks/gatsby-theme-event, added by bernhard@unserwein.at at 2023-02-22"
    ) {
      uid
      representativeId
      ...UserProfileCardHorizontal
    }
  }
`);

/**
 * The company's representatives.
 */
export default function Representatives({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const exhibitor = useFragment(fragment, data);
  const { userProfile } = useUserProfile();
  if (!hasContent(data)) return null;
  return (
    <Grid
      sx={{ gridTemplateColumns: ["1fr", "1fr 1fr"], gap: ["1px", 3] }}
      {...rest}
    >
      {exhibitor.representatives?.map((representative) => {
        // If the representative is the currently logged-in user,
        // use their profile data to show updated content, such as the profile image.
        const profile =
          userProfile?.primaryRole === "REPRESENTATIVE" &&
          userProfile.representativeId === representative.representativeId
            ? {
                ...representative,
                ...userProfile,
                profileImage: userProfile.profileImageUrl
                  ? { url: userProfile.profileImageUrl, publicId: "" }
                  : representative.profileImage,
              }
            : representative;
        return (
          <UserProfileCard.Horizontal
            key={representative.representativeId}
            profile={profile}
            shadow="xs"
            sx={{ backgroundColor: "white" }}
          />
        );
      })}
    </Grid>
  );
}

function hasContent(data: FragmentType<typeof fragment>) {
  const exhibitor = useFragment(fragment, data);
  if (!exhibitor) return false;
  if (exhibitor.representatives?.length) return true;
  return false;
}

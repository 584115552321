import merge from "lodash/merge";
import { darken } from "polished";
import { Helmet } from "react-helmet";
import { ThemeUIProvider } from "theme-ui";
import baseTheme from "../../../theme";

export default function TopDropLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <ThemeUIProvider theme={useTheme()}>
      <Helmet>
        <link
          rel="stylesheet"
          href="//fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap"
        ></link>
        <style type="text/css">
          {`
              // Mantine defaults to antialiased font smoothing, which looks
              // terrible on the Top Drop site
              :root {
                --mantine-webkit-font-smoothing: subpixel-antialiased;
              }
              html, body {
                -webkit-font-smoothing: subpixel-antialiased;
              }
              .bb-header,
              .bb-mobile-menu,
              .bb-mobile-menu a, 
              .bb-footer {
                background-color: black;
                border-color: black;
                color: white;
              }

              .bb-mobile-menu a .bb-text {
                text-decoration: none;
              }
              
              .bb-multisite-swicher div,
              .bb-multisite-swicher svg
              .bb-footer svg,
              .bb-footer div,
              .bb-search-button,
              .bb-menu-item,
              .bb-section-accent .bb-text,
              .bb-footer .bb-text,
              .bb-section-accent .bb-text > span {
                color: white;
              }

              .bb-site-search {
                color: black;
              }

              .bb-section-accent a .bb-text {
                text-decoration: underline;
              }
          `}
        </style>
      </Helmet>
      {children}
    </ThemeUIProvider>
  );
}

function useTheme() {
  return {
    ...baseTheme,
    colors: merge({}, baseTheme.colors, {
      ...baseTheme.colors,
      primary: "#888",
      secondary: darken(0.1)("#888"),
      accent: "black",
    }),
    fonts: merge({}, baseTheme.fonts, {
      heading: "Montserrat, sans-serif",
      body: "Montserrat, sans-serif",
    }),
    fontWeights: {
      ...baseTheme.fontWeights,
      heading: "700",
      body: "400",
    },
    title: merge({}, baseTheme.title, {
      _base: {
        WebkitFontSmoothing: "antialiased",
      }, // Add antialiasing to titles.
    }),

    label: {
      ...baseTheme.label,
      _base: { ...baseTheme.label._base, color: "#666" },
    },
    text: {
      ...baseTheme.text,
    },
    styles: {
      ...baseTheme.styles,
      root: {
        ...baseTheme.styles.root,
        WebkitFontSmoothing: "subpixel-antialiased",
      },
    },
  };
}

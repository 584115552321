/** @jsxImportSource theme-ui */
import { P } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";

const fragment = graphql(`
  fragment BrandRegionSection on Profile {
    region
  }
`);

export default function BrandRegionSection({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const profile = useFragment(fragment, data);
  if (!profile.region) return null;
  return (
    <Section title={<Trans>Growing area</Trans>} {...rest}>
      <P variant="title">{profile.region}</P>
    </Section>
  );
}

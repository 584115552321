import { Flex } from "@bottlebooks/gatsby-design-system";

export default function ThumbnailContainer({
  children,
  height = 136,
  ...rest
}) {
  return (
    <Flex align="center" justify="center" sx={{ height }} {...rest}>
      {children}
    </Flex>
  );
}

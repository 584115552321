/** @jsxImportSource theme-ui */
window.global ||= window;
import useAuthentication from "@bottlebooks/gatsby-plugin-firebase-auth/src/useAuthentication";
import { Button, CloseIcon, Flex } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import Dialog from "@reach/dialog";
import "@reach/dialog/styles.css";
import React from "react";
import createPersistedState from "use-persisted-state";
import FeatureOverview from "./FeatureOverview";

const useWhatsNewState = createPersistedState("bottlebooks:whatsNew");

/**
 * @param {{ closeDialog: ()=> void; }} props
 */
export default function EducationalContentDialog({ closeDialog }) {
  const { setHasBeenShown } = useEducationalContentDialog();
  const { signIn } = useAuthentication();
  function handleClose() {
    setHasBeenShown(true);
    closeDialog();
  }
  return (
    <Dialog
      onDismiss={handleClose}
      aria-label="login"
      sx={{
        width: ["100vw", 600],
        marginY: [0, "10vh"],
        position: "relative",
      }}
    >
      <Button
        variant="text"
        onClick={handleClose}
        sx={{ position: "absolute", top: 2, right: 2 }}
      >
        <CloseIcon size="xsmall" />
      </Button>
      <Flex
        direction="column"
        justify="center"
        sx={{ marginY: 4, width: "100%" }}
      >
        <FeatureOverview />
        <Button
          variant="primary"
          onClick={() => {
            handleClose();
            signIn();
          }}
          sx={{ alignSelf: "center", marginTop: 3 }}
        >
          <Trans>Log in</Trans>
        </Button>
      </Flex>
    </Dialog>
  );
}

export function useEducationalContentDialog() {
  // Don't show the content again after the user has read this page
  // TODO: not sure why the hook doesn't work here, but resorting to hard code for now.
  const [whatsNewState, setWhatsNewState] = useWhatsNewState({});

  return {
    shouldShow: !whatsNewState.educationalContent,
    setHasBeenShown: (value = true) =>
      setWhatsNewState({ ...whatsNewState, educationalContent: value }),
  };
}

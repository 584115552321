/** @jsxImportSource theme-ui */
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";
import Video from "../Video/Video.next";

const fragment = graphql(`
  fragment ProductVideos on Product {
    videos {
      videoId
      title
      ...Video
    }
  }
`);

export default function ProductVideos({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const product = useFragment(fragment, data);
  if (!product?.videos?.length) return false;
  if (!hasContent(data)) return null;
  const needsTitle = !product.videos[0]?.title;
  return (
    <Section title={needsTitle && <Trans>Videos</Trans>} {...rest}>
      {product.videos.map((video) => (
        <Video key={video?.videoId} data={video} />
      ))}
    </Section>
  );
}
ProductVideos.hasContent = hasContent;
function hasContent(data: FragmentType<typeof fragment>) {
  const product = useFragment(fragment, data);
  if (!product?.videos?.length) return false;
  return product.videos.some((video) => Video.hasContent(video));
}

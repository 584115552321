/** @jsxImportSource theme-ui */
import { Title } from "@bottlebooks/gatsby-design-system";
import { TitleVariant } from "@bottlebooks/gatsby-design-system/dist/components/Title/titleVariants";
import { FragmentType, graphql, useFragment } from "~/gql";

const fragment = graphql(`
  fragment BrandName on Profile {
    name
  }
`);

export default function BrandName({
  data,
  variant,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
  variant?: TitleVariant;
}) {
  const brand = useFragment(fragment, data);
  if (!brand) return null;
  return (
    <Title variant={variant} {...rest}>
      {/* {brand.prefix && <span>{brand.prefix} </span>} */}
      {brand.name}
      {/* {brand.suffix && <span> {brand.suffix}</span>} */}
    </Title>
  );
}

import { useCollectionLayout } from "@bottlebooks/bottlebooks-site-base/src/components/CollectionLayoutProvider.next";
import React, { createContext, ReactNode, useContext, useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

type GoogleAnalyticsProviderProps = {
  children: ReactNode;
};

const GoogleAnalyticsContext = createContext<string | null>(null);

export const GoogleAnalyticsProvider: React.FC<
  GoogleAnalyticsProviderProps
> = ({ children }) => {
  const location = useLocation();
  const { siteConfig } = useCollectionLayout();
  const googleMeasurementId = siteConfig.googleMeasurementId;
  useEffect(() => {
    // Initialize ReactGA
    if (googleMeasurementId)
      return ReactGA.initialize([
        // Standard measurement id
        { trackingId: "G-2H0GDR20Y0" },
        // Client measurement id
        { trackingId: googleMeasurementId },
      ]);
    return ReactGA.initialize("G-2H0GDR20Y0");
  }, [googleMeasurementId]);

  useEffect(() => {
    // Notify GA4 of the pageview when the route changes
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return (
    <GoogleAnalyticsContext.Provider value="G-2H0GDR20Y0">
      {children}
    </GoogleAnalyticsContext.Provider>
  );
};

export const useGoogleAnalytics = () => {
  const trackingId = useContext(GoogleAnalyticsContext);
  if (!trackingId) {
    console.error(
      "useGoogleAnalytics must be used within a GoogleAnalyticsProvider"
    );
  }
  return (eventCategory: string, eventAction: string, eventLabel?: string) => {
    ReactGA.event({
      category: eventCategory,
      action: eventAction,
      label: eventLabel,
    });
  };
};

// Usage Example:
// Wrap your app with GoogleAnalyticsProvider
// <GoogleAnalyticsProvider trackingId="YOUR_TRACKING_ID">
//   <App />
// </GoogleAnalyticsProvider>

// In a component
// const trackEvent = useGoogleAnalytics();
// trackEvent('category', 'action', 'label');

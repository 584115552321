import flags from "../../assets/flags";
import React from "react";

function checkCountry(country) {
  if (flags[country]) return country;
  return null;
}

/**
 * Displays a flag icon.
 * @param {object} props
 * @param {string} props.country The 2-letter ISO code for the country.
 * @param {string} props.alt The alternate name for the country.
 */
export default function Flag({ country, alt, ...rest }) {
  if (!country) return null;
  const lowerCaseCountry = country.toLowerCase();
  const mappedCountry =
    checkCountry(lowerCaseCountry) ||
    // flags library doesn't have flags for england and scotland, for example
    checkCountry(lowerCaseCountry.slice(0, 2));
  if (!mappedCountry) {
    console.warn(`no flag available for code ${country}`);
  }
  return (
    <img
      src={flags[mappedCountry]}
      alt={alt}
      width={16}
      style={{ verticalAlign: "middle" }}
      quality={100}
      {...rest}
    />
  );
}

/** @jsxImportSource theme-ui */
import { Flex, SemiTitle, Text } from "@bottlebooks/gatsby-theme-base/src";
import getOnAccentColor from "@bottlebooks/bottlebooks-site-base/src/helpers/getOnAccentColor";
// import { graphql } from "gatsby";
import { CallToAction, RichText } from "..";
import GridImage from "./GridImage";

export default function IconBlock({
  icon,
  label,
  callToAction,
  sectionVariant,
  projectId,
  _rawText,
  ...rest
}) {
  return (
    <Flex direction="column" align="center" {...rest}>
      {icon && (
        <Flex
          align="center"
          justify="center"
          sx={{ height: 100, marginBottom: 3 }}
        >
          <GridImage image={icon} sx={{ width: 100 }} projectId={projectId} />
        </Flex>
      )}
      <SemiTitle
        sx={(theme) => {
          return {
            marginBottom: 2,
            color:
              sectionVariant === "accent"
                ? getOnAccentColor(theme.colors.accent)
                : "inherit",
          };
        }}
      >
        {label}
      </SemiTitle>
      <Text
        sx={(theme) => {
          return {
            textAlign: "center",
            color:
              sectionVariant === "accent"
                ? getOnAccentColor(theme.colors.accent)
                : "inherit",
          };
        }}
      >
        <RichText>{_rawText}</RichText>
      </Text>
      <CallToAction {...callToAction} sectionVariant={sectionVariant} />
    </Flex>
  );
}

// export const fragment = graphql`
//   fragment SanityIconBlockFragment on SanityIconBlock {
//     icon {
//       ...ImageWithPreview
//     }
//     label
//     _rawText
//     callToAction {
//       ...CallToAction
//     }
//   }
// `;

// ts-check
/** @jsxImportSource theme-ui */
import { Flex } from "@bottlebooks/gatsby-theme-base/src";
import ProductCardGoToProductAction from "./ProductCardGoToProductAction";

export default function ProductCardActions({
  product,
  ...rest
}: {
  product:
    | {
        productId: string;
        exhibitorId: string;
      }
    | { productId: string; producerId: string };
}) {
  return (
    <Flex direction="column" gap={2.5} {...rest}>
      <ProductCardGoToProductAction product={product} />
    </Flex>
  );
}

import { useProducts } from "@bottlebooks/bottlebooks-site-base/src/components/SiteSearch/useEventData";
import { useFavorites } from ".";

export default function useBookmarksByExhibitor() {
  const { favorites } = useFavorites();
  type Favorite = Extract<typeof favorites, {}>[number];
  const products = useProducts();
  const getExhibitorId = ({ type, id }: Favorite) => {
    if (type === "registration") return id;
    if (type === "product") return products.findById(id)?.exhibitorId;
    return undefined;
  };
  const groupedByExhibitorId: Record<
    string,
    {
      exhibitorId: string;
      bookmark?: Extract<Favorite, { type: "registration" }>;
      products: Extract<Favorite, { type: "product" }>[];
    }
  > =
    favorites?.reduce((grouped, bookmark) => {
      const exhibitorId = getExhibitorId(bookmark);
      if (!exhibitorId) return grouped;
      if (!grouped[exhibitorId])
        grouped[exhibitorId] = { exhibitorId, products: [] };
      if (bookmark.type === "product") {
        grouped[exhibitorId].products.push(bookmark);
        return grouped;
      }
      if (bookmark.type === "registration") {
        grouped[exhibitorId].bookmark = bookmark;
        return grouped;
      }
      return grouped;
    }, {}) || {};
  return {
    exhibitors: Object.values(groupedByExhibitorId),
    byExhibitorId: groupedByExhibitorId,
    get: (exhibitorId: string) => groupedByExhibitorId[exhibitorId],
  };
}

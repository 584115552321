/** @jsxImportSource theme-ui */
import { Box, Flex, Link, Text } from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";
import AvatarSubstitute from "./AvatarSubstitute";
import React from "react";
import type { Theme } from "theme-ui";

const fragment = graphql(`
  fragment Contact on Contact {
    contactId
    fullName
    websiteUrl: website(format: URL)
    websiteText: website(format: TEXT)
    contactTypes
    title
    companyName
  }
`);

export default function Contact({
  data,
  variant = "default",
  children,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
  variant?: string;
  children: React.ReactNode;
}) {
  const contact = useFragment(fragment, data);
  if (!hasContent(contact)) return null;
  return (
    <Box
      sx={{
        display: "flex",
        textAlign: "left",
        alignItems: "stretch",
        transition: "backgroundColor",
      }}
      {...rest}
    >
      {variant === "default" && (
        <Flex
          sx={(theme: Theme) => ({
            minWidth: 100,
            width: 100,
            height: 100,
            marginRight: 4,
            borderRadius: "round",
            border: "1px solid white",
            boxShadow: `0 0 0 4px ${theme?.colors?.nuanced}`,
            backgroundColor: "nuanced",
            transition: "transform",
            justifyContent: "center",
            alignItems: "center",
          })}
        >
          <AvatarSubstitute contact={contact} sx={{ color: "lightestText" }} />
          {/* <Avatar
          seed={contact.fullName}
          style={{
            maxWidth: 80,
            maxHeight: 80,
            borderRadius: 999,
            margin: -3,
            marginTop: -9,
          }}
        /> */}
        </Flex>
      )}
      <Flex direction="column" sx={{ flexGrow: 1 }}>
        <Text
          variant="small"
          sx={{ ...(variant === "default" && { fontWeight: "bold" }) }}
        >
          {contact.fullName}
        </Text>
        <Text variant="smallest" sx={{ color: "lighterText" }}>
          {contact.title !== "Other" && contact.title}
          {contact.title &&
            contact.title !== "Other" &&
            (contact.companyName || contact.websiteUrl) &&
            ", "}
          {contact.contactTypes?.includes("DISTRIBUTION") ? (
            <Link
              openInNewTab
              href={contact.websiteUrl || undefined}
              sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}
            >
              {contact.companyName || contact.websiteText}
            </Link>
          ) : (
            contact.companyName
          )}
        </Text>
        {children}
      </Flex>
    </Box>
  );
}
Contact.hasContent = hasContent;

function hasContent(contact: {
  fullName?: string | null;
  companyName?: string | null;
  websiteUrl?: string | null;
}) {
  if (!contact) return false;
  if (contact.fullName) return true;
  if (contact.companyName) return true;
  if (contact.websiteUrl) return true;
  return false;
}

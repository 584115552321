/** @jsxImportSource theme-ui */
import { Flex } from "@bottlebooks/gatsby-design-system";
import type { ReactNode } from "react";
import type { FlexProps } from "./UserProfileCard";

type ActionsProps = FlexProps & {
  children: ReactNode;
};

export default function UserProfileCardActions({
  children,
  ...rest
}: ActionsProps) {
  return (
    <Flex gap={2} sx={{ marginTop: "auto", paddingTop: 3.5 }} {...rest}>
      {children}
    </Flex>
  );
}

/** @jsxImportSource theme-ui */
import { Container } from "@bottlebooks/gatsby-theme-base/src";
import Header from "./Related.Header";
import Link from "./Related.Link";
import List from "./Related.List";
import Title from "./Related.Title";

export default function Related({ children, ...rest }) {
  return <Container {...rest}>{children}</Container>;
}
Related.Header = Header;
Related.Title = Title;
Related.Link = Link;
Related.List = List;

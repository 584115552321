/** @jsxImportSource theme-ui */
// ts-check
import Card from "../../Card";
import {
  Flex,
  Link,
  SemiTitle,
  Text,
} from "@bottlebooks/gatsby-theme-base/src";
import { useFavorite } from "@bottlebooks/bottlebooks-site-bookmarks/src";
import ToggleFavorite from "@bottlebooks/bottlebooks-site-bookmarks/src/components/ToggleFavorite";
import { TastingNoteButton } from "../../../../bottlebooks-site-tastingnotes/src";
import React from "react";
import ListRowBody from "../List/ListRowBody";
import ProductImage from "../Product/ProductImage";
import BaseProductName from "../Product/ProductName.next";
import useSiteConfig from "../useSiteConfig";

/** @param {{ product: object; style?:React.CSSProperties; className?: string; elevation?: Parameters<Card>[0]["elevation"]; [x: string]:any; }} props */
export default function ProductCardSmall({
  product,
  className,
  style,
  variant,
  elevation = "flat",
  ...rest
}) {
  const favorite = useFavorite(
    product && { type: "product", id: product.productId }
  );
  return (
    <Card
      elevation={elevation}
      sx={{ display: "flex", flexDirection: "column" }}
      className={className}
      style={style}
    >
      <Link
        sx={{
          display: "block",
          color: "inherit",
          position: "relative",
          flexGrow: 1,
        }}
        {...rest}
      >
        <ProductImage
          product={product}
          sx={{
            maxWidth: "35%",
            height: "initial",
            margin: "auto",
            ".gatsby-image-wrapper": { paddingTop: "200%", height: 0 },
          }}
        />
        <ListRowBody sx={{ paddingY: 2 }}>
          {product?.exhibitor?.name !== product?.producer?.name && (
            <Text variant="smallest">{product?.producer?.name}</Text>
          )}
          <SemiTitle sx={{ paddingTop: 0 }}>
            <BaseProductName {...product} />
          </SemiTitle>
        </ListRowBody>
      </Link>
      {!favorite.isLoading && (
        <ToggleFavorite
          isToggled={favorite.isSet}
          variant="small"
          sx={{ position: "absolute", top: 0, right: 0 }}
          onClick={() => favorite.toggle()}
        />
      )}
      <ProductCardActionsBar
        product={product}
        sx={{ borderTop: 1, borderTopColor: "border", flex: "0 0 auto" }}
      />
    </Card>
  );
}

function ProductCardActionsBar({ product, ...rest }) {
  const { tastingNotesEnabled } = useSiteConfig();
  const { getButtonProps, getIconProps, tastingNote } = TastingNoteButton.use({
    productId: product.productId,
  });
  if (!tastingNotesEnabled) return null;

  return (
    <Flex
      gap={2}
      wrap="wrap"
      align="center"
      sx={{
        width: "100%",
        backgroundColor: "light",
        padding: 2,
        paddingBottom: 0,
      }}
      {...rest}
    >
      {tastingNote ? (
        <React.Fragment>
          <TastingNoteButton
            {...getButtonProps()}
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              marginBottom: 2,
            }}
            variant="text"
          >
            <TastingNoteButton.Icon
              {...getIconProps({ size: "smallMedium" })}
              sx={{ flexShrink: 0 }}
            />
            {tastingNote.note && (
              <Text
                variant="small"
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {tastingNote.note}
              </Text>
            )}
          </TastingNoteButton>
        </React.Fragment>
      ) : (
        <TastingNoteButton.Large
          productId={product.productId}
          sx={{ marginBottom: 2 }}
        />
      )}
    </Flex>
  );
}

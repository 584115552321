/** @jsxImportSource theme-ui */
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import ShoppingCartButton from "../../Shop/ShoppingCartButton";
import ProductPrice from "../ProductPrice/ProductPrice.next";

const fragment = graphql(`
  fragment ProductShopExternalButton on RegisteredProduct {
    product {
      onlineShop
      producer {
        onlineShop
      }
    }
    ...ProductPrice
  }
`);

/** A buy button for the product in an external online shop. */
export default function ProductShopExternalButton({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const registeredProduct = useFragment(fragment, data);
  const product = registeredProduct.product;
  const onlineShop = afterQuery(data);
  if (!onlineShop) return null;
  return (
    <ShoppingCartButton
      href={onlineShop}
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
    >
      {product.onlineShop ? (
        <Trans>Buy online</Trans>
      ) : (
        <Trans>Go to online shop</Trans>
      )}{" "}
      <ProductPrice
        pricing={registeredProduct}
        sx={{ paddingLeft: 2, opacity: 0.8 }}
      />
    </ShoppingCartButton>
  );
}

function afterQuery(data: FragmentType<typeof fragment>) {
  const registeredProduct = useFragment(fragment, data);
  const product = registeredProduct.product;
  const onlineShop = product?.onlineShop;
  const producerOnlineShop = product?.producer?.onlineShop;
  if (!onlineShop && !producerOnlineShop) return null;
  const shop = onlineShop || producerOnlineShop;
  if (!shop) return null;
  if (shop.startsWith("http")) return shop;
  return `https://${shop}`;
}

/** @jsxImportSource theme-ui */
import { Dialog } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import type { DialogProps } from "@reach/dialog";
import { Fragment } from "react";
import LoginState from "./Login";
import LoginBenefits from "./Login.Benefits";

type NotLoggedInProps = {
  onDismiss: DialogProps["onDismiss"];
  profile: { displayName?: string | null };
};
export default function NotLoggedIn({ onDismiss, profile }: NotLoggedInProps) {
  return (
    <Fragment>
      <Dialog.Header onDismiss={onDismiss}>
        <Trans>Start a chat with {profile.displayName}</Trans>
      </Dialog.Header>
      <Dialog.Content sx={{ textAlign: "center" }}>
        <LoginState
          availableMethods={["GOOGLE", "EMAIL"]}
          append={<LoginBenefits />}
        />
      </Dialog.Content>
    </Fragment>
  );
}

/** @jsxImportSource theme-ui */
import { Trans } from "@lingui/macro";
import { Chats } from "@phosphor-icons/react";
import "@reach/dialog/styles.css";
import { useState } from "react";
import { FragmentType, graphql, useFragment } from "~/gql";
import useLocale from "../../useLocale.js";
import type { ActionButtonProps } from "../ActionButton.js";
import ActionButton from "../ActionButton.js";
import { useCollectionLayout } from "../CollectionLayoutProvider.next.js";
import useSiteConfig from "../useSiteConfig.js";
import type { RequestChatButtonDialogData } from "./RequestChatButton.Dialog.next.js";
import RequestChatButtonDialog from "./RequestChatButton.Dialog.next.js";

const fragment = graphql(`
  fragment RequestChatButton on SiteUserProfile {
    ...RequestChatButtonDialog
  }
`);
/** A chat button that triggers the "start a chat" request flow. */
export default function RequestChatButton({
  profile: data,
  ...rest
}: {
  profile: FragmentType<typeof fragment>;
}) {
  const profile = useFragment(fragment, data);
  const { isChatEnabled } = useSiteConfig();
  const [isOpen, setOpen] = useState(false);
  const { collectionId } = useCollectionLayout();
  const locale = useLocale();
  if (!isChatEnabled) return null;
  return (
    <ActionButton onClick={() => setOpen(true)} {...rest}>
      <Chats size={16} weight="light" />
      <Trans>Chat</Trans>
      {isOpen && (
        <RequestChatButtonDialog
          collectionId={collectionId}
          locale={locale || "en"}
          profile={profile}
          onDismiss={() => setOpen(false)}
          isOpen={isOpen}
        />
      )}
    </ActionButton>
  );
}

export function RequestChatButtonSkeleton(
  rest: Omit<ActionButtonProps, "children">
) {
  const { isChatEnabled } = useSiteConfig();
  if (!isChatEnabled) return null;
  return (
    <ActionButton disabled {...rest}>
      <Chats size={16} weight="light" />
      <Trans>Chat</Trans>
    </ActionButton>
  );
}

export type RequestChatButtonData = RequestChatButtonDialogData & {};

/** @jsxImportSource theme-ui */
import { gql } from "urql";

export default gql`
  fragment ImageBlockFragment on ImageBlock {
    image {
      ...ImageWithPreview
    }
    style {
      ...BlockStyle
    }
  }
`;

/** @jsxImportSource theme-ui */
// import { jsx } from 'theme-ui';
import { graphql } from "~/gql";

export default function ProducerActions({ producer, ...rest }) {
  return null;
  // This section left empty for now.
}

export const fragment = graphql(`
  fragment ProducerActions on Producer {
    __typename
  }
`);

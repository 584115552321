import jfwSchedule from "./com.jacksonfamilywines.Schedule";
import PortWineFestBanner from "./com.portwinefest.PortWineFestBanner";
import PortWineFestImageGalleryChicago2024 from "./com.portwinefest.PortWineFestImageGalleryChicago2024";
import PortWineFestImageGalleryLasVegas2024 from "./com.portwinefest.PortWineFestImageGalleryLasVegas2024";
import PortWineFestImageGalleryNewYork2024 from "./com.portwinefest.PortWineFestImageGalleryNewYork2024";
import PortWineFestImageGalleryPebbleBeach from "./com.portwinefest.PortWineFestImageGalleryPebbleBeach";
import PortWineFestImageGalleryVail from "./com.portwinefest.PortWineFestImageGalleryVail";

export default {
  "com.jacksonfamilywines.Schedule": jfwSchedule,
  "com.portwinefest.PortWineFestImageGalleryPebbleBeach":
    PortWineFestImageGalleryPebbleBeach,
  "com.portwinefest.PortWineFestBanner": PortWineFestBanner,
  "com.portwinefest.PortWineFestImageGalleryVail": PortWineFestImageGalleryVail,
  "com.portwinefest.PortWineFestImageGalleryLasVegas2024":
    PortWineFestImageGalleryLasVegas2024,
  "com.portwinefest.PortWineFestImageGalleryChicago2024":
    PortWineFestImageGalleryChicago2024,
  "com.portwinefest.PortWineFestImageGalleryNewYork2024":
    PortWineFestImageGalleryNewYork2024,
};

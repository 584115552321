import React from "react";
import encodeFilters from "../../encodeFilters";
// import { navigate } from 'gatsby';
import reducer, {
  initialState as defaultInitialState,
  stateChanged,
} from "./filterReducer";
/**
 * @param {{hash: string;state: any;}} location
 */
export default function useLocationSyncedState(location) {
  // We need to alter the state after reading from the URL client-side.
  // We do it in useEffect so it's not executed server-side, but also renders correctly in the client
  // (no mixed-up styles any more).
  const [locationWasRead, setLocationWasRead] = React.useState(false);
  /** @type {[state: typeof defaultInitialState, dispatch: React.Dispatch<[event: string, payload?: any]>]} */
  const [state, dispatch] = React.useReducer(reducer, defaultInitialState);
  React.useEffect(() => {
    if (locationWasRead) return;
    const stateFromLocation = getStateFromLocation(
      location,
      defaultInitialState
    );
    const initialState = {
      ...stateFromLocation,
      sort: stateFromLocation.sort?.toString(),
    };
    dispatch(stateChanged(initialState));
    setLocationWasRead(true);
  }, [location, locationWasRead]);

  // Change the URL hash when the filters change.
  // We use URL hash also for other state information such as orderBy
  useSyncedLocation(state, { initialState: defaultInitialState, location });
  return /** @type {[typeof state, typeof dispatch]} */ ([state, dispatch]);
}

function useSyncedLocation(state, { initialState, location }) {
  const hash = encodeFilters(getChangedState(state, initialState));
  const pageUrl = location?.href?.replace(location?.hash, "");
  const url = hash || pageUrl;
  React.useEffect(() => {
    /* eslint-disable no-restricted-globals */
    if (typeof history !== "undefined" && "replaceState" in history) {
      history.replaceState(null, "", url);
    }
    /* eslint-enable no-restricted-globals */
    // TODO Use navigate instead of history.replaceState
    // Doesn't work currently because trailing slashes are added by Netlify and removed by Gatsby, leading to infinite redirects.
    // if (hash) {
    //   navigate(url, { replace: true });
    //   return;
    // }
    // navigate(`${url}/`.replace(/\/\/$/, '/'), { replace: true });
  }, [hash, url]);
}

function parseHash(hash, { facetSeparator = "&", valueSeparator = "," } = {}) {
  if (!hash) return {};
  const facets = decodeURI(hash)
    .replace(/^#/, "")
    .split(facetSeparator)
    .map((facetText) => {
      // Get each facet's values.
      // TODO parse object, boolean, numeric and string values etc.
      const facet = facetText.split("=");
      if (facet.length !== 2) return [];
      return /** @type {[string, string[]]} */ ([
        facet[0],
        facet[1].split(valueSeparator),
      ]);
    })
    .reduce((facets, [key, values]) => {
      if (!key) return facets;
      facets[key] = values;
      return facets;
    }, {});

  return facets;
}

/**
 * Gets the state from the location's hash and state.
 * @param {{ hash: string; state: any; }} location The location from @reach/router
 * @param {object} initialState The initial state imported from the filter reducer.
 */
function getStateFromLocation(location, initialState) {
  const { hash, state } = location;
  //TODO filter on valid state keys to avoid state pollution.
  return { ...initialState, ...parseHash(hash), ...state };
}

/**
 * Get the state values that have changed from the initial state.
 * @param {object} state The filter state.
 * @param {object} initialState The initial state, used to detect the whiteisted properties and to compare changes.
 */
function getChangedState(state, initialState) {
  const blacklistedProps = ["key"];
  return Object.entries(state)
    .map(([key, value]) => {
      const initial = initialState[key] || [];
      if (Array.isArray(value)) {
        if (value?.some?.((value) => !initial?.includes(value)))
          return [key, value];
        return undefined;
      }
      switch (typeof initial) {
        case "string":
          if (value !== initial) return [key, value];
          return undefined;
        default:
          return [key, value];
      }
    })
    .filter(([key, value] = []) => {
      if (blacklistedProps.includes(key)) return false;
      if (!key) return false;
      if (!value) return false;
      return true; //whitelistedProps.includes(key);
    })
    .reduce((changedState, /** @type {[string, any]} */ [key, value]) => {
      changedState[key] = value;
      return changedState;
    }, {});
}

/** @jsxImportSource theme-ui */
import { Flex, Text } from "@bottlebooks/gatsby-design-system";
import getOnAccentColor from "@bottlebooks/bottlebooks-site-base/src/helpers/getOnAccentColor";
// import { graphql } from "gatsby";
import { darken } from "polished";
import { gql } from "urql";
import { RichText, Section } from "..";
import CallToAction from "../Elements/CallToAction";
import UserHelp from "../UserHelp";

export default function TextSection({
  title,
  _key,
  style,
  callToAction,
  numberOfColumns = 1,
  _rawText,
}) {
  if (!title && !_rawText)
    return (
      <UserHelp>
        <Text>Add content to the section.</Text>
      </UserHelp>
    );
  return (
    <Section key={_key} variant={style?.variant} sx={style}>
      <Flex
        direction="column"
        align={
          style?.alignment === "center"
            ? "center"
            : style?.alignment === "right"
            ? "flex-end"
            : "flex-start"
        }
      >
        {title && (
          <Section.Title
            // Force black when against accent
            sx={(theme) => {
              return {
                textAlign: style?.alignment,
                // Avoid hover effect
                color:
                  style?.variant === "accent"
                    ? getOnAccentColor(theme.colors.accent)
                    : "inherit",
              };
            }}
          >
            {title}
          </Section.Title>
        )}

        <Section.Body
          as={Flex}
          sx={{
            flexDirection: "column",
            alignItems:
              style?.alignment === "center"
                ? "center"
                : style?.alignment === "right"
                ? "flex-end"
                : "flex-start",
          }}
        >
          {_rawText && (
            <Text
              // Force black when against accent
              sx={(theme) => {
                return {
                  textAlign: style?.alignment,
                  // Avoid hover effect
                  color:
                    style?.variant === "accent"
                      ? getOnAccentColor(theme.colors.accent)
                      : "inherit",
                  columnCount: [1, null, numberOfColumns],
                  columnGap: [0, null, 3],
                  padding: 0,
                };
              }}
            >
              <RichText>{_rawText}</RichText>
            </Text>
          )}
          <CallToAction
            {...callToAction}
            sectionVariant={style?.variant}
            sx={{ marginTop: 3 }}
          />
        </Section.Body>
      </Flex>
    </Section>
  );
}

// export const fragment = graphql`
//   fragment SanityTextSectionFragment on SanityTextSection {
//     title
//     _rawText
//     numberOfColumns
//     callToAction {
//       ...CallToAction
//     }
//     style {
//       ...SectionStyle
//     }
//   }
// `;

TextSection.fragment = gql`
  fragment TextSectionFragment on TextSection {
    title
    _rawText: textRaw
    numberOfColumns
    callToAction {
      ...CallToAction
    }
    style {
      ...SectionStyle
    }
  }
  ${CallToAction.fragment}
`;

import Gallery from "@bottlebooks/bottlebooks-site-base/src/components/Gallery/Gallery.next";
import Section from "@bottlebooks/bottlebooks-site-base/src/components/Section/Section";
import { Container } from "@bottlebooks/gatsby-design-system";
import React from "react";

const publicIds = [
  "portwinefest.com/01_Port_Wine_Fest_Website_Taste_of_Vail.JPG",
  "portwinefest.com/02_Port_Wine_Fest_Website_Taste_of_Vail.JPG",
  "portwinefest.com/03_Port_Wine_Fest_Website_Taste_of_Vail.JPG",
  "portwinefest.com/04_Port_Wine_Fest_Website_Taste_of_Vail.JPG",
  "portwinefest.com/05_Port_Wine_Fest_Website_Taste_of_Vail.JPG",
  "portwinefest.com/06_Port_Wine_Fest_Website_Taste_of_Vail.JPG",
  "portwinefest.com/07_Port_Wine_Fest_Website_Taste_of_Vail.JPG",
  "portwinefest.com/08_Port_Wine_Fest_Website_Taste_of_Vail.JPG",
  "portwinefest.com/09_Port_Wine_Fest_Website_Taste_of_Vail.JPG",
  "portwinefest.com/10_Port_Wine_Fest_Website_Taste_of_Vail.JPG",
  "portwinefest.com/11_Port_Wine_Fest_Website_Taste_of_Vail.JPG",
  "portwinefest.com/12_Port_Wine_Fest_Website_Taste_of_Vail.JPG",
  "portwinefest.com/13_Port_Wine_Fest_Website_Taste_of_Vail.JPG",
  "portwinefest.com/14_Port_Wine_Fest_Website_Taste_of_Vail.JPG",
  "portwinefest.com/15_Port_Wine_Fest_Website_Taste_of_Vail.JPG",
];

const images = publicIds.map((id) => ({
  sourceUrl: `//res.cloudinary.com/bottlebooks/image/upload/${id}`,
  // Thumbnail
  image: {
    src: `https://res.cloudinary.com/bottlebooks/image/upload/c_fill,w_360,h_360/${id}`,
    sizes: "(max-width: 360px) 100vw, 360px",
    width: null,
    height: null,
    aspectRatio: 1,
  },
  fullPreview: {
    src: `https://res.cloudinary.com/bottlebooks/image/upload/w_360,h_360/${id}`,
  },
  full: {
    src: `https://res.cloudinary.com/bottlebooks/image/upload/w_1920/${id}`,
  },
}));

export default function PortWineFestImageGalleryVail() {
  return (
    <Container>
      <Section
        title="Gallery"
        sx={{
          paddingY: 3,
          margin: "auto",
          position: "relative",
          width: "100%",
          paddingBottom: "56.25%",
        }}
      >
        <Gallery data={images} />
      </Section>
    </Container>
  );
}

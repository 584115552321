/** @jsxImportSource theme-ui */
import { useLingui } from "@lingui/react";
import { FragmentType, graphql, useFragment } from "~/gql";

// We need to define the valid currency translations so the numberFormat doesn't throw errors.
const currencies = {
  $: "USD",
  USD: "USD",
  US$: "USD",
  "€": "EUR",
  EUR: "EUR",
  "£": "GBP",
  GBP: "GBP",
  CA$: "CAD",
  CAD: "CAD",
  INR: "INR",
  AED: "AED",
  NZD: "NZD",
  AUD: "AUD",
  OMR: "OMR",
  default: "EUR",
};

const fragment = graphql(/* GraphQL */ `
  fragment ProductPriceExact on ProductPrice {
    priceType
    price
    currencyCode: currency(format: RAW)
  }
`);

export default function ProductPriceExact({
  pricing: data,
  prependPriceType = false,
  currencyDisplay,
  useGrouping,
  ...rest
}: {
  pricing: FragmentType<typeof fragment>;
  prependPriceType?: boolean;
  currencyDisplay?: "symbol" | "code" | "name";
  useGrouping?: boolean;
  [x: string]: any;
}) {
  const pricing = useFragment(fragment, data);
  const { i18n } = useLingui();
  const priceFormat = {
    style: "currency" as const,
    currency: currencies[pricing?.currencyCode ?? ""] || currencies.default,
    currencyDisplay,
    useGrouping,
  };
  if (!pricing?.price) return null;
  if (prependPriceType)
    return (
      <span {...rest}>
        {pricing?.priceType} {i18n.number(pricing.price, priceFormat)}
      </span>
    );
  return <span {...rest}>{i18n.number(pricing.price, priceFormat)}</span>;
}

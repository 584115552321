/** @jsxImportSource theme-ui */
import {
  Box,
  Button,
  CloseIcon,
  FilterIcon,
  Flex,
  Text,
} from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";

export default function FiltersToggleButton({
  filtersHidden,
  onToggle,
  ...rest
}) {
  return (
    <Button onClick={onToggle} variant="text" sx={{ paddingX: 0 }} {...rest}>
      <Flex justify="space-between" align="center" gap={3}>
        <Text>
          {filtersHidden ? (
            <Trans>Show filters</Trans>
          ) : (
            <Trans>Hide filters</Trans>
          )}
        </Text>
        <FilterIcon size="small" />
      </Flex>
    </Button>
  );
}

import { Anchor, Button, createTheme } from "@mantine/core";

export const theme = createTheme({
  fontFamily: "Source Sans Pro, sans-serif",
  headings: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: "300",
  },
  primaryColor: "blue",
  components: {
    Anchor: Anchor.extend({
      defaultProps: {
        underline: "never",
      },
    }),
    Button: {
      defaultProps: {
        // blue.7
        color: "#0073e6",
      },
    },
  },
  colors: {
    blue: [
      "#e4f6ff",
      // lightestBlue
      "#d7e6f6",
      // lighterBlue
      "#7ba7de",
      // lightBlue
      "#638bbf",
      "#3d9afc",
      "#248bfc",
      "#1384fe",
      "#0073e6",
      // "#0071e3",
      "#0064cb",
      // '#0056b4',
      "#002f69",
    ],
    grey: [
      // silver
      "#f9f9f9",

      // lightGray
      // border
      // nuanced
      "#f2f2f2",
      "#e7e7e7",
      // gray
      "#ddd",
      // lightSmoke
      // borderNuanced
      // lightestText
      "#bbb",
      // smoke
      // lighterText
      "#888",
      "#848484",
      "#717171",
      // lightGraphite
      // lightText
      "#555",
      // graphite
      // title
      "#333",
    ],
    green: [
      "#f0faf3",
      "#e0f2e6",
      "#bce3c9",
      "#95d5aa",
      "#76c98f",
      "#61c17f",
      "#56be75",
      "#46a763",
      "#3b9457",
      "#2d8149",
    ],
    yellow: [
      "#fffae5",
      "#fff4d0",
      "#fee79f",
      "#feda69",
      "#fecf3f",
      "#fec826",
      "#fec419",
      "#e3ac0c",
      "#c99900",
      "#ae8400",
    ],
    red: [
      "#ffe9ec",
      "#ffd0d6",
      "#fd9da9",
      "#fb6879",
      "#fb3d52",
      "#fb2438",
      "#fc172b",
      "#e10b1e",
      "#c8011a",
      "#b00013",
    ],
  },
  /** Put your mantine theme override here */
});

/** @jsxImportSource theme-ui */
import { Text } from "@bottlebooks/gatsby-design-system";
import React from "react";
import { FragmentType, graphql, useFragment } from "~/gql";
import Flag from "../Flag/Flag";
import locationHierarchy from "./locationHierarchy";

const fragment = graphql(`
  fragment BrandRegion on Profile {
    countryName: country(format: LOCALIZED)
    stateProvince
    countryCode: country(format: RAW)
    region
    city
  }
  # fragment BrandRegion on AbstractBrand {
  #   countryName: country(full: true)
  #   stateProvince
  #   countryCode: country(full: false)
  #   region
  #   city
  # }
  fragment RegisteredBrand_BrandRegion on RegisteredBrand {
    profile {
      countryName: country(format: LOCALIZED)
      stateProvince
      countryCode: country(format: RAW)
      region
      city
    }
  }
`);

export default function BrandRegion({
  data,
  children,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
  children?: React.ReactNode;
}) {
  if (children) {
    return (
      <Text variant="smallest" {...rest}>
        {children}
      </Text>
    );
  }
  return (
    <BrandRegion data={data} {...rest}>
      <DefaultContent data={data} />
    </BrandRegion>
  );
}

export function DefaultContent({
  data,
}: {
  data: FragmentType<typeof fragment>;
}) {
  const brand = useFragment(fragment, data);
  if (!brand) return null;
  return (
    <React.Fragment>
      <Flag
        country={brand.countryCode || ""}
        // We don't need to provide an alt text because we add the countryName directly next to the flag.
        alt=""
        sx={{ marginRight: 0.5, verticalAlign: "baseline" }}
      />{" "}
      <span>{locationHierarchy({ brand }).filter(Boolean).join(", ")}</span>
    </React.Fragment>
  );
}

import BookmarksPage from "@bottlebooks/bottlebooks-site-bookmarks/src/components/BookmarksPage";
import { z } from "zod";
import { bbCollectionIdSchema } from "~/bbCollectionIdSchema";
import { useParams } from "~/router";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
  collectionId: z.string(),
});

export default function BookmarksRoute({ ...rest }) {
  const params = useParams("/:locale/collections/:collectionId/my/bookmarks");
  const { locale, collectionId: collectionIdSegment } =
    paramsSchema.parse(params);
  const collectionId = bbCollectionIdSchema.parse(collectionIdSegment);

  return (
    <BookmarksPage locale={locale} collectionId={collectionId} {...rest} />
  );
}

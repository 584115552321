/** @jsxImportSource theme-ui */
import { Box, Flex, Label, Text } from "@bottlebooks/gatsby-design-system";
import { BottlebooksLogo, Link } from "@bottlebooks/gatsby-theme-base/src";
// @ts-ignore TS doesn't like direct image imports.
import backToTop from "@bottlebooks/bottlebooks-site-base/src/assets/back-to-top.svg";
import getOnAccentColor from "@bottlebooks/bottlebooks-site-base/src/helpers/getOnAccentColor";
import SocialMediaBlock from "@bottlebooks/bottlebooks-site-cms/src/components/Blocks/SocialMediaBlock";
import { Trans } from "@lingui/macro";
import React from "react";
import sponsorBanner from "./assets/sponsor-banner.png";

export default function PortWineFestFooter({
  children,
  homePath = "",
  ...rest
}: {
  children: React.ReactNode;
  homePath?: string;
}) {
  return (
    <Box sx={{ paddingTop: [2, 4] }}>
      <Wrapper sx={{ backgroundColor: "transparent", paddingBottom: [2, 4] }}>
        <Top />
      </Wrapper>
      <Wrapper sx={{ paddingBottom: [2, 4], paddingTop: [2, 4] }}>
        {/* <SimpleGridSystem blocks={[]} style={{ variant: "accent" }} /> */}

        <Flex justify="center" sx={{ marginBottom: 2 }}>
          <SocialMediaBlock
            facebookUrl="https://www.facebook.com/profile.php?id=61555815104842"
            twitterUrl="https://x.com/portwine_fest"
            instagramUrl="https://www.instagram.com/portwinefest"
          />
        </Flex>

        <Flex justify="center" sx={{ paddingX: [5, 150, 200, 400] }}>
          <img
            src={sponsorBanner}
            alt=""
            sx={{ width: "100%", height: "auto" }}
          />
        </Flex>
        <Bottom />
      </Wrapper>
      <MetaPixel />
    </Box>
  );
}

function MetaPixel() {
  React.useEffect(() => {
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    );
    fbq("init", "1179646819437450"); // Insert your Pixel ID here
    fbq("track", "PageView");
  }, []);

  return null;
}

// function MetaPixel() {
//   return (
//     <Helmet>

// <script>
// !function(f,b,e,v,n,t,s)
// {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
// n.callMethod.apply(n,arguments):n.queue.push(arguments)};
// if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
// n.queue=[];t=b.createElement(e);t.async=!0;
// t.src=v;s=b.getElementsByTagName(e)[0];
// s.parentNode.insertBefore(t,s)}(window, document,'script',
// 'https://connect.facebook.net/en_US/fbevents.js');
// fbq('init', '1179646819437450');
// fbq('track', 'PageView');
// </script>
// <noscript><img height="1" width="1" style="display:none"
// src="https://www.facebook.com/tr?id=1179646819437450&ev=PageView&noscript=1"
// /></noscript>

//     </Helmet>
//   );
// }

export function Wrapper({ children, ...rest }) {
  return (
    <Box
      as="footer"
      sx={{
        variant: "styles.Footer",
        backgroundColor: "accent",
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}

export function Top({ ...rest }) {
  function handleScrollToTop(e) {
    window.scrollTo({ top: 0, behavior: "smooth" });
    e.preventDefault();
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        paddingBottom: [2, 4],
        paddingX: [2, 5],
      }}
      {...rest}
    >
      <Link
        href="#"
        onClick={handleScrollToTop}
        sx={{ marginLeft: "auto", color: "black" }}
      >
        <Label>
          <Trans>Back to the top</Trans>{" "}
          <img src={backToTop} alt="" sx={{ verticalAlign: "middle" }} />
        </Label>
      </Link>
    </Box>
  );
}

export function Bottom({ ...rest }) {
  return (
    <Flex direction="column" sx={{ paddingX: [2, 5], paddingTop: 3 }} {...rest}>
      <Box
        sx={(theme) => {
          return {
            width: "100%",
            borderTop: 1,
            borderColor: getOnAccentColor(theme.colors.accent),
            marginBottom: 3,
          };
        }}
      />
      <Flex align="baseline" gap={2}>
        <Text
          variant="smallest"
          sx={(theme) => {
            return {
              // Avoid hover effect
              color: getOnAccentColor(theme.colors.accent),
            };
          }}
        >
          <Flex align="center">
            <Trans>Powered by</Trans>{" "}
            <Link
              href="https://www.bottlebooks.me"
              sx={{ textTransform: "uppercase" }}
            >
              <BottlebooksLogo
                title="Bottlebooks®"
                sx={(theme) => {
                  return {
                    // Avoid hover effect
                    color: getOnAccentColor(theme.colors.accent),
                  };
                }}
              />
            </Link>
          </Flex>
          <Text variant="smallest">
            <Trans>Data management for the wine and drinks industry</Trans>
          </Text>
        </Text>

        <Text
          variant="smallest"
          sx={(theme) => {
            return {
              // Avoid hover effect
              marginLeft: "auto",
              color: getOnAccentColor(theme.colors.accent),
            };
          }}
        >
          2024
        </Text>
      </Flex>
    </Flex>
  );
}

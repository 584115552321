import { matchSorter, rankings } from 'match-sorter';

export default function useFacetFilter({ values, filter, selectedValues }) {
  return matchSorter(values, filter, {
    keys: [
      'value',
      {
        // Match all selected values so they don't simply disappear while searching.
        key: (item) => {
          return selectedValues?.includes?.(item.value) ? filter : null;
        },
        maxRanking: rankings.WORD_STARTS_WITH,
      },
    ],
    // This baseSort function will use the original index of items as the tie breaker
    baseSort: (a, b) => (a.index < b.index ? -1 : 1),
  });
}

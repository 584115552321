/** @jsxImportSource theme-ui */
import { P } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";

const fragment = graphql(`
  fragment BrandAnnualProduction_Profile on BrandProfile {
    annualProductionWine
  }
`);

export default function BrandAnnualProduction({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment> | null | undefined;
}) {
  const brand = useFragment(fragment, data);
  if (!brand || !brand.annualProductionWine) return null;
  return (
    <Section title={<Trans>Annual production (bottles)</Trans>} {...rest}>
      <P variant="title">{brand.annualProductionWine}</P>
    </Section>
  );
}

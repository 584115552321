/** @jsxImportSource theme-ui */
import { Box } from "@bottlebooks/gatsby-design-system";
import type { BoxProps } from "theme-ui";

export default function ContainerDialog({
  children,
  withBorder,
  ...rest
}: Omit<BoxProps, "sx"> & { children: React.ReactNode; withBorder?: boolean }) {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: "white",
        [`@media screen and (min-width: ${theme.breakpoints[1]})`]: {
          maxWidth: 480,
          marginY: 3,
          marginX: "auto",
          boxShadow: withBorder ? undefined : "xs",
          border: withBorder
            ? `2px dashed ${theme.colors.borderSecondary}`
            : undefined,
          borderRadius: "large",
        },
        // Just be sure the max-width is overwritten on desktop, we can activate this.
        // Shouldn't be needed though.
        [`@media screen and (min-width: ${theme.breakpoints[2]})`]: {
          maxWidth: 480,
        },
      })}
      {...rest}
    >
      {children}
    </Box>
  );
}

const linkVariants = {
  default: {
    textDecoration: 'none',
    transition: 'color',
  },

  text: {
    // The variant colors override the defaults set in the Link component.
    // Any colors defined on a child instance overrides these props.
    color: 'text',
    transition: 'color',
    textDecoration: 'none',
    ':hover': { color: 'secondary' },
  },

  onPrimary: {
    // The variant colors override the defaults set in the Link component.
    // Any colors defined on a child instance overrides these props.
    color: 'onPrimary',
    transition: 'color',
    textDecoration: 'underline',
    ':hover': { color: 'onPrimary', textDecoration: 'none' },
  },

  // The base variant for the <Button> component.
  // Those styles are applied to all Button components in addition to one of the buttonVariants and have to be overriden.
  button: {
    variant: 'text.small',
    display: 'inline-block',
    textDecoration: 'none',
    paddingX: 2,
    paddingY: 1,
    backgroundColor: 'transparent',
    borderRadius: 'default',
    borderColor: 'border',
  },

  menu: {
    variant: 'text.default',
    textDecoration: 'none',
    transition: 'color',
    display: 'inline-block',
    paddingX: 2,
    paddingY: 2,
    color: 'lightText',
    ':hover': { color: 'secondary' },
  },
} as const;

export default linkVariants;

export type LinkVariant = keyof typeof linkVariants;

/** @jsxImportSource theme-ui */
import {
  AwardIcon,
  FormattedText,
  P,
  Text,
} from "@bottlebooks/gatsby-design-system";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";

const fragment = graphql(`
  fragment ProductTastingNotes on Product {
    tastingNotes {
      noteDate
      note
      author
      publication
      rating
    }
  }
`);

export default function ProductTastingNotes({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const { i18n } = useLingui();
  const product = useFragment(fragment, data);
  if (!hasContent(data)) return null;
  return (
    <Section title={i18n._(t`Tasting Notes`)} {...rest}>
      {product.tastingNotes?.map((note) => (
        <TastingNote
          key={`${note.publication} - ${note.noteDate} – ${note.rating}`}
          data={note}
        />
      ))}
    </Section>
  );
}

function hasContent(data: FragmentType<typeof fragment>) {
  const product = useFragment(fragment, data);
  if (!product.tastingNotes?.length) return false;
  return product.tastingNotes.some((tastingNote) =>
    TastingNote.hasContent(tastingNote)
  );
}

const tastingNoteFragment = graphql(`
  fragment ProductTastingNotes_TastingNote on ProductTastingNote {
    noteDate
    note
    author
    publication
    rating
  }
`);

function TastingNote({
  data,
  ...rest
}: {
  data: FragmentType<typeof tastingNoteFragment>;
}) {
  const tastingNote = useFragment(tastingNoteFragment, data);
  return (
    <article {...rest}>
      <P>
        <AwardIcon sx={{ marginRight: 2 }} />
        {tastingNote.rating && (
          <Text as="span" variant="semiTitle" sx={{ marginRight: 2 }}>
            {tastingNote.rating}{" "}
          </Text>
        )}
        {tastingNote.publication}
        {tastingNote.noteDate && (
          <Text as="span" variant="small" sx={{ marginRight: 2 }}>
            {" "}
            {tastingNote.noteDate}
          </Text>
        )}
      </P>
      <FormattedText variant="large" text={tastingNote.note} />
      {tastingNote.author && <P>{tastingNote.author}</P>}
    </article>
  );
}
TastingNote.hasContent = function tastingNoteHasContent(
  data: FragmentType<typeof tastingNoteFragment>
) {
  const tastingNote = useFragment(tastingNoteFragment, data);
  if (!tastingNote) return false;
  if (tastingNote.noteDate) return true;
  if (tastingNote.note) return true;
  if (tastingNote.author) return true;
  if (tastingNote.publication) return true;
  if (tastingNote.rating) return true;
};

/** @jsxImportSource theme-ui */
import { Container } from "@bottlebooks/gatsby-design-system";
import React from "react";

export default React.forwardRef(function ListContainer(
  { children, ...rest },
  ref
) {
  return (
    <Container
      ref={ref}
      fluid
      // @ts-expect-error The sx prop is missing in the type
      sx={{
        maxWidth: "container.fluid",
        paddingX: [0, "gutter"],
        paddingBottom: 5,
      }}
      {...rest}
    >
      {children}
    </Container>
  );
});

/** @jsxImportSource theme-ui */
import { Col } from "@bottlebooks/gatsby-design-system";
import { FragmentType, graphql, useFragment } from "~/gql";
import ProductAnalytics from "../Product/ProductAnalytics.next";
import ProductAwards from "../Product/ProductAwards.next";
import ProductBottleVariants from "../Product/ProductBottleVariants.next";
import ProductCertifications from "../Product/ProductCertifications.next";
import ProductDescription from "../Product/ProductDescription.next";
import ProductGallery from "../Product/ProductGallery.next";
import ProductPackagingVariants from "../Product/ProductPackagingVariants.next";
import ProductPairingDescription from "../Product/ProductPairingDescription.next";
import ProductPalateDescription from "../Product/ProductPalateDescription.next";
import ProductRange from "../Product/ProductRange.next";
import ProductSensory from "../Product/ProductSensory.next";
import ProductTastingNotes from "../Product/ProductTastingNotes.next";
import ProductVideos from "../Product/ProductVideos.next";
import WineHarvestNotes from "../Product/WineHarvestNotes.next";
import WineMaturation from "../Product/WineMaturation.next";
import WineSoilType from "../Product/WineSoilType.next";
import WineVineyard from "../Product/WineVineyard.next";
import WineVinification from "../Product/WineVinification.next";
import WineViticulture from "../Product/WineViticulture.next";
import WineWinemaker from "../Product/WineWinemaker.next";

const fragment = graphql(`
  fragment ProductDetailsSection on Product {
    ...ProductDescription
    ...ProductAwards
    ...ProductTastingNotes
    ...ProductCertifications
    ...ProductAnalytics
    ...ProductGallery
    ...ProductVideos
    ...ProductRange
    ...WineWinemaker
    ...WineSoilType
    ...WineVineyard
    ...WineHarvestNotes
    ...WineViticulture
    ...WineVinification
    ...WineMaturation
    ...ProductPalateDescription
    ...ProductPairingDescription
    ...ProductBottleVariants
    ...ProductPackagingVariants
    ...ProductSensory
  }
`);

export default function ProductDetailsSection({
  data,
}: {
  data: FragmentType<typeof fragment>;
}) {
  const product = useFragment(fragment, data);
  return (
    <Col flex>
      <ProductDescription data={product} />
      <ProductAwards data={product} />
      <ProductTastingNotes data={product} />
      <ProductCertifications data={product} />
      <ProductAnalytics data={product} />
      <ProductGallery data={product} />
      <ProductVideos data={product} />
      <ProductRange data={product} />
      <WineVineyard data={product} />
      <WineSoilType data={product} />
      <WineWinemaker data={product} />
      <WineHarvestNotes data={product} />
      <WineViticulture data={product} />
      <WineVinification data={product} />
      <WineMaturation data={product} />
      <ProductSensory data={product} />
      <ProductPalateDescription data={product} />
      <ProductPairingDescription data={product} />
      <ProductBottleVariants data={product} />
      <ProductPackagingVariants data={product} />
    </Col>
  );
}

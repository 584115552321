/** @jsxImportSource theme-ui */
import { PersonalPageLayout } from "@bottlebooks/bottlebooks-site-bookmarks/src";
import {
  Box,
  Button,
  Container,
  Grid,
  P,
  Text,
  Title,
} from "@bottlebooks/gatsby-theme-base/src";
import { ProductCard } from "@bottlebooks/bottlebooks-site-base/src/components/ProductsList";
import { useProducts } from "@bottlebooks/bottlebooks-site-base/src/components/SiteSearch/useEventData";
import useSiteConfig from "@bottlebooks/bottlebooks-site-base/src/components/useSiteConfig";
import useLink from "@bottlebooks/bottlebooks-site-base/src/useLink";
import { Trans } from "@lingui/macro";
import React from "react";
import { useTastingNotes } from "../useTastingNote";
import ExploreAllProductsBanner from "./ExploreAllProductsBanner";

export default function TastingNotesPage({ locale, collectionId }) {
  return (
    <PersonalPageLayout
      locale={locale}
      collectionId={collectionId}
      sx={{ backgroundColor: "light", boxShadow: "none" }}
    >
      <Content />
    </PersonalPageLayout>
  );
}

function Content() {
  const { all, isLoading, error } = useTastingNotes();
  const { tastingNotesEnabled } = useSiteConfig();
  if (!tastingNotesEnabled) return <TastingNotesDisabled />;
  if (isLoading) return <Loading />;
  if (!all?.length) return <Empty />;
  if (error) return null;

  // const sortedNotes = orderBy(tastingNotes, 'addedAt', 'desc');
  return <TastingNotes tastingNotes={all} />;
}

function Loading(rest) {
  return (
    <Container sx={{ paddingY: [3, null, 5] }} {...rest}>
      <Text>Loading…</Text>
    </Container>
  );
}

function Empty(rest) {
  const link = useLink();
  return (
    <Container
      sx={{
        textAlign: [null, "center"],
        paddingBottom: 5,
        paddingX: "gutter",
      }}
      {...rest}
    >
      <Title sx={{ paddingTop: 5, marginBottom: 4 }}>
        <Trans>No products tasted.</Trans>
      </Title>
      <Text variant="large" sx={{ margin: "auto" }}>
        <Trans>
          You haven't saved any tasting notes. Start your list by tasting some
          products.
        </Trans>
      </Text>
      <Button variant="primary" to={link.products()} sx={{ marginTop: 4 }}>
        <Trans>Explore all products</Trans>
      </Button>
    </Container>
  );
}

function TastingNotes({ tastingNotes, ...rest }) {
  const products = useProducts();
  return (
    <>
      <Container
        fluid
        sx={{
          paddingX: [0, "gutter"],
          paddingTop: 4,
          paddingBottom: 5,
          maxWidth: "container.fluid",
        }}
        {...rest}
      >
        <Grid width={300} repeat="fit">
          {tastingNotes?.map((tastingNote) => (
            <ProductCard
              key={tastingNote.productId}
              product={products.findById(tastingNote.productId)}
            />
          ))}
        </Grid>
      </Container>
      <ExploreAllProductsBanner />
    </>
  );
}

function TastingNotesDisabled() {
  const link = useLink();
  return (
    <Container sx={{ paddingY: [3, null, 5] }}>
      <Box>
        <Title variant="large" sx={{ marginTop: 5, marginBottom: 4 }}>
          <Trans>Not available</Trans>
        </Title>
        <P>
          <Trans>Tasting notes are not enabled on this site.</Trans>
        </P>
        <Button variant="primary" to={link.products()} sx={{ marginTop: 4 }}>
          <Trans>Explore all products</Trans>
        </Button>
      </Box>
    </Container>
  );
}

/** @jsxImportSource theme-ui */
import { Link } from "@bottlebooks/gatsby-theme-base/src";

export default function ListRowSmall({ to, children, ...rest }) {
  const hasLink = Boolean(to || rest.onClick || rest.href);
  return (
    <Link
      variant="text"
      to={to}
      sx={{
        display: "block",
        paddingY: 2,
        paddingX: 3,
        ":hover": hasLink && { backgroundColor: "light" },
      }}
      {...rest}
    >
      {children}
    </Link>
  );
}

// This must be client-side because of the current version of emotion
import { LinkProvider } from "@bottlebooks/bottlebooks-site-base/src/useLink";
import { TastingNotesProvider } from "@bottlebooks/bottlebooks-site-tastingnotes/src/useTastingNote";
import sharedTheme from "@bottlebooks/gatsby-shared/src/theme";
import baseTheme from "@bottlebooks/bottlebooks-site-base/src/theme";
import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import { MantineEmotionProvider, emotionTransform } from "@mantine/emotion";
import { useParams } from "react-router-dom";
import { ThemeUIProvider } from "theme-ui";
import { z } from "zod";
import { theme as mantineTheme } from "~/mantineTheme";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
  collectionId: z.string(),
});

export default function CollectionProviders({
  children,
}: {
  children: React.ReactNode;
}) {
  const params = paramsSchema.parse(useParams());
  const paths = {
    products: "/products",
    exhibitors: "/exhibitors",
    producers: "/producers",
    base: `/${params.locale}/collections/${params.collectionId}`,
  };
  return (
    <ThemeUIProvider theme={{ ...baseTheme, ...sharedTheme }}>
      <MantineProvider theme={mantineTheme} stylesTransform={emotionTransform}>
        <MantineEmotionProvider>
          <TastingNotesProvider>
            <LinkProvider
              value={(context: any) =>
                context || paths
                  ? { ...context, ...paths, locale: params.locale }
                  : undefined
              }
              locale={params.locale}
              collectionId={params.collectionId}
            >
              {children}
            </LinkProvider>
          </TastingNotesProvider>
        </MantineEmotionProvider>
      </MantineProvider>
    </ThemeUIProvider>
  );
}

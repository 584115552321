/** @jsxImportSource theme-ui */
import { Text, Title } from "@bottlebooks/gatsby-design-system";
import { Button, Dialog } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import type { DialogProps } from "@reach/dialog";
import { X, XCircle } from "@phosphor-icons/react";
import { Fragment } from "react";
import type { ErrorActions } from "./RequestChatButton.StateMachine";

type ErrorProps = ErrorActions & {
  onDismiss: DialogProps["onDismiss"];
  profile: { displayName?: string | null };
};

export default function SubmissionError({
  onDismiss,
  profile,
  message,
  title,
  onRetry,
}: ErrorProps): JSX.Element | null {
  return (
    <Fragment>
      <Dialog.Header onDismiss={onDismiss}>
        <Trans>Start a chat with {profile.displayName}</Trans>
      </Dialog.Header>
      <Dialog.Content sx={{ textAlign: "center" }}>
        <XCircle size={64} weight="light" sx={{ color: "brand.warningRed" }} />
        <Title variant="small">{title}</Title>
        <Text variant="small">{message}</Text>
      </Dialog.Content>
      <Dialog.Footer justify="space-between">
        {onRetry && (
          <Button variant="secondary" onClick={onRetry}>
            <Trans>Back</Trans>
          </Button>
        )}
        <Button variant="primary" onClick={onDismiss}>
          <X size={22} weight="light" sx={{ marginRight: 1 }} />
          <Trans>Close</Trans>
        </Button>
      </Dialog.Footer>
    </Fragment>
  );
}

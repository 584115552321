import { gql } from "urql";
import { SanityPage } from "../../../components";

export const pageQuery = gql`
  query ($pageId: ID!) {
    Page(id: $pageId) {
      ...SanityPage
    }
  }
  ${SanityPage.fragment}
`;

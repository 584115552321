/** @jsxImportSource theme-ui */
import Card from "@bottlebooks/gatsby-design-system/src/components/Card/Card";
import React from "react";
import { FragmentType, graphql, useFragment } from "~/gql";
import useLink from "../../useLink";
import { ExhibitorListRow } from "../ExhibitorsList";
import { ProducerListRow } from "../ProducersList";
import ProductCard from "../ProductCard/ProductCard.next";
import { useExhibitor } from "../SiteSearch/useEventData";
import FlightListRow from "./FlightListRow";
import ProductsList from "./ProductsList";

const fragment = graphql(/* GraphQL */ `
  fragment GroupedProductsCard on Product {
    productId
    ...ProductCard
    # This is used to get the logo for the producer in the ProducerListRow
    producer {
      ...BrandLogo
    }
  }

  # fragment GroupedProductsCard_RegisteredProduct on RegisteredProduct {
  #   ...FlightListRow
  # }
`);

export default function GroupedProductsCard({
  exhibitorId,
  groupedBy = "exhibitor",
  products: data,
  ...rest
}: {
  exhibitorId: string;
  groupedBy?: "exhibitor" | "producer" | "room" | "flightName" | "hidden";
  products: FragmentType<typeof fragment>[];
  to?: string;
  onClick?: React.MouseEventHandler;
  children?: React.ReactNode;
}) {
  const link = useLink();
  const products = useFragment(fragment, data);
  const exhibitorData = useExhibitor(exhibitorId);
  const producer = products?.[0]?.producer;
  const flightName = products?.[0]?.flightName;
  if (groupedBy === "flightName")
    return (
      <FlightListRow flightName={flightName}>
        <ProductsList sx={{ marginBottom: 4 }}>
          {products.map((product) => (
            <ProductCard
              key={product.productId}
              product={product}
              elevation="flat"
            />
          ))}
        </ProductsList>
      </FlightListRow>
    );
  return (
    <Card
      sx={{ gap: 3, padding: 3, gridTemplateRows: "auto", gridAutoRows: "1fr" }}
      {...rest}
    >
      {groupedBy === "exhibitor" ? (
        <ExhibitorListRow
          elevation="flat"
          exhibitor={exhibitorData}
          to={exhibitorData && link.exhibitor(exhibitorData)}
        />
      ) : groupedBy === "producer" ? (
        <ProducerListRow
          elevation="flat"
          producer={{ exhibitorId, ...producer }}
          exhibitor={exhibitorData}
          to={exhibitorData && link.exhibitor(exhibitorData)}
        />
      ) : null}
      <ProductsList repeat="fit">
        {products.map((product) => (
          <ProductCard
            key={product.productId}
            product={product}
            elevation="flat"
          />
        ))}
      </ProductsList>
    </Card>
  );
}

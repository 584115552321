/** @jsxImportSource theme-ui */
import { Flex, Text } from "@bottlebooks/gatsby-design-system";
import { keyframes } from "@emotion/react";
import { Trans } from "@lingui/macro";
import { CircleNotch } from "@phosphor-icons/react";
import type { ComponentPropsWithoutRef, ReactNode } from "react";

type LoadingAuthProps = ComponentPropsWithoutRef<typeof Flex> & {
  prepend?: ReactNode;
  append?: ReactNode;
};

const rotateCenter = keyframes({
  "0%": { transform: "rotate(0)" },
  to: { transform: "rotate(360deg)" },
});

export default function LoadingAuth({
  append,
  prepend,
  ...rest
}: LoadingAuthProps) {
  return (
    <Flex direction="column" gap={3} sx={{ textAlign: "center" }} {...rest}>
      {prepend}
      <CircleNotch
        size={64}
        weight="thin"
        sx={{
          animation: `${rotateCenter} 1.5s ease-in-out infinite both`,
          color: "lighterText",
          marginX: "auto",
        }}
      />
      <Text sx={{ textAlign: "center" }}>
        <Trans>Checking your login…</Trans>
      </Text>
      {append}
    </Flex>
  );
}

/** @jsxImportSource theme-ui */
import { Box } from "@bottlebooks/gatsby-design-system";
import type { ComponentProps } from "react";

type DialogContentProps = Omit<ComponentProps<typeof Box>, "sx"> & {
  withPanels?: boolean;
};

export default function DialogContent({
  children,
  withPanels,
  ...rest
}: DialogContentProps) {
  return (
    <Box
      sx={{
        padding: 3.5,
        ...(withPanels && {
          display: ["block", "grid"],
          gridTemplateColumns: "320px 1fr",
        }),
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}

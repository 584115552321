/** @jsxImportSource theme-ui */
import { Text } from "@bottlebooks/gatsby-design-system";
import { Col, Link } from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";
import ProductImage from "../Product/ProductImage";
import ProductName from "../Product/ProductName.next";

const fragment = graphql(`
  fragment RelatedProduct on RegisteredProduct {
    product {
      ...ProductName
      bottleImage {
        ...Image
      }
    }
  }
  # fragment RelatedProduct on Product {
  #   slug
  #   ...ProductImage
  #   ...ProductName
  # }
`);

export default function RelatedProduct({
  data,
  to,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
  to?: string;
}) {
  const registeredProduct = useFragment(fragment, data);
  return (
    <Col {...rest}>
      <Link to={to} fallback="div">
        <ProductImage product={registeredProduct?.product} size="default" />
        <Text
          variant="small"
          sx={{
            color: "lighterText",
            overflow: "hidden",
            textOverflow: "ellipsis",
            paddingY: 2,
          }}
        >
          <ProductName {...registeredProduct?.product} />
        </Text>
      </Link>{" "}
    </Col>
  );
}

/** @jsxImportSource theme-ui */
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import Gallery from "../Gallery/Gallery.next";
import Section from "../Section/Section";

const fragment = graphql(`
  fragment ProductGallery on Product {
    galleryImages {
      ...Gallery
    }
  }
`);

export default function ProductGallery({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const product = useFragment(fragment, data);
  if (!hasContent(product)) return null;
  return (
    <Section title={<Trans>Gallery</Trans>} {...rest}>
      <Gallery data={product.galleryImages} />
    </Section>
  );
}

function hasContent(product) {
  if (!product) return false;
  if (product.galleryImages?.filter(Boolean).length) return true;
  return false;
}

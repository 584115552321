/** @jsxImportSource theme-ui */
import {
  BookmarkIcon,
  EditIcon,
  ExportIcon,
  Flex,
  P,
  SemiTitle,
  Title,
} from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import React from "react";
// import Video from "@bottlebooks/bottlebooks-site-base/src/components/Video/Video";

export default function FeatureOverview() {
  return (
    <React.Fragment>
      <Title sx={{ flexGrow: 1, marginBottom: 3 }}>
        <Trans>Create your personal tasting list</Trans>
      </Title>

      <P>
        <Trans>
          Personalize your visit to be as productive and enjoyable as possible.
        </Trans>
      </P>

      {/* <Video
        type="YOUTUBE"
        videoId="E3Jw52OYGTA"
        url="https://www.youtube.com/watch?v=E3Jw52OYGTA"
        sx={{ marginBottom: 3, maxWidth: 600, width: '100%' }}
      /> */}
      <React.Fragment>
        {/* <Title sx={{ marginTop: 3 }}>Key features</Title> */}
        <SemiTitle>
          <Flex
            justify="center"
            align="center"
            sx={{
              border: 1,
              borderColor: "black",
              borderRadius: 999,
              display: "inline-block",
              marginRight: 3,
              width: 30,
              height: 30,
            }}
          >
            <BookmarkIcon
              variant="filled"
              size="smallMedium"
              sx={{
                width: 25,
                height: 25,
                marginTop: "2px",
                marginLeft: "2px",
              }}
            />
          </Flex>
          <Trans>My list</Trans>
        </SemiTitle>
        <P>
          <Trans>
            Click the bookmark icon to add exhibitors and products to your list.
          </Trans>
        </P>

        <SemiTitle>
          <EditIcon size="smallMedium" sx={{ marginRight: 3 }} />
          <Trans>Tasting notes</Trans>
        </SemiTitle>
        <P>
          <Trans>
            Add tasting notes on your smartphone at the event, and export them
            when you get home.
          </Trans>
        </P>
      </React.Fragment>
      <SemiTitle>
        <ExportIcon size="smallMedium" sx={{ marginRight: 3 }} />
        <Trans>Excel export</Trans>
      </SemiTitle>
      <P>
        <Trans>
          Need to import data into another program? Export your list and tasting
          notes in Excel.
        </Trans>
      </P>
    </React.Fragment>
  );
}

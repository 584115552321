import { print } from "graphql";
import { DocumentNode } from "graphql/language/ast";
import env from "~/env";

export default async function fetchSanity<GraphQLType>(
  query: DocumentNode,
  variables: {
    // This is required for caching
    collectionId: string;
  } & Record<string, any>,
  options?: {
    projectId: string;
    "bottlebooks-use-request-cache"?: string;
    "bottlebooks-request-cache-invalidation-key"?: string;
  }
) {
  const projectId = options?.projectId;
  if (!projectId) throw new Error("No projectId was provided");
  if (!query) throw new Error("No query was provided");
  const parsedQuery = print(query);
  const response = await fetch(`${env.VITE_API_URL}/pub/sanity/${projectId}`, {
    method: "POST",
    credentials: "include",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      ...getHeaders(options),
    },
    body: JSON.stringify({
      query: parsedQuery,
      variables,
      projectId,
    }),
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json() as Promise<{
    data: GraphQLType;
    errors: { message: string; path: string }[];
  }>;
}

function getHeaders(options?: {
  "bottlebooks-use-request-cache"?: string;
  "bottlebooks-request-cache-invalidation-key"?: string;
}):
  | {
      "bottlebooks-use-request-cache": string;
      "bottlebooks-request-cache-invalidation-key": string;
    }
  | {
      "bottlebooks-use-request-cache": string;
    }
  | null {
  if (!options) return null;
  if (!options["bottlebooks-use-request-cache"]) {
    return null;
  }
  if (options["bottlebooks-request-cache-invalidation-key"]) {
    return {
      "bottlebooks-use-request-cache": "true",
      "bottlebooks-request-cache-invalidation-key":
        options["bottlebooks-request-cache-invalidation-key"],
    };
  }
  return {
    "bottlebooks-use-request-cache": "true",
  };
}

/** @jsxImportSource theme-ui */
import "@reach/dialog/styles.css";
import type { ReactNode } from "react";
import type { IconButtonProps } from "./IconButton";
import IconButton from "./IconButton";

export type ActionButtonProps = IconButtonProps & {
  id?: string;
  children: ReactNode;
};

/** An action button styled as in the Networking templates. */
export default function ActionButton({ children, ...rest }: ActionButtonProps) {
  return (
    <IconButton
      variant="secondary"
      sx={{
        display: "inline-flex",
        alignItems: "center",
        textAlign: "center",
        paddingX: 3,
        fontSize: "smallest",
        lineHeight: 1,
      }}
      {...rest}
    >
      {children}
    </IconButton>
  );
}

/** @jsxImportSource theme-ui */
import React from "react";
import { FragmentType, graphql, useFragment } from "~/gql";
import BrandHeader from "../Brand/BrandHeader.next";
import ExhibitorStand from "./ExhibitorStand.next";

const fragment = graphql(`
  fragment ExhibitorHeader on Profile {
    # Not available on Profile
    # ...ExhibitorStand
    ...BrandHeader
  }
`);

export default function ExhibitorHeader({
  data,
  onDark = false,
  gap = 2,
  as = undefined,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
  onDark?: boolean;
  gap?: number;
  as?: React.ReactNode;
}) {
  const exhibitor = useFragment(fragment, data);
  return (
    <React.Fragment>
      <ExhibitorStand
        exhibitor={exhibitor || {}}
        sx={{ paddingBottom: gap, color: onDark ? "onDark" : "lighterText" }}
      />
      <BrandHeader as={as} data={exhibitor || {}} {...rest} />
    </React.Fragment>
  );
}

import { z } from 'zod';

/** The base schema for reading data from the database. */
const attendeeProfileSchema = z.object({
  displayName: z.string(),
});

/**
 * Used for form validation.
 * Meant to be used with form fields.
 * Pick the fields you need from this object or use `.shape`.
 */
export const attendeeProfileFormSchema = z.object({
  /** Display name is required and must be at least 1 character.
  We shouldn't make this any stricter because there are always exceptions such as single-character names. */
  displayName: z.string().trim().min(1, `Please enter your name.`),
  jobTitle: z.string().trim(),
  companyName: z.string().trim(),

  /** Telephone number is a string starting with a '+' and 7-14 digits. */
  telephone: z
    .string()
    .trim()
    .refine(
      (value) =>
        !value ||
        (value.startsWith('+') && /^\+?[1-9][0-9]{7,14}$/.test(value)),
      `Please enter a phone number starting with a '+' and 7-14 digits. 
      Do not include spaces or other characters.`
    )
    .optional()
    .transform((value) => value || ''), // Convert to empty string to override values in Firebase.

  /** A LinkedIn URL - must contain linkedin.com, or be an empty string. */
  linkedInURL: z
    .string()
    .trim()
    .transform((value) => value || undefined) // Convert to undefined if empty string, so we can use .optional() in the next step.
    .pipe(
      z
        .string()
        .regex(
          /^https:\/\/\w*\.?linkedin.com\//,
          /*i18n*/ { message: `Please enter a valid LinkedIn URL.` }
        )
        .optional()
    )
    .transform((value) => value || ''),

  /**  This is the existing profile image URL. It won't be set by the form, but should still be there for reference. */
  profileImageUrl: z.string().url().or(z.literal('')),

  /** A new profile image that gets uploaded. It's a File object with props we can use to validate file size or format. */
  profileImage: z
    .any()
    .refine(
      (value) => !(value instanceof File) || value.size > 5000,
      `The image is too small. It should at least have 5kb.`
    )
    .refine(
      (value) => !(value instanceof File) || value.size < 10_000_000,
      `The image is too large. Try one smaller than 10MB.`
    ),
});

/** @jsxImportSource theme-ui */
import { FormattedText } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";

const fragment = graphql(`
  fragment WineSoilType on Product {
    __typename
    ... on Wine {
      soilType
    }
  }
`);

export default function WineSoilType({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const product = useFragment(fragment, data);
  const wine = product.__typename === "Wine" ? product : null;
  if (!wine?.soilType) return null;
  return (
    <Section title={<Trans>Soil Type</Trans>} {...rest}>
      <FormattedText text={wine.soilType} variant="large" />
    </Section>
  );
}

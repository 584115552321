/** @jsxImportSource theme-ui */
import { Col, Row } from "@bottlebooks/gatsby-theme-base/src";
import { graphql } from "~/gql";
import useLink from "../../useLink";
import {
  ProductHeaderDetails,
  ProductName,
  ProductProducer,
} from "../Product/ProductHeader.next";
import ProductImageCol from "./ProductImageCol";
import ProductNavigation from "./ProductNavigation";

const fragment = graphql(/* GraphQL */ `
  fragment ProductPageHeader on RegisteredProduct {
    ...ProductHeaderDetails
    product {
      ...ProductHeaderProductName
      ...ProductHeaderProducerName
    }
  }
`);

export default function ProductPageHeader({
  product,
  next,
  previous,
  overlapImage,
  className = "",
}) {
  const link = useLink();
  return (
    <ProductPageHeaderRow className={className}>
      <ProductImageCol product={product} overlap={overlapImage} />
      <ProductMainCol>
        <ProductProducer data={product} to={link.producer(product?.producer)} />
        <ProductName as="h1" product={product} />
        <ProductHeaderDetails data={registeredProduct} />
      </ProductMainCol>

      <ProductNavigation previous={previous} next={next} />
    </ProductPageHeaderRow>
  );
}

export function ProductPageHeaderRow({ children, ...rest }) {
  return (
    <Row sx={{ justifyContent: "center" }} {...rest}>
      {children}
    </Row>
  );
}

/**
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @returns
 */
export function ProductMainCol({ children }) {
  return (
    <Col
      sx={{
        flex: "1",
        minWidth: ["100%", "unset"],
        order: [1, "unset"],
      }}
    >
      {children}
    </Col>
  );
}

// export const fragment = graphql(`;
//   fragment ProductPageHeader_Navigation on Product {
//     ...ChangeProductLink
//     ...useLink_Product
//   }
//   fragment bb_ProductPageHeader on Bottlebooks_Product {
//     ...bb_ProductHeaderDetails
//     producer {
//       ...useLink_bb_Producer
//     }
//   }
//   fragment ProductPageHeader on Product {
//     ...ProductImageLarge
//     ...ProductProducerName
//     ...ProductName
//     ...ProductHeaderDetails
//   }
// `);

import { useLingui } from '@lingui/react';
import { t } from '@lingui/macro';

/**
 * Translates a key for a filter.
 * @param {Record<string, Record<string, string | import('@lingui/core').MessageDescriptor>> | undefined} definitions
 */
export default function useFilterTranslations(definitions) {
  const { i18n } = useLingui();
  if (!definitions) {
    return (/** @type {string} */ key) => key || i18n._(t`(No value)`);
  }
  return function translate(name, /** @type {string} */ key) {
    const translation = definitions?.[name]?.translations?.[key];
    if (!translation) return key;
    if (typeof translation === 'string') return translation;
    if (!translation.id) return key;
    return i18n._(translation.id);
  };
}

/** @jsxImportSource theme-ui */
import { FragmentType, graphql, useFragment } from "~/gql";
import CoverImage from "../CoverImage";

const fragment = graphql(`
  fragment BrandBanner_RegistrationProfile on RegistrationProfile {
    name
    mainImage {
      publicId
      fluid(maxWidth: 2560, maxHeight: 720, crop: FILL, gravity: FACES_AUTO) {
        src
        srcSet
        sizes
        base64
        aspectRatio
      }
    }
  }
  fragment BrandBanner_BrandProfile on BrandProfile {
    name
    mainImage {
      publicId
      fluid(maxWidth: 2560, maxHeight: 720, crop: FILL, gravity: FACES_AUTO) {
        src
        srcSet
        sizes
        base64
        aspectRatio
      }
    }
  }
  fragment BrandBanner on Profile {
    name
    mainImage {
      publicId
      fluid(maxWidth: 2560, maxHeight: 720, crop: FILL, gravity: FACES_AUTO) {
        src
        srcSet
        sizes
        base64
        aspectRatio
      }
    }
  }
`);

export default function BrandBanner({
  data,
  className,
}: {
  data: FragmentType<typeof fragment>;
  className?: string;
}) {
  const { name, mainImage } = useFragment(fragment, data);
  return (
    <div
      sx={{
        minHeight: [200, 250],
        position: "relative",
        backgroundColor: "light",
      }}
      className={className}
    >
      {mainImage && (
        <CoverImage
          fluid={mainImage.fluid}
          loading="eager"
          sx={{ position: ["absolute", null, "static"] }}
          alt={name}
        />
      )}
    </div>
  );
}

/** @jsxImportSource theme-ui */
import { Box, Flex, Text } from "@bottlebooks/gatsby-design-system";
import { Button } from "@bottlebooks/gatsby-theme-base/src";
import type { FieldAttributes } from "formik";
import { ErrorMessage, Field, useField } from "formik";
import type { ComponentPropsWithRef, ReactNode } from "react";
import type { Theme } from "theme-ui";

type FieldProps<T> = FieldAttributes<T> & {
  label?: ReactNode;
};

export function TextField({
  name,
  label,
  ...rest
}: FieldAttributes<ComponentPropsWithRef<"input"> & { label?: ReactNode }>) {
  const [, meta] = useField({ name, ...rest });
  const hasError = Boolean(meta.error && meta.touched);
  return (
    <Box>
      <Box as="label" sx={{ display: "block" }}>
        <Text variant="small">{label}</Text>
        <Field
          name={name}
          sx={(theme: Theme) => ({
            border: 1,
            borderColor: hasError ? "brand.warningRed" : "borderNuanced",
            boxShadow: hasError
              ? // @ts-expect-error brand colors aren't defined yet.
                `0 0 0 1px ${theme.colors?.brand?.warningRed}`
              : undefined,
            borderRadius: "default",
            paddingX: 2,
            paddingY: 2,
            display: "block",
            width: "100%",
            fontFamily: "body",
            fontSize: "default",
          })}
          {...rest}
        />
      </Box>
      <ErrorMessage name={name} component={Error} />
    </Box>
  );
}

export function SelectField({
  name,
  label,
  children,
  ...rest
}: FieldAttributes<ComponentPropsWithRef<"select"> & { label?: ReactNode }>) {
  const [, meta] = useField({ name, ...rest });
  const hasError = Boolean(meta.error && meta.touched);
  return (
    <Box>
      <Box as="label" sx={{ display: "block" }}>
        <Text variant="small">{label}</Text>
        <Field
          name={name}
          as="select"
          sx={(theme: Theme) => ({
            border: 1,
            borderColor: hasError ? "brand.warningRed" : "borderNuanced",
            boxShadow: hasError
              ? // @ts-expect-error brand colors aren't defined yet.
                `0 0 0 1px ${theme.colors?.brand?.warningRed}`
              : undefined,
            borderRadius: "default",
            paddingX: 2,
            paddingY: 2,
            display: "block",
            width: "100%",
            fontFamily: "body",
            fontSize: "default",
            appearance: "none",
            color: "lightText",
            backgroundImage: `linear-gradient(45deg,transparent 50%,${theme.colors?.lightText} 50%),linear-gradient(135deg,${theme.colors?.lightText} 50%,transparent 50%),linear-gradient(to right,${theme.colors?.lightText},${theme.colors?.lightText})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: `calc(100% - 18px) 18px,calc(100% - 13px) 18px,calc(100% - 2.5em) 5px`,
            backgroundSize: `5px 5px,5px 5px,1px 28px`,
          })}
          {...rest}
        >
          {children}
        </Field>
      </Box>
      <ErrorMessage name={name} component={Error} />
    </Box>
  );
}

export function CheckboxField({
  name,
  label,
  className,
  ...rest
}: FieldAttributes<ComponentPropsWithRef<"input"> & { label?: ReactNode }>) {
  return (
    <Box className={className}>
      <Text as="label" sx={{ display: "block" }}>
        <Flex align="flex-start" gap={2}>
          <Field
            type="checkbox"
            name={name}
            sx={{ minWidth: 18, minHeight: 18 }}
            {...rest}
          />{" "}
          <Box>{label}</Box>
        </Flex>
      </Text>
      <ErrorMessage name={name} component={Error} sx={{ marginBottom: 1 }} />
    </Box>
  );
}

export type RadioGroupFieldProps = Omit<
  ComponentPropsWithRef<typeof Box> &
    FieldAttributes<{
      label?: ReactNode;
      children: ReactNode;
    }>,
  "sx"
>;

export function RadioGroupField({
  name,
  label,
  children,
  ...rest
}: RadioGroupFieldProps) {
  return (
    <Box>
      <Text as="label" sx={{ display: "block" }}>
        <Text variant="small">{label}</Text>
        <Box {...rest}>{children}</Box>
      </Text>
      <ErrorMessage name={name} component={Error} sx={{ marginBottom: 1 }} />
    </Box>
  );
}

export function RadioToggleField({
  name,
  label,
  value,
  id = value,
  ...rest
}: FieldProps<
  ComponentPropsWithRef<"input"> & {
    label?: ReactNode;
    value: string;
    id?: string;
  }
>) {
  return (
    <Box
      sx={{
        "& input[type=radio]:checked+label": {
          color: "onPrimary",
          backgroundColor: "primary",
          borderColor: "primary",
          ":hover": {
            color: "onSecondary",
            backgroundColor: "secondary",
            borderColor: "secondary",
          },
        },
        "& input[type=radio]:disabled+label": {
          backgroundColor: "borderNuanced",
          borderColor: "borderNuanced",
          opacity: 0.3,
        },
      }}
    >
      <Field
        type="radio"
        name={name}
        id={id}
        value={value}
        sx={{ display: "none" }}
        {...rest}
      />{" "}
      <Button
        as="label"
        variant="empty"
        htmlFor={id}
        sx={{
          display: "block",
          paddingY: [2, 2.5],
          textAlign: "center",
          border: 1,
          borderRadius: "default",
          borderColor: "borderNuanced",
          transition: "all 0.3s ease-out",
          fontSize: "smaller",
          ":hover": {
            borderColor: "text",
            backgroundColor: "light",
          },
        }}
      >
        {label}
      </Button>
    </Box>
  );
}

function Error({ children, ...rest }) {
  if (!children) return null;
  return (
    <Text sx={{ color: "brand.warningRed", fontSize: "small" }} {...rest}>
      {children}
    </Text>
  );
}

/** @jsxImportSource theme-ui */
import { Button, P, Text, Title } from "@bottlebooks/gatsby-theme-base/src";
import useLink from "@bottlebooks/bottlebooks-site-base/src/useLink";
import { Trans } from "@lingui/macro";
import React from "react";
import { PersonalPageLayout, useFavorites } from "..";
import BookmarksList from "./BookmarksList";
import ExploreAllExhibitorsBanner from "./ExploreAllExhibitorsBanner";
import ListContainer from "./ListContainer";

export default function BookmarksPage({
  locale,
  collectionId,
}: {
  locale: string;
  collectionId: string;
}) {
  return (
    <PersonalPageLayout
      locale={locale}
      collectionId={collectionId}
      sx={{ boxShadow: "none" }}
    >
      <Content locale={locale} />
    </PersonalPageLayout>
  );
}

function Content({ locale }: { locale: string }) {
  const { favorites, isLoading, error } = useFavorites();
  if (isLoading) return <Loading />;
  if (error) return <Error error={error} />;
  if (!favorites?.length) return <EmptyState />;
  return <BookmarksList locale={locale} />;
}

/**
 * @param {JSX.IntrinsicAttributes & { [x: string]: any; }} rest
 */
function Loading(rest) {
  return (
    <React.Fragment>
      <ListContainer {...rest}>
        <Text>
          <Trans>Loading…</Trans>
        </Text>
      </ListContainer>
      <ExploreAllExhibitorsBanner />
    </React.Fragment>
  );
}

/** @param {{ error: Error; [x: string]: any }} props */
function Error({ error, ...rest }) {
  return (
    <React.Fragment>
      {/* @ts-ignore TODO forwardRef seems not to handle types correctly. */}
      <ListContainer>
        <Text sx={{ color: "brand.warningRed" }}>
          <Trans>An error has occurred.</Trans>
        </Text>
        <Text variant="small">{error.message}</Text>
      </ListContainer>
      <ExploreAllExhibitorsBanner />
    </React.Fragment>
  );
}

/**
 * @param {JSX.IntrinsicAttributes} rest
 */
function EmptyState(rest) {
  const link = useLink();
  return (
    // @ts-ignore TODO forwardRef seems not to handle types correctly.
    <ListContainer
      sx={{ textAlign: [null, "center"], paddingX: "gutter" }}
      {...rest}
    >
      <Title sx={{ paddingTop: 5, marginBottom: 4 }}>
        <Trans>No exhibitors saved.</Trans>
      </Title>
      <P variant="large" sx={{ margin: "auto" }}>
        <Trans>
          You haven't saved any exhibitors to visit. Start your bookmark list by
          saving some exhibitors.
        </Trans>
      </P>
      <Button variant="primary" to={link.exhibitors()} sx={{ marginTop: 4 }}>
        <Trans>Explore all exhibitors</Trans>
      </Button>
    </ListContainer>
  );
}

import Box from '../Grid/Box';
import React from 'react';

interface CardProps {
  children: React.ReactNode;
  /** The amount of shadow the card is casting. */
  elevation?: import('./cardVariants').CardElevation;
  variant?: import('./cardVariants').CardVariant;
  [x: string]: any;
}

function Card(
  { children, elevation = 'medium', variant = 'default', ...rest }: CardProps,
  ref: React.Ref<any> | undefined
) {
  return (
    <Box
      ref={ref}
      sx={{
        display: 'grid', // Makes it easier to lay out content, for example expand to full height.
        position: 'relative', // Allows content to be positioned relative to the card.
        borderRadius: 'default',
        backgroundColor: 'background',
        variant: `card.${variant}.${elevation}`,
      }}
      {...rest}
    >
      {children}
    </Box>
  );
}

export default React.forwardRef(Card);

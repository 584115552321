/** @jsxImportSource theme-ui */
import { useFavorites } from "@bottlebooks/bottlebooks-site-bookmarks/src";
import { Button, Dialog, Label } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";

export default function EditNameDialog({ onDismiss, list }) {
  const { updateListName } = useFavorites();
  function handleSubmit(e) {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const name = formData.get("listName");
    if (name) {
      updateListName(list.listId, name);
    }
    onDismiss();
  }
  return (
    <Dialog style={{ maxWidth: 400 }} onDismiss={onDismiss}>
      <form onSubmit={handleSubmit}>
        <Dialog.Header onDismiss={onDismiss}>
          <Trans>Edit name</Trans>
        </Dialog.Header>
        <Dialog.Content>
          <Label>
            <Trans>List name</Trans>
          </Label>
          <input
            name="listName"
            defaultValue={list.name}
            sx={{ fontSize: 16, height: 38, paddingX: 2 }}
          />
        </Dialog.Content>
        <Dialog.Footer gap={2}>
          <Button variant="secondary" onClick={onDismiss}>
            <Trans>Cancel</Trans>
          </Button>
          <Button variant="primary" type="submit">
            <Trans>Update</Trans>
          </Button>
        </Dialog.Footer>
      </form>
    </Dialog>
  );
}

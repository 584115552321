/** @jsxImportSource theme-ui */
import { gql } from "urql";

export default gql`
  fragment TextBlockFragment on TextBlock {
    _rawText: textRaw
    callToAction {
      ...CallToAction
    }
    style {
      ...BlockStyle
    }
    alignment
  }
`;

/** @jsxImportSource theme-ui */
import { Box, Link, SemiTitle } from "@bottlebooks/gatsby-theme-base/src";

export default function PolyvideoVideo({ videoId, title, url, ...rest }) {
  // See https://stackoverflow.com/questions/24739663/embebing-instagram-webpage-inside-an-iframe
  return (
    <Box {...rest}>
      <SemiTitle>
        <Link
          href={url}
          variant="text"
          target="_blank"
          rel="noopener noreferrer"
        >
          {title}
        </Link>
      </SemiTitle>
      <Box sx={{ paddingBottom: `${(9 / 16) * 100}%`, position: "relative" }}>
        <iframe
          title={title}
          src={`https://go.plvideo.cn/front/video/preview?vid=${videoId}`}
          width="560"
          height="315"
          frameBorder="0"
          allow="autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          mozallowfullscreen
          webkitallowfullscreen
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
        ></iframe>
      </Box>
    </Box>
  );
}

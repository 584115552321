import React from "react";

export default function GridImage({
  image,
  width: baseWidth = 1202,
  height: baseHeight,
  alt = "Image",
  projectId,
  ...rest
}) {
  if (!image) return null;
  const imageId = image.asset._id
    ?.replace("image-", "")
    .replace("-png", ".png")
    .replace("-jpg", ".jpg")
    .replace("-webp", ".webp");
  const width = Array.isArray(baseWidth)
    ? baseWidth[baseWidth.length - 1]
    : baseWidth;
  const height = Array.isArray(baseHeight)
    ? baseHeight[baseHeight.length - 1]
    : baseHeight; // TODO height doesn't work due to a bug in sanity/image-url upstream library: https://github.com/sanity-io/image-url/issues/32
  const imageUrl = `https://cdn.sanity.io/images/${projectId}/production/${imageId}?auto=format&fit=max&q=75&w=${
    width * 2
  }`;
  return (
    <img
      src={imageUrl}
      alt={alt}
      height={height}
      sx={{
        objectFit: "contain",
        objectPosition: "center center",
        // Always ensure the images are full-width
        width: "100%",
        borderRadius: "default",
      }}
      {...rest}
    />
  );
}

/** @jsxImportSource theme-ui */
import {
  Box,
  Grid,
  Link,
  Section,
  SemiTitle,
  Text,
  Title,
} from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";

const fragment = graphql(`
  fragment BrandDistributors on Profile {
    distributors(filter: { country: { ne: null } }) {
      byCountry: group(
        field: country
        sort: { fields: fieldValue, order: ASC }
      ) {
        country: fieldValue
        # The filter removes empty distributors from the country, while still grouping by country.
        distributors: nodes(filter: { name: { nin: [null, ""] } }) {
          name
          url
        }
      }
    }
    seekingDistributionInCountries
  }
`);

export default function BrandDistributors({
  brand: data,
  ...rest
}: {
  brand: FragmentType<typeof fragment>;
}) {
  const brand = useFragment(fragment, data);
  if (!hasContent(data)) return null;
  return (
    // PresentedBrand links to this id with a hash
    <Section id="distributors" {...rest}>
      <Title sx={{ marginBottom: 3 }}>
        <Trans comment="The title of the Brand distributors - either a list of countries or a list of importers per country.">
          Distribution
        </Trans>
      </Title>
      {(brand.distributors?.byCountry?.length ?? 0) > 0 && (
        <Grid columns={[2, 3, 4]} gap={2}>
          {brand.distributors?.byCountry.map(
            ({ country, distributors }, index) => (
              <Box key={index}>
                <SemiTitle key={country} sx={{ marginTop: 0 }}>
                  {country}
                </SemiTitle>
                {distributors.map(({ name, url }) => (
                  <Text key={name} sx={{ marginBottom: 2 }}>
                    <Link href={url ?? undefined}>{name}</Link>
                  </Text>
                ))}
              </Box>
            )
          )}
        </Grid>
      )}
      {brand.seekingDistributionInCountries?.length && (
        <Box>
          <SemiTitle sx={{ marginTop: 0 }}>
            <Trans>Seeking distribution partners</Trans>
          </SemiTitle>
          <Text sx={{ marginBottom: 2 }}>
            {brand.seekingDistributionInCountries.join(", ")}
          </Text>
        </Box>
      )}
    </Section>
  );
}
BrandDistributors.hasContent = hasContent;

function hasContent(data: FragmentType<typeof fragment>) {
  const brand = useFragment(fragment, data);
  if (!brand) return false;
  const byCountry = brand.distributors?.byCountry;
  if (byCountry?.length && byCountry[0]?.country) return true;
  if (brand.seekingDistributionInCountries?.length) return true;
}

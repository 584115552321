import {
  Link,
  P,
  Label,
  SemiTitle,
  Text,
  Title,
} from "@bottlebooks/gatsby-design-system";
import { PortableText } from "@portabletext/react";
import React from "react";

const components = {
  // container: (props) => {
  //   console.log(props);
  // },
  marks: {
    i: ({ children }) => (
      <span style={{ fontStyle: "italic" }}>{children}</span>
    ),
    strong: ({ children }) => (
      <span style={{ fontWeight: 600 }}>{children}</span>
    ),
    // span: ({ children }) => (
    //   <Text as="span" sx={{ fontWeight: 600 }}>
    //     {children}
    //   </Text>
    // ),
    link: (props) => {
      const { value, children } = props;
      const { href } = value;
      if (!href) return <Text as="span">{children}</Text>;
      if (href.match(/^http/))
        return (
          <Link href={href} target="_blank">
            <Text as="span" sx={{ color: "primary" }}>
              {children}
            </Text>
          </Link>
        );
      if (href.match(/^mailto/))
        return (
          <Link href={href} target="_blank">
            <Text as="span" sx={{ color: "primary" }}>
              {children}
            </Text>
          </Link>
        );
      return (
        <Link href={href}>
          <Text as="span" sx={{ color: "primary" }}>
            {children}
          </Text>
        </Link>
      );
    },
  },

  block: {
    h1: ({ children }) => <Title variant="large">{children}</Title>,
    h2: ({ children }) => <Title>{children}</Title>,
    h3: ({ children }) => <Title variant="small">{children}</Title>,
    h4: ({ children }) => <SemiTitle>{children}</SemiTitle>,
    h5: ({ children }) => <SemiTitle variant="small">{children}</SemiTitle>,
    h6: ({ children }) => <SemiTitle variant="smallest">{children}</SemiTitle>,
    p: ({ children }) => <P>{children}</P>,
    largeText: ({ children }) => <P variant="large">{children}</P>,
    normal: ({ children, node }) => (
      // For convenience, we want to render all CMS text as paragraphs.
      // This ensures we don't have text that is the width of the page. It
      // also ensures a nice margin between paragraphs.
      // However, it has the trade off that there is padding automatically added
      // at the end of every text section. This causes vertical padding to be off.
      // We try to strip the margin from the last element of a block.
      <P sx={{ ...(node.isLastChild && { marginBottom: 0 }) }}>{children}</P>
    ),
    smallText: ({ children }) => <P variant="small">{children}</P>,
    largeLabel: ({ children }) => <Label variant="large">{children}</Label>,
    label: ({ children }) => <Label>{children}</Label>,
    smallLabel: ({ children }) => <Label variant="small">{children}</Label>,
  },
  list: {
    bullet: ({ children }) => (
      <Text>
        <ul style={{ marginTop: 0, marginBottom: 0 }}>{children}</ul>
      </Text>
    ),
    number: ({ children }) => (
      <Text>
        <ol style={{ marginTop: 0, marginBottom: 0 }}>{children}</ol>
      </Text>
    ),
  },
  listItem: ({ children }) => <li>{children}</li>,
};

export default function RichText({ children }) {
  // See comments above for normal text foro explanation.
  const enrichedChildren = children?.map((child, index) => {
    return {
      ...child,
      isLastChild: index === children.length - 1,
    };
  });
  return <PortableText value={enrichedChildren} components={components} />;
}

/** @jsxImportSource theme-ui */
import { useEffect } from "react";

export default function TryBooking({ eventId }) {
  useEffect(() => {
    loadTryBooking();
  });
  return (
    <div
      className="tryb-widget"
      data-type="expressEmbed"
      data-eid={eventId}
    ></div>
  );
}

const loadTryBooking = () => {
  const existingScript = document.getElementById("tryBooking-script");
  // We need to force the widget to reload. Otherwise, it only renders the first time the user loads the page.
  // This widget doesn't seem to have any public functions to reload.
  if (existingScript)
    existingScript?.parentElement?.removeChild(existingScript);
  // This property also needs to be reset in order to ensure the widget reloads
  if (window.trybWidgetsInitialized) window.trybWidgetsInitialized = false;

  const script = document.createElement("script");
  script.src = "https://www.trybooking.com/us/widget.js";
  script.id = "tryBooking-script";
  document.body.appendChild(script);
};

/** @jsxImportSource theme-ui */
import { FormattedText } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";

const fragment = graphql(`
  fragment ProductPalateDescription on Product {
    palateDescription
  }
`);

/**
 * Describes the product's palate.
 */
export default function ProductPalateDescription({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const product = useFragment(fragment, data);
  if (!product.palateDescription) return null;
  return (
    <Section title={<Trans>Palate</Trans>} {...rest}>
      <FormattedText text={product.palateDescription} variant="large" />
    </Section>
  );
}

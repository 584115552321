/** @jsxImportSource theme-ui */
import { Label, Link } from "@bottlebooks/gatsby-theme-base/src";
import { graphql } from "~/gql";
// @ts-ignore SVG isn't understood by TS
import previous from "../../assets/arrow-left.svg";
// @ts-ignore SVG isn't understood by TS
import next from "../../assets/arrow-right.svg";
const icons = { next, previous };

function getName({ name, stand }) {
  if (!stand || !stand.name) return name;
  return `${stand.name} – ${name}`;
}

export default function ChangeProducerLink({
  producer,
  direction = "next",
  to,
  showName = false,
  ...rest
}) {
  if (!producer) return null;
  return (
    <Link
      to={to}
      title={getName(producer)}
      sx={{ display: "inline-flex", alignItems: "center" }}
      {...rest}
    >
      {showName && direction === "next" && <Label>{producer.name}</Label>}
      <img
        src={icons[direction]}
        alt={producer.name}
        sx={{ padding: 3, boxSizing: "content-box" }}
        width={16}
        height={16}
      />
      {showName && direction === "previous" && <Label>{producer.name}</Label>}
    </Link>
  );
}

export const fragment = graphql(`
  fragment ChangeProducerLink on Producer {
    name
    stand {
      name: title
    }
    ...useLink_Producer
  }
`);

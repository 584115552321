/** @jsxImportSource theme-ui */

export default function PhysicalEventIcon({
  backgroundColor = "#fff",
  ...rest
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="29"
      fill="none"
      viewBox="0 0 29 29"
      {...rest}
    >
      <rect
        width="27.729"
        height="28.008"
        x="0.521"
        fill={backgroundColor}
        rx="13.865"
      ></rect>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M14.385 4.668c3.19 0 5.777 3.25 5.777 7.261 0 2.674-1.925 6.477-5.777 11.41-3.851-4.933-5.777-8.736-5.777-11.41 0-4.01 2.587-7.261 5.777-7.261zm0 3.112c-1.595 0-2.888 1.393-2.888 3.112s1.293 3.112 2.888 3.112c1.596 0 2.889-1.393 2.889-3.112S15.98 7.78 14.385 7.78z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

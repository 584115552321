/** @jsxImportSource theme-ui */
import { Button, ShoppingCartIcon } from "@bottlebooks/gatsby-theme-base/src";

export default function ShoppingCartButton({ children, ...rest }) {
  return (
    <Button variant="primary" sx={{ justifyContent: "center" }} {...rest}>
      <ShoppingCartIcon
        size="small"
        sx={{ marginRight: 2, color: "currentColor" }}
      />
      {children}
    </Button>
  );
}

import React from 'react';
import useFacetFilter from './useFacetFilter';

export default function useDefaultFacet({
  pageSize,
  allValues,
  selectedValues,
  count,
  totalCount,
}) {
  const [isCollapsed, setCollapsed] = React.useState(true);
  const [filter, setFilter] = React.useState('');
  const allFacetsCount = allValues?.length;
  // Don't show facets that would match all items.
  // - If all products are wines, don't show the "wines" filter.
  // - If all the wines are from Austria, it wouldn't make sense to show a filter on Austria.
  const meaningfulFilters =
    allFacetsCount > 0 && !selectedValues?.length
      ? allValues?.filter((item) => {
          if (totalCount && item.count === totalCount) return false;
          if (count && item.count === count) return false;
          return true;
        })
      : allValues || [];
  const filteredValues = useFacetFilter({
    values: meaningfulFilters,
    filter,
    selectedValues,
  });
  const facetsCount = meaningfulFilters?.length;
  if (!facetsCount) return undefined;
  const limit = isCollapsed ? pageSize : Infinity;
  /** @type {{value: string; label: string; key: string; level: number; count: number}[]} */ //@ts-ignore
  const values = filteredValues.slice(0, limit);
  const hasFilter = facetsCount > 2 * pageSize;
  const hasMore = filteredValues.length > values.length;
  return {
    filter,
    setFilter,
    hasFilter,
    hasMore,
    isCollapsed,
    collapse: (isCollapsed) => setCollapsed(isCollapsed),
    values,
  };
}

/** @jsxImportSource theme-ui */
import { gql } from "urql";
import PageReference from "../PageReference";

export default gql`
  fragment CardBlockFragment on CardBlock {
    image {
      ...ImageWithPreview
    }
    variant
    label
    title
    subtitle
    _rawText: textRaw
    page {
      ...PageReference
    }
    style {
      ...BlockStyle
    }
  }
  ${PageReference.fragment}
`;

/** @jsxImportSource theme-ui */
import { gql } from "@apollo/client";
import {
  BottleImagePlaceholder,
  Box,
} from "@bottlebooks/gatsby-theme-base/src";
import { graphql } from "~/gql";
import Image from "../Image/Image";
import React from "react";

/** @param {import('cloudinary-core').Transformation.Options} preset */
function preset(preset) {
  return preset;
}
const presets = {
  default: preset({
    transformation: [{ effect: "trim:20" }],
    width: 100,
    height: 150,
    crop: "pad",
    background: "#fff0",
  }),
  xlarge: preset({
    transformation: [{ effect: "trim:20" }],
    width: 300,
    height: 480,
    crop: "pad",
    background: "#fff0",
  }),
  large: preset({
    transformation: [{ effect: "trim:20" }],
    width: 150,
    height: 300,
    crop: "pad",
    background: "#fff0",
  }),
  smaller: preset({
    transformation: [{ effect: "trim:20" }],
    width: 32,
    height: 64,
    crop: "pad",
    background: "#fff0",
  }),
};

export default function ProductImage({ product, ...rest }) {
  if (!product) return null;
  if (!product.bottleImage?.publicId) {
    return <ProductImageLegacy product={product} {...rest} />;
  }
  const { size } = rest;
  if (presets[size]) {
    return (
      <Image
        publicId={product.bottleImage.publicId}
        layout="fixed"
        {...rest}
        options={{ ...presets[size], ...rest.options }}
      />
    );
  }
  return (
    <Image publicId={product.bottleImage?.publicId} layout="fixed" {...rest} />
  );
}

const dimensions = {
  smaller: { width: 40, height: 40 },
  default: { width: 100, height: 150 },
  large: { width: 150, height: 300 },
};

const paddings = {
  smaller: {},
  default: { padding: 2 },
  large: { padding: 2 },
};

/**
 *
 * @param {object} props
 * @param {object} props.product
 * @param {keyof typeof dimensions} [props.size]
 * @param {string} [props.className]
 * @returns
 */
function ProductImageLegacy({ product, size = "default", className, ...rest }) {
  if (!product) return null;
  const { bottleImage } = product;
  const hasImage = Boolean(bottleImage?.fixed);
  return (
    <div
      style={{
        position: "relative",
        ...dimensions[size],
        ...paddings[size],
        boxSizing: "content-box",
        color: "lighterText",
      }}
      className={className}
    >
      {hasImage ? (
        <div style={{ width: null, height: null, objectFit: null }}>
          <img
            src={bottleImage.fixed?.src}
            alt={product.name}
            style={{
              borderRadius: "default",
              width: "100%",
              height: "100%",
              objectFit: "contain !important",
            }}
            {...rest}
          />
        </div>
      ) : (
        // Simulate a gatsby-image Image with wrapper.
        <Box
          className="gatsby-image-wrapper"
          style={{
            position: "relative",
            overflow: "hidden",
            height: 300,
            maxHeight: "100%",
          }}
        >
          <BottleImagePlaceholder
            sx={{
              position: "absolute",
              top: 0,
              width: "100%",
              height: "100%",
              color: "nuanced",
            }}
          />
        </Box>
      )}
    </div>
  );
}

export const fragments = graphql(`
  fragment bb_Image on Bottlebooks_CloudinaryAsset {
    # We only need the publicId (and potentially the cloudName) to render images.
    # This is how we'll render images going forward.
    publicId
  }

  fragment bb_ProductImage on Bottlebooks_Product {
    bottleImage {
      ...bb_Image
    }
  }

  fragment ProductImageSmall_list on Product {
    bottleImage {
      publicId
      fixed(rawTransformation: "e_trim:20/w_120,h_120,c_pad,b_rgb:fff0") {
        src
        #width
        #height
      }
    }
  }
  fragment ProductImage on Product {
    bottleImage {
      publicId
      fixed(rawTransformation: "e_trim:20/w_100,h_150,c_pad,b_rgb:fff0") {
        src
        srcSet
        #width
        #height
        base64
      }
    }
  }

  fragment ProductImage_list on Product {
    bottleImage {
      publicId
      fixed(rawTransformation: "e_trim:20/w_100,h_150,c_pad,b_rgb:fff0") {
        src
        #width
        #height
      }
    }
  }

  fragment ProductImageLarge on Product {
    bottleImage {
      publicId
      fixed(rawTransformation: "e_trim:20/w_150,h_300,c_pad,b_rgb:fff0") {
        src
        srcSet
        #width
        #height
        base64
      }
    }
  }

  fragment ProductImageLarge_list on Product {
    bottleImage {
      publicId
      fixed(rawTransformation: "e_trim:20/w_300,h_600,c_pad,b_rgb:fff0") {
        src
        #width
        #height
      }
    }
  }
`);

export const ProductImageFragment = gql`
  fragment ProductImage on Product {
    bottleImage {
      publicId
      fixed(width: 100, height: 150, crop: PAD, background: "#FFF0") {
        src
        srcSet
        #width
        #height
        base64
      }
    }
  }
`;

export const ProductImageFragment_large = gql`
  fragment ProductImageLarge on Product {
    bottleImage {
      publicId
      fixed(width: 150, height: 300, crop: PAD, background: "#FFF0") {
        src
        srcSet
        #width
        #height
        base64
      }
    }
  }
`;

/**
 * @typedef {{
 *  bottleImage: {
 *    fixed: {
 *      src: string;
 *      height: number;
 *      width: number;
 *    } | null;
 *  } | null;
 * }} ProductImage_list
 */

/**
 * @typedef {{
 *  bottleImage: {
 *    fixed: {
 *      src: string;
 *      srcSet: string;
 *      height: number;
 *      width: number;
 *      base64: string;
 *    } | null;
 *  } | null;
 * }} ProductImage
 */

import { Box, Link } from "@bottlebooks/gatsby-design-system";

export default function ClickableFile({
  children,
  to,
  href,
  onClick,
  width = 96,
  ...rest
}) {
  return (
    <Link
      to={to}
      href={href}
      onClick={onClick}
      fallback={Box}
      sx={{ width, display: "block", ":hover": { textDecoration: "none" } }}
      {...rest}
    >
      {children}
    </Link>
  );
}

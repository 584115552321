/** @jsxImportSource theme-ui */
import {
  useFavorite,
  useFavorites,
} from "@bottlebooks/bottlebooks-site-bookmarks/src";
import type { List } from "@bottlebooks/bottlebooks-site-bookmarks/src/useFavorite";
import {
  Button,
  Dialog,
  Flex,
  Link,
  Text,
} from "@bottlebooks/gatsby-theme-base/src";
import { Plural, Trans } from "@lingui/macro";
import { Circle, PlusCircle } from "@phosphor-icons/react";
import React from "react";
import useLink from "@bottlebooks/bottlebooks-site-base/src/useLink";

export default function AddToListDialog({ onDismiss, product }) {
  const favorite = useFavorite(
    product && { type: "product", id: product.productId }
  );
  const { lists, favorites } = useFavorites();
  const [isCreatingList, setIsCreatingList] = React.useState(false);
  const [selectedList, setSelectedList] = React.useState<List | undefined>(
    favorite.list || undefined
  );
  const link = useLink();
  function handleSave(e) {
    e.preventDefault();
    favorite.set({
      list: {
        name: e.target[0].value,
        // Create a unique number
        // This could eventually be nanoid, but that is not installed at the moment
        listId: new Date().valueOf().toString(),
      },
    });
    onDismiss();
  }
  const favoritesPerList = favorites.reduce((acc, favorite) => {
    if (!favorite.list) {
      return acc;
    }
    const listId = favorite.list.listId;
    if (!acc[listId]) {
      acc[listId] = [];
    }
    acc[listId].push(favorite);
    return acc;
  }, {});
  const sortedLists = [...lists].sort((a, b) => a.name.localeCompare(b.name));
  function handleSelect(list) {
    setIsCreatingList(false);
    favorite.set({
      list: {
        name: list.name,
        listId: list.listId,
      },
    });
    onDismiss();
  }
  function removeBookmark() {
    favorite.unset();
    onDismiss();
  }
  const nextListName = `Flight ${String(lists.length + 1).padStart(2, "0")}`;
  return (
    <Dialog style={{ maxWidth: 400 }} onDismiss={onDismiss}>
      <Dialog.Header onDismiss={onDismiss}>
        <Trans>Add to list</Trans>
      </Dialog.Header>
      <Dialog.Content sx={{ paddingY: 2 }}>
        <Flex direction="column" gap={1}>
          {sortedLists.map((list) => {
            const isSelected = selectedList?.listId === list.listId;
            return (
              <ListItem
                key={list.listId}
                onClick={handleSelect}
                list={list}
                isSelected={isSelected}
                favorites={favoritesPerList[list.listId]}
              />
            );
          })}
          {isCreatingList ? (
            <form onSubmit={handleSave}>
              <Flex align="center" gap={2} sx={{ paddingX: 2, paddingY: 1 }}>
                <Circle
                  size={24}
                  weight="fill"
                  sx={{
                    color: "primary",
                    flexShrink: 0,
                  }}
                />
                <input
                  name="listName"
                  autoFocus
                  sx={{
                    height: 38,
                    paddingX: 2,
                    fontSize: 16,
                    width: 100,
                    flexGrow: 1,
                  }}
                  defaultValue={nextListName}
                  onChange={(evt) =>
                    setSelectedList({
                      name: evt.target.value,
                      // Create a unique number
                      // This could eventually be nanoid, but that is not installed at the moment
                      listId: new Date().valueOf().toString(),
                    })
                  }
                />
                <Button variant="primary" type="submit">
                  <Trans>Save</Trans>
                </Button>
              </Flex>
            </form>
          ) : (
            <Button
              variant="text"
              onClick={() => {
                setSelectedList(undefined);
                setIsCreatingList(true);
              }}
              sx={{ height: 44 }}
            >
              <Flex align="center" gap={2}>
                <PlusCircle size={24} />
                <Text>{nextListName}</Text>
              </Flex>
            </Button>
          )}
        </Flex>
      </Dialog.Content>
      <Dialog.Footer justify="space-between" align="center">
        <Link to={link.tastingList()} onClick={onDismiss}>
          <Text>
            <Trans>Go to lists</Trans>
          </Text>
        </Link>
        <Button variant="text" onClick={removeBookmark}>
          <Text sx={{ color: "brand.warningRed" }}>
            <Trans>Remove bookmark</Trans>
          </Text>
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}

function ListItem({ onClick, list, isSelected, favorites }) {
  return (
    <Button variant="text" onClick={() => onClick(list)} sx={{ height: 44 }}>
      <Flex align="center" gap={2}>
        <Circle
          size={24}
          weight={isSelected ? "fill" : undefined}
          sx={{ color: isSelected ? "primary" : "auto" }}
        />
        <Text>{list.name}</Text>
        <Text sx={{ color: "lighterText" }}>
          (
          <Plural value={favorites.length} one="# product" other="# products" />
          )
        </Text>
      </Flex>
    </Button>
  );
}

/** @jsxImportSource theme-ui */
import { gql } from "urql";

export default gql`
  fragment ProductBlockFragment on ProductBlock {
    label
    registeredProduct {
      productId
      referenceName
      collectionId
    }
    style {
      ...BlockStyle
    }
  }
`;

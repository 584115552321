import { theme as baseTheme } from "@bottlebooks/gatsby-design-system";
import button from "../components/Button/buttonVariants";
import link from "../components/Link/linkVariants";

const theme = {
  ...baseTheme,
  sizes: {
    ...baseTheme.sizes,
    container: {
      ...baseTheme.sizes?.container,
      // Restrict the max-size of the container to 960 for now.
      large: 960,
      fluid: 1170,
    },
    shadows: {
      ...baseTheme.shadows,
      // Taken from Mantine UI https://mantine.dev/core/paper/
      smallest: "rgb(0 0 0 / 5%) 0px 1px 3px, rgb(0 0 0 / 10%) 0px 1px 2px",
    },
    // Use the theme-ui convention name to fix typing issues.
    transitions: { ...baseTheme.transition },
  },

  styles: {
    // baseTheme.styles.root is applied to the body element automatically by theme-ui 0.3.x+.
    root: {
      padding: 0,
      margin: 0,
      backgroundColor: "background",
      color: "text",
      MozOsxFontSmoothing: "auto",
      WebkitFontSmoothing: "antialiased",
    },
  },

  button,

  link,

  input: {
    default: { variant: "text.default", paddingY: 2 },
    large: { variant: "text.large", paddingY: 3 },
  },
};

export default theme;

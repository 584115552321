/** @jsxImportSource theme-ui */
import { Badge, Box, Label } from "@bottlebooks/gatsby-design-system";
import { FragmentType, graphql, useFragment } from "~/gql";

const fragment = graphql(`
  # fragment ExhibitorStand on Exhibitor {
  #   stand {
  #     name: title
  #   }
  # }
  fragment ExhibitorStand_SingleRegistration on SingleRegistration {
    stand {
      # Raw information
      name
      # Used for rendering
      standTypeWithNumber
      # Used for rendering
      roomName
    }
  }
`);

export default function ExhibitorStand({
  exhibitor: data,
  ...rest
}: {
  exhibitor: FragmentType<typeof fragment>;
}) {
  const exhibitor = useFragment(fragment, data);
  if (!exhibitor?.stand?.name) return null;

  return (
    <Box {...rest}>
      {exhibitor.stand?.roomName ? (
        <Badge
          sx={{
            backgroundColor: "primary",
            color: "onPrimary",
            marginBottom: 1,
          }}
        >
          {exhibitor.stand?.roomName}
        </Badge>
      ) : null}
      <Label>{exhibitor.stand?.standTypeWithNumber}</Label>
    </Box>
  );
}

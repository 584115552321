/** @jsxImportSource theme-ui */
import { Row } from "@bottlebooks/gatsby-theme-base/src";

export default function RelatedHeader({ children, ...rest }) {
  return (
    <Row
      sx={{
        paddingX: [0, `${100 / 12}%`],
        paddingBottom: 4,
        alignItems: "center",
      }}
      {...rest}
    >
      {children}
    </Row>
  );
}

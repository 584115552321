/** @jsxImportSource theme-ui */
import type { DialogProps } from "@reach/dialog";
import BaseDialog from "@reach/dialog";
import DialogContent from "./Dialog.Content";
import DialogFooter from "./Dialog.Footer";
import DialogHeader from "./Dialog.Header";
import DialogInfoPanel from "./Dialog.InfoPanel";
import DialogMainPanel from "./Dialog.MainPanel";
import { keyframes } from "@emotion/react";

const slideUp = keyframes({
  "0%": { transform: "translateY(1000px)", opacity: 0 },
  to: { transform: "translateY(0)", opacity: 1 },
});
export default function Dialog({ children, ...rest }: DialogProps) {
  return (
    <BaseDialog
      {...rest}
      sx={{
        position: ["fixed", "relative"],
        bottom: [0, "initial"],
        left: 0,
        right: 0,
        borderRadius: "large",
        overscrollBehavior: "contain",
        overflow: "auto",
        maxHeight: "100%",
        animation: [
          `${slideUp} .5s cubic-bezier(.25,.46,.45,.94) both`,
          "none",
        ],
        // Overrides for Reach UI Dialog. Needed to fight specificity.
        "&&": {
          width: "100%",
          maxWidth: 880,
          padding: 0,
          marginTop: ["auto", "10vh"],
          marginBottom: [0, "10vh"],
        },
      }}
    >
      {children}
    </BaseDialog>
  );
}

Dialog.Header = DialogHeader;
Dialog.Content = DialogContent;
Dialog.Footer = DialogFooter;
Dialog.InfoPanel = DialogInfoPanel;
Dialog.MainPanel = DialogMainPanel;

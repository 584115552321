export default function cssOverlay(overlayColor = 'overlay') {
  return {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: overlayColor,
  };
}

/** @jsxImportSource theme-ui */
import Section from "@bottlebooks/bottlebooks-site-base/src/components/Section/Section";
import { Flex } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import isEqual from "lodash/isEqual";
import uniqWith from "lodash/uniqWith";
import { FragmentType, graphql, useFragment } from "~/gql";
import BottleVariant from "./BottleVariant.next";

const fragment = graphql(`
  fragment ProductBottleVariants on Product {
    bottleVariants {
      ...BottleVariant
    }
  }
`);

export default function ProductBottleVariants({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  if (!hasContent(data)) return null;
  return (
    <Section title={<Trans>Bottling</Trans>} {...rest}>
      <Flex gap={3} wrap="wrap">
        {getUniqueVariants(data).map(
          (bottleVariant, index) => (
            // We only have the index as an identifier - it's ok here because the order can't be changed.
            <BottleVariant key={index} data={bottleVariant} />
          )
        )}
      </Flex>
    </Section>
  );
}

/**
 * Gets the unique BottleVariants, deferring to BottleVariant.
 * This is because only the BottleVariants component knows which fields are used.
 * We have to do this because the bottle variants also contain other data that might be used elsewhere, such as the internalId.
 */
function getUniqueVariants(data: FragmentType<typeof fragment>) {
  const product = useFragment(fragment, data);
  return uniqWith(product.bottleVariants, (value, other) =>
    isEqual(BottleVariant.getContent(value), BottleVariant.getContent(other))
  );
}

function hasContent(data: FragmentType<typeof fragment>) {
  const product = useFragment(fragment, data);
  if (
    product?.bottleVariants?.some((bottleVariant) =>
      BottleVariant.hasContent(bottleVariant)
    )
  ) {
    return true;
  }
  return false;
}

import { print } from "graphql";
import { DocumentNode } from "graphql/language/ast";
import env from "~/env";

export default async function fetchGraphQL<GraphQLType>(
  query: DocumentNode,
  variables?: {}
) {
  if (!query) throw new Error("No query was provided");
  // if (!query.loc) throw new Error("Query appears to be malformed");
  const parsedQuery = print(query);
  const response = await fetch(env.VITE_GRAPHQL_URL, {
    method: "POST",
    credentials: "include",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query: parsedQuery,
      variables,
    }),
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json() as Promise<{
    data: GraphQLType;
    errors: { message: string; path: string }[];
  }>;
}

import { Box } from "@bottlebooks/gatsby-theme-base/src";
import React from "react";
import TicketButton from "./TicketButton";

// This is a place for starter events to extend.
export default function TopMenuItems() {
  return (
    <Box>
      <TicketButton />
    </Box>
  );
}

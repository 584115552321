/**
 * Encodes an object to a location hash.
 * @param {object} filters The values to encode.
 * @param {object} param1 The separator configuration.
 * @returns {string}
 */
export default function encodeFilters(
  filters,
  { facetSeparator = "&", valueSeparator = "," } = {}
) {
  if (!filters) return "";
  // console.log(filters);
  const hash = Object.entries(filters)
    .map(([key, values]) => {
      if (Array.isArray(values)) {
        return encodeURI(`${key}=${values.join(valueSeparator)}`);
      }
      // TODO special-case objects.
      return encodeURI(`${key}=${values}`);
    })
    .join(facetSeparator);
  // console.log(query);

  if (!hash) return "";
  return `#${hash}`;
}

/** @jsxImportSource theme-ui */
import { Flex } from "@bottlebooks/gatsby-design-system";
import { Button } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import type { DialogProps } from "@reach/dialog";
import type { ComponentProps } from "react";

export default function DialogFooter({
  children,
  onDismiss,
  ...rest
}: ComponentProps<typeof Flex> & { onDismiss?: DialogProps["onDismiss"] }) {
  return (
    <Flex
      justify={onDismiss ? "space-between" : "flex-end"}
      sx={{
        paddingX: 3.5,
        paddingY: 2.5,
        backgroundColor: "white",
        position: "sticky",
        bottom: 0,
        borderTop: 1,
        borderColor: "border",
      }}
      {...rest}
    >
      {onDismiss ? (
        <Button
          variant="text"
          onClick={onDismiss}
          sx={{ color: "lightestText" }}
        >
          <Trans>Close</Trans>
        </Button>
      ) : null}
      {children}
    </Flex>
  );
}

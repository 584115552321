/** @jsxImportSource theme-ui */
import { Button } from "@bottlebooks/gatsby-theme-base/src";
import { keyframes } from "@emotion/react";
import { Spinner } from "@phosphor-icons/react";
import type { ComponentPropsWithoutRef } from "react";

export type IconButtonProps = Omit<
  ComponentPropsWithoutRef<typeof Button>,
  "sx"
>;

export default function IconButton({ children, ...rest }: IconButtonProps) {
  return (
    <Button
      sx={{
        display: "flex",
        gap: 2,
        justifyContent: "center",
        alignItems: "center",
      }}
      {...rest}
    >
      {children}
    </Button>
  );
}

const rotateCenter = keyframes({
  "0%": { transform: "rotate(0)" },
  to: { transform: "rotate(360deg)" },
});

export function SubmitButton({
  children,
  isSubmitting,
  ...rest
}: {
  children: React.ReactNode;
  submitLabel?: React.ReactNode;
  isSubmitting: boolean;
} & React.ComponentProps<typeof IconButton> &
  React.ComponentProps<"button"> &
  React.ComponentProps<"input"> &
  React.ComponentProps<"a"> &
  React.ComponentProps<"div"> &
  React.ComponentProps<"span">) {
  if (isSubmitting) {
    return (
      <IconButton variant="primary" type="submit" disabled {...rest}>
        <Spinner
          size={24}
          weight="light"
          sx={{
            animation: `${rotateCenter} .6s ease-in-out infinite both`,
            flexGrow: 0,
          }}
        />
        {children}
      </IconButton>
    );
  }
  return (
    <IconButton variant="primary" type="submit" {...rest}>
      {children}
    </IconButton>
  );
}

/** @jsxImportSource theme-ui */
import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import React from "react";
import useUser from "./useUser";

const AuthenticationContext = React.createContext(
  // @ts-expect-error - we are initializing with undefined so that it is easier to see if anything fails with initialization
  /** @type {[boolean, React.Dispatch<React.SetStateAction<boolean>>]} */ (
    undefined
  )
);

export function AuthenticationProvider({ children }) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <AuthenticationContext.Provider value={[isOpen, setIsOpen]}>
      {children}
    </AuthenticationContext.Provider>
  );
}

/**
 * This hook exposes the core authentication API and context.
 * It should be used wherever authentication status is needed.
 * @returns
 */
export default function useAuthentication() {
  const [, setIsOpen] = React.useContext(AuthenticationContext);
  const [user, isLoadingUser] = useUser();
  return {
    // Simple check to see if we are in a login process.
    // This can be combined with isAuthenticated to lazy require authentication.
    // We've done this in the MenuActions component before.
    isAuthenticating: isLoadingUser,
    // Simple check to see if the user is logged in
    isAuthenticated: () => {
      if (user && !user.isAnonymous) return true;
      if (isLoadingUser) return null;
      return false;
    },
    // Prompts to log in if not already logged in
    requireAuthentication: () => {
      if (user && !user.isAnonymous) return true;
      setIsOpen(true);
      return false;
    },
    // Prompts to log in if needed
    signIn: () => {
      setIsOpen(true);
    },
    // Signs user out of firebase
    signOut: () => {
      const auth = getAuth(getApp());
      auth.signOut();
    },
  };
}

/**
 * This hook is private and currently only used by the Login Dialog to interact with the context
 * @returns {[boolean, React.Dispatch<React.SetStateAction<boolean>>]}
 */
export function useLoginDialog() {
  const [isOpen, setIsOpen] = React.useContext(AuthenticationContext);
  return [isOpen, setIsOpen];
}

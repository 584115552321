/** @jsxImportSource theme-ui */
import { gql } from "urql";

export default gql`
  fragment IconBlockFragment on IconBlock {
    icon {
      ...ImageWithPreview
    }
    label
    _rawText: textRaw
    callToAction {
      ...CallToAction
    }
    style {
      ...BlockStyle
    }
  }
`;

import useFirestore from "@bottlebooks/gatsby-plugin-firebase-app/src/useFirestore";
import { useCollectionLayout } from "@bottlebooks/bottlebooks-site-base/src/components/CollectionLayoutProvider.next";
import type {
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import * as React from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { z } from "zod";
import useUser from "./useUser";

const UserProfileContext = React.createContext<
  ReturnType<typeof useProfileFetcher> | undefined
>(undefined);

export const UserProfileProvider = ({ children }) => {
  useProfileSeeder();
  const value = useProfileFetcher();
  return (
    <UserProfileContext.Provider value={value}>
      {children}
    </UserProfileContext.Provider>
  );
};

export default function useUserProfile() {
  const data = React.useContext(UserProfileContext);
  if (!data) {
    throw new Error(
      `useUserProfile() hook was used without a <UserProfileProvider>. Add it to your App.`
    );
  }
  return data;
}

function useProfileFetcher() {
  const { collectionId } = useCollectionLayout();
  const [firebaseUser, isLoadingUser] = useUser();
  const uid = firebaseUser?.uid;
  const userProfilesRef = useUserProfilesRef(collectionId);
  const userProfileRef = uid ? doc(userProfilesRef, uid) : undefined;
  const [userProfile, isLoadingUserProfile, error] =
    useDocumentData(userProfileRef);
  const isLoading = isLoadingUser || isLoadingUserProfile;
  return {
    uid,
    userProfile: userProfile && userProfileSchema.parse(userProfile),
    status: isLoading ? "loading" : error ? "error" : "success",
    isLoading,
    error,
    firebaseUser,
  };
}

/**
 * Ensures that the user profile is seeded with the values we get from firebase auth.
 */
function useProfileSeeder() {
  const { collectionId } = useCollectionLayout();
  const [user] = useUser();
  const uid = user?.uid;
  const userProfilesRef = useUserProfilesRef(collectionId);
  React.useEffect(() => {
    if (!uid) return;
    const userProfileRef = doc(userProfilesRef, uid);
    getDoc(userProfileRef).then((profile) => {
      if (profile.exists()) return;
      console.log("Seeding user profile with auth values");
      setDoc(userProfileRef, userToProfile.parse(user), { merge: true });
    });
  }, [collectionId, uid, user, userProfilesRef]);
}

function useUserProfilesRef(collectionId: string) {
  const firestore = useFirestore();
  const userProfilesRef = collection(
    firestore,
    "collections",
    collectionId,
    "users"
  ).withConverter(converter);
  return userProfilesRef;
}

// This needs to be defined outside of the hook so that it's not recreated on every render when used in useEffect.
const converter = {
  // This is only needed to get the documents typed.
  toFirestore: (userProfile: UserProfile) => userProfile,
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<FirestoreUserProfile>,
    options: SnapshotOptions | undefined
  ) => {
    const userProfile = snapshot.data(options);
    return userProfile;
  },
};
/**
 * The user profile schema we're storing in firebase.
 */
export type UserRole = z.infer<typeof userRole>;

export const userRole = z.enum(["REPRESENTATIVE", "VISITOR", "ANONYMOUS"]);
export const userRoles = z.array(userRole);
export const profileSchema = z.object({
  uid: z.string(),
  email: z.string().email().nullish(),
  displayName: z
    .string()
    .nullish()
    .transform((value) => value || ""),
  profileImageUrl: z
    .string()
    .url()
    .nullish()
    .or(z.string().max(0))
    .transform((value) => value || ""),
  linkedInURL: z
    .string()
    .url()
    .regex(/^https:\/\/\w*\.?linkedin.com\//)
    .nullish()
    .or(z.string().max(0))
    .transform((value) => value || ""),
  jobTitle: z
    .string()
    .nullish()
    .transform((value) => value || ""),
  telephone: z
    .string()
    .nullish()
    .transform((value) => value || ""),
  companyName: z
    .string()
    .nullish()
    .transform((value) => value || ""),
  primaryRole: userRole,
  roles: userRoles,
  visibleToRoles: userRoles,
  acceptsChatsFrom: userRoles,
  acceptsMeetingsFrom: userRoles,
  hasAcceptedTerms: z.boolean().default(false),
  contactMeVia: z
    .array(z.enum(["TELEPHONE", "EMAIL", "WHATSAPP", "IMESSAGE", "LINKEDIN"]))
    .default(["EMAIL"]),
  /** @deprecated Replaced by visibleToRoles */
  visibility: z.array(z.enum(["EXHIBITOR", "VISITOR", "PUBLIC"])).optional(),
});

export const visitorProfileSchema = profileSchema.extend({
  primaryRole: z.literal("VISITOR").default("VISITOR"),
  roles: userRoles.default(["VISITOR"]),
  visibleToRoles: userRoles.default(["REPRESENTATIVE"]),
  acceptsChatsFrom: userRoles.default(["REPRESENTATIVE"]),
  acceptsMeetingsFrom: userRoles.default(["REPRESENTATIVE"]),
});

export const representativeProfileSchema = profileSchema.extend({
  primaryRole: z.literal("REPRESENTATIVE").default("REPRESENTATIVE"),
  roles: userRoles.default(["REPRESENTATIVE"]),
  visibleToRoles: userRoles.default(["REPRESENTATIVE", "VISITOR", "ANONYMOUS"]),
  acceptsChatsFrom: userRoles.default(["REPRESENTATIVE", "VISITOR"]),
  acceptsMeetingsFrom: userRoles.default(["REPRESENTATIVE", "VISITOR"]),
  registrationId: z.string().length(24),
  representativeId: z.string().length(24),
});

export const userProfileSchema = z.preprocess((raw) => {
  if (raw == null || typeof raw !== "object") return raw;
  // Derive the primaryRole from the representativeId.
  if ("representativeId" in raw && raw.representativeId) {
    return { ...raw, primaryRole: "REPRESENTATIVE" };
  }
  return { ...raw, primaryRole: "VISITOR" };
}, z.discriminatedUnion("primaryRole", [representativeProfileSchema, visitorProfileSchema]));

export type UserProfileSchema = z.infer<typeof userProfileSchema>;

/** The firebase user schema. */
const firebaseUserSchema = z.object({
  displayName: z.string().nullable(),
  email: z.string().nullable(),
  phoneNumber: z.string().nullable(),
  photoURL: z.string().nullable(),
  providerId: z.string(),
  uid: z.string(),
});

/** Converts a firebase user schema to a userProfile schema. */
export const userToProfile = firebaseUserSchema.transform((user) => {
  return userProfileSchema.parse({
    ...user,
    telephone: user.phoneNumber,
    profileImageUrl: user.photoURL,
    primaryRole: "VISITOR",
  });
});

export type UserProfile = z.infer<typeof userProfileSchema>;

type FirestoreUserProfile = UserProfile;

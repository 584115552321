/** @jsxImportSource theme-ui */
import { Text } from "@bottlebooks/gatsby-theme-base/src";
// import { graphql } from "gatsby";
import { SanityImage } from "sanity-image";
import React from "react";
import { gql } from "urql";
import UserHelp from "../../UserHelp";
import toSanityImage from "../../../helpers/toSanityImage";

/**
 * This is a responsive banner that is optimized for agencies providing graphics including text.
 */
export default function BannerSection({
  image,
  mobileImage,
  projectId,
}: {
  image: ImageWithPreview;
  mobileImage: ImageWithPreview;
  projectId: string;
}) {
  if (!image)
    return (
      <UserHelp>
        <Text>Please set a main image</Text>
      </UserHelp>
    );
  const mobile = mobileImage || image;
  return (
    <React.Fragment>
      <SanityImage
        {...toSanityImage({ image, projectId })}
        {...image}
        width={1280}
        sizes="100vw"
        alt={"Banner"}
        sx={{
          display: ["none", null, "block"],
          position: "relative",
          objectFit: "contain",
          // Always ensure the images are full-width
          width: "100%",
          height: "100%",
        }}
      />
      <SanityImage
        {...toSanityImage({ image, projectId })}
        {...mobile}
        width={640}
        sizes="100vw"
        alt={"Banner"}
        sx={{
          display: ["block", null, "none"],
          position: "relative",
          objectFit: "contain",
          // Always ensure the images are full-width
          width: "100%",
          height: "100%",
        }}
      />
    </React.Fragment>
  );
}

// export const fragment = graphql`
//   fragment SanityBannerSectionFragment on SanityBannerSection {
//     image {
//       ...ImageWithPreview
//     }
//     mobileImage {
//       ...ImageWithPreview
//     }
//   }
// `;

BannerSection.fragment = gql`
  fragment BannerSectionFragment on BannerSection {
    image {
      ...ImageWithPreview
    }
    mobileImage {
      ...ImageWithPreview
    }
  }
`;

/** @jsxImportSource theme-ui */
import { Box, Button, Text } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";

export default function Sort({ sortings, value, onChange, ...rest }) {
  const { i18n } = useLingui();
  if (sortings.length < 2) return null;
  return (
    <Box {...rest}>
      <Text variant="small" sx={{ display: "inline-block", marginRight: 2 }}>
        <Trans>Order by:</Trans>
      </Text>
      <Box sx={{ display: "inline-block" }}>
        {sortings.map(({ key, title }, index) => {
          return (
            <Button
              key={key}
              variant={value === key ? "primary" : "outline"}
              onClick={() => onChange(key)}
              sx={{
                backgroundColor: value === key ? undefined : "background",
                paddingX: 2,
                paddingY: 1,
                ...(index > 0 && {
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  marginLeft: "-1px",
                }),
                ...(index < sortings.length - 1 && {
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }),
              }}
            >
              {i18n._(title)}
            </Button>
          );
        })}
      </Box>
    </Box>
  );
}

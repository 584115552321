/** @jsxImportSource theme-ui */
import { gql, isApolloError, useMutation } from "@apollo/client";
import { Text } from "@bottlebooks/gatsby-design-system";
import useUserProfile from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUserProfile";
import { Link } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { Fragment } from "react";
import useImageUpload from "../../useImageUpload";
import type { ContentLocale } from "../../useLocale";

type UseSubmitOptions = {
  onSuccess: () => void;
  onError: (
    message: string | JSX.Element,
    title?: string | JSX.Element
  ) => void;
};

export default function useSubmitChatRequest({
  onSuccess,
  onError,
}: UseSubmitOptions) {
  const { userProfile, firebaseUser } = useUserProfile();
  const { uploadImage } = useImageUpload();
  const [requestChat] = useMutation<SendChatData, SendChatVariables>(
    REQUEST_CHAT
  );
  return async function submit(variables: SendChatVariables) {
    try {
      // Double-check that you haven't logged out in the meantime.
      if (!userProfile || !firebaseUser) {
        throw new Error("You are not logged in.");
      }
      // Get a token to write to your data.
      const senderToken = await firebaseUser.getIdToken();
      if (!senderToken) throw new Error("No token could be acquired.");

      const newProfileImageUrl = await uploadImage(
        // @ts-expect-error - this is in the form, but is not in the data submitted to the server.
        variables.input.profileImage
      );

      const { data, errors } = await requestChat({
        variables: {
          ...variables,
          input: {
            ...variables.input,
            profileImageUrl:
              newProfileImageUrl || variables.input.profileImageUrl,
          },
        },
        errorPolicy: "none",
      });
      // Even if we get errors back, the success flag is set to ok if we sent the message.
      // Errors might come from an invalid query on the mutation, but we don't want to handle this,
      // because it might confuse the user about why there was an error.
      if (data?.requestChat?.success) return onSuccess();
      // Show the GraphQL errors.
      if (errors) {
        return onError(
          <Fragment>
            {errors.map((error, index) => (
              <Text key={index}>{error}</Text>
            ))}
          </Fragment>
        );
      }
      // Otherwise display the message returned from the server if there was an error.
      if (data?.requestChat?.message) {
        return onError(data.requestChat.message);
      }
      // If the server accepted the mutation, but hasn't implemented the mutation response,
      // we show an "empty message" error.
      return onError(
        <Trans>
          The server responded with an empty message.
          <br />
          Please contact{" "}
          <Link href="mailto:support@bottlebooks.me">
            support@bottlebooks.me
          </Link>
          .
        </Trans>
      );
    } catch (error) {
      console.log({ ...error });
      if (isApolloError(error)) {
        const networkError = error.networkError as any;
        if (networkError?.result?.errors?.map) {
          return onError(
            <Fragment>
              {networkError.result?.errors?.map((error, index) => (
                <Text key={index}>{error.message}</Text>
              ))}
            </Fragment>
          );
        }
      }
      return onError(error?.message);
    }
  };
}

export const REQUEST_CHAT = gql`
  mutation requestChat(
    $collectionId: ID!
    $locale: ContentLocale!
    $senderId: ID!
    $senderToken: String!
    $input: RequestChatInput!
    $useTestMode: Boolean = false
  ) {
    requestChat(
      collectionId: $collectionId
      locale: $locale
      senderId: $senderId
      senderToken: $senderToken
      useTestMode: $useTestMode
      input: $input
    ) {
      code
      success
      message
    }
  }
`;

type SendChatVariables = {
  collectionId: string;
  locale: ContentLocale;
  senderId: string;
  senderToken: string;
  useTestMode?: boolean;
  input: RequestChatInput;
};
// copied from @bottlebooks/graphql-server/src/index.d.ts:
type RequestChatInput = {
  /** The recipient of the chat invitation. */
  recipientId: string;
  displayName: string;
  email: string;
  telephone: string;
  message: string | null;
  companyName: string | null;
  jobTitle: string | null;
  linkedInURL: string | null;
  contactMeVia: (
    | "EMAIL"
    | "IMESSAGE"
    | "LINKEDIN"
    | "TELEPHONE"
    | "WHATSAPP"
  )[];
  agreeToShareContactDetails: boolean;
  acceptTerms: boolean;
  profileImageUrl: string | null;
};

type SendChatData = {
  requestChat: {
    code: string;
    success: boolean;
    message: string;
  } | null;
};

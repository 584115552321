/** @jsxImportSource theme-ui */
import { Flex } from "@bottlebooks/gatsby-design-system";
import { keyframes } from "@emotion/react";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Form, Formik, useFormikContext } from "formik";
import { ArrowLeft, SignIn, Spinner } from "@phosphor-icons/react";
import type { ComponentPropsWithoutRef, ReactNode } from "react";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { TextField } from "../Form/Field";
import IconButton from "../IconButton";
import { emailInitialSchema, emailSchema } from "./Login.EnterEmail";
import type {
  EnterPasswordContext,
  EnterPasswordOptions,
} from "./Login.useState";

type EnterPasswordProps = ComponentPropsWithoutRef<typeof Flex> &
  EnterPasswordOptions &
  EnterPasswordContext & {
    prepend?: ReactNode;
    append?: ReactNode;
  };

export const passwordInitialSchema = emailInitialSchema
  .extend({ password: z.string().default("") })
  .passthrough();

export const passwordSchema = emailSchema.extend({
  // TODO add password strength validation.
  password: z.string().min(6),
});

export type PasswordSchema = z.infer<typeof passwordSchema>;

export default function EnterPassword({
  onContinue,
  onBack,
  prepend,
  append,
  initialValues,
  ...rest
}: EnterPasswordProps) {
  const { i18n } = useLingui();
  return (
    <Formik<PasswordSchema>
      validationSchema={toFormikValidationSchema(passwordSchema)}
      initialValues={initialValues}
      onSubmit={(values, formikHelpers) => onContinue(values, formikHelpers)}
    >
      <Form>
        <Flex direction="column" gap={3} sx={{ textAlign: "left" }} {...rest}>
          {prepend}
          <TextField name="email" label={i18n._(t`Your email`)} disabled />
          <TextField
            name="password"
            type="password"
            label="Your password"
            autoFocus
          />
          <Flex gap={3} justify="space-between">
            <BackButton onBack={onBack} />
            <SubmitButton />
          </Flex>
          {append}
        </Flex>
      </Form>
    </Formik>
  );
}

const rotateCenter = keyframes({
  "0%": { transform: "rotate(0)" },
  to: { transform: "rotate(360deg)" },
});

function SubmitButton() {
  const { isSubmitting } = useFormikContext<PasswordSchema>();
  if (isSubmitting) {
    return (
      <IconButton variant="primary" disabled>
        <Spinner
          size={22}
          weight="light"
          sx={{ animation: `${rotateCenter} .6s ease-in-out infinite both` }}
        />
        <Trans>Logging in…</Trans>
      </IconButton>
    );
  }

  return (
    <IconButton type="submit" variant="primary">
      <SignIn size={22} weight="light" />
      <Trans>Log in</Trans>
    </IconButton>
  );
}

function BackButton({ onBack, ...rest }: Pick<EnterPasswordOptions, "onBack">) {
  const { values } = useFormikContext<PasswordSchema>();
  return (
    <IconButton
      variant="base"
      sx={{ paddingLeft: 0, color: "lightText" }}
      onClick={() => onBack(values)}
      {...rest}
    >
      <ArrowLeft size={22} weight="light" />
      <Trans>Back</Trans>
    </IconButton>
  );
}

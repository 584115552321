import { useCollectionLayout } from "@bottlebooks/bottlebooks-site-base/src/components/CollectionLayoutProvider.next";
import useFirestore from "@bottlebooks/gatsby-plugin-firebase-app/src/useFirestore";
import useAuthentication from "@bottlebooks/gatsby-plugin-firebase-auth/src/useAuthentication";
import useUser from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUser";
import type {
  DocumentData,
  QueryDocumentSnapshot,
  SetOptions,
  SnapshotOptions,
  Timestamp,
} from "@firebase/firestore";
import {
  collection,
  deleteDoc,
  doc,
  orderBy,
  query,
  setDoc,
  writeBatch,
} from "firebase/firestore";
import React from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";

interface FavoriteProduct {
  id: string;
  type: "product";
}
interface FavoriteRegistration {
  id: string;
  type: "registration";
}
export type FavoriteSubject = FavoriteProduct | FavoriteRegistration;

export type List = { listId: string; name: string };

interface FavoriteData {
  addedAt: Date;
  id: string;
  /** A list of string tags. */
  tags?: string[];
  /** The list this favorite item is in. */
  list?: List;
}

interface ProductFavorite extends FavoriteData {
  type: "product";
}
interface RegistrationFavorite extends FavoriteData {
  type: "registration";
}

// export type Favorite = ProductFavorite | RegistrationFavorite;

type FirestoreFavorite = Omit<ProductFavorite | RegistrationFavorite, "id"> & {
  addedAt: Timestamp;
};

const FavoritesContext = React.createContext<
  ReturnType<typeof useBookmarkData> | undefined
>(undefined);

export const FavoritesProvider = ({ children }) => {
  const value = useBookmarkData();
  return (
    <FavoritesContext.Provider value={value}>
      {children}
    </FavoritesContext.Provider>
  );
};

export function useFavorites() {
  const data = React.useContext(FavoritesContext);
  if (!data) {
    throw new Error(
      "useFavorites() was used without a <FavoritesProvider>. Add it to your app."
    );
  }

  function find(item: FavoriteSubject | undefined) {
    return data?.favorites?.find((favorite) => favorite.id === item?.id);
  }

  // unset: (item: FavoriteSubject | undefined) => void;
  const { favoritesRef, order, setOrder, ...publicData } = data;
  const lists =
    data.favorites?.reduce<List[]>((acc, favorite) => {
      if (!favorite.list) return acc;
      if (!acc.find((list) => list.listId === favorite.list?.listId))
        acc.push(favorite.list);
      return acc;
    }, []) || [];
  const enrichedLists = lists
    .map((list) => {
      const favoritesOnList = data.favorites?.filter(
        (favorite) => favorite.list?.listId === list.listId
      );
      return { ...list, items: favoritesOnList };
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  return {
    ...publicData,
    order,
    setOrder,
    get(item: FavoriteSubject | undefined) {
      return find(item);
    },
    set(
      item: FavoriteProduct | FavoriteRegistration | undefined,
      newFavorite?: Partial<ProductFavorite> | Partial<RegistrationFavorite>,
      options?: SetOptions
    ) {
      const favoriteRef = doc(favoritesRef, item?.id);
      if (!favoriteRef) return;
      const favorite = find(item);
      const value = {
        addedAt: favorite?.addedAt || new Date(),
        ...newFavorite,
        type: item?.type,
      };
      if (options) return setDoc(favoriteRef, value, options);
      return setDoc(favoriteRef, value, { merge: true });
    },
    unset(item: FavoriteProduct | FavoriteRegistration | undefined) {
      const favoriteRef = doc(favoritesRef, item?.id);
      if (!favoriteRef) return;
      deleteDoc(favoriteRef);
    },
    updateListName(listId: string, name: string) {
      const batch = writeBatch(favoritesRef.firestore);
      const favoritesOnList = data.favorites?.filter(
        (favorite) => favorite.list?.listId === listId
      );
      favoritesOnList?.forEach((favorite) => {
        const favoriteRef = doc(favoritesRef, favorite.id);
        batch.set(favoriteRef, { list: { listId, name } }, { merge: true });
      });
      batch.commit();
    },
    lists: enrichedLists,
  };
}

export function useFavorite(item: FavoriteSubject | undefined) {
  const favorites = useFavorites();
  const { requireAuthentication } = useAuthentication();
  if (!favorites) {
    throw new Error(
      `useFavorite() hook was used without a <FavoritesProvider>. Add it to your App.`
    );
  }
  const isSet =
    favorites.isLoading || !item ? null : Boolean(favorites.get(item));
  const favorite = favorites.get(item);
  const mayChange = !favorites.isLoading && !favorites.error;
  const mayDelete = mayChange && isSet === true && !favorite?.tags?.length;
  return {
    isLoading: favorites.isLoading,
    error: favorites.error,
    favorite,
    isSet,
    mayDelete,
    mayToggle: mayChange && (!isSet || mayDelete),
    toggle() {
      if (!requireAuthentication()) return;
      if (!item) return;
      if (!mayChange) return;
      if (!isSet) return void favorites.set(item);
      if (!mayDelete) return;
      favorites.unset(item);
    },
    set(
      data?: Partial<ProductFavorite> | Partial<RegistrationFavorite>,
      options?: SetOptions
    ) {
      if (!requireAuthentication()) return;
      if (!mayChange) return;
      if (item) favorites.set(item, data, options);
    },
    unset() {
      if (!requireAuthentication()) return;
      if (item) favorites.unset(item);
    },
    list: favorite?.list,
  };
}

function useFavoritesRef(collectionId: string, userId: string | undefined) {
  const firestore = useFirestore();
  const tastingNotesRef = collection(
    firestore,
    "collections",
    collectionId,
    "users",
    userId || "-",
    "favorites"
  ).withConverter(converter);
  return tastingNotesRef;
}

function useBookmarkData() {
  const { collectionId } = useCollectionLayout();
  const [user, isLoadingUser] = useUser();
  const userId = user?.uid;
  const favoritesRef = useFavoritesRef(collectionId, userId);
  const [all, isLoadingFavorites, error] = useCollectionData<
    ProductFavorite | RegistrationFavorite
  >(
    query(favoritesRef, orderBy("addedAt"))
    // favoritesRef,
  );
  const [order, setOrder] = React.useState<string[]>([]);
  const isLoading = isLoadingUser || isLoadingFavorites;

  return {
    favorites: all,
    status: isLoading ? "loading" : error ? "error" : "success",
    error,
    isLoading,
    favoritesRef,
    order,
    setOrder,
  };
}

const converter = {
  // This is only needed to get the documents typed.
  toFirestore: (
    tastingNote: ProductFavorite | RegistrationFavorite
  ): DocumentData => tastingNote,
  fromFirestore: (
    snapshot: QueryDocumentSnapshot<FirestoreFavorite>,
    options: SnapshotOptions | undefined
  ) => {
    const favorite = snapshot.data(options);
    return {
      id: snapshot.id,
      ...favorite,
      addedAt: favorite.addedAt && favorite.addedAt.toDate(),
    };
  },
};

/** @jsxImportSource theme-ui */
import { Flex } from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";
import BrandActions from "../Brand/BrandActions.next";
import RegistrationInfoSheetButton from "./ExhibitorActions/RegistrationInfoSheetButton.next";
import RequestMeetingOnSectorGlobal from "./ExhibitorActions/RequestMeetingOnSectorGlobal";

const fragment = graphql(`
  fragment ExhibitorActions_SingleRegistration on SingleRegistration {
    companyId
    ...RegistrationInfoSheetButton
    profile {
      ...BrandActions
    }
  }
`);

export default function ExhibitorActions({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const registration = useFragment(fragment, data);
  return (
    <Flex>
      <RequestMeetingOnSectorGlobal
        companyId={registration.companyId}
        {...rest}
      />
      <RegistrationInfoSheetButton exhibitor={registration} {...rest} />
      {registration.profile ? (
        <BrandActions data={registration.profile} />
      ) : null}
    </Flex>
  );
}

/** @jsxImportSource theme-ui */
import { Box } from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";
import BrandOnlineShopButton from "./BrandActions/BrandOnlineShopButton.next";

const fragment = graphql(`
  fragment BrandActions on Profile {
    ...BrandOnlineShopButton
  }
`);

export default function BrandActions({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const brand = useFragment(fragment, data);
  if (!hasContent(data)) return null;
  return (
    <Box {...rest}>
      <BrandOnlineShopButton data={brand} sx={{ marginRight: 2 }} />
      {/* Add additional actions here. */}
    </Box>
  );
}

BrandActions.hasContent = hasContent;

function hasContent(data: FragmentType<typeof fragment>) {
  const brand = useFragment(fragment, data);
  if (BrandOnlineShopButton.hasContent(brand)) return true;
  return false;
}

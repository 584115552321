import { queryOptions, useQuery } from "@tanstack/react-query";
import { LoaderFunctionArgs } from "react-router-dom";
import { z } from "zod";
import { bbCollectionIdSchema } from "~/bbCollectionIdSchema";
import fetchSanity from "~/fetchSanity";
import getInvalidationPath from "~/getRequestCacheKey";
import { graphql } from "~/gql";
import graphQLClient from "~/graphQLClient";
import queryClient from "~/queryClient";
import { useParams } from "~/router";
import SanityPage from "../../../../../../bottlebooks-site-cms/src/components/SanityPage";
import { allPageQuery } from "../../../../../../bottlebooks-site-cms/src/pages/cms-preview/pages/allPageQuery";

const paramsSchema = z.object({
  locale: z.enum(["en", "de", "es", "fr"]),
  collectionId: z.string(),
  slug: z.string(),
});

function getQuery(params: z.infer<typeof paramsSchema>) {
  return queryOptions({
    queryKey: [params.locale, params.collectionId, params.slug],
    queryFn: async () => {
      const { locale, collectionId, slug } = params;
      const result = await graphQLClient.request(
        graphql(/* GraphQL */ `
          query CMSPage($collectionId: ID!, $locale: ContentLocale) {
            collection(collectionId: $collectionId, locale: $locale) {
              collectionId
              site {
                templateSettings {
                  name
                  value
                }
              }
            }
          }
        `),
        {
          collectionId,
          locale,
        }
      );
      if (!result.collection) throw new Error("No collection");
      const templateSettings = result.collection.site?.templateSettings ?? [];
      const projectId = templateSettings.find(
        ({ name }) => name === "projectId"
      )?.value;
      if (!projectId) throw new Error("No projectId");
      const sanityResult = await fetchSanity(
        allPageQuery,
        { collectionId },
        {
          projectId,
          ...getInvalidationPath({ collectionId, slug }),
        }
      );
      const pageForSlug = sanityResult.data?.allPage?.find(
        (page) => page.slug?.current === slug
      );
      if (!pageForSlug) throw new Error("No page");
      return {
        ...pageForSlug,
        projectId,
      };
    },
  });
}

export async function Loader({ params }: LoaderFunctionArgs) {
  const {
    locale,
    collectionId: collectionIdSegment,
    slug,
  } = paramsSchema.parse(params);
  const collectionId = bbCollectionIdSchema.parse(collectionIdSegment);
  await queryClient.fetchQuery(getQuery({ locale, collectionId, slug }));
  return null;
}

export default function LandingPageTemplate() {
  const params = useParams("/:locale/collections/:collectionId/:slug");
  const {
    locale,
    collectionId: collectionIdSegment,
    slug,
  } = paramsSchema.parse(params);
  const collectionId = bbCollectionIdSchema.parse(collectionIdSegment);
  // const parsed = paramsSchema.parse(useParams());
  const { data, isFetching } = useQuery(
    getQuery({ locale, collectionId, slug })
  );
  if (!data) return null;
  return <SanityPage {...data} />;
}

/** @jsxImportSource theme-ui */
import { P, Text } from "@bottlebooks/gatsby-design-system";
import { attendeeProfileFormSchema } from "@bottlebooks/gatsby-plugin-firebase-auth/src/attendeeProfileSchema";
import useUserProfile from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUserProfile";
import { Dialog } from "@bottlebooks/gatsby-theme-base/src";
import { keyframes } from "@emotion/react";
import { Trans } from "@lingui/macro";
import { ArrowLeft, CalendarCheck, Spinner } from "@phosphor-icons/react";
import type { DialogProps } from "@reach/dialog";
import { Form, Formik, useFormikContext } from "formik";
import { z } from "zod";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { FragmentType, graphql, useFragment } from "~/gql";
import { CheckboxField } from "../Form/Field";
import IconButton from "../IconButton";
import UserProfileFormFields from "../UserProfile/UserProfileForm.Fields";
import Terms from "../UserProfile/UserProfileForm.Terms";
import type { RequestMeetingButtonInfoData } from "./RequestMeetingButton.Info.next";
import RequestMeetingButtonInfo from "./RequestMeetingButton.Info.next";

// We need to pass optional() at the end because toFormikValidationSchema() seems to use it. TODO Verify this.
export const requestMeetingProfileFormSchema = z.object({
  displayName: attendeeProfileFormSchema.shape.displayName,
  jobTitle: attendeeProfileFormSchema.shape.jobTitle.optional(),
  companyName: attendeeProfileFormSchema.shape.companyName.optional(),
  telephone: attendeeProfileFormSchema.shape.telephone.optional(),
  linkedInURL: attendeeProfileFormSchema.shape.linkedInURL.optional(),
  // This is the actual value that gets submitted to the server
  profileImageUrl: attendeeProfileFormSchema.shape.profileImageUrl.optional(),
  // This is required because we upload the image via REST upon saving the form if a profileImage exists
  profileImage: attendeeProfileFormSchema.shape.profileImage.optional(),
  makeVisibleToRepresentatives: z.boolean().optional(),
  acceptTerms: z.boolean().optional(),
});

export type RequestMeetingButtonProfileFormSchema = z.infer<
  typeof requestMeetingProfileFormSchema
>;

const fragment = graphql(`
  fragment RequestMeetingButtonProfileForm on SiteUserProfile {
    displayName
    ...RequestMeetingButtonInfo
  }
`);

export default function RequestMeetingButtonProfileForm({
  initialValues,
  recipient: recipientData,
  onDismiss,
  onContinue,
  onBack,
}: {
  onDismiss: DialogProps["onDismiss"];
  recipient: FragmentType<typeof fragment>;
  initialValues: RequestMeetingButtonProfileFormSchema;
  onContinue: (values: RequestMeetingButtonProfileFormSchema) => void;
  onBack: (values: RequestMeetingButtonProfileFormSchema) => void;
}) {
  const { userProfile } = useUserProfile();
  const recipient = useFragment(fragment, recipientData);
  return (
    <Formik<RequestMeetingButtonProfileFormSchema>
      // Make sure the form is reinitialized if the login changes.
      // This means that any form state is lost when changing the login!
      // It's probably just slightly inconvenient because it should happen only in rare cases that the uid is changed.
      key={userProfile?.uid}
      initialValues={initialValues}
      validationSchema={toFormikValidationSchema(
        requestMeetingProfileFormSchema
      )}
      onSubmit={onContinue}
    >
      <Form>
        <Dialog.Header onDismiss={onDismiss}>
          <Trans>Book a meeting with {recipient.displayName}</Trans>
        </Dialog.Header>
        <Dialog.Content withPanels>
          <Dialog.InfoPanel>
            <P>
              <Trans>
                Provide more details about yourself before sending the request.
              </Trans>
            </P>
            <P>
              <Trans>
                After submitting you will receive an email with further
                information.
              </Trans>
            </P>
            <RequestMeetingButtonInfo recipient={recipient} />
          </Dialog.InfoPanel>
          <Dialog.MainPanel>
            <P variant="small" sx={{ "&&": { marginBottom: 3.5 } }}>
              <Trans>
                Please take a moment to complete your profile so that we can
                tell {recipient.displayName} a bit more about you and what you
                do.
              </Trans>
            </P>
            <UserProfileFormFields />
            {!initialValues.makeVisibleToRepresentatives && (
              <CheckboxField
                name="makeVisibleToRepresentatives"
                label={
                  <Text variant="small">
                    <Trans>Make me discoverable for exhibitors.</Trans>
                  </Text>
                }
                sx={{ marginTop: 2.5 }}
              />
            )}
            {!userProfile?.hasAcceptedTerms && <Terms />}
          </Dialog.MainPanel>
        </Dialog.Content>
        <Dialog.Footer justify="space-between">
          <BackButton onBack={onBack} />
          <SubmitButton />
        </Dialog.Footer>
      </Form>
    </Formik>
  );
}

function BackButton({
  onBack,
}: Pick<RequestMeetingButtonProfileFormProps, "onBack">) {
  // I extracted this button to be able to use a hook for checking isSubmitting.
  const { isSubmitting, values } =
    useFormikContext<RequestMeetingButtonProfileFormSchema>();
  return (
    <IconButton
      type="button"
      onClick={() => onBack(values)}
      disabled={isSubmitting}
      variant="text"
      sx={{ color: "lightestText" }}
    >
      <ArrowLeft size={24} weight="duotone" />
      <Trans>Back</Trans>
    </IconButton>
  );
}

const rotateCenter = keyframes({
  "0%": { transform: "rotate(0)" },
  to: { transform: "rotate(360deg)" },
});

function SubmitButton() {
  // I extracted this button to be able to use a hook for checking isSubmitting.
  const { isSubmitting } = useFormikContext();
  if (isSubmitting) {
    return (
      <IconButton variant="primary" type="submit" disabled>
        <Spinner
          size={24}
          weight="light"
          sx={{ animation: `${rotateCenter} .6s ease-in-out infinite both` }}
        />
        <Trans>Sending…</Trans>
      </IconButton>
    );
  }
  return (
    <IconButton variant="primary" type="submit">
      <CalendarCheck size={24} weight="light" />
      <Trans>Book the meeting</Trans>
    </IconButton>
  );
}

export type RequestMeetingProfileFormData = RequestMeetingButtonInfoData & {
  displayName: string | null;
};

/** @jsxImportSource theme-ui */
import { FragmentType, graphql, useFragment } from "~/gql";
import type { Prettify } from "~/Prettify";
import type { RequestMeetingButtonData } from "../Meeting/RequestMeetingButton.next";
import RequestMeetingButton, {
  RequestMeetingButtonSkeleton,
} from "../Meeting/RequestMeetingButton.next";
import type { RequestChatButtonData } from "./RequestChatButton.next";
import RequestChatButton, {
  RequestChatButtonSkeleton,
} from "./RequestChatButton.next";
import type { UserProfileCardProps } from "./UserProfileCard";
import UserProfileCard, { UserProfileCardSkeleton } from "./UserProfileCard";
import type { UserProfileCardAvatarData } from "./UserProfileCard.Avatar.next";
import type { UserProfileCardCompanyData } from "./UserProfileCard.Company.next";
import type { UserProfileCardDisplayNameData } from "./UserProfileCard.DisplayName.next";
import type { UserProfileCardLinkedInData } from "./UserProfileCard.LinkedIn.next";

type HorizontalProps = Omit<UserProfileCardProps, "children"> & {
  profile: UserProfileCardHorizontalData;
  hideActions?: boolean;
};

const fragment = graphql(`
  fragment UserProfileCardHorizontal on SiteUserProfile {
    ...UserProfileCardAvatar
    ...UserProfileCardDisplayName
    ...UserProfileCardCompany
    ...UserProfileCardLinkedIn
    ...RequestChatButton
    ...RequestMeetingButton
  }
`);

export default function UserProfileCardHorizontal({
  profile: data,
  hideActions,
  ...rest
}: {
  profile: FragmentType<typeof fragment>;
  hideActions?: boolean;
}) {
  const profile = useFragment(fragment, data);
  return (
    <UserProfileCard profile={profile} direction="horizontal" {...rest}>
      <UserProfileCard.Avatar profile={profile} />
      <UserProfileCard.Main>
        <UserProfileCard.DisplayName profile={profile} />
        <UserProfileCard.Company profile={profile} />
        <UserProfileCard.LinkedIn profile={profile} />
        {!hideActions && (
          <UserProfileCard.Actions>
            <RequestChatButton profile={profile} />
            <RequestMeetingButton profile={profile} />
          </UserProfileCard.Actions>
        )}
      </UserProfileCard.Main>
    </UserProfileCard>
  );
}

UserProfileCardHorizontal.fragment = fragment;

export type UserProfileCardHorizontalData = Prettify<
  UserProfileCardAvatarData &
    UserProfileCardDisplayNameData &
    UserProfileCardCompanyData &
    UserProfileCardLinkedInData &
    RequestChatButtonData &
    RequestMeetingButtonData
>;

export type UserProfileCardHorizontalSkeletonProps = Omit<
  HorizontalProps,
  "profile"
>;

export function UserProfileCardHorizontalSkeleton({
  hideActions,
  ...rest
}: UserProfileCardHorizontalSkeletonProps) {
  return (
    <UserProfileCardSkeleton direction="horizontal" {...rest}>
      <UserProfileCardSkeleton.Avatar />
      <UserProfileCardSkeleton.Main>
        <UserProfileCardSkeleton.DisplayName />
        <UserProfileCardSkeleton.Company />
        <UserProfileCardSkeleton.LinkedIn />
        {!hideActions && (
          <UserProfileCardSkeleton.Actions>
            <RequestChatButtonSkeleton />
            <RequestMeetingButtonSkeleton />
          </UserProfileCardSkeleton.Actions>
        )}
      </UserProfileCardSkeleton.Main>
    </UserProfileCardSkeleton>
  );
}

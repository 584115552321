import Section from "@bottlebooks/bottlebooks-site-base/src/components/Section/Section";
import React from "react";

export default function PortWineFestBanner() {
  function scrollDown() {
    window.scrollTo({
      top:
        window.innerWidth < 460
          ? window.innerWidth * 0.56
          : window.innerWidth * 0.56 + 100,
      behavior: "smooth",
    });
  }
  return (
    <Section
      sx={{
        paddingY: 0,
        margin: "auto",
        position: "relative",
        width: "100%",
        height: 0,
        paddingBottom: "56.25%",
      }}
    >
      <iframe
        style={{
          position: "absolute",
          // top: -50,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
          border: "none",
        }}
        width="640"
        height="360"
        src={
          "https://www.youtube.com/embed/ZtI9wCQdhLI?controls=0&amp;rel=0&amp;playsinline=1&amp;origin=https%3A%2F%2Fportwinefest.com&amp;widgetid=1&autoplay=1&mute=1&loop=1"
        }
      />

      {/* <Box
        sx={{
          width: '100%',
          height: '100%',
          top: -50,
          position: 'absolute',
          backgroundColor: 'black',
          opacity: 0.5,
          zIndex: -1,
        }}
      /> */}
      {/* <Flex
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: ['5%', '10%'],
        }}
      >
        <Container
          sx={{ maxWidth: 'container.fluid', textAlign: 'center', zIndex: 1 }}
        >
          <img
            src="//cdn.sanity.io/images/9j32h2tk/production/a6b9091d14ff23937432143f6dfa84633d2aff8a-1080x1080.jpg?w=300&fit=max&auto=format"
            style={{ width: '300px' }}
          />
          <Title
            sx={{ color: 'white', marginTop: -30, fontSize: [16, 22, 26, 32] }}
          >
            a festival of portuguese wines
          </Title>
          <Flex
            sx={{
              color: 'white',
              alignItems: 'center',
              flexDirection: 'column',
              flexGrow: 0,
              flexShrink: 0,
              justifyContent: 'flex-start',
            }}
          >
            <Box sx={{ marginTop: [1, 15, 30, 40] }} onClick={scrollDown}>
              <CaretDoubleDown size={42} weight="bold" />
            </Box>
          </Flex>
        </Container>
      </Flex> */}
    </Section>
  );
}

/** @jsxImportSource theme-ui */
import { gql } from "urql";

export default gql`
  fragment TitleBlockFragment on TitleBlock {
    title
    subtitle
    style {
      ...BlockStyle
    }
    alignment
  }
`;

/** @jsxImportSource theme-ui */
import { FragmentType, graphql, useFragment } from "~/gql";
import ProductPriceExact from "./ProductPriceExact.next";
import ProductPriceRange from "./ProductPriceRange.next";

export const fragment = graphql(`
  fragment ProductPrice on RegisteredProduct {
    pricing {
      priceStrategy
      ...ProductPriceExact
      ...ProductPriceRange
      # This doesn't seem to be displayed anywhere...
      # bottleSize

      price
      priceRange
    }
  }
`);

export default function ProductPrice({
  pricing: data,
  prependPriceType = false,
  showMorePricingInfo = false,
  currencyDisplay,
  useGrouping,
  ...rest
}: {
  pricing: FragmentType<typeof fragment>;
  prependPriceType?: boolean;
  showMorePricingInfo?: boolean;
  currencyDisplay?: "symbol" | "code" | "name";
  useGrouping?: boolean;
  [x: string]: any;
}) {
  const registeredProduct = useFragment(fragment, data);
  const pricing = registeredProduct.pricing;
  if (!hasContent(data)) return null;
  if (pricing?.priceStrategy === "BY_PRICE")
    return (
      <ProductPriceExact
        pricing={pricing}
        prependPriceType={prependPriceType}
        currencyDisplay={currencyDisplay}
        useGrouping={useGrouping}
        {...rest}
      />
    );
  if (pricing?.priceStrategy === "BY_PRICE_RANGE")
    return (
      <ProductPriceRange
        pricing={pricing}
        showMorePricingInfo={showMorePricingInfo}
        {...rest}
      />
    );
  return null;
}

ProductPrice.hasContent = hasContent;
function hasContent(data: FragmentType<typeof fragment>) {
  const registeredProduct = useFragment(fragment, data);
  const pricing = registeredProduct.pricing;
  if (pricing?.priceStrategy === "BY_PRICE" && pricing?.price != null)
    return true;
  if (
    pricing?.priceStrategy === "BY_PRICE_RANGE" &&
    pricing?.priceRange != null
  )
    return true;
  return false;
}

/** @jsxImportSource theme-ui */
import { Box, Label, Text } from "@bottlebooks/gatsby-theme-base/src";
import { Plural, Trans } from "@lingui/macro";
import useLink from "../../useLink";
import ExhibitorListRowSmall from "./ExhibitorListRowSmall.next";
import ListRowSmall from "./ListRowSmall";
import ProductListRowSmall from "./ProductListRowSmall.next";
import { FragmentType, graphql, useFragment } from "~/gql";

const exhibitorFragment = graphql(/* GraphQL */ `
  fragment ExhibitorSearchResults on SingleRegistration {
    exhibitorId: companyId
    ...ExhibitorListRowSmall
  }
`);

const productFragment = graphql(/* GraphQL */ `
  fragment ProductSearchResults on RegisteredProduct {
    productId
    exhibitorId: companyId
    ...ProductListRowSmall
  }
`);

export default function SearchResults({
  exhibitors: exhibitorsData,
  products: productsData,
  searchTerm,
  onDismiss,
  ...rest
}: {
  exhibitors: FragmentType<typeof exhibitorFragment>[];
  products: FragmentType<typeof productFragment>[];
  searchTerm: string;
  [key: string]: any;
}) {
  const link = useLink();
  const exhibitors = exhibitorsData.flatMap((data) =>
    useFragment(exhibitorFragment, data)
  );
  const products = productsData.flatMap((data) =>
    useFragment(productFragment, data)
  );
  const productCount = products?.length || 0;
  const exhibitorCount = exhibitors?.length || 0;
  return (
    <Box
      sx={{
        position: "absolute",
        backgroundColor: ["light", null, "white"],
        borderRadius: "default",
        boxShadow: [null, null, "overlay"],
        left: 0,
        right: 0,
        paddingY: 4,
      }}
      {...rest}
    >
      {exhibitors?.slice(0, 6).map((exhibitor) => (
        <ExhibitorListRowSmall
          key={exhibitor.exhibitorId}
          to={link.exhibitor(exhibitor)}
          onClick={onDismiss}
          data={exhibitor}
          searchTerm={searchTerm}
          sx={{
            ":hover": {
              backgroundColor: ["background", "light"],
            },
          }}
        />
      ))}
      {exhibitorCount < 6 &&
        products?.slice(0, 6 - exhibitorCount).map((product) => (
          <ProductListRowSmall
            key={product.productId}
            to={link.product(product)}
            onClick={onDismiss}
            data={product}
            searchTerm={searchTerm}
            sx={{
              ":hover": {
                backgroundColor: ["background", "light"],
              },
            }}
          />
        ))}
      {(exhibitorCount > 6 || productCount > 6) && (
        <Box sx={{ paddingTop: 4 }}>
          <Label sx={{ color: "primary", marginBottom: 3, paddingX: 3 }}>
            <Trans>Explore</Trans>
          </Label>
          {exhibitorCount > 6 && (
            <ListRowSmall
              to={`${link.exhibitors()}#searchTerm=${encodeURIComponent(
                searchTerm
              )}`}
              sx={{ paddingY: 1 }}
            >
              <Text sx={{ fontWeight: "body" }}>
                <Plural
                  value={exhibitorCount}
                  one={<Trans>1 exhibitor with "{searchTerm}"</Trans>}
                  other={<Trans># exhibitors with "{searchTerm}"</Trans>}
                />
              </Text>
            </ListRowSmall>
          )}
          {productCount > 6 && (
            <ListRowSmall
              variant="text"
              to={`${link.products()}#searchTerm=${encodeURIComponent(
                searchTerm
              )}`}
              sx={{ paddingY: 1 }}
            >
              <Text sx={{ fontWeight: "body" }}>
                <Plural
                  value={productCount}
                  one={<Trans>1 product with "{searchTerm}"</Trans>}
                  other={<Trans># products with "{searchTerm}"</Trans>}
                />
              </Text>
            </ListRowSmall>
          )}
        </Box>
      )}
      {exhibitorCount === 0 && productCount === 0 && (
        <Text sx={{ paddingX: 4 }}>
          <Trans>Nothing found. Try another search term.</Trans>
        </Text>
      )}
    </Box>
  );
}

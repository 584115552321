/** @jsxImportSource theme-ui */
import Card from "./Card";
import {
  Box,
  Button,
  ChevronDownIcon,
  ChevronUpIcon,
  Flex,
  Grid,
  Text,
} from "@bottlebooks/gatsby-theme-base/src";
import { ExhibitorListRow } from "@bottlebooks/bottlebooks-site-base/src/components/ExhibitorsList";
import { Plural, Trans } from "@lingui/macro";
import React from "react";
import useLink from "@bottlebooks/bottlebooks-site-base/src/useLink";
import { useTastingNotes } from "../../../bottlebooks-site-tastingnotes/src";
import { useFavorites } from "../useFavorite";
import BookmarkedProduct from "./BookmarkedProduct";

/** @typedef {ReturnType<import('@bottlebooks/bottlebooks-site-base/src/components/SiteSearch/useEventData').useProducts>["all"][number]} Product */
/** @typedef {NonNullable<ReturnType<useFavorites>["favorites"]>[number]} Bookmark */
/** @typedef {NonNullable<ReturnType<useTastingNotes>["byId"]>[string]} TastingNote */
/** @typedef {{ exhibitorId: string }} Exhibitor */

/**
 *
 * @param {{
 *  exhibitor: Exhibitor | undefined;
 *  onClick?: () => void;
 *  locale: string;
 *  listId?: string;
 *  products: Product[];
 *  dragHandleProps?: import('react-beautiful-dnd').DraggableProvidedDragHandleProps;
 *  onMoveUp?: () => void;
 *  onMoveDown?: () => void;
 *  [x: string]: any;
 * }} props
 * @returns
 */
function BookmarkedExhibitor(
  {
    exhibitor,
    products,
    onClick,
    listId,
    locale,
    children,
    dragHandleProps,
    onMoveUp,
    onMoveDown,
    ...rest
  },
  ref
) {
  const link = useLink();
  const [isCollapsed, setCollapsed] = React.useState(true);
  const bookmarks = useFavorites();
  const tastingNotes = useTastingNotes();
  const productsWithBookmarks = products?.map((product) => ({
    product,
    bookmark: bookmarks.get({ type: "product", id: product.productId }),
    tastingNote: tastingNotes.byId?.[product.productId],
  }));
  const mayDrag = Boolean(dragHandleProps);

  if (!exhibitor) return null;
  return (
    <Card
      ref={ref}
      sx={
        mayDrag
          ? { paddingLeft: 0, gridTemplateColumns: "40px auto" }
          : undefined
      }
      {...rest}
    >
      {mayDrag && (
        <DragHandle
          onMoveDown={onMoveDown}
          onMoveUp={onMoveUp}
          {...dragHandleProps}
        />
      )}
      <Grid sx={{ padding: 3, paddingLeft: mayDrag ? 0 : undefined }}>
        <ExhibitorListRow
          elevation="flat"
          dragHandleProps={dragHandleProps}
          exhibitor={exhibitor}
          to={link.exhibitor({ exhibitorId: exhibitor.exhibitorId })}
          onClick={onClick}
        />

        {products.length ? (
          isCollapsed ? (
            <BookmarkedProducts
              exhibitor={exhibitor}
              products={productsWithBookmarks}
              onShowAll={() => setCollapsed(false)}
            />
          ) : (
            <AllProducts
              exhibitor={exhibitor}
              products={productsWithBookmarks}
              onShowBookmarked={() => setCollapsed(true)}
            />
          )
        ) : null}
      </Grid>
    </Card>
  );
}

export default React.forwardRef(BookmarkedExhibitor);

function DragHandle({ onMoveUp, onMoveDown, ...rest }) {
  return (
    <Flex
      direction="column"
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        color: "lightestText",
        textShadow: "smallest",
        paddingY: 1,
      }}
      {...rest}
    >
      <Box>
        {onMoveUp ? (
          <Button variant="text" onClick={onMoveUp}>
            <ChevronUpIcon />
          </Button>
        ) : (
          <Box sx={{ height: 30 }} />
        )}
      </Box>
      <Text variant="large">⋮⋮⋮</Text>
      <Box>
        {onMoveDown ? (
          <Button variant="text" onClick={onMoveDown}>
            <ChevronDownIcon />
          </Button>
        ) : (
          <Box sx={{ height: 30 }} />
        )}
      </Box>
    </Flex>
  );
}

/**
 * @param {{
 *  exhibitor: Exhibitor;
 *  products: { product: Product; bookmark?: Bookmark; tastingNote?: TastingNote; }[];
 *  onShowAll?: () => void;
 * }} props
 */
function BookmarkedProducts({ products, exhibitor, onShowAll }) {
  const link = useLink();
  const { exhibitorId } = exhibitor;
  const bookmarkedProducts = products.filter((product) =>
    Boolean(product.bookmark)
  );
  const hasMore = bookmarkedProducts.length < products.length;
  if (!bookmarkedProducts.length && hasMore) {
    return (
      <Button variant="outline" onClick={onShowAll}>
        <Plural
          value={products.length}
          one="Show # product"
          other="Show all # products"
        />
      </Button>
    );
  }

  return (
    <Scrollable>
      <ScrollableContent gap={3} sx={{ borderLeft: 1, borderColor: "border" }}>
        {bookmarkedProducts.map(({ product }) => (
          <ScrollableProduct
            key={product.productId}
            product={product}
            to={link.product({ exhibitorId, productId: product.productId })}
          />
        ))}
        <Spacer />
      </ScrollableContent>
      {hasMore && (
        <VerticalButton
          onClick={onShowAll}
          sx={{ boxShadow: "0 0 16px rgba(0,0,0,0.1)" }}
        >
          <Plural
            value={products.length}
            one="Show # product"
            other="Show all # products"
          />
        </VerticalButton>
      )}
    </Scrollable>
  );
}

/**
 * @param {{
 *  exhibitor: Exhibitor;
 *  products: { product: Product; bookmark?: Bookmark; tastingNote?: TastingNote; }[];
 *  onShowBookmarked?: () => void;
 * }} props */
function AllProducts({ exhibitor, products, onShowBookmarked }) {
  const link = useLink();
  const { exhibitorId } = exhibitor;
  return (
    <Scrollable>
      <VerticalButton onClick={onShowBookmarked}>
        <Trans>Show only bookmarks</Trans>
      </VerticalButton>
      <ScrollableContent>
        {products.map(({ product }) => (
          <ScrollableProduct
            key={product.productId}
            product={product}
            to={link.product({ exhibitorId, productId: product.productId })}
          />
        ))}
        <Spacer />
      </ScrollableContent>
      <Shadow />
    </Scrollable>
  );
}

/** The wrapper for a scrollable area + button. */
function Scrollable({ children }) {
  return (
    <Flex sx={{ overflow: "hidden", margin: "-1px", borderRadius: "default" }}>
      {children}
    </Flex>
  );
}

/** The scrollable content. */
function ScrollableContent({ children, ...rest }) {
  return (
    <Flex
      gap={3}
      sx={{
        overflowX: "auto",
        flex: "1 1 auto",
        alignItems: "stretch",
        scrollSnapType: "x mandatory",
        padding: 1,
      }}
      {...rest}
    >
      {children}
    </Flex>
  );
}

function ScrollableProduct({ product, ...rest }) {
  return (
    <BookmarkedProduct
      product={product}
      variant="large"
      sx={{ flex: "1 0 260px", scrollSnapAlign: "start", padding: 1 }}
      {...rest}
    />
  );
}

/** A button with vertical text on it. */
function VerticalButton({ children, onClick, flip = false, ...rest }) {
  return (
    <Button
      variant="text"
      onClick={onClick}
      sx={{
        paddingX: 2,
        border: 1,
        borderColor: "borderSecondary",
        flex: "0 0 auto",
        zIndex: "elevated",
      }}
      {...rest}
    >
      <Text
        variant="small"
        sx={{
          writingMode: "vertical-rl",
          transform: flip ? "rotate(180deg)" : undefined,
        }}
      >
        {children}
      </Text>
    </Button>
  );
}

/** A spacer to prevent a single product from taking up too much space */
function Spacer(props) {
  return (
    <Box
      sx={{
        flex: "1 1 260px",
        borderRadius: "default",
        backgroundColor: "background",
        zIndex: 2,
        minWidth: 16, // Covers the shadow at the end of the scroll area.
        // // Alternative: Add borders and a light background.
        // borderTop: 1,
        // borderBottom: 1,
        // borderColor: 'border',
        // backgroundColor: 'light',
      }}
      {...props}
    />
  );
}

/** A vertical shadow, signalling that there is content in the scroll area. */
function Shadow() {
  return (
    <Box
      sx={{ boxShadow: "0 0 8px 8px rgba(0,0,0,0.05)", zIndex: "elevated" }}
    />
  );
}

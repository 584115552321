import { Box, Flex, SemiTitle } from '@bottlebooks/gatsby-theme-base/src';
import React from 'react';

export default function UserHelp({ children }) {
  return (
    <Flex
      sx={{
        backgroundColor: 'lightYellow',
        padding: 5,
        // Why is Flex not accepting basic configuration?
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <SemiTitle>The component configuration is incomplete</SemiTitle>
      <Box>{children}</Box>
    </Flex>
  );
}

/** @jsxImportSource theme-ui */
import { Button } from "@bottlebooks/gatsby-theme-base/src";

// TODO for now the variants are embedded here - we can move them to the theme later.
const variants = {
  default: {
    variant: "text.small",
    borderRadius: "round",
    paddingX: 3,
    paddingY: 1,
  },
  small: {
    variant: "text.smallest",
    borderRadius: "round",
    paddingX: 3,
    paddingY: "3px",
  },
  smaller: {
    variant: "text.smallest",
    borderRadius: "round",
    paddingX: 2.5,
    paddingY: 0,
  },
  facetBar: {
    variant: "text.smallest",
    borderRadius: "round",
    paddingX: 3,
    paddingY: "3px",
    marginBottom: 1,
    marginRight: 2,
  },
};

/**
 *
 * @param {object} props
 * @param {number} [props.count] The count displayed next to the label. Optional for the FacetsBar.
 * @param {boolean} [props.isSelected]
 * @param {() => void} [props.onClick]
 * @param {React.ReactNode} props.children The label.
 * @param {keyof variants} [props.variant]
 * @returns
 */
export default function FacetValue({
  count,
  isSelected,
  onClick,
  children,
  variant = "default",
  ...rest
}) {
  const showX = variant !== "default" && onClick;
  return (
    <Button
      onClick={onClick}
      sx={{
        ...(variants[variant] || variants.default),
        backgroundColor: isSelected ? "primary" : "nuanced",
        variant: isSelected ? "link.onPrimary" : "link.text",
        textDecoration: "none",
        border: 0,
        textAlign: "left",
        outline: "none",
        transition: (theme) =>
          `${theme.transition?.boxShadow}, ${theme.transition?.backgroundColor}, ${theme.transition?.color}`,
        ":hover": isSelected
          ? { backgroundColor: "secondary", color: "onSecondary" }
          : null,
        ":focus": { boxShadow: "raised" },
      }}
      {...rest}
    >
      {children}{" "}
      {count != null && (
        <span sx={{ color: isSelected ? "onPrimary" : "primary" }}>
          ({count})
        </span>
      )}{" "}
      {showX && (
        <span
          sx={{
            paddingLeft: 1,
            width: "10px",
            display: "inline-block",
            fontSize: "smallest",
            lineHeight: "smallest",
          }}
        >
          ✕
        </span>
      )}
    </Button>
  );
}

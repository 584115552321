import * as XLSX from "xlsx/xlsx.mjs";
import { useProducts } from "@bottlebooks/bottlebooks-site-base/src/components/SiteSearch/useEventData";
import { useTastingNotes } from "./useTastingNote";
import {
  useEnrichedFavorites,
  useFavorite,
  useFavorites,
} from "@bottlebooks/bottlebooks-site-bookmarks/src";
import { uniq, sortBy } from "lodash";
import { fa } from "make-plural";

/**
 * Exports all products from website to Excel
 */
export default function useExportAllProducts() {
  const { all: products } = useProducts();
  const isProductLedEvent = products.some((product) => !!product.productLabel);
  const { favorites } = useFavorites();
  const myListMap = favorites?.reduce((acc, item) => {
    acc[item.id] = item.list?.name;
    return acc;
  }, {});
  const sortedProducts = isProductLedEvent
    ? sortBy(products, ["sortIndex"])
    : sortBy(products, [
        "exhibitor.stand.name",
        "exhibitor.name",
        "producer.name",
        "shortName",
      ]);

  function exportAllProductsAsExcel() {
    const header = [
      "My list",
      ...(isProductLedEvent
        ? ["Flight name", "Product Label"]
        : ["Stand", "Exhibitor"]),
      "Producer",
      "Name",
      "Vintage",
      "Varieties",
      "Country",
      "Region",
      "Subregion",
      "Classification",
      "Special classification",
      "Alcohol",
      "Tasting note",
      "Rating",
      "Product Id",
    ];
    const columnWidths = [30, 30, 30, 30, 30, 10, 30, 15, 15, 15, 20, 20, 20];

    const data = sortedProducts?.map(
      ({
        exhibitor,
        producer,
        shortName,
        vintage,
        grapeVarieties,
        countryName,
        region,
        alcohol,
        subregion,
        classification,
        specialClassification,
        note,
        rating,
        productId,
        flightName,
        productLabel,
      }) => [
        myListMap[productId],
        ...(isProductLedEvent
          ? [flightName, productLabel]
          : [exhibitor.stand?.title, exhibitor.name]),
        producer.name,
        shortName,
        vintage,
        grapeVarieties?.map(({ varietyName }) => varietyName).join(", "),
        countryName,
        region,
        subregion,
        classification,
        specialClassification,
        alcohol,
        note,
        rating,
        productId,
      ]
    );
    const ws = XLSX.utils.aoa_to_sheet([header, ...data]);
    const wb = XLSX.utils.book_new();
    var wscols = columnWidths.map((width) => {
      return {
        wch: width,
      };
    });

    ws["!cols"] = wscols;
    XLSX.utils.book_append_sheet(wb, ws, "Event catalog");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "Tasting notes.xlsx");
  }
  return { exportAllProductsAsExcel };
}

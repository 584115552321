import { i18n } from "@lingui/core";
// Plurals are not included in the compiled scripts for lingui 3.0.0-11+
import { de, en, es, fr, it } from "make-plural/plurals";
import { messages as deMessages } from "../../locales/de";
import { messages as enMessages } from "../../locales/en";
import { messages as esMessages } from "../../locales/es";
import { messages as frMessages } from "../../locales/fr";
import { messages as itMessages } from "../../locales/it";
import { messages as deCmsMessages } from "../../../../bottlebooks-site-cms/src/locales/de";
import { messages as enCmsMessages } from "../../../../bottlebooks-site-cms/src/locales/en";
import { messages as esCmsMessages } from "../../../../bottlebooks-site-cms/src/locales/es";
import { messages as frCmsMessages } from "../../../../bottlebooks-site-cms/src/locales/fr";
import { messages as itCmsMessages } from "../../../../bottlebooks-site-cms/src/locales/it";
import { messages as deBookmarksMessages } from "@bottlebooks/bottlebooks-site-bookmarks/src/locales/de";
import { messages as enBookmarksMessages } from "@bottlebooks/bottlebooks-site-bookmarks/src/locales/en";
import { messages as esBookmarksMessages } from "@bottlebooks/bottlebooks-site-bookmarks/src/locales/es";
import { messages as frBookmarksMessages } from "@bottlebooks/bottlebooks-site-bookmarks/src/locales/fr";
import { messages as itBookmarksMessages } from "@bottlebooks/bottlebooks-site-bookmarks/src/locales/it";
import { messages as deListsMessages } from "../../../../bottlebooks-site-lists/src/locales/de";
import { messages as enListsMessages } from "../../../../bottlebooks-site-lists/src/locales/en";
import { messages as esListsMessages } from "../../../../bottlebooks-site-lists/src/locales/es";
import { messages as frListsMessages } from "../../../../bottlebooks-site-lists/src/locales/fr";
import { messages as itListsMessages } from "../../../../bottlebooks-site-lists/src/locales/it";
import { messages as deTastingNotes } from "../../../../bottlebooks-site-tastingnotes/src/locales/de";
import { messages as enTastingNotes } from "../../../../bottlebooks-site-tastingnotes/src/locales/en";
import { messages as esTastingNotes } from "../../../../bottlebooks-site-tastingnotes/src/locales/es";
import { messages as frTastingNotes } from "../../../../bottlebooks-site-tastingnotes/src/locales/fr";
import { messages as itTastingNotes } from "../../../../bottlebooks-site-tastingnotes/src/locales/it";

i18n.loadLocaleData("en", { plurals: en });
i18n.loadLocaleData("de", { plurals: de });
i18n.loadLocaleData("it", { plurals: it });
i18n.loadLocaleData("es", { plurals: es });
i18n.loadLocaleData("fr", { plurals: fr });

export const catalogs = {
  de: {
    messages: {
      ...deMessages,
      ...deCmsMessages,
      ...deBookmarksMessages,
      ...deListsMessages,
      ...deTastingNotes,
    },
  },
  en: {
    messages: {
      ...enMessages,
      ...enCmsMessages,
      ...enBookmarksMessages,
      ...enListsMessages,
      ...enTastingNotes,
    },
  },
  it: {
    messages: {
      ...itMessages,
      ...itCmsMessages,
      ...itBookmarksMessages,
      ...itListsMessages,
      ...itTastingNotes,
    },
  },
  es: {
    messages: {
      ...esMessages,
      ...esCmsMessages,
      ...esBookmarksMessages,
      ...esListsMessages,
      ...esTastingNotes,
    },
  },
  fr: {
    messages: {
      ...frMessages,
      ...frCmsMessages,
      ...frBookmarksMessages,
      ...frListsMessages,
      ...frTastingNotes,
    },
  },
};

export const defaultLocale = "en";

export const locales = {
  en: "English",
  de: "Deutsch",
  it: "Italiano",
  es: "Español",
  fr: "Français",
};

export function load(locale) {
  if (!locale) return {};
  const catalog = catalogs[locale];
  i18n.load(locale, catalog.messages);
  return catalog.messages;
}

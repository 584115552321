/** @jsxImportSource theme-ui */
import {
  Box,
  ChevronDownIcon,
  ChevronRightIcon,
  Flex,
  P,
  Text,
} from "@bottlebooks/gatsby-design-system";
import { Button } from "@bottlebooks/gatsby-theme-base/src";
// import { graphql } from "gatsby";
import React from "react";
import { gql } from "urql";
import { RichText } from "..";
import Section from "../Layout/Section";
import UserHelp from "../UserHelp";

export default function FaqSection({
  id = "faqs",
  title,
  questions,
  variant = "light",
  style,
  ...rest
}) {
  if (!title && !questions)
    return (
      <UserHelp>
        <Text>Add questions to FAQ section</Text>
      </UserHelp>
    );
  return (
    <Section variant={variant} {...style} {...rest}>
      <Section.Title>{title}</Section.Title>
      {questions?.map((question, index) => {
        return (
          <Question
            key={`question_${index}`}
            sx={{ marginBottom: 2 }}
            {...question}
          />
        );
      })}
    </Section>
  );
}

function Question({ question, answer, ...rest }) {
  const [showAnswer, setShowAnswer] = React.useState(false);
  return (
    <Box sx={{ borderBottom: "1px solid #efefef", paddingBottom: 1 }} {...rest}>
      <Button
        variant="text"
        onClick={() => setShowAnswer(!showAnswer)}
        sx={{
          width: "100%",
          padding: 0,
          ":hover .chevron": {
            backgroundColor: (theme) => `${theme.colors?.secondary}`,
          },
        }}
      >
        <Flex align="center">
          <Flex
            className="chevron"
            align="center"
            justify="center"
            sx={{
              marginRight: 2,
              color: "white",
              borderRadius: 100,
              width: 20,
              height: 20,
              transition: "all 0.3s ease-out",
              backgroundColor: (theme) => `${theme.colors?.primary}`,
              flexShrink: 0,
            }}
          >
            {showAnswer ? <ChevronDownIcon /> : <ChevronRightIcon />}
          </Flex>
          <Text sx={{ fontWeight: 600 }}>{question}</Text>
        </Flex>
      </Button>

      <Flex
        sx={{
          maxHeight: showAnswer ? "500px" : 0,
          transition: "max-height 0.3s ease-in-out",
          overflowY: "hidden",
        }}
      >
        <Box sx={{ paddingLeft: 24 }}>
          <P>
            <RichText>{answer}</RichText>
          </P>
        </Box>
      </Flex>
    </Box>
  );
}

// export const fragment = graphql`
//   fragment SanityFaqSectionFragment on SanityFaqSection {
//     title
//     questions {
//       question
//       answer: _rawAnswer
//     }
//     style {
//       ...SectionStyle
//     }
//   }
// `;

FaqSection.fragment = gql`
  fragment FaqSectionFragment on FaqSection {
    title
    questions {
      question
      answer: answerRaw
    }
    style {
      ...SectionStyle
    }
  }
`;

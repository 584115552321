/** @jsxImportSource theme-ui */
import { Flex, Text } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { ArrowLeft, ArrowsClockwise, GoogleLogo } from "@phosphor-icons/react";
import type { ComponentPropsWithoutRef, ReactNode } from "react";
import IconButton from "../IconButton";
import type {
  GoogleSignInContext,
  GoogleSignInOptions,
} from "./Login.useState";

type GoogleProps = ComponentPropsWithoutRef<typeof Flex> &
  GoogleSignInOptions &
  GoogleSignInContext & { prepend?: ReactNode; append?: ReactNode };

export default function GoogleSignIn({
  retries,
  onRetry,
  onBack,
  message,
  prepend,
  append,
  ...rest
}: GoogleProps) {
  return (
    <Flex direction="column" gap={3} sx={{ textAlign: "center" }} {...rest}>
      {prepend}
      <GoogleLogo
        size={64}
        weight="thin"
        sx={{ color: "lighterText", marginX: "auto" }}
      />
      <Text sx={{ textAlign: "center" }}>
        <Trans>Logging in via Google…</Trans>
      </Text>
      {message && <Text variant="small">{message}</Text>}
      <Flex gap={3} justify="space-between">
        <IconButton variant="outline" onClick={onBack}>
          <ArrowLeft size={22} weight="light" />
          <Trans>Back</Trans>
        </IconButton>
        <IconButton variant="primary" onClick={onRetry}>
          <ArrowsClockwise size={22} weight="light" />
          <Trans>Try again</Trans>
        </IconButton>
      </Flex>
      {append}
    </Flex>
  );
}

/** @jsxImportSource theme-ui */
import React from "react";
import { FragmentType, graphql, useFragment } from "~/gql";
import useLink from "../../useLink";
import Related from "../Related/Related";
import RelatedProduct from "../RelatedProduct/RelatedProduct.next";

const fragment = graphql(`
  fragment RelatedProductsSection on RegisteredProduct {
    productId
    ...RelatedProduct
    # TODO exhibitorId isnt' always resolved.
    # ...useLink_bb_RegisteredProduct
  }
  # fragment RelatedProductsSection on Product {
  #   id
  #   ...RelatedProduct
  #   ...useLink_Product
  # }
`);

export default function RelatedProductsSection({
  registeredProducts: data,
  exhibitor,
  title,
  link,
  ...rest
}: {
  registeredProducts: FragmentType<typeof fragment>[];
  exhibitor?: { exhibitorId?: string };
  title: React.ReactNode;
  link: React.ReactNode;
}) {
  const links = useLink();
  const registeredProducts = useFragment(fragment, data);
  if (!registeredProducts?.length) return null;
  return (
    <Related {...rest}>
      <Related.Header>
        <Related.Title>{title}</Related.Title>
        {link}
      </Related.Header>
      <Related.List>
        {registeredProducts.map((registeredProduct) => (
          <RelatedProduct
            key={registeredProduct.productId}
            to={links.product({
              ...registeredProduct,
              exhibitorId: exhibitor?.exhibitorId,
            })}
            data={registeredProduct}
            sx={{
              width: [`${100 / 3}%`, null, `${100 / 4}%`, `${100 / 6}%`],
            }}
          />
        ))}
      </Related.List>
    </Related>
  );
}

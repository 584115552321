/** @jsxImportSource theme-ui */
import { Button, Link } from "@bottlebooks/gatsby-theme-base/src";
import getOnAccentColor from "@bottlebooks/bottlebooks-site-base/src/helpers/getOnAccentColor";
// import { graphql } from "gatsby";
import { darken } from "polished";
import { gql } from "urql";
import getUrlFromPageReference from "../../helpers/getUrlFromPageReference";
import PageReference from "../PageReference";

/**
 * Implements CallToActionElement
 * @param {object} props
 * @param {string} props.label
 * @param {string} props.url
 * @param {object} props.page
 * @param {string} [props.sectionVariant="light"]
 * @param {boolean} [props.shouldOpenInNewWindow=false]
 * @param {'text' | 'primary' | 'outline' } props.variant
 * @returns
 */
export default function CallToAction({
  label,
  page,
  sectionVariant,
  variant,
  shouldOpenInNewWindow,
  ...rest
}) {
  if (!label) return null;
  const url = getUrlFromPageReference(page);
  const isExternal = url?.includes("http");
  const link = isExternal ? { href: url } : { to: url };
  if (variant === "text")
    return (
      <Link
        {...link}
        target={shouldOpenInNewWindow ? "_blank" : "_self"}
        {...rest}
      >
        {label}
      </Link>
    );
  return (
    <Button
      variant={variant}
      {...link}
      sx={(theme) => {
        return {
          marginTop: 3,
          color:
            sectionVariant === "accent" && variant === "outline"
              ? getOnAccentColor(theme.colors.accent)
              : "auto",
          "&:hover": {
            color:
              sectionVariant === "accent" && variant === "outline"
                ? darken(0.4, getOnAccentColor(theme.colors.accent))
                : "auto",
            borderColor:
              sectionVariant === "accent" && variant === "outline"
                ? darken(0.4, getOnAccentColor(theme.colors.accent))
                : "auto",
          },
        };
      }}
      target={shouldOpenInNewWindow ? "_blank" : "_self"}
      {...rest}
    >
      {label}
    </Button>
  );
}

// export const fragment = graphql`
//   fragment CallToAction on SanityCallToActionElement {
//     label
//     page {
//       ...SanityPageReference
//     }
//     variant
//     shouldOpenInNewWindow
//   }
// `;

CallToAction.fragment = gql`
  fragment CallToAction on CallToActionElement {
    label
    page {
      ...PageReference
    }
    variant
    shouldOpenInNewWindow
  }
  ${PageReference.fragment}
`;

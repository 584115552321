// import { graphql as gatsbyGraphQL, useStaticQuery } from "gatsby";
import { graphql } from "~/gql";
import React from "react";
import useLocale from "../../useLocale";
import type { Search_Exhibitor } from "../ExhibitorsPage/useExhibitorSearch.next";
import type { Search_Product } from "../ProductsPage/useProductSearch.next";
import { useEventData as useEventData_next } from "./EventDataProvider.next";

export default function useEventData() {
  return useEventData_next();
  console.log("useEventData needs to be fixed");
  return {
    exhibitors: [],
    products: [],
    collection: { collectionId: "65c0dd08ca0a885c5ef4483f" },
  };
}

// export default function useEventData() {
//   const eventData = useStaticQuery<EventData>(QUERY);
//   const locale = useLocale();

//   return React.useMemo(() => {
//     const {
//       exhibitors: { nodes: allExhibitors },
//       products: { nodes: allProducts },
//       registeredProducts: { nodes: allRegisteredProducts },
//       collection,
//     } = eventData;

//     const exhibitors = allExhibitors.filter(
//       (exhibitor) => exhibitor.locale === locale
//     );
//     const enrichedProducts = allProducts.map((product) => {
//       const registeredProduct = allRegisteredProducts.find(
//         (registeredProduct) => registeredProduct.productId === product.productId
//       );
//       const merged = {
//         ...product,
//         ...registeredProduct,
//       };
//       // This logic is duplicated almost identically in @bottlebooks/bottlebooks-site-base-pages/src/helpers/handleUnspecifiedProducer.js
//       const shouldUseExhibitorAsProducer =
//         !merged.producer ||
//         merged.producer.producerId === "500000000000000000000000";
//       return shouldUseExhibitorAsProducer
//         ? {
//             ...merged,
//             producer: {
//               ...merged.exhibitor,
//               exhibitorId: merged.exhibitor.brandId,
//               producerId: merged.exhibitor.brandId,
//               // This is to simplify the logic in EventProductPageHeader
//               isUnspecifiedProducerReplaced: true,
//             },
//           }
//         : merged;
//     });
//     const products = enrichedProducts.filter(
//       (product) => product.locale === locale
//     );
//     return {
//       exhibitors,
//       products: products?.map((product) => ({
//         ...product,
//         denomination: product.subregion || product.region,
//         exhibitor: exhibitors.find(
//           (exhibitor) => exhibitor.exhibitorId === product.exhibitorId
//         ),
//       })),
//       collection,
//     };
//   }, [eventData, locale]);
// }

// const QUERY = graphql(/* GraphQL */ `
//   fragment SearchResult_Product on Product {
//     shortName
//     ...ProductImageLarge_list
//     exhibitor {
//       name
//     }
//     producer {
//       name
//       # Added to support detecting Unspecified producer.
//       # producer will probably never be null because it is coming from pubCache
//       # which means we need to rely on producerId.
//       producerId
//     }
//     ...useLink_Product
//   }

//   fragment SearchResult_Brand on AbstractBrand {
//     name
//     countryName: country(full: true)
//     # This is required to render the country flag on CMS pages
//     countryCode: country(full: false)
//     # This is required to render region hierarchy on CMS pages
//     stateProvince
//     region
//     city
//     ...BrandLogoLarge_list
//     ...useLink_exhibitor_AbstractBrand
//   }

//   fragment SearchResult_RegisteredProduct on RegisteredProduct {
//     productId
//     registrationId
//     flightName
//     productLabel
//     sortIndex
//   }

//   query SiteSearch {
//     collection {
//       collectionId
//       name
//     }
//     exhibitors: allExhibitor {
//       nodes {
//         brandId
//         locale
//         # ...ExhibitorListRow_mainImage
//         ...SearchResult_Brand
//         ...Search_Exhibitor
//       }
//     }
//     products: allProduct {
//       nodes {
//         productId
//         locale
//         countryName: country(full: true)
//         countryCode: country(full: false)
//         region
//         subregion
//         ...SearchResult_Product
//         ...Search_Product
//       }
//     }
//     registeredProducts: allRegisteredProduct {
//       nodes {
//         ...SearchResult_RegisteredProduct
//       }
//     }
//   }
// `);

// TODO Filter on locale
export function useExhibitors() {
  const { exhibitors } = useEventData();
  return {
    all: exhibitors,
    /** @param {string} exhibitorId */
    findById(exhibitorId) {
      return exhibitors.find(({ brandId }) => brandId === exhibitorId);
    },
  };
}

// TODO Filter on locale
/** @param {string | undefined} exhibitorId */
export function useExhibitor(exhibitorId) {
  const exhibitors = useExhibitors();
  if (!exhibitorId) return undefined;
  return exhibitors.findById(exhibitorId);
}

// TODO Filter on locale
export function useProducts() {
  const { products } = useEventData();
  return {
    all: products,
    filter: (
      /** @type {(product: ReturnType<useEventData>["products"][number], index: number, allProducts: ReturnType<useEventData>["products"]) => boolean}*/ predicate
    ) => products.filter(predicate),

    findByExhibitorId(/** @type {string} */ exhibitorId) {
      return products.filter((product) => product.exhibitorId === exhibitorId);
    },

    findById(/** @type {string} */ productId) {
      return products.find((product) => product.productId === productId);
    },
  };
}

/** @param {string | undefined} productId */
export function useProduct(productId) {
  const products = useProducts();
  if (!productId) return undefined;
  return products.findById(productId);
}

export function useCollection() {
  const { collection } = useEventData();
  return collection;
}

export interface EventData {
  collection: {
    collectionId: string;
    name: string;
  };
  exhibitors: {
    nodes: Array<
      {
        brandId: string;
        locale: string;
        exhibitorId: string;
      } & SearchResult_Brand &
        Search_Exhibitor
    >;
  };
  products: {
    nodes: Array<
      {
        productId: string;
        registrationId: string;
        locale: string;
        countryName?: string | null;
        countryCode?: string | null;
        region?: string | null;
        subregion?: string | null;
      } & SearchResult_Product &
        Search_Product
    >;
  };
  registeredProducts: {
    nodes: Array<SearchResult_RegisteredProduct>;
  };
}

interface SearchResult_RegisteredProduct {
  productId: string;
  flightName: string;
  productLabel: string;
  sortIndex: number;
}

interface SearchResult_Brand {
  name: string;
  countryName: string | null;
  region: string | null;
  city: string | null;
  logo: {
    fixed: {
      src: string;
      height: number;
      width: number;
    } | null;
  } | null;
}

interface SearchResult_Product extends ProductImage_list {
  shortName: string;
  exhibitor: { name: string; brandId: string };
  producer: { name: string; producerId: string };
  productId: string;
  exhibitorId: string;
}

interface ProductImage_list {
  bottleImage: {
    fixed: {
      src: string;
      height: number;
      width: number;
    } | null;
  } | null;
}

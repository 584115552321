import { FragmentType, graphql, useFragment } from "~/gql";
import { matchSorter, rankings } from "match-sorter";
import React from "react";

// Using an alias instead of `graphql` avoids IDE errors from the GraphQL extension. TODO fix by setting up a graphql.config.json for Gatsby.
const fragment = graphql(/* GraphQL */ `
  fragment Search_Product on Product {
    regionName
    ... on Wine {
      grapeVarieties {
        varietyName
      }
      classification
      specialClassification
      vintage(removeNonVintage: true)
    }
    shortName

    producer {
      name
    }
  }
`);

/** @param {Search_Product} product */
const getProductFullName = ({ shortName, vintage }) =>
  [shortName, vintage].filter(Boolean).join(" ").trim();

/** @param {Search_Product} product */
const getProductNameWithProducer = ({ shortName, vintage, producer }) =>
  [producer?.name, shortName, vintage].filter(Boolean).join(" ").trim();

/** @param {Search_Product} product */
const getProductVarieties = ({
  grapeVarieties,
}: {
  grapeVarieties?: { varietyName: string | null }[] | null;
}) => grapeVarieties?.map(({ varietyName }) => varietyName || "") || [];

/**
 * @param {readonly Search_Product[]} items
 * @param {string} query
 */
export default function useProductSearch(
  data: FragmentType<typeof fragment>[],
  query: string
) {
  const items = data.flatMap((item) => {
    const product = useFragment(fragment, item);
    return {
      ...product,
      ...product.producer,
    };
  });
  return React.useMemo(() => {
    if (!query) return items;
    return matchSorter(items, query, {
      threshold: rankings.WORD_STARTS_WITH,
      keys: [
        getProductFullName,
        "producer.name",
        "exhibitor.name",
        { key: "vintage", threshold: rankings.EQUAL },
        "regionName",
        "classification",
        getProductVarieties,
        getProductNameWithProducer,
      ],
      // This baseSort function will use the original index of items as the tie breaker
      baseSort: (a, b) => {
        return a.index < b.index ? -1 : 1;
      },
    });
  }, [items, query]);
}

/**
 * @typedef {{
 *  grapeVarieties?: { varietyName: string | null }[] | null;
 *  classification?: string?;
 *  specialClassification?: string?;
 *  shortName: string;
 *  vintage?: string;
 *  producer: { name: string; } | null;
 * }} Search_Product
 */

/** @jsxImportSource theme-ui */
import { Text } from "@bottlebooks/gatsby-design-system";
import { Link } from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";

export const fragment = graphql(`
  fragment ProductProducerName on Product {
    producer {
      name
    }
  }
`);

export default function ProductProducerName({
  product: data,
  to,
  href,
  onClick,
  ...rest
}: {
  product: FragmentType<typeof fragment>;
  to?: string;
  href?: string;
  onClick?: () => void;
}) {
  const product = useFragment(fragment, data);
  const producer = product?.producer;
  if (!producer) return null;
  return (
    <Text {...rest}>
      <Link
        to={to}
        onClick={onClick}
        href={href}
        sx={{ color: "lightestText" }}
      >
        {producer.name}
      </Link>
    </Text>
  );
}

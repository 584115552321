const filterFieldVariants = {
  default: {
    wrapper: {},
    icon: { width: 16, height: 16, color: 'currentcolor' },
    input: {
      variant: 'text.default',
      paddingX: 2,
      paddingY: 1,
      '::placeholder': {
        color: 'lightestText',
      },
    },
    button: { paddingX: 2, paddingY: 1 },
  },

  mobile: {
    wrapper: {},
    icon: { width: 24, height: 24, color: 'lightText' },
    input: {
      variant: 'text.default',
      paddingX: 2,
      paddingY: 1,
      '::placeholder': {
        color: 'lightestText',
      },
    },
    button: { display: 'none' },
  },

  large: {
    wrapper: {},
    icon: {},
    input: {
      paddingX: 2,
      paddingY: 1,
      // Make sure we see whatever color the wrapper has.
      backgroundColor: 'transparent',
      variant: 'text.default',
      '::placeholder': {
        color: 'lighterText',
      },
    },
  },

  largest: {
    wrapper: {
      borderRadius: 'default',
      boxShadow: 'overlay',
      transition: 'boxShadow',
      transitionProperty: 'box-shadow, color, border-color, background-color',
      ':hover': { boxShadow: 'dialog' },
    },
    icon: { width: 32, height: 32, marginLeft: 3, color: 'currentcolor' },
    input: {
      variant: 'text.default',
      fontWeight: 'lowContrast',
      paddingX: 2,
      paddingY: 3,
      '::placeholder': {
        color: 'lightestText',
      },
    },
    button: { padding: 3, marginX: 2 },
  },
};

export default filterFieldVariants;

/** @typedef {keyof typeof filterFieldVariants} FilterFieldVariant */

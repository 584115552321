import { Box } from "@bottlebooks/gatsby-theme-base/src";
import qrcode from "qrcode";
import React from "react";

const DEFAULT_CORRECTION_LEVEL = "M";

/**
 * Generate a QR code as SVG
 * @param {import('qrcode').QRCodeToStringOptions & {url: string, stroke?: string;}} props
 * @returns
 */
export default function QRCode({
  url,
  margin = 0,
  errorCorrectionLevel = DEFAULT_CORRECTION_LEVEL,
  stroke = "#000",
  width = 50,
}) {
  const [qr, setQr] = React.useState({
    path: "",
    viewBox: "",
    svg: "",
  });
  if (qr.path) {
    return (
      <svg viewBox={qr.viewBox} width={width}>
        <path stroke={stroke} d={qr.path} />
      </svg>
    );
  }
  // We need to wait for the qr code to be generated. It's a fulfilled promise though, so we can immediately rerender.
  qrcode
    .toString(url, { margin, errorCorrectionLevel, type: "svg" })

    .then(
      /** @param {string} svg */ (svg) => {
        const [, viewBox, path] =
          svg.match(/ viewBox="(.*)" shape.* d="(.*)"/) || [];
        if (!viewBox) {
          console.warn("no qrcode could be generated.");
          return;
        }
        return setQr({ viewBox, path, svg });
      }
    );
  return <Box sx={{ width, height: width }} />;
}

/**
 * Reusable function to download data urls.
 * @param {import('qrcode').QRCodeToStringOptions & {url: string; name: string}} props
 */
QRCode.downloadPng = async function downloadQRCodePNG({
  name = "qrcode",
  url,
  margin = 0,
  errorCorrectionLevel = DEFAULT_CORRECTION_LEVEL,
}) {
  const result = await qrcode.toDataURL(url, {
    margin,
    errorCorrectionLevel,
    type: "image/png",
  });

  downloadDataUrl({ dataUrl: result, name: `${name}.png` });
};

/**
 * Reusable function to download data urls.
 * @param {import('qrcode').QRCodeToStringOptions & {url: string; name: string}} props
 */
QRCode.downloadSvg = async function downloadQRCodeSVG({
  name = "qrcode",
  url,
  margin = 0,
  errorCorrectionLevel = DEFAULT_CORRECTION_LEVEL,
}) {
  const result = await qrcode.toString(url, {
    margin,
    errorCorrectionLevel,
    type: "svg",
  });
  const svgBlob = new Blob([result], { type: "image/svg+xml;charset=utf-8" });
  const svgUrl = URL.createObjectURL(svgBlob);
  downloadDataUrl({ dataUrl: svgUrl, name: `${name}.svg` });
};

/**
 * Reusable function to download data urls.
 * @param {object} props
 * @param {string} props.dataUrl
 * @param {string} props.name
 */
function downloadDataUrl({ dataUrl, name }) {
  const downloadLink = document.createElement("a");
  downloadLink.href = dataUrl;
  downloadLink.download = name;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

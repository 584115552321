/** @jsxImportSource theme-ui */

export default function LocalEventIcon({ backgroundColor = "#fff", ...rest }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="29"
      fill="none"
      viewBox="0 0 28 29"
      {...rest}
    >
      <rect
        width="27.729"
        height="28.008"
        fill={backgroundColor}
        rx="13.865"
      ></rect>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M22.08 21.119H5.648V9.524c0-.58.46-1.054 1.027-1.054h1.027v1.054c0 .291.23.527.514.527a.52.52 0 00.513-.527V8.47H19v1.054c0 .291.23.527.514.527a.52.52 0 00.513-.527V8.47h1.027c.567 0 1.027.473 1.027 1.054V21.12zM21.053 7.416h-1.027V6.362a.52.52 0 00-.513-.527.52.52 0 00-.514.527v1.054H8.73V6.362a.52.52 0 00-.513-.527.52.52 0 00-.514.527v1.054H6.675c-1.133 0-2.054.945-2.054 2.108v12.649h18.486V9.524c0-1.163-.921-2.108-2.054-2.108zm-2.567 4.216a.52.52 0 00-.514.527c0 .291.23.527.514.527a.52.52 0 00.513-.527.52.52 0 00-.513-.527zm-3.081 0a.52.52 0 00-.514.527c0 .291.23.527.514.527a.52.52 0 00.513-.527.52.52 0 00-.513-.527zm-3.081 0a.52.52 0 00-.514.527c0 .291.23.527.514.527a.52.52 0 00.513-.527.52.52 0 00-.513-.527zm-3.081 6.324a.52.52 0 00-.514.527c0 .291.23.527.514.527a.52.52 0 00.513-.527.52.52 0 00-.513-.527zm6.162 0a.52.52 0 00-.514.527c0 .291.23.527.514.527a.52.52 0 00.513-.527.52.52 0 00-.513-.527zm-3.081 0a.52.52 0 00-.514.527c0 .291.23.527.514.527a.52.52 0 00.513-.527.52.52 0 00-.513-.527zm-3.081-3.162a.52.52 0 00-.514.527c0 .291.23.527.514.527a.52.52 0 00.513-.527.52.52 0 00-.513-.527zm9.243 0a.52.52 0 00-.514.527c0 .291.23.527.514.527a.52.52 0 00.513-.527.52.52 0 00-.513-.527zm-3.081 0a.52.52 0 00-.514.527c0 .291.23.527.514.527a.52.52 0 00.513-.527.52.52 0 00-.513-.527zm-3.081 0a.52.52 0 00-.514.527c0 .291.23.527.514.527a.52.52 0 00.513-.527.52.52 0 00-.513-.527z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

/** @jsxImportSource theme-ui */
import DefaultFacet from "./DefaultFacet/DefaultFacet";
import { useLingui } from "@lingui/react";
import useFilterTranslations from "./useFilterTranslations";
import React from "react";
import { Text } from "@bottlebooks/gatsby-theme-base/src";

export default function GenericFacet({
  aggregation,
  definitions,
  onToggle,
  totalCount,
  count,
  ...rest
}) {
  const { i18n } = useLingui();

  const translate = useFilterTranslations(definitions);
  const visibleBuckets = aggregation.buckets?.filter(
    ({ doc_count, selected }) => doc_count > 0 || selected
  );
  const mappedValues = visibleBuckets.map(({ key, doc_count, selected }) => ({
    value: key,
    count: doc_count,
    label: translate(aggregation.name, key),
    selected,
  }));
  return (
    <DefaultFacet
      title={
        typeof aggregation.title === "function" ? (
          aggregation.title(visibleBuckets?.length)
        ) : (
          <React.Fragment>
            {i18n._(aggregation.title)}{" "}
            <Text variant="small" as="span">
              ({visibleBuckets.length})
            </Text>
          </React.Fragment>
        )
      }
      allValues={mappedValues}
      selectedValues={mappedValues
        .filter(({ selected }) => selected)
        .map(({ value }) => value)}
      onToggle={(
        /** @type {string} */ value,
        /** @type {boolean} */ isSelected
      ) =>
        onToggle(
          aggregation,
          // For now this has to find the bucket so it's compatible with the DefaultFacet API.
          // TODO Change the onToggle signature to return a bucket.
          visibleBuckets.find(({ key }) => key === value),
          isSelected
        )
      }
      totalCount={totalCount}
      pageSize={definitions[aggregation.name]?.pageSize ?? 10}
      count={count}
      {...rest}
    />
  );
}

/** @jsxImportSource theme-ui */
import { Link, P } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";

export default function Terms() {
  return (
    <P variant="small" sx={{ marginTop: 2 }}>
      <Trans>
        By continuing you agree to our{" "}
        <Link
          openInNewTab
          href="https://www.bottlebooks.me/terms-and-conditions"
        >
          Terms of Service
        </Link>{" "}
        and{" "}
        <Link openInNewTab href="https://www.bottlebooks.me/privacy-policy">
          Privacy Policy
        </Link>
        .
      </Trans>
    </P>
  );
}

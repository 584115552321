/** @jsxImportSource theme-ui */
import { P } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";

const fragment = graphql(`
  fragment BrandFounded_Profile on Profile {
    founded
  }
  # fragment BrandFounded on Bottlebooks_AbstractBrand {
  #   founded
  # }
`);

export default function BrandFounded({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const brand = useFragment(fragment, data);
  if (!brand || !brand.founded) return null;
  return (
    <Section title={<Trans>Founded</Trans>} {...rest}>
      <P variant="title">{brand.founded}</P>
    </Section>
  );
}

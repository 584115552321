/** @jsxImportSource theme-ui */
import { Box, View } from "@bottlebooks/gatsby-design-system";
import cssOverlay from "@bottlebooks/bottlebooks-site-base/src/helpers/cssOverlay";
import { SanityImage } from "sanity-image";
import toSanityImage from "../../../helpers/toSanityImage";

export default function SliderImage({
  image,
  children,
  height,
  projectId,
  alt = "Image",
  parallax = false,
  withOverlay,
}) {
  return (
    <View>
      <Box
        sx={{
          padding: 0,
          margin: 0,
          // @ts-ignore Not sure why TS complains about undefined.
          "::after": withOverlay ? cssOverlay() : undefined,
        }}
      >
        {image && (
          <SanityImage
            {...toSanityImage({ image, projectId })}
            {...image}
            loading="eager"
            width={1280}
            sizes="100vw"
            // height={Array.isArray(height) ? height[height.length - 1] : height} // TODO height doesn't work due to a bug in sanity/image-url upstream library: https://github.com/sanity-io/image-url/issues/32
            alt={alt}
            sx={{
              position: "relative",
              objectFit: "cover",
              // Always ensure the images are full-width
              width: "100%",
              height: height,
            }}
          />
        )}
      </Box>
      {children}
    </View>
  );
}

/** @jsxImportSource theme-ui */
import Card from "@bottlebooks/gatsby-design-system/src/components/Card/Card";
import { Box, Title } from "@bottlebooks/gatsby-theme-base/src";
import { graphql } from "~/gql";

/**
 *
 * @param {object} props
 * @param {object} props.flightName
 * @param {React.ReactNode} [props.children]
 * @returns
 */
export default function FlightListRow({ flightName, children, ...rest }) {
  return (
    <Card
      sx={{
        paddingRight: 5,
        flexGrow: 1,
      }}
      {...rest}
    >
      <Box
        sx={{
          marginBottom: 3,
          zIndex: "modal",
          position: "sticky",
          top: 0,
          backgroundColor: "white",
          paddingY: 2.5,
          paddingX: [2, 3],
        }}
      >
        <Box
          sx={{ border: 1, borderColor: "border", paddingY: 2, paddingX: 3 }}
        >
          <Title variant="small">{flightName || "Other"}</Title>
        </Box>
      </Box>
      <Box sx={{ paddingX: [2, 3] }}>{children}</Box>
    </Card>
  );
}

export const fragment = graphql(/* GraphQL */ `
  fragment bb_FlightListRow on Bottlebooks_RegisteredProduct {
    flightName
  }
`);

/** @jsxImportSource theme-ui */
import { Flex, Link, Text, Title } from "@bottlebooks/gatsby-theme-base/src";
import getOnAccentColor from "@bottlebooks/bottlebooks-site-base/src/helpers/getOnAccentColor";
// import { graphql } from "gatsby";
import getUrlFromPageReference from "../../helpers/getUrlFromPageReference";

/**
 *
 * @param {object} props
 * @param {JSX.Element} props.title
 * @param {Array<{_key: string, name: string, page: object}>} props.menuItems
 * @param {string} props.sectionVariant
 * @param {'left' | 'center' | 'right'} props.alignment
 * @returns
 */
export default function MenuItemBlock({
  title,
  menuItems,
  sectionVariant,
  ...rest
}) {
  return (
    <Flex direction="column" gap={2} {...rest}>
      {title && (
        <Title
          variant="small"
          sx={(theme) => {
            return {
              marginBottom: 2,
              color:
                sectionVariant === "accent"
                  ? getOnAccentColor(theme.colors.accent)
                  : "inherit",
            };
          }}
        >
          {title}
        </Title>
      )}
      {menuItems?.map((menuItem) => {
        const url = getUrlFromPageReference(menuItem.page);
        return (
          <Link key={menuItem._key} to={url}>
            <Text
              sx={(theme) => {
                return {
                  color:
                    sectionVariant === "accent"
                      ? getOnAccentColor(theme.colors.accent)
                      : "inherit",
                };
              }}
            >
              {menuItem.name}
            </Text>
          </Link>
        );
      })}
    </Flex>
  );
}

// export const fragment = graphql`
//   fragment SanityMenuItemBlockFragment on SanityMenuItemBlock {
//     title
//     menuItems {
//       _key
//       name
//       page {
//         ...SanityPageReference
//       }
//     }
//   }
// `;

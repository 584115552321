/** @jsxImportSource theme-ui */
import { FormattedText, P, Text } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import React from "react";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";

const fragment = graphql(`
  fragment WineVineyard on Product {
    __typename
    ... on Wine {
      vineyard
      vineyardInformation
      pruning
      training
      vineDensity
      yield
      vineyardAge
      vineyardElevation
      soilType
      soilTypes {
        soilType
        percentage
      }
    }
  }
`);

export default function WineVineyard({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const product = useFragment(fragment, data);
  const wine = product.__typename === "Wine" ? product : null;
  if (!wine) return null;
  if (
    !wine.vineyardInformation &&
    !wine.vineyard &&
    !wine.pruning &&
    !wine.training &&
    !wine.vineDensity &&
    !wine.yield &&
    !wine.vineyardAge &&
    !wine.vineyardElevation
  ) {
    return null;
  }
  // If only the vineyard name is set:  Display vineyard name in the content, as large text.
  // If the vineyardInformation is set, display it in the content
  return (
    <Section
      title={
        <React.Fragment>
          <Trans>Vineyard</Trans>
          {wine.vineyard && wine.vineyardInformation && (
            <React.Fragment>: {wine.vineyard}</React.Fragment>
          )}
        </React.Fragment>
      }
      {...rest}
    >
      {wine.vineyardInformation ? (
        <FormattedText variant="large" text={wine.vineyardInformation} />
      ) : (
        // If there's no vineyard description, we display the vineyard as a title.
        <P variant="title">{wine.vineyard}</P>
      )}
      <VineyardValue title={<Trans>Pruning</Trans>}>
        {wine.pruning}
      </VineyardValue>
      <VineyardValue title={<Trans>Training</Trans>}>
        {wine.training}
      </VineyardValue>
      <SoilTypes data={data} />
      <VineyardValue title={<Trans>Vine density</Trans>}>
        {wine.vineDensity}
      </VineyardValue>
      {wine.yield && (
        <VineyardValue title={<Trans>Yield</Trans>}>
          {wine.yield} hl/ha
        </VineyardValue>
      )}
      {wine.vineyardAge && (
        <VineyardValue title={<Trans comment="The vineyard's age.">Age</Trans>}>
          {wine.vineyardAge}
        </VineyardValue>
      )}
      {wine.vineyardElevation && (
        <VineyardValue
          title={<Trans comment="The vineyard's elevation.">Elevation</Trans>}
        >
          {wine.vineyardElevation}
        </VineyardValue>
      )}
    </Section>
  );
}

function SoilTypes({ data, ...rest }: { data: FragmentType<typeof fragment> }) {
  const wine = useFragment(fragment, data);
  if (!wine.soilTypes?.filter(({ soilType }) => Boolean(soilType)).join("")) {
    return null;
  }
  return (
    <VineyardValue title={<Trans>Soil</Trans>} {...rest}>
      {wine.soilTypes
        .map(
          ({ soilType, percentage }) =>
            `${percentage ? `${percentage}% ` : ""}${soilType}`
        )
        .join(", ")}
    </VineyardValue>
  );
}

function VineyardValue({
  title,
  value = undefined,
  children,
}: {
  title: React.ReactNode;
  value?: string | null;
  children: React.ReactNode;
}) {
  // Only if the raw value is null or empty we have supplied it.
  if (value === null || value === "") return null;
  if (!children) return null;
  return (
    <Text variant="large">
      {title}: {children}
    </Text>
  );
}

/** @jsxImportSource theme-ui */
import { FormattedText, Text } from "@bottlebooks/gatsby-design-system";
import { Flex, Link } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { ArrowSquareOut } from "@phosphor-icons/react";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";

const fragment = graphql(`
  fragment ProductDescription on Product {
    description
    consumerDescription
    website(format: URL)
  }
`);

export default function ProductDescription({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const product = useFragment(fragment, data);
  if (!hasContent(data)) return null;
  return (
    <Section title={<Trans>Description</Trans>} {...rest}>
      <FormattedText
        variant="large"
        text={product.description || product.consumerDescription}
      />
      {product.website && (
        <Link
          href={product.website}
          target="_blank"
          rel="noopener noreferrer"
          variant="text"
        >
          <Flex align="center" gap={2}>
            <Text>
              <Trans>Read more on company website</Trans>
            </Text>
            <ArrowSquareOut />
          </Flex>
        </Link>
      )}
    </Section>
  );
}
ProductDescription.hasContent = hasContent;

function hasContent(data: FragmentType<typeof fragment>) {
  const product = useFragment(fragment, data);
  if (!product) return false;
  if (product.description) return true;
  if (product.consumerDescription) return true;
  return false;
}

/** @jsxImportSource theme-ui */
import {
  Box,
  Flex,
  Link,
  Text,
  Title,
} from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";
import CoverImage from "../CoverImage";
import BrandLogo from "./BrandLogo";

const fragment = graphql(`
  fragment FeaturedBrand on Profile {
    name
    countryName: country(format: LOCALIZED)
    region
    ...BrandLogo
    # ...useLink_exhibitor_bb_AbstractBrand
    mainImage {
      fluid(maxWidth: 660, maxHeight: 300, crop: FILL, gravity: FACES_AUTO) {
        src
        srcSet
        sizes
        base64
        aspectRatio
      }
    }
  }

  # fragment FeaturedBrand on AbstractBrand {
  #   name
  #   countryName: country(full: true)
  #   region
  #   ...BrandLogoDefault
  #   ...useLink_exhibitor_AbstractBrand
  #   mainImage {
  #     fluid(maxWidth: 660, maxHeight: 300, crop: FILL, gravity: FACES_AUTO) {
  #       src
  #       srcSet
  #       sizes
  #       base64
  #       aspectRatio
  #     }
  #   }
  # }
`);

/**
 * A single brand displayed with text and image side by side.
 */
export default function FeaturedBrand({
  brand: data,
  to,
  ...rest
}: {
  brand: FragmentType<typeof fragment>;
  to?: string;
}) {
  const brand = useFragment(fragment, data);
  return (
    <Flex
      as={Link}
      to={to}
      align="stretch"
      direction="row"
      sx={{
        color: "text",
        overflow: "hidden",
        // Hover effects:
        ":hover": {
          color: "lightText",
          h4: { color: "secondary" },
          ".bottlebooks-coverImage img": { transform: "scale(1.05)" },
        },
        // Mirror the odd items to display the name first.
        "&:nth-of-type(odd)": { flexDirection: "row-reverse" },
      }}
      {...rest}
    >
      <Box
        sx={{
          backgroundColor: "nuanced",
          position: "relative",
          width: "calc(50% + 20px)",
        }}
      >
        {brand.mainImage && (
          <CoverImage
            {...brand.mainImage}
            alt={brand.name}
            imgStyle={{ transition: null }}
            sx={{
              minHeight: 150,
              img: {
                transition: (theme) =>
                  // @ts-ignore
                  `${theme.transition.transform}, ${theme.transition.opacity}`,
              },
            }}
            className="bottlebooks-coverImage"
          />
        )}
      </Box>
      <Flex direction="column" align="stretch" sx={{ zIndex: "raised" }}>
        <Box sx={{ backgroundColor: "white", flex: "1", marginX: "-20px" }} />
        <Box
          sx={{
            marginX: "-20px",
            border: "20px solid",
            borderColor: "white",
            borderLeftColor: "transparent",
            borderRightColor: "transparent",
          }}
        />
        <Box sx={{ backgroundColor: "white", flex: "1", marginX: "-20px" }} />
      </Flex>
      <Flex
        direction="column"
        align="center"
        justify="center"
        sx={{ paddingX: 3, paddingY: 4, textAlign: "center", zIndex: "raised" }}
        // Fall back to 50% width if calc isn't supported.
        css={{ width: ["50%", "calc(50% - 20px)"] }}
      >
        <BrandLogo size="default" brand={brand} />
        <Title
          variant="small"
          sx={{
            marginTop: 3,
            marginBottom: 1,
            color: "primary",
            overflow: "visible",
            // This is ugly
            // could not get variant={["smallest", null, "small"]} to work
            // overriden manually
            fontSize: ["20px", null, "24px"],
            lineHeight: ["28px", null, "36px"],
          }}
        >
          {brand.name}
        </Title>
        <Text variant="small">
          {[brand.countryName, brand.region].filter(Boolean).join(", ")}
        </Text>
      </Flex>
    </Flex>
  );
}

const sizes = {
  xxsmall: 10,
  xsmall: 15,
  small: 20,
  smallMedium: 30,
  medium: 40,
  mediumLarge: 50,
  large: 60,
  xlarge: 120,
} as const;
export default sizes;

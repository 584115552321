/** @jsxImportSource theme-ui */
import {
  BookmarkIcon,
  Box,
  Button,
  Flex,
  Text,
} from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";

export default function ToggleFavorite({
  isToggled,
  onClick,
  onDark = false,
  variant = "large",
  ...rest
}) {
  return (
    <Button
      variant="text"
      onClick={onClick}
      sx={variant === "large" ? null : { borderRadius: "round", padding: 2 }}
      {...rest}
    >
      <Flex gap={2} align="center">
        <FavoriteIcon
          isToggled={isToggled}
          sx={(theme) => ({
            transition: `${theme.transition?.boxShadow}, ${theme.transition?.color}`,
            ":hover": { boxShadow: "border" },
          })}
        />{" "}
        {variant === "large" && (
          <Box as={Text}>
            {isToggled ? (
              <Trans>Remove from my list</Trans>
            ) : (
              <Trans>Add to my list</Trans>
            )}
          </Box>
        )}
      </Flex>
    </Button>
  );
}

function FavoriteIcon({ isToggled = false, ...rest }) {
  return (
    <Box
      as="span"
      sx={{
        display: "inline-grid",
        borderRadius: "round",
        color: isToggled ? "primary" : "lightText",
        transition: "color",
        ":hover": { color: "secondary" },
      }}
      {...rest}
    >
      <BookmarkIcon
        variant={isToggled ? "filled" : "hollow"}
        size="smallMedium"
      />
    </Box>
  );
}

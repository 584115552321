/** @jsxImportSource theme-ui */
import { Text } from "@bottlebooks/gatsby-design-system";
import uniq from "lodash/uniq";
import React from "react";
import { FragmentType, graphql, useFragment } from "~/gql";
import Flag from "../Flag/Flag";

const fragment = graphql(`
  fragment ProductRegion on Product {
    countryName: country(format: LOCALIZED)
    countryCode: country(format: RAW)
    denomination
  }
`);

export default function ProductRegion({
  data,
  children,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
  children?: React.ReactNode;
}) {
  if (!children) children = <DefaultContent data={data} />;
  return (
    <Text variant="smallest" sx={{ color: "text" }} {...rest}>
      {children}
    </Text>
  );
}

export function DefaultContent({
  data,
}: {
  data: FragmentType<typeof fragment>;
}) {
  const product = useFragment(fragment, data);
  if (!product) return null;
  return (
    <React.Fragment>
      <Flag
        alt=""
        country={product.countryCode || ""}
        sx={{ marginRight: 1, verticalAlign: "baseline" }}
      />
      <span>
        {uniq([product.countryName, product.denomination])
          .filter(Boolean)
          .join(", ")}
      </span>
    </React.Fragment>
  );
}

const badgeVariants = {
  neutral: {},
  default: {
    backgroundColor: 'brand.lightestBlue',
    color: 'lightText',
  },

  primary: {
    backgroundColor: 'primary',
    color: 'onPrimary',
  },

  secondary: {
    backgroundColor: 'onPrimary',
    color: 'primary',
  },

  success: {
    backgroundColor: 'brand.successGreen',
    color: 'onDark',
    paddingX: 2,
    variant: 'label.small',
  },
};

export default badgeVariants;

/** @typedef {keyof badgeVariants} BadgeVariant */

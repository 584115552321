/** @jsxImportSource theme-ui */
import { Box, VideoIcon } from "@bottlebooks/gatsby-design-system";

/**
 *
 * @param {object} props
 * @param {string} [props.backgroundColor]
 * @param {string} [props.className]
 */
export default function VideosIcon({ backgroundColor = "#fff", className }) {
  return (
    <Box
      sx={{
        borderRadius: "round",
        backgroundColor,
        width: 24,
        height: 24,
        paddingTop: "2px",
        textAlign: "center",
      }}
    >
      <VideoIcon size="xsmall" className={className} />
    </Box>
  );
}

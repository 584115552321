/** @jsxImportSource theme-ui */
import { Box, Link, SemiTitle } from "@bottlebooks/gatsby-theme-base/src";

export default function FacebookVideo({ videoId, title, url, ...rest }) {
  // I used the simpler iframe code from https://developers.facebook.com/docs/plugins/embedded-video-player/#embed_html
  return (
    <Box {...rest}>
      <SemiTitle>
        <Link
          href={url}
          variant="text"
          target="_blank"
          rel="noopener noreferrer"
        >
          {title}
        </Link>
      </SemiTitle>
      <Box
        sx={{
          paddingBottom: `${(9 / 16) * 100}%`,
          position: "relative",
        }}
      >
        <iframe
          title={title}
          src={`https://www.facebook.com/facebook/videos/${videoId}`}
          width="640"
          height="360"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
          }}
        ></iframe>
      </Box>
    </Box>
  );
}

/** @jsxImportSource theme-ui */
import {
  Button,
  Grid,
  Image,
  Link,
  Text,
} from "@bottlebooks/gatsby-theme-base/src";
import type { CloudinaryFixed } from "@bottlebooks/graphql-server/src";
import { Plural, Trans } from "@lingui/macro";
import React from "react";
import { FragmentType, graphql, useFragment } from "~/gql";
import GalleryLightbox from "./GalleryLightbox.next";

const fragment = graphql(`
  fragment Gallery on UploadedFile {
    sourceUrl
    ...Gallery_GalleryImage
  }
`);

export default function Gallery({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>[] | undefined | null;
}) {
  // 12 is the optimal number for a grid (12 is the lowest common multiple of 2, 3, and 4):
  // - mobile:  6x2 (2 images per row, 6 rows)
  // - tablet:  4x3 (3 images per row, 4 rows)
  // - desktop: 3x4 (4 images per row, 3 rows)
  const galleryImages = useFragment(fragment, data);
  const maxImages = 12;
  const images = galleryImages?.filter((image) => Boolean(image?.sourceUrl));
  const [isExpanded, expand] = React.useState(false);
  const [currentIndex, showIndex] = React.useState<null | number>(null);
  if (!images?.length) return null;
  const mayExpand = images.length > maxImages;
  const nextIndex =
    currentIndex == null || !images?.length
      ? 0
      : (currentIndex + 1) % images.length;
  const previousIndex =
    currentIndex == null || !images?.length
      ? 0
      : (currentIndex + images.length - 1) % images.length;

  return (
    <React.Fragment>
      <Grid
        gap={2}
        sx={{
          gridTemplateColumns: `repeat(auto-fill, minmax(180px, 1fr))`,
          // We limit to only three rows if the grid isn't expanded. This works in mobile and desktop.
          gridTemplateRows: "auto auto auto",
          gridAutoRows: isExpanded ? undefined : "0",
          rowGap: 0,
        }}
        {...rest}
      >
        {images
          .slice(0, isExpanded ? undefined : maxImages)
          .map((image, index) =>
            image.sourceUrl ? (
              <GalleryImage
                key={image.sourceUrl}
                data={image}
                sx={{ marginBottom: 2, overflow: "hidden" }}
                onClick={() => showIndex(index)}
              />
            ) : null
          )}
      </Grid>
      {mayExpand && !isExpanded && (
        <Button variant="outline" onClick={() => expand(true)}>
          <Plural
            value={images.length}
            one="Show # image"
            other="Show all # images"
          />
        </Button>
      )}
      {isExpanded && (
        <Button variant="outline" onClick={() => expand(false)}>
          <Trans>Show less</Trans>
        </Button>
      )}
      {currentIndex == null ? null : (
        <GalleryLightbox
          sx={{ fontFamily: "text", color: "red" }}
          current={images[currentIndex]}
          next={images[nextIndex]}
          previous={images[previousIndex]}
          onClose={() => showIndex(null)}
          onPrevious={() => showIndex(previousIndex)}
          onNext={() => showIndex(nextIndex)}
        />
      )}
    </React.Fragment>
  );
}

const galleryImageGragment = graphql(`
  fragment Gallery_GalleryImage on UploadedFile {
    title
    publicId
    image: fluid(maxWidth: 360, maxHeight: 360, crop: FILL) {
      src
      srcSet
      sizes
      width
      height
      aspectRatio
    }
    ...GalleryLightbox
  }
`);

function GalleryImage({
  data,
  full,
  fullPreview,
  description,
  className,
  onClick,
  to,
  ...rest
}: {
  data: FragmentType<typeof galleryImageGragment>;
  image: CloudinaryFixed | null;
  description: string | null;
  fullPreview: { src?: string } | null;
  full: { src?: string; sizes?: string } | null;
  className?: string;
  onClick?: () => void;
  to?: string;
}) {
  const file = useFragment(galleryImageGragment, data);
  if (!file.image?.src) return null;

  return (
    <Link
      variant="text"
      className={className}
      onClick={onClick}
      to={to}
      sx={{
        position: "relative",
        display: "block",
        transition: "outline",
        outline: "2px solid",
        outlineColor: "transparent",
        ":hover:is(button,a[href]), :focus:is(button,a[href])": {
          outlineColor: "primary",
        },
      }}
    >
      <Image
        alt={file.title || ""}
        title={file.title}
        // @ts-ignore We check src already, but the other props can safely be ignored
        fluid={{
          ...file,
          ...file.image,
          sizes: "(max-width: 360px) 100vw, (max-width: 40em) 50vw, 272px",
        }}
        {...rest}
      />
      {file.title && (
        <Text
          variant="small"
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            padding: 2,
            backgroundColor: "overlay",
            color: "onDark",
          }}
          // Clamp the text to 3 lines in browsers that support it (Chrome/Firefox), falling back to 45% of the image height.
          // @ts-ignore lineClamp needs to be set to a string because theme-ui changes it to pixels. That doesn't match the type of lineClamp.
          css={{
            maxHeight: "45%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            // The Webkit hack:
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            // Standard line clamping:
            lineClamp: "3",
          }}
        >
          {file.title}
        </Text>
      )}
    </Link>
  );
}

/** @jsxImportSource theme-ui */
import useUser from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUser";
import type { UserProfileSchema } from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUserProfile";
import useUserProfile from "@bottlebooks/gatsby-plugin-firebase-auth/src/useUserProfile";
import { useEffect, useState } from "react";
import useLocale from "../../useLocale";
import { useCollection } from "../SiteSearch/useEventData";
import type { RequestMeetingButtonProfileFormSchema } from "./RequestMeetingButton.ProfileForm.next";
import type { RequestMeetingButtonTimeFormSchema } from "./RequestMeetingButton.TimeForm.next";
import useSubmitMeetingRequest from "./RequestMeetingButton.useSubmit";

export type ErrorActions = {
  message: string | JSX.Element;
  title?: string | JSX.Element;
  onRetry?: () => void;
};

type MeetingRequestState =
  | ["LOADING"]
  | ["LOGIN"]
  | ["SELECT_DATE"]
  | ["REVIEW_PROFILE"]
  | ["SUBMITTING"]
  | ["SUCCESS"]
  | ["ERROR", string | JSX.Element];

export default function useMeetingRequestState({
  recipientId,
}: {
  recipientId: string;
}) {
  const [user, isLoading, error] = useUser();
  const { collectionId } = useCollection();
  const locale = useLocale();
  if (!locale) throw new Error("No locale defined.");
  const isAnonymous = !user || user.isAnonymous;
  const initialState: MeetingRequestState = isLoading
    ? ["LOADING"]
    : isAnonymous
    ? ["LOGIN"]
    : ["SELECT_DATE"];
  const [state, setState] = useState<MeetingRequestState>(initialState);
  const { userProfile } = useUserProfile();
  const [meeting, setMeeting] = useState<
    RequestMeetingButtonTimeFormSchema & RequestMeetingButtonProfileFormSchema
  >({
    day: "",
    start: "",
    ...toProfileFormSchema(userProfile),
  });

  // Handle changes in the user loading state.
  useEffect(() => {
    if (error?.message) return setState(["ERROR", error.message]);
    if (isLoading) return setState(["LOADING"]);
    if (isAnonymous) return setState(["LOGIN"]);
    setState(["SELECT_DATE"]);
  }, [isLoading, isAnonymous, error?.message]);
  // Handle changes in the user profile (loading or editing).
  useEffect(() => {
    setMeeting((meeting) => ({
      ...meeting,
      ...toProfileFormSchema(userProfile),
    }));
  }, [userProfile]);
  const submit = useSubmitMeetingRequest({
    onSuccess: () => setState(["SUCCESS"]),
    onError: (message) => setState(["ERROR", message]),
  });
  return {
    state,
    meeting,
    onContinue: async (
      values:
        | RequestMeetingButtonTimeFormSchema
        | RequestMeetingButtonProfileFormSchema
    ) => {
      switch (state[0]) {
        case "SELECT_DATE":
          setMeeting((meeting) => ({
            ...meeting,
            ...values,
          }));
          return setState(["REVIEW_PROFILE"]);
        case "REVIEW_PROFILE": {
          const updatedMeeting = { ...meeting, ...values };
          setMeeting(updatedMeeting);
          try {
            if (!meeting) throw new Error("No meeting time was provided.");
            // Double-check that you haven't logged out in the meantime.
            if (!userProfile || !user) {
              throw new Error("You are not logged in.");
            }
            // Get a token to write to your data.
            const updateToken = await user.getIdToken();
            if (!updateToken) throw new Error("No token could be acquired.");
            setState(["SUBMITTING"]);
            return submit({
              collectionId,
              locale,
              organizerId: userProfile.uid,
              updateToken,
              useTestMode: false,
              input: {
                recipientId,
                start: updatedMeeting.start,
                displayName: updatedMeeting.displayName,
                jobTitle: updatedMeeting.jobTitle || "",
                companyName: updatedMeeting.companyName || "",
                linkedInURL: updatedMeeting.linkedInURL || "",
                telephone: updatedMeeting.telephone,
                acceptTerms: updatedMeeting.acceptTerms || true,
                makeVisibleToRepresentatives:
                  updatedMeeting.makeVisibleToRepresentatives || false,
                // @ts-expect-error - this is in the form, but is not in the data submitted to the server.
                profileImage: updatedMeeting.profileImage,
                profileImageUrl: updatedMeeting.profileImageUrl || "",
              },
            });
          } catch (error) {
            return setState(["ERROR", error.message]);
          }
        }
        default:
          throw new Error(`Unexpected state on action: ${state[0]}`);
      }
    },
    onBack: (values) => {
      switch (state[0]) {
        case "REVIEW_PROFILE":
          setMeeting((meeting) => ({ ...meeting, ...values }));
          return setState(["SELECT_DATE"]);
        case "ERROR":
          return setState(["SELECT_DATE"]);
        default:
          throw new Error(`Unexpected back on action: ${state[0]}`);
      }
    },
  };
}

function toProfileFormSchema(profile: UserProfileSchema | null | undefined) {
  return {
    useTestMode: false,
    displayName: profile?.displayName || "",
    jobTitle: profile?.jobTitle || "",
    companyName: profile?.companyName || "",
    linkedInURL: profile?.linkedInURL || "",
    telephone: profile?.telephone || "",
    profileImageUrl: profile?.profileImageUrl || "",
    makeVisibleToRepresentatives:
      profile?.visibleToRoles.includes("REPRESENTATIVE") || false,
    hasAcceptedTerms: profile?.hasAcceptedTerms || false,
  };
}

import { GraphQLClient } from "graphql-request";

import env from "~/env";

const graphQLClient = new GraphQLClient(env.VITE_GRAPHQL_URL, {
  credentials: "include",
  mode: "cors",
});

export default graphQLClient;

/** @jsxImportSource theme-ui */
import { Box, Label, Link } from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";


const fragment = graphql(`
  fragment Registration_ProducerStand on SingleRegistration {
    exhibitorId: companyId
    stand {
      name
    }
    profile {
      name
    }
  }

  # fragment ProducerStand on Producer {
  #   stand {
  #     name: title
  #   }
  # }
`);

export default function ProducerStand({
  exhibitor,
  producer: producerData,
  to,
  href,
  onClick,
  ...rest
}: {
  exhibitor: any;
  producer: FragmentType<typeof fragment>;
  to?: string;
  href?: string;
  onClick?: () => void;
}) {
  const producer = useFragment(fragment, producerData);
  const { stand, registrationCount } = producer;
  if (registrationCount === 1)
    return (
      <Label {...rest}>
        <Link to={to} onClick={onClick} href={href}>
          {[exhibitor?.stand?.name || stand?.name, exhibitor?.profile?.name]
            .filter(Boolean)
            .join(" — ")}
        </Link>
      </Label>
    );
  if (registrationCount > 1)
    return (
      <Box {...rest}>
        <Label>Presented by multiple exhibitors</Label>
        <Label>See products below</Label>
      </Box>
    );
  // This should never happen
  return null;
}

/** @jsxImportSource theme-ui */
import useAuthentication from "@bottlebooks/gatsby-plugin-firebase-auth/src/useAuthentication";
import { useTastingNote } from "@bottlebooks/bottlebooks-site-tastingnotes/src/useTastingNote";
import {
  Box,
  Button,
  CloseIcon,
  Container,
  Stack,
} from "@bottlebooks/gatsby-theme-base/src";
import { useProduct } from "@bottlebooks/bottlebooks-site-base/src/components/SiteSearch/useEventData";
import useSiteConfig from "@bottlebooks/bottlebooks-site-base/src/components/useSiteConfig";
import Dialog from "@reach/dialog";
import "@reach/dialog/styles.css";
import React from "react";
import StarIcon from "./StarIcon";
import TastingNoteForm from "./TastingNoteForm";
/**
 * @typedef {ReturnType<ReturnType<useTastingNoteButton>['getButtonProps']>} ButtonProps
 */

/**
 * @param {ButtonProps & { children: React.ReactNode; [x: string]: any; afterSubmit?: (values: any) => void }} props
 */
export default function TastingNoteButton({
  product,
  productId,
  size,
  children,
  isEnabled,
  isOpen,
  toggleEditor,
  tastingNote,
  actions,
  afterSubmit,
  ...rest
}) {
  if (!isEnabled) return null;
  return (
    <TastingNoteTrigger tastingNote={tastingNote} size={size} {...rest}>
      {children}
      {isOpen && (
        <TastingNoteDialog
          tastingNote={tastingNote}
          isOpen
          onDelete={tastingNote && (() => actions.delete())}
          onDismiss={() => toggleEditor(false)}
          onSubmit={async (values) => {
            await actions.set(values);
            if (afterSubmit) afterSubmit(values);
          }}
          productId={productId}
        />
      )}
    </TastingNoteTrigger>
  );
}
TastingNoteButton.use = useTastingNoteButton;
TastingNoteButton.Icon = TastingNoteIcon;
TastingNoteButton.Text = TastingNoteText;

/** A large outlined tasting note button with icon and text. */
TastingNoteButton.Large = LargeTastingNoteButton;

/** @param {{ productId: string; [x: string]: any }} props */
function LargeTastingNoteButton({ productId, ...rest }) {
  const { getButtonProps, getIconProps, getTextProps } = TastingNoteButton.use({
    productId,
  });
  return (
    <TastingNoteButton {...getButtonProps(rest)}>
      <TastingNoteButton.Icon {...getIconProps()} />
      <TastingNoteButton.Text {...getTextProps()} />
    </TastingNoteButton>
  );
}

/**
 *
 * @param {{ productId: string | undefined; size?: 'default' | 'compact';}} props
 * @returns
 */
export function useTastingNoteButton({ productId, size }) {
  const product = useProduct(productId);
  const [isOpen, toggleEditor] = React.useState(false);
  const { tastingNote, ...actions } = useTastingNote(product);
  const { tastingNotesEnabled } = useSiteConfig();
  const { requireAuthentication } = useAuthentication();

  return {
    tastingNote,
    getButtonProps: (
      /** @type {{ onClick?: React.MouseEventHandler<HTMLButtonElement>; [x: string]: any }} */
      props
    ) => ({
      ...props,
      productId,
      product,
      size,
      tastingNote,
      isOpen,
      toggleEditor,
      isEnabled: tastingNotesEnabled,
      onClick: (
        /** @type {React.MouseEvent<HTMLButtonElement, MouseEvent>} */
        event
      ) => {
        if (!requireAuthentication()) return;
        if (props?.onClick) props.onClick(event);
        toggleEditor(true);
      },
      actions,
    }),
    getIconProps: (props) => ({ ...props, tastingNote, variant: size }),
    getTextProps: (props) => ({ ...props, tastingNote, variant: size }),
  };
}

function TastingNoteTrigger({ tastingNote, onClick, size, children, ...rest }) {
  if (size === "compact") {
    return (
      <Button
        onClick={onClick}
        variant="text"
        sx={{
          padding: 0,
          display: "flex",
          gap: 2,
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "round",
          backgroundColor: "background",
          color: "lightText",
        }}
        {...rest}
      >
        {children}
      </Button>
    );
  }
  return (
    <Button
      onClick={onClick}
      variant="text"
      sx={{ justifyContent: ["flex-start", "center"] }}
      {...rest}
    >
      {children}
    </Button>
  );
}

/**
 *
 * @param {{
 *  variant?: 'default' | 'compact';
 *  tastingNote?: import('@bottlebooks/bottlebooks-site-tastingnotes/src/useTastingNote').TastingNote;
 *  [x: string]: any
 * }} props
 * @returns
 */
export function TastingNoteIcon({ variant, tastingNote, ...rest }) {
  if (tastingNote) {
    return <Rating rating={tastingNote.rating} {...rest} />;
  }

  return <Rating rating={0} {...rest} />;
}

function Rating({ rating, ...rest }) {
  return (
    <Stack
      direction="row"
      spacing={0}
      sx={{
        color: "lightestText",
        flex: "0 0 auto",
        ":hover": { color: "secondary" },
      }}
      {...rest}
    >
      <RatingIcon value="1" rating={rating} sx={{ paddingRight: 1 }} />
      <RatingIcon value="2" rating={rating} sx={{ paddingRight: 1 }} />
      <RatingIcon value="3" rating={rating} sx={{ paddingRight: 1 }} />
      <RatingIcon value="4" rating={rating} sx={{ paddingRight: 1 }} />
      <RatingIcon value="5" rating={rating} />
    </Stack>
  );
}

function RatingIcon({ rating, value, ...rest }) {
  return (
    <Box
      sx={(theme) => ({
        transition: `${theme.transition.transform}, ${theme.transition.color}`,
        color: rating >= value ? "primary" : "inherit",
        ":hover": { transform: "scale(1.1)" },
        ":hover ~ *": { color: "lightestText" },
      })}
      {...rest}
    >
      <StarIcon
        size="small"
        variant={rating >= value ? "filled" : "hollow"}
        aria-hidden
      />
    </Box>
  );
}

/** @param {ButtonProps} props */
function TastingNoteText({ tastingNote }) {
  return null;
  // if (tastingNote) return <Trans>Edit tasting note</Trans>;
  // return <Trans>Add tasting note</Trans>;
}

function TastingNoteDialog({
  tastingNote,
  productId,
  isOpen,
  onDismiss,
  onSubmit,
  onDelete,
  ...rest
}) {
  const product = useProduct(productId);
  return (
    <Dialog
      isOpen={isOpen}
      aria-label="Add tasting note"
      onDismiss={onDismiss}
      sx={{
        margin: 0,
        padding: 0,
        width: "100%",
        minHeight: "100%",
        zIndex: "modal",
      }}
      {...rest}
    >
      <Box sx={{ padding: "gutter", paddingBottom: 0 }}>
        <Button
          variant="text"
          onClick={onDismiss}
          sx={{ marginLeft: "auto", display: "block" }}
        >
          <CloseIcon />
        </Button>
      </Box>
      <Container>
        <TastingNoteForm
          product={product}
          tastingNote={tastingNote}
          onSubmit={(values) => {
            onSubmit(values);
            onDismiss();
          }}
          onDelete={
            onDelete &&
            (() => {
              onDelete();
              onDismiss();
            })
          }
        />
      </Container>
    </Dialog>
  );
}

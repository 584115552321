/** @jsxImportSource theme-ui */
// import { graphql } from "gatsby";
import { gql } from "urql";

/**
 * This is used only to document the fragments in a way that avoids recursiveness.
 * Pages can have menus that link to other pages.
 * @returns
 */
export default function PageReference() {
  return null;
}

// export const fragment = graphql`
//   fragment SanityPageReference on SanityBottlebooksPageOrExternalPageOrPage {
//     __typename
//     ... on SanityBottlebooksPage {
//       type
//     }
//     ... on SanityPage {
//       slug {
//         current
//       }
//     }
//     ... on SanityExternalPage {
//       title
//       type
//       url
//     }
//   }
// `;

PageReference.fragment = gql`
  fragment PageReference on BottlebooksPageOrExternalPageOrPage {
    __typename
    ... on BottlebooksPage {
      id: _id
      type
    }
    ... on Page {
      id: _id
      slug {
        current
      }
    }
    ... on ExternalPage {
      id: _id
      title
      type
      url
    }
  }
`;

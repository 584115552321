/** @jsxImportSource theme-ui */
import { useFormikContext } from "formik";
import { FragmentType, graphql, useFragment } from "~/gql";
import Meeting from "./Meeting";
import type { RequestMeetingButtonTimeFormSchema } from "./RequestMeetingButton.TimeForm.next";
import type { TimeslotData } from "./Timeslot.Field.next";
import { gql } from "@apollo/client";
import Timeslot from "./Timeslot.next";

export type RequestMeetingButtonInfoProps = {
  recipient: RequestMeetingButtonInfoData;
};

const fragment = graphql(`
  fragment RequestMeetingButtonInfo on SiteUserProfile {
    displayName
    profileImage {
      url
      publicId
    }
    collection {
      meetingTimeslots(
        experiment: "california-networking"
        experimentToken: "Used in @bottlebooks/gatsby-theme-event, added by bernhard@unserwein.at at 2023-02-25"
      ) {
        location
        isAvailable
        ...Timeslot
      }
    }
  }
`);

export default function RequestMeetingButtonInfo({
  recipient: data,
}: {
  recipient: FragmentType<typeof fragment>;
}) {
  const recipient = useFragment(fragment, data);
  const { values } = useFormikContext<RequestMeetingButtonTimeFormSchema>();
  const start = values.start;
  const timeslot = recipient.collection.meetingTimeslots.find(
    (timeslot) => timeslot.id === start
  );
  return (
    <Meeting
      meeting={{
        recipient,
        dateLabel: timeslot?.dateLabel,
        timeslotLabel: timeslot?.timeslotLabel,
      }}
    />
  );
}

export type RequestMeetingButtonInfoData = {
  displayName: string | null;
  profileImage: {
    url: string;
    publicId: string | null;
  } | null;
  collection: {
    meetingTimeslots: ({
      location: string | null;
      isAvailable: string | null;
    } & TimeslotData)[];
  };
};

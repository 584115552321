/** @jsxImportSource theme-ui */
import { Link, Text } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import useLink from "../../useLink";
import { ProducerCard } from "../ProducersList";
import DistributionContacts from "./BrandContacts/DistributionContacts.next";
import useSiteConfig from "../useSiteConfig";

export const fragment = graphql(`
  fragment RegisteredBrand_PresentedBrand on RegisteredBrand {
    brandId
    # TODO: not sure this is the correct mapping
    producerId: brandId
    companyId
    ...ProducerCard_RegisteredBrand
    ...DistributionContacts
    # ...useLink_bb_RegisteredBrand
    profile {
      distributorsCount: distributors {
        totalCount
      }
    }
  }
`);

export default function PresentedBrand({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const link = useLink();
  const brand = useFragment(fragment, data);
  const { showDistributionDetails } = useSiteConfig();
  return (
    <ProducerCard
      key={brand.brandId}
      variant="info"
      producer={brand}
      to={link.producer(brand)}
      {...rest}
    >
      {["all", "inMarket"].includes(showDistributionDetails) && (
        <DistributionContacts
          brand={brand}
          variant="card"
          sx={{
            backgroundColor: "light",
            paddingX: 3,
            paddingBottom: 3,
            borderTop: 1,
            borderColor: "border",
          }}
        />
      )}
      {(["all", "global"].includes(showDistributionDetails) &&
        brand.profile?.distributorsCount?.totalCount) ??
        (0 > 0 && (
          <Text sx={{ paddingX: 3, paddingBottom: 3 }}>
            {/* Scroll to distribution section */}
            <Link
              to={`${link.producer({
                producerId: brand.brandId,
              })}#distributors`}
            >
              <Trans>See global distribution</Trans>
            </Link>
          </Text>
        ))}
    </ProducerCard>
  );
}

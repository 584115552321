import { Grid } from "@bottlebooks/gatsby-design-system";

export default function FileList({ children, width = 96, ...rest }) {
  return (
    <Grid
      gap={4}
      sx={{ gridTemplateColumns: `repeat(auto-fit, ${width}px)` }}
      {...rest}
    >
      {children}
    </Grid>
  );
}

/** @jsxImportSource theme-ui */
import { Box, Container, Flex } from "@bottlebooks/gatsby-theme-base/src";
import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import SiteSearch from "../SiteSearch/SiteSearch.next";
import Logo from "./DesktopLogo";
import MenuActions from "./MenuActions";
import MenuItem from "./MenuItem";

/**
 * @param {object} props
 * @param {object[]} props.menuItems
 * @param {string} props.landingPage
 * @param {boolean} [props.hideSearch]
 * @param {boolean} [props.isScrolledDown]
 * @param {string} props.locale
 * @returns
 */
export default function MainMenuDesktop({
  menuItems,
  event,
  landingPage,
  isScrolledDown,
  hideSearch,
  locale,
  ...rest
}: {
  menuItems: any[];
  event: any;
  landingPage: string;
  isScrolledDown?: boolean;
  hideSearch?: boolean;
  locale: string;
  [key: string]: any;
}) {
  const { i18n } = useLingui();
  return (
    <Container fluid sx={{ maxWidth: "container.fluid" }} {...rest}>
      <Flex align="center">
        <Box sx={{ marginRight: 3 }}>
          <Logo
            to={landingPage}
            isScrolledDown={isScrolledDown}
            event={event}
          />
        </Box>
        <Flex direction="column" sx={{ flexGrow: 1 }}>
          <Flex align="center" sx={{ height: "64px" }}>
            {!hideSearch && (
              <Box sx={{ position: "relative" }}>
                <SiteSearch
                  variant="large"
                  locale={locale}
                  placeholder={i18n._(t`Search for producer or wine…`)}
                  sx={{
                    width: "300px",
                    marginLeft: 2,
                    backgroundColor: "light",
                  }}
                />
              </Box>
            )}
            <MenuActions />
          </Flex>
          <Flex align="center" sx={{ height: "64px" }}>
            {menuItems.map((item, index) => (
              <MenuItem
                key={index}
                {...item}
                className="bb-menu-item"
                sx={{ width: ["48%", "48%", "initial", "initial"] }}
              />
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Container>
  );
}

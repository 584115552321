/** @jsxImportSource theme-ui */
import {
  Box,
  Container,
  Link,
  Title,
} from "@bottlebooks/gatsby-theme-base/src";
import useEventData from "@bottlebooks/bottlebooks-site-base/src/components/SiteSearch/useEventData";
import useLink from "@bottlebooks/bottlebooks-site-base/src/useLink";
import { Trans } from "@lingui/macro";

export default function ExploreAllProductsBanner() {
  const data = useEventData();
  const link = useLink();
  return (
    <Box sx={{ backgroundColor: "nuanced", paddingY: 2 }}>
      <Container>
        <Title variant="large">
          <Link
            to={link.products()}
            sx={{ display: "block", paddingY: [4, 5] }}
          >
            <Trans>Explore all {data.products?.length} products</Trans>
          </Link>
        </Title>
      </Container>
    </Box>
  );
}

/** @jsxImportSource theme-ui */
import { Box, Text } from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";

const fragment = graphql(`
  fragment ProductLabel_RegisteredProduct on RegisteredProduct {
    flightName
    productLabel
  }
`);

export default function ProductLabel({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const product = useFragment(fragment, data);
  const badgeLabel = [product.flightName, product.productLabel]
    .filter(Boolean)
    .join(" ");
  if (!badgeLabel) return null;
  return (
    <Box
      sx={{
        paddingY: 1,
        paddingX: 3,
        backgroundColor: "light",
        borderRadius: 999,
        display: "inline-block",
        border: 1,
        borderColor: "border",
      }}
      {...rest}
    >
      <Text>{badgeLabel}</Text>
    </Box>
  );
}

import {
  useExhibitors,
  useProducts,
} from "@bottlebooks/bottlebooks-site-base/src/components/SiteSearch/useEventData";
import { useFavorites } from ".";

export default function useEnrichedFavorites() {
  const { favorites } = useFavorites();
  const products = useProducts();
  const exhibitors = useExhibitors();
  if (!favorites) return null;
  return favorites.map((favorite) => {
    switch (favorite.type) {
      case "product": {
        return {
          ...favorite,
          ...products.findById(favorite.id),
        };
      }
      case "registration":
      default: {
        return {
          ...favorite,
          ...exhibitors.findById(favorite.id),
          exhibitorId: favorite.id,
        };
      }
    }
  });
}

/** @jsxImportSource theme-ui */
import { Label } from "@bottlebooks/gatsby-design-system";
import { Col, Link } from "@bottlebooks/gatsby-theme-base/src";

export default function RelatedLink({ children, to, ...rest }) {
  return (
    <Col>
      <Link to={to} sx={{ display: "block", paddingY: 2 }} {...rest}>
        <Label>{children}</Label>
      </Link>
    </Col>
  );
}

/** @jsxImportSource theme-ui */
import LoginDialog from "@bottlebooks/gatsby-plugin-firebase-auth/src/LoginDialog";
import { Box } from "@bottlebooks/gatsby-theme-base/src";
import { Helmet } from "react-helmet";
import { useCollectionLayout } from "../CollectionLayoutProvider.next";

/**
 * IMPORTANT!!! This component should not be directly shadowed because it contains
 * important technical wiring for the site.
 * Instead, it should be imported by the shadowing Component. See example code below.
 */
export default function BaseLayout({
  children,
  ...rest
}: {
  children: React.ReactNode;
}) {
  // At the moment, we're opting out of using shadowing for the favicon and apple-touch-icon
  const data = useCollectionLayout();
  const { logo, isSanity } = data;
  const iconUrl = isSanity
    ? getSanityUrl({ logoPath: logo?.asset?.path, size: 32 })
    : getCloudinaryUrl({ publicId: logo?.publicId, size: 32 });
  return (
    <Box
      sx={{ minHeight: "100vh", flexDirection: "column", display: "flex" }}
      {...rest}
    >
      <Helmet>
        {iconUrl ? (
          <link rel="icon" href={iconUrl} type="image/png"></link>
        ) : null}
        {iconSizes.map((size) => {
          const href = isSanity
            ? getSanityUrl({ logoPath: logo?.asset?.path, size })
            : getCloudinaryUrl({ publicId: logo?.publicId, size });
          if (!href) return null;
          return (
            <link
              key={size}
              rel="apple-touch-icon"
              sizes={`${size}x${size}`}
              href={href}
            ></link>
          );
        })}
        {iconUrl && <meta name="image" content={iconUrl} />}
        {iconUrl && <meta property="og:image" content={iconUrl} />}
        {iconUrl && <meta name="twitter:image" content={iconUrl} />}
      </Helmet>
      {children}
      <LoginDialog />
    </Box>
  );
}

function getSanityUrl({ size, logoPath }) {
  if (!logoPath) return null;
  return `https://cdn.sanity.io/${logoPath}?q=75&fit=max&auto=format&w=${size}&h=${size}`;
}

function getCloudinaryUrl({ size, publicId }) {
  if (!publicId) return null;
  return `https://res.cloudinary.com/bottlebooks/image/upload/c_fit,w_${size},h_${size},q_75,f_auto/${publicId}`;
}

const iconSizes = [48, 72, 96, 144, 192, 256, 384, 512];

// import BaseBaseLayout from '@bottlebooks/bottlebooks-site-base/src/components/Layout/BaseLayout';
// import React from 'react';
// import { Helmet } from 'react-helmet';
// import { jsx } from 'theme-ui';

// export default function BaseLayout({ children, ...rest }) {
//   return (
//     <React.Fragment>
//       {/* We need to inline stylesheet because we are loading these fonts directly. */}
//       <Helmet>
//         <style type="text/css">
//           {`@font-face {
//             font-family: RockfordSans;
//             font-weight: 700;
//             src: url(//discovercaliforniawines.com/wp-content/themes/dcw/fonts/RockfordSans/normal_normal_bold.woff2) format("woff");
//           }
//           @font-face {
//             font-family: RockfordSans;
//             font-weight: 400;
//             src: url(//discovercaliforniawines.com/wp-content/themes/dcw/fonts/RockfordSans/normal_normal_normal.woff2) format("woff");
//           }
//           `}
//         </style>
//       </Helmet>
//       <BaseBaseLayout {...rest}>{children}</BaseBaseLayout>
//     </React.Fragment>
//   );
// }

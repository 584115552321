/** @jsxImportSource theme-ui */
import {
  AwardIcon,
  MultilineText,
  P,
  Text,
} from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";

const fragment = graphql(`
  fragment ProductAwards on Product {
    productId
    awards {
      award
      medal
    }
    otherAwards
  }
`);

export default function ProductAwards({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const product = useFragment(fragment, data);
  if (!hasContent(data)) return null;
  return (
    <Section title={<Trans>Awards</Trans>} {...rest}>
      {product.awards?.map((award) => (
        <P key={award.award}>
          <AwardIcon sx={{ marginRight: 2 }} />
          {award.medal && (
            <Text as="span" variant="semiTitle" sx={{ marginRight: 2 }}>
              {award.medal}{" "}
            </Text>
          )}
          {award.award}
        </P>
      ))}
      <MultilineText text={product.otherAwards || ""} />
    </Section>
  );
}

function hasContent(data: FragmentType<typeof fragment>) {
  const product = useFragment(fragment, data);
  if (!product) return false;
  if (product.awards?.length) return true;
  if (product.otherAwards) return true;
  return false;
}

/** @jsxImportSource theme-ui */
import { Grid, Text } from "@bottlebooks/gatsby-design-system";
// import { graphql } from "gatsby";
import { Section } from "..";
import { ContentBlock } from "../Sections/SimpleGridSection";
import UserHelp from "../UserHelp";

export default function GridColumnBlock({
  blocks = [],
  gridTemplateRows: baseGridTemplateRows,
  sectionVariant,
  projectId,
  ...rest
}) {
  if (!blocks || blocks?.length === 0)
    return (
      <Section {...rest}>
        <UserHelp>
          <Text>Add blocks to section</Text>
        </UserHelp>
      </Section>
    );
  const gridTemplateRows =
    baseGridTemplateRows || `repeat(${blocks?.length || 1}, 1fr)`;
  return (
    <Grid columns={1} sx={{ gridTemplateRows }} {...rest}>
      {blocks?.map((block) => (
        <ContentBlock
          key={block._key}
          projectId={projectId}
          block={block}
          blocks={blocks}
          sectionVariant={sectionVariant}
          totalColumnUnits={1}
        />
      ))}
    </Grid>
  );
}

// export const fragment = graphql`
//   fragment SanityGridColumnBlockFragment on SanityGridColumnBlock {
//     blocks {
//       ...BlocksFragment
//     }
//     gridTemplateRows
//     style {
//       ...BlockStyle
//     }
//   }
// `;

/** @jsxImportSource theme-ui */
import { Box, ShoppingCartIcon } from "@bottlebooks/gatsby-design-system";

/**
 *
 * @param {object} props
 * @param {string} [props.backgroundColor]
 * @param {string} [props.className]
 */
export default function OnlineShopIcon({
  backgroundColor = "#fff",
  className,
}) {
  return (
    <Box
      sx={{
        borderRadius: "round",
        // border: 1,
        // borderColor: 'primary',
        backgroundColor,
        width: 24,
        height: 24,
        paddingTop: "3px",
        textAlign: "center",
      }}
    >
      <ShoppingCartIcon size="xsmall" className={className} />
    </Box>
  );
}

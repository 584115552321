/** @jsxImportSource theme-ui */
import { Box } from "@bottlebooks/gatsby-theme-base/src";
import {
  useExhibitors,
  useProducts,
} from "@bottlebooks/bottlebooks-site-base/src/components/SiteSearch/useEventData";
import React from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useFavorites } from "..";
import useBookmarksByExhibitor from "../useBookmarksByExhibitor";
import BookmarkedExhibitor from "./BookmarkedExhibitor";
import ExploreAllExhibitorsBanner from "./ExploreAllExhibitorsBanner";
import ListContainer from "./ListContainer";

export default function BookmarksList({ locale }: { locale: string }) {
  const { setOrder } = useFavorites();
  const exhibitors = useExhibitors();
  const products = useProducts();
  const bookmarkedExhibitors = useBookmarksByExhibitor().exhibitors;
  if (!bookmarkedExhibitors?.length) return null;
  /**
   * @param {number} sourceIndex
   * @param {number} destinationIndex
   */
  function reorderItems(sourceIndex, destinationIndex) {
    if (!bookmarkedExhibitors) return;
    if (sourceIndex === destinationIndex) return;
    /** @type {string[]} */ // @ts-ignore
    let order = bookmarkedExhibitors
      .map(({ exhibitorId }) => exhibitorId)
      .filter(Boolean);
    // Move the item to the new position.
    order.splice(destinationIndex, 0, order.splice(sourceIndex, 1)[0]);
    setOrder(order);
  }
  return (
    <DragDropContext
      onDragEnd={({ source, destination }) => {
        if (!source || !destination || !bookmarkedExhibitors) return;
        reorderItems(source.index, destination.index);
      }}
    >
      <Box>
        <Droppable isDropDisabled droppableId="1">
          {(provided) => (
            // @ts-ignore TODO forwardRef seems not to handle types correctly.
            <ListContainer
              ref={provided.innerRef}
              {...provided.droppableProps}
              sx={{ paddingTop: 3, display: "grid", gap: 5 }}
            >
              {bookmarkedExhibitors?.map(({ exhibitorId }, index) => (
                <React.Fragment>
                  <Draggable
                    isDragDisabled
                    key={exhibitorId}
                    draggableId={exhibitorId}
                    index={index}
                  >
                    {(provided) => (
                      <BookmarkedExhibitor
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        dragHandleProps={provided.dragHandleProps}
                        onMoveUp={
                          index > 0
                            ? () => reorderItems(index, index - 1)
                            : undefined
                        }
                        onMoveDown={
                          index < bookmarkedExhibitors.length - 1
                            ? () => reorderItems(index, index + 1)
                            : undefined
                        }
                        locale={locale}
                        exhibitorId={exhibitorId}
                        exhibitor={exhibitors.findById(exhibitorId)}
                        products={products.findByExhibitorId(exhibitorId)}
                      />
                    )}
                  </Draggable>
                </React.Fragment>
              ))}
              {provided.placeholder}
            </ListContainer>
          )}
        </Droppable>
        <ExploreAllExhibitorsBanner />
      </Box>
    </DragDropContext>
  );
}

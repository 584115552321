/** @jsxImportSource theme-ui */
import { gql } from "urql";

export default gql`
  fragment VideoBlockFragment on VideoBlock {
    title
    url
    style {
      ...BlockStyle
    }
  }
`;

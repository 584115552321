/** @jsxImportSource theme-ui */
import { Box } from "@bottlebooks/gatsby-design-system";
import type { ComponentPropsWithRef } from "react";
import { keyframes } from "@emotion/react";

const fade = keyframes({
  "from, to": { opacity: 0.4 },
  "50%": { opacity: 1 },
});
export default function Skeleton({
  width,
  height,
  inline,
  radius = "default",
  ...rest
}: Omit<ComponentPropsWithRef<typeof Box>, "sx"> & {
  width: string | number;
  height: string | number;
  radius?: string | number;
  inline?: boolean;
}) {
  return (
    <Box
      as={inline ? "span" : "div"}
      sx={{
        display: inline ? "inline-block" : "block",
        position: "relative",
        width,
        height,
        overflow: "hidden",
        borderRadius: radius,
        "&:before": {
          borderRadius: radius,
          content: '""',
          position: "absolute",
          inset: 0,
          backgroundColor: "white",
        },
        "&:after": {
          borderRadius: radius,
          content: '""',
          position: "absolute",
          inset: 0,
          backgroundColor: "nuanced",
          animation: `${fade} 1.5s linear infinite`,
        },
      }}
      {...rest}
    />
  );
}

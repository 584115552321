/** @jsxImportSource theme-ui */
import { Grid } from "@bottlebooks/gatsby-theme-base/src";
import { graphql } from "~/gql";
import {
  QuickLinkButton,
  QuickLinkCard,
  QuickLinkContent,
  QuickLinkText,
  QuickLinkTitle,
  SubLink,
} from "./QuickLink";

const max1 = "1fr";
const two = "repeat(2, minmax(226px, 1fr))";
const max3 = "repeat(auto-fit, minmax(304px, 1fr))";
const max4 = "repeat(auto-fit, minmax(226px, 1fr))";

const columns = {
  default: max4,
  3: [max1, null, max4],
  4: [max1, two, null, max4],
  5: max3,
};

export default function QuickLinks({ localeConfig, ...rest }) {
  if (!localeConfig?.quickLinks?.length) return null;
  const gridTemplateColumns =
    columns[localeConfig.quickLinks.length] || columns.default;
  return (
    <Grid gap={2} sx={{ gridTemplateColumns }} {...rest}>
      {localeConfig.quickLinks.map((link, index) => (
        <QuickLinkCard key={`${link.url}-${index}`}>
          <QuickLinkContent to={link.url} title={link.text}>
            <QuickLinkTitle to={link.url}>{link.title}</QuickLinkTitle>
            <QuickLinkText>{link.text}</QuickLinkText>
            <QuickLinkButton
              to={link.url}
              sx={{ paddingTop: link.links?.length ? 3 : 4 }}
            >
              {link.callToAction}
            </QuickLinkButton>
          </QuickLinkContent>
          {link.links?.map((link) => (
            <SubLink to={link.url} key={`${link.url}-${index}`}>
              {link.callToAction}
            </SubLink>
          ))}
        </QuickLinkCard>
      ))}
    </Grid>
  );
}
export const fragment = graphql(`
  fragment QuickLinks on LocaleConfig {
    quickLinks {
      ...QuickLink
    }
  }
`);

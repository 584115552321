/** @jsxImportSource theme-ui */
import { FormattedText } from "@bottlebooks/gatsby-design-system";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import Section from "../Section/Section";

const fragment = graphql(`
  fragment BrandDescription_Profile on Profile {
    shortDescription
  }
`);

export default function BrandDescription({
  data,
  ...rest
}: {
  data: FragmentType<typeof fragment>;
}) {
  const brand = useFragment(fragment, data);
  if (!brand || !brand.shortDescription) return null;
  return (
    <Section
      title={
        <Trans comment="The header of the Brand description - about the brand">
          About
        </Trans>
      }
      {...rest}
    >
      <FormattedText text={brand.shortDescription} variant="large" />
    </Section>
  );
}
